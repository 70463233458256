import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AccountContext from "account/AccountContext";
import TenantContext from 'idserver/TenantContext';
import { Avatar, Box, Button, IconButton, makeStyles, Menu, MenuItem, Typography, useTheme, withStyles, WithStyles } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import Routes from "general/helpers/Routes";
import AppHeaderStyle from "general/styles/AppHeaderStyle";
import { drawerItemHoverFocus, getDrawerItemColor } from "general/styles/AppNavDrawerStyle";
import AccountProvider, { manageUserBackupKey } from "account/AccountProvider";
import AuthenticateManageService, { AuthenticateManageServiceOption } from "idserver/helpers/AuthenticateManageService";

const useStylesElement = () => {
  const theme = useTheme();
  const drawerItemColor = getDrawerItemColor(theme);
  return makeStyles({
    itemDrawerButton: {
      color: drawerItemColor,
      '&:hover,&:focus': drawerItemHoverFocus,
      padding: '10px 8px 9px 4px',
      maxWidth: '140px',
    },
    itemDrawerButtonText: {
      fontSize: 'inherit',
      fontWeight: 500,
    },
  })();
}

export interface AccountElementProps extends WithStyles<typeof AppHeaderStyle> {
  onActionCallback?: (e: any) => void;
  isUsernameVisible?: boolean;
}

function AccountElement(props: AccountElementProps) {
  const classes = props.classes;
  const classesElement = useStylesElement();
  const accountContext = useContext(AccountContext);
  const tenantContext = useContext(TenantContext);
  const routeHistory = useHistory();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const username = accountContext?.user?.preferred_username ?? t('nav.account.profile');

  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAction(e: any) {
    handleClose();
    if (props.onActionCallback) {
      props.onActionCallback(e);
    }
  }

  async function handleManageUserClick(e: any) {
    const loginSubjectId = accountContext?.manageUser?.sub;
    if (!loginSubjectId) return;

    // Load backuped manageUser in current user session
    await AccountProvider.restoreBackupUserAsync(manageUserBackupKey);

    // Trigger IdServer re-login
    const authOption = (tenantContext?.tenant?.isMainTenant ?? false) ? AuthenticateManageServiceOption.manage : AuthenticateManageServiceOption.company;
    var authManageService = new AuthenticateManageService(authOption);
    await authManageService.loginAs(loginSubjectId, false, routeHistory);
  }

  if (accountContext?.user?.isAuthenticated) {
    return (
      <div>
        <Box display="flex" alignItems="flex-start">
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            className={classes.iconButtonAvatar}>
            {accountContext?.user?.initials ?
              <Avatar>{accountContext?.user?.initials}</Avatar> :
              <AccountCircle />}
          </IconButton>
          {(props.isUsernameVisible ?? false) ?
            <Button
              className={classesElement.itemDrawerButton}
              onClick={handleMenu}>
              <Typography noWrap className={classesElement.itemDrawerButtonText}>
                {username}
              </Typography>
            </Button> : null}
        </Box>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={handleAction}
            component={Link}
            to={Routes.Portal.Account.Profile}>
            {(props.isUsernameVisible ?? false) ?
              t('nav.account.profile')
              : username}
          </MenuItem>
          {accountContext?.manageUser ?
            <MenuItem onClick={handleManageUserClick}>
              {t('app:accountElement:buttonManageSignIn', { username: accountContext?.manageUser.preferred_username ?? '' })}
            </MenuItem> : null}
          <MenuItem
            onClick={handleAction}
            component={Link}
            to={Routes.Account.Logout}>
            {t('nav.account.logout')}
          </MenuItem>
        </Menu>
      </div>
    );
  } else {
    return (
      <Button
        color="inherit"
        component={Link}
        to={Routes.Account.Login}>
        {t('nav.account.login')}
      </Button>
    );
  }
};

export default withStyles(AppHeaderStyle)(AccountElement);