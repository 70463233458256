import React, { createContext, Component } from 'react';

export interface RouteStates {
	[location: string]: any
}

export interface RouteContextType {
	title: string;
	setTitle(value: string, isNavigateBackVisible?: boolean): void;
	isNavigateBackVisible: boolean;
	setIsNavigateBackVisible(value: boolean): void;
	setIsNavigateBackVisibleDelayed(value: boolean, delay?: number): void;
	routeStates: RouteStates;
	setRouteState(location: string, value: any): void;
	clearRouteState(location: string): void;
}

const defaultRouteContext = {
	title: '',
	setTitle: (value: string, isNavigateBackVisible?: boolean) => { },
	isNavigateBackVisible: false,
	setIsNavigateBackVisible: (value: boolean) => { },
	setIsNavigateBackVisibleDelayed: (value: boolean, delay?: number) => { },
	routeStates: {} as RouteStates,
	setRouteState: (locaiton: string, value: any) => { },
	clearRouteState: (location: string) => { }
} as RouteContextType;

const RouteContext = createContext(defaultRouteContext);

class RouteContextProvider extends Component {
	state = {
		title: '',
		isNavigateBackVisible: false,
		routeStates: {} as RouteStates
	}

	setTitle = (value: string, isNavigateBackVisible?: boolean): void => {
		if (isNavigateBackVisible !== undefined) {
			this.setState({
				title: value,
				isNavigateBackVisible: isNavigateBackVisible
			});
		} else {
			this.setState({
				title: value
			});
		}
	}

	setIsNavigateBackVisible = (value: boolean): void => {
		this.setState({ isNavigateBackVisible: value });
	}

	setIsNavigateBackVisibleDelayed = (value: boolean, delay?: number): void => {
		// Set slightly delayed, otherwise router overwrites when setting the title
		setTimeout(() => this.setIsNavigateBackVisible(value), delay ?? 1);
	}

	setRouteState = (location: string, value: any): void => {
		const currentRoutes = this.state.routeStates;
		currentRoutes[location] = value;
		this.setState({
			routeStates: currentRoutes
		});
	}

	clearRouteState = (location: string): void => {
		// Deletes only the routeState for the location. 
		// Deliberately does not trigger any rendering.
		const currentRoutes = this.state.routeStates;
		currentRoutes[location] = undefined;
	}

	render() {
		return (
			<RouteContext.Provider value={{
				...this.state,
				setTitle: this.setTitle,
				setIsNavigateBackVisible: this.setIsNavigateBackVisible,
				setIsNavigateBackVisibleDelayed: this.setIsNavigateBackVisibleDelayed,
				setRouteState: this.setRouteState,
				clearRouteState: this.clearRouteState
			} as RouteContextType}>
				{this.props.children}
			</RouteContext.Provider>
		);
	}
}

export default RouteContext;
export { RouteContextProvider };