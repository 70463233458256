import BaseGroup, { BaseRightGroup } from "./BaseGroup";

class CompanyGroup extends BaseGroup {

  constructor(dataGroup?: any) {
    if (dataGroup) {
      super(dataGroup);
    } else {
      super();
    }
  }
}

class CompanyRightGroup extends BaseRightGroup {

  constructor(dataRightGroup?: any) {
    if (dataRightGroup) {
      super(dataRightGroup);
    } else {
      super();
    }
  }
}

export default CompanyGroup;
export { CompanyRightGroup };