import { createStyles, createMuiTheme, Theme, PaletteColorOptions } from '@material-ui/core/styles';
import { TypeBackground } from '@material-ui/core/styles/createPalette';
import { AppPaletteColor, getContrastColor } from 'general/helpers/ui/AppStyleHelper';

const AppDrawerWidth = 256;
//const MuiTabsBottomLineColor = '#afb2b4';

interface AppThemeOptions {
  primary?: PaletteColorOptions;
  secondary?: PaletteColorOptions;
  background?: Partial<TypeBackground>;
  portalBackground?: PaletteColorOptions;
  error?: PaletteColorOptions;
  warning?: PaletteColorOptions;
  info?: PaletteColorOptions;
  success?: PaletteColorOptions;
}

function createAppTheme(options: AppThemeOptions): Theme {
  let muiTheme = createMuiTheme({
    palette: {
      primary: options.primary,
      secondary: options.secondary,
      background: options.background ?? {
        paper: '#fff',
        default: '#fff',
      },
      portalBackground: options.portalBackground ?? {
        main: '#eaeff1',
      },
      error: options.error,
      warning: options.warning,
      info: options.info,
      success: options.success,
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });

  const primaryContrastColor = getContrastColor(AppPaletteColor.primary, muiTheme);
  const secondaryContrastColor = getContrastColor(AppPaletteColor.secondary, muiTheme);
  const portalBackgroundContrastColor = getContrastColor(AppPaletteColor.portalBackground, muiTheme);

  return {
    ...muiTheme,
    overrides: {
      MuiDrawer: {
        paper: {
          backgroundColor: muiTheme.palette.secondary.main,
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      MuiTabs: {
        root: {
          color: muiTheme.palette.portalBackground?.contrastText,
          marginLeft: muiTheme.spacing(1),
          marginRight: muiTheme.spacing(1),
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          //borderBottomColor: MuiTabsBottomLineColor,
          borderBottomColor: portalBackgroundContrastColor,
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: muiTheme.palette.secondary.main,
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
          marginLeft: 0,
          marginRight: '16px',
          minWidth: 0,
          padding: 0,
          [muiTheme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
          /*
          '&:hover': {
            backgroundColor: muiTheme.palette.secondary.light,
            '& $wrapper': {
              color: muiTheme.palette.secondary.main,
            },
          },
          */
          '&$selected': {
            '& *': {
              color: muiTheme.palette.secondary.main,
            },
          },
        },
        wrapper: {
          paddingLeft: muiTheme.spacing(1),
          paddingRight: muiTheme.spacing(1),
        }
      },
      MuiFab: {
        primary: {
          backgroundColor: muiTheme.palette.primary.main,
          '&:hover,&:focus': {
            backgroundColor: primaryContrastColor,
          }
        },
        secondary: {
          backgroundColor: muiTheme.palette.secondary.main,
          '&:hover,&:focus': {
            backgroundColor: secondaryContrastColor,
          }
        },
      },
      MuiIconButton: {
        root: {
          padding: muiTheme.spacing(1),
        },
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: secondaryContrastColor,
        },
      },
      MuiListItemText: {
        primary: {
          fontWeight: muiTheme.typography.fontWeightMedium,
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      MuiAvatar: {
        root: {
          width: 32,
          height: 32,
        },
        colorDefault: {
          color: muiTheme.palette.common.white,
          backgroundColor: muiTheme.palette.grey[400],
        }
      },
    },
  };
}

const AppStyle = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    app: {
      // Needed for scrollable childs -> e.g. customDataGrid
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      minWidth: 0,

      display: 'flex',
      flexDirection: 'column',
    },
    mainTypography: {
      color: getContrastColor(AppPaletteColor.portalBackground, theme),
    },
    main: {
      flex: 1,
      padding: theme.spacing(1),
      background: theme.palette.portalBackground?.main,
    },
    footer: {
      padding: theme.spacing(2),
      background: theme.palette.portalBackground?.main,
    },
    defaultPaper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    defaultAvatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      width: 40,
      height: 40,
    },
    titleAvatar: {
      margin: theme.spacing(0, 1, 0, 0),
      width: 30,
      height: 30,
    },
    defaultForm: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    defaultFormTitle: {
      marginBottom: theme.spacing(2),
    },
    defaultFormSubmitButton: {
      margin: theme.spacing(2, 0),
    },
    defaultFormGrid: {
      maxWidth: '600px',
    },
    defaultSearchBarGridItem: {
      maxWidth: '500px',
    },
    formCheckbox: {
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
    },
    displayCheckbox: {
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'not-allowed',
      },
    },
    formDisplayCheckbox: {
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'not-allowed',
      },
    },
    formDisplayCheckboxLabel: {
      '&:hover': {
        cursor: 'text',
      },
    },
    iconActionButton: {
      padding: 12,
      marginTop: '-8px',
      marginBottom: '-8px',
      marginRight: '-8px',
      width: 48,
      height: 48,
    },
    portalPaper: {
      padding: theme.spacing(1),
    },
    portalPaperTitleBox: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    portalPaperActionButtonsBox: {
      display: 'flex',
      flexGrow: 1,
      flexShrink: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    portalPaperContentBox: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
    },
    wrapper: {
      flexDirection: 'row',
    },
    customTabContainer: {
      flexGrow: 1,
    },
    fixedTabs: {
      backgroundColor: theme.palette.portalBackground?.main,
      position: 'sticky',

      /* MediumUp layout has two toolbars und SmallDown layout has one toolbar */
      top: '48px',
      [theme.breakpoints.up('md')]: {
        top: '96px',
      },

      /* Fix transparent backgroundColor on sticky header */
      zIndex: 2, // 

      /* Fixed the content appearing at the margins when scrolling */
      marginTop: theme.spacing(-1),
      marginLeft: '-1px',
      marginRight: '-1px',
    },
    muiDataGridRoot: {
      backgroundColor: theme.palette.common.white,
    },
  });

export default AppStyle;
export { AppDrawerWidth, createAppTheme };