import React from 'react';

export interface ButtonGroupContainerProps {
  children: React.ReactNode;
}

function ButtonGroupContainer(props: ButtonGroupContainerProps) {
  const { children } = props;

  return (
    <div style={{ overflowX: 'auto' }}>
      {children}
    </div>
  );
}

export default ButtonGroupContainer;