import BaseService from "general/helpers/api/BaseService";
import ManageGroup from "manage/users/models/ManageGroup";

class ManageGroupsService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/manage/groups`;

  public async getGroups(searchText: string, lang: string): Promise<Array<ManageGroup>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new ManageGroup(data);
    });
  }

  public async getGroup(groupId: string, lang: string): Promise<ManageGroup> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${groupId}?lang=${lang}`);
    return new ManageGroup(resp.data);
  }

  public async createGroup(group: ManageGroup): Promise<ManageGroup> {
    const resp = await (await this.useApiWithAuth())
      .post(this._serviceUrl,
        JSON.stringify(group));
    return new ManageGroup(resp.data);
  }

  public async updateGroup(group: ManageGroup): Promise<void>{
    await (await this.useApiWithAuth())
      .put(`${this._serviceUrl}/${group.groupId}`,
        JSON.stringify(group));
  }

  public async deleteGroup(groupId: string): Promise<void>{
    await (await this.useApiWithAuth())
      .delete(`${this._serviceUrl}/${groupId}`);
  }
}

export default ManageGroupsService;