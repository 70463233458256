import { AlignX, AlignY } from "general/components/customTable/utils/styleTypes";

export function useAlignX(alignX?: AlignX): string {
  switch(alignX) {
    case AlignX.center:
      return 'center';
    case AlignX.right:
      return 'flex-end';
    default:
      return 'flex-start';
  }
}

export function useAlignY(alignY?: AlignY): string {
  switch(alignY) {
    case AlignY.center:
      return 'center';
    case AlignY.bottom:
      return 'flex-end';
    default:
      return 'flex-start';
  }
}

export function useTextAlignX(alignX?: AlignX): string {
  switch(alignX) {
    case AlignX.center:
      return 'center';
    case AlignX.right:
      return 'right';
    default:
      return 'left';
  }
}

export function useWidth(width?: number): string {
  return `${width ?? 100}px`;
}

export function useFlex(width?: number): string {
  return `${width ?? 100} 0 auto`;
}
