import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import AppStyle from 'general/styles/AppStyle';
import LanguageMenu from 'general/components/app/LanguageMenu';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

export interface AppDefaultContainerProps extends WithStyles<typeof AppStyle> {
  children: React.ReactNode
}

function AppDefaultContainer(props: AppDefaultContainerProps) {
  // const classes = props.classes;
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container alignItems="flex-start" justify="flex-end" direction="row">
        <Grid item>
          <LanguageMenu />
        </Grid>
      </Grid>
      <div className="App-body">
        {props.children}
      </div>
    </React.Fragment>
  );
}

export default AppDefaultContainer;