import React, { createContext, Component } from 'react';
import AccountProvider, { manageUserBackupKey } from 'account/AccountProvider';
import AccountUser from 'account/models/AccountUser';

export type AccountContextType = {
	isInitialized: boolean;
	user: AccountUser;
	setUser: (_newUser: AccountUser) => void;
	manageUser: AccountUser | undefined;
	setManageUser: (_newManageUser: AccountUser | undefined) => void;
}

const AccountContext = createContext({
	isInitialized: false,
	user: new AccountUser(),
	setUser: (_newUser: AccountUser) => { },
	manageUser: undefined,
	setManageUser: (_newManageUser: AccountUser | undefined) => { },
} as AccountContextType);

class AccountContextProvider extends Component {
	state = {
		isInitialized: false,
		user: new AccountUser(),
		manageUser: undefined,
	}

	async componentDidMount() {
		const user = await AccountProvider.getAccountUserAsync();
		if (user !== null) {
			this.setUser(user);
		}

		const manageUserAccount = await AccountProvider.getBackupAccountUserAsync(manageUserBackupKey);
		if (manageUserAccount !== null) {
			this.setManageUser(manageUserAccount);
		}

		this.setState({ isInitialized: true });
	}

	setUser = (_newUser: AccountUser) => {
		this.setState({ user: _newUser });
	}

	setManageUser = (_newManageUser: AccountUser | undefined) => {
		this.setState({ manageUser: _newManageUser });
	}

	render() {
		return (
			<AccountContext.Provider
				value={{
					...this.state,
					setUser: this.setUser,
					setManageUser: this.setManageUser
				}}
			>
				{this.props.children}
			</AccountContext.Provider>
		);
	}
}

export default AccountContext;
export { AccountUser, AccountContextProvider };