import React, { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ResponsiveDialog, { DialogResult } from 'general/components/common/ResponsiveDialog';
import ManageUser, { ManageUserChangePasswordRequest } from 'manage/users/models/ManageUser';
import ManageUsersService from 'manage/users/helpers/ManageUsersService';
import { Box, Container, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import BoxedAlert from 'general/components/common/BoxedAlert';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export interface UserChangePasswordDialogProps {
  open: boolean;
  onClose: (dialogResult: DialogResult) => void;
  user: ManageUser | undefined;
  usersService: ManageUsersService;
}

function UserChangePasswordDialog(props: UserChangePasswordDialogProps) {
  const { open, onClose, user, usersService } = props;
  const { t } = useTranslation(['manage']);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const formMethods = useForm({
    defaultValues: {
      password: '',
      passwordRepeat: '',
    }
  });
  const { getValues, trigger } = formMethods;

  async function handleDialogClose(dialogResult: DialogResult) {
    if (dialogResult === DialogResult.ok) {
      setErrorMessage('');

      if (!await trigger()) return;

      try {
        setIsLoading(true);

        const data = getValues();
        const request = new ManageUserChangePasswordRequest();
        request.subjectId = user?.subjectId;
        request.password = data.password;
        request.passwordRepeat = data.passwordRepeat;

        await usersService.changePassword(request);

        setIsLoading(false);
        onClose(dialogResult);

      } catch (error) {
        if ((error?.response?.status ?? 0) === 400) {
          setErrorMessage(t(`manage:userDetails.userTab.changePasswordUserDialog.error.${error?.response?.data?.code}`));
        } else {
          console.error(error);
          setErrorMessage(error?.message ?? '');
        }
        setIsLoading(false);
      };
    } else {
      setIsLoading(false);
      setErrorMessage('');
      onClose(dialogResult);
    }
  }

  return (
    <ResponsiveDialog
      open={open}
      onClose={handleDialogClose}
      title={t('manage:userDetails.userTab.changePasswordUserDialog.title')}
      buttonOkContent={isLoading ? 
        t('common:loading'): 
        t('manage:userDetails.userTab.changePasswordUserDialog.buttonOk')}
      buttonOkDisabled={isLoading}>
      <Box>
        <BoxedAlert message={errorMessage} severity="error" />
        <FormProvider {...formMethods} >
          <ChangePasswordForm />
        </FormProvider>
      </Box>
    </ResponsiveDialog>
  );
}

interface ChangePasswordFormProps { }

function ChangePasswordForm(props: ChangePasswordFormProps) {
  const { t } = useTranslation(['manage']);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const { register, watch, errors } = useFormContext();

  const valRequired = t('validation:required');
  const valMinLength8 = t('validation:minLength', { minLength: 8 });
  const valMaxLength30 = t('validation:maxLength', { maxLength: 30 });
  const valpasswordsNotMatch = t('validation:passwordsNotMatch');
  return (
    <Container maxWidth="xs">
      <Box mb={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('manage:manageUser.password')}
              name="password"
              autoComplete="new-password"
              autoFocus
              type={showPassword ? "text" : "password"}
              inputRef={register({
                required: valRequired,
                minLength: { value: 8, message: valMinLength8 },
                maxLength: { value: 30, message: valMaxLength30 }
              })}
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                      tabIndex={-1}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('manage:manageUser.passwordRepeat')}
              name="passwordRepeat"
              type={showPasswordRepeat ? "text" : "password"}
              inputRef={register({
                required: valRequired,
                minLength: { value: 8, message: valMinLength8 },
                maxLength: { value: 30, message: valMaxLength30 },
                validate: (value) => value === watch('password') || valpasswordsNotMatch
              })}
              error={!!errors.passwordRepeat}
              helperText={errors.passwordRepeat ? errors.passwordRepeat.message : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                      onMouseDown={() => setShowPasswordRepeat(!showPasswordRepeat)}
                      tabIndex={-1}
                    >
                      {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default UserChangePasswordDialog;