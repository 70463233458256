import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import AccountProvider from 'account/AccountProvider';
import AccountContext, { AccountUser } from 'account/AccountContext';
import { withTranslation, WithTranslation } from 'react-i18next';
import Routes from 'general/helpers/Routes';
import Loading from 'general/views/Loading';

/* Could not be a react hook function 
	 because context has to be changed in useEffect */
class OidcLoginCallback extends Component<WithTranslation> {
	static contextType = AccountContext;
	private currentAfterLoginRedirectUrl: string | undefined;
	state = {
		redirect: false,
	};

	async componentDidMount() {
		console.log('oidc login callback triggered');

		// read redirectUrl
		this.currentAfterLoginRedirectUrl = AccountProvider.CurrentAfterLoginRedirectUrl;
		console.log('CurrentAfterLoginRedirectUrl', this.currentAfterLoginRedirectUrl);

		// reset redirectUrl
		AccountProvider.CurrentAfterLoginRedirectUrl = undefined;

		const user = await AccountProvider.handleLoginCallbackAsync();
		this.context.setUser(user ?? new AccountUser());
		if (user !== null) {
			this.setState({ redirect: true });
		}
	}

	render() {
		//const { t } = this.props;
		const RedirectElement = () => {
			return (this.state.redirect) ? (<Redirect to={this.currentAfterLoginRedirectUrl ?? Routes.Portal.General.Home} />) : null;
		}

		return (
			<div>
				<Loading />
				<RedirectElement />
			</div>
		);
	}
}

export default withTranslation()(OidcLoginCallback);