import StringHelper from "general/helpers/types/StringHelper";
import { ClientHostUrl } from "idserver/models/ClientConfig";

class CompanyTenant {
  tenantId: string | undefined;
  createDate: Date | undefined;
  isActive: boolean | undefined;
  name: string | undefined;
  description: string | undefined;
  subDomain: string | undefined;
  domain: string | undefined;
  isMainTenant: boolean | undefined;
  isSignUpAllowed: boolean | undefined;
  selectedThemeId: string | undefined;
  favicon: CompanyTenantFavicon | undefined;

  constructor(dataTenant?: any) {
    if (dataTenant) {
      this.tenantId = dataTenant.tenantId;
      this.createDate = dataTenant.createDate;
      this.isActive = dataTenant.isActive;
      this.name = dataTenant.name;
      this.description = dataTenant.description;
      this.subDomain = dataTenant.subDomain;
      this.domain = dataTenant.domain;
      this.isMainTenant = dataTenant.isMainTenant;
      this.isSignUpAllowed = dataTenant.isSignUpAllowed;
      this.selectedThemeId = dataTenant.selectedThemeId;
      this.favicon = new CompanyTenantFavicon(dataTenant.favicon);
    }
  }

  getSubDomainUrl(clientHostUrl: ClientHostUrl | undefined): string | undefined {
    if (this.subDomainParamsEmpty(clientHostUrl)) return undefined;

    return `${clientHostUrl?.scheme}://${this.subDomain}.${clientHostUrl?.authority}${clientHostUrl?.absolutePath ?? ''}`;
  }

  getSubDomainDisplayUrl(clientHostUrl: ClientHostUrl | undefined): string | undefined {
    if (this.subDomainParamsEmpty(clientHostUrl)) return undefined;

    return `${this.subDomain}.${clientHostUrl?.authority}${clientHostUrl?.absolutePathDiplay ?? ''}`;
  }

  getDomainUrl(clientHostUrl: ClientHostUrl | undefined): string | undefined {
    if (this.domainParamsEmpty(clientHostUrl)) return undefined;

    return `${clientHostUrl?.scheme}://${this.domain}${clientHostUrl?.portUrlPart}${clientHostUrl?.absolutePath}`;
  }

  getDomainDisplayUrl(clientHostUrl: ClientHostUrl | undefined): string | undefined {
    if (this.domainParamsEmpty(clientHostUrl)) return undefined;

    return `${this.domain}${clientHostUrl?.portUrlPart}${clientHostUrl?.absolutePathDiplay}`;
  }

  private subDomainParamsEmpty(clientHostUrl: ClientHostUrl | undefined): boolean {
    if (StringHelper.empty(this.subDomain) ||
      clientHostUrl === undefined ||
      clientHostUrl?.scheme === undefined ||
      clientHostUrl?.authority === undefined) return true;
    return false;
  }

  private domainParamsEmpty(clientHostUrl: ClientHostUrl | undefined): boolean {
    if (StringHelper.empty(this.domain) ||
      clientHostUrl === undefined ||
      clientHostUrl?.scheme === undefined) return true;
    return false;
  }
}

class CompanyTenantFavicon {
  fileId: string | undefined;
  name: string | undefined;

  constructor(dataFile?: any) {
    if (dataFile) {
      this.fileId = dataFile.fileId;
      this.name = dataFile.name;
    }
  }
}

export default CompanyTenant;
export { CompanyTenantFavicon }