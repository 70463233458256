import { useMemo } from "react";
import { AccountContextType } from "account/AccountContext";

export const right_wildcard: string = "*";
export const right_group_separator = "_";
export const right_Manage_Users_Users_View: string = "Manage_Users_Users_View";
export const right_Manage_Users_Users_Edit: string = "Manage_Users_Users_Edit";
export const right_Manage_Users_Users_Delete: string = "Manage_Users_Users_Delete";
export const right_Manage_Users_Groups_View: string = "Manage_Users_Groups_View";
export const right_Manage_Users_Groups_Edit: string = "Manage_Users_Groups_Edit";
export const right_Manage_Users_Groups_Delete: string = "Manage_Users_Groups_Delete";
export const right_Manage_Users_Rights_View: string = "Manage_Users_Rights_View";
export const right_Manage_Users_Rights_Edit: string = "Manage_Users_Rights_Edit";

export const right_Company_Tenants_View: string = "Company_Tenants_View";
export const right_Company_Tenants_Edit: string = "Company_Tenants_Edit";
export const right_Company_Tenants_Delete: string = "Company_Tenants_Delete";
export const right_Company_Users_Users_View: string = "Company_Users_Users_View";
export const right_Company_Users_Users_Edit: string = "Company_Users_Users_Edit";
export const right_Company_Users_Users_Delete: string = "Company_Users_Users_Delete";
export const right_Company_Users_Groups_View: string = "Company_Users_Groups_View";
export const right_Company_Users_Groups_Edit: string = "Company_Users_Groups_Edit";
export const right_Company_Users_Groups_Delete: string = "Company_Users_Groups_Delete";
export const right_Company_Users_Rights_View: string = "Company_Users_Rights_View";
export const right_Company_Users_Rights_Edit: string = "Company_Users_Rights_Edit";

export const right_Company_Files_View: string = "Company_Files_View";
export const right_Company_Files_Edit: string = "Company_Files_Edit";
export const right_Company_Files_Delete: string = "Company_Files_Delete";

export function useRight(rightKey: string, accountContext: AccountContextType): boolean {
  return useMemo(() => {
    //console.log('Init useRight', rightKey);
    return accountContext?.user?.hasRight(rightKey);
  } , [rightKey, accountContext]);
}

/*
export function useRights(rights: Array<string>): Map<string, boolean> {
  const accountContext = useContext(AccountContext);
  const hasRight: Map<string, boolean> = useMemo(() => {
    console.log('Init useRights', rights.length);
    const user = accountContext?.user;
    const values = new Map<string, boolean>();
    rights.forEach((rightKey: string) => {
      values.set(rightKey, user.hasRight(rightKey));
    });
    return values;
  }, [accountContext, rights]);

  return hasRight;
}
*/