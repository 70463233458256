import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AccountContextProvider } from 'account/AccountContext';
import AppStyle from 'general/styles/AppStyle';
import PrivateRoute from 'general/components/PrivateRoute';
import Welcome from 'general/views/Welcome';
import Test from 'general/views/Test';
import Home from 'general/views/Home';
import IdServerLogin from 'idserver/views/Login';
import IdServerLogout from 'idserver/views/Logout';
import IdServerError from 'idserver/views/Error';
import IdServerSignUp from 'idserver/views/SignUp';
import IdServerSignUpConfirm from 'idserver/views/SignUpConfirm';
import IdServerPasswordReset from 'idserver/views/PasswordReset';
import IdServerPasswordResetConfirm from 'idserver/views/PasswordResetConfirm';
import OidcLogin from 'account/views/OidcLogin';
import OidcLoginCallback from 'account/views/OidcLoginCallback';
import OidcLogout from 'account/views/OidcLogout';
import OidcLoggedOut from 'account/views/OidcLoggedOut';
import Profile from 'account/views/Profile';
import ChangePassword from 'account/views/ChangePassword';
import ManageUsers from 'manage/users/views/ManageUsers';
import UserDetails from 'manage/users/views/UserDetails';
import UserCreate from 'manage/users/views/UserCreate';
import Loading from 'general/views/Loading';
import UrlHelper from 'general/helpers/UrlHelper';
import Routes, { RouteAccount, RouteIdServer, RoutePortal, RouteCompany } from 'general/helpers/Routes';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import './general/helpers/LangProvider';
import './App.css';
import GroupDetails from 'manage/users/views/GroupDetails';
import GroupCreate from 'manage/users/views/GroupCreate';
import GroupRightsConfig from 'manage/users/views/GroupRightsConfig';
import RightDetails from 'manage/users/views/RightDetails';
import { TenantContextProvider } from 'idserver/TenantContext';
import Tenants from 'company/tenants/views/Tenants';
import TenantDetails from 'company/tenants/views/TenantDetails';
import TenantCreate from 'company/tenants/views/TenantCreate';
import { DefaultContainer, IdServerContainer, PortalContainer } from 'general/components/app/AppThemeContainers';
import TenantEmailConfirm from 'company/tenants/views/TenantEmailConfirm';
import CompanyUsers from 'company/users/views/CompanyUsers';
import CompanyUserDetails from 'company/users/views/UserDetails';
import CompanyUserCreate from 'company/users/views/UserCreate';
import CompanyGroupDetails from 'company/users/views/GroupDetails';
import CompanyGroupCreate from 'company/users/views/GroupCreate';
import CompanyGroupRightsConfig from 'company/users/views/GroupRightsConfig';
import CompanyRightDetails from 'company/users/views/RightDetails';
import CompanyFiles from 'company/files/views/Files';

interface AppProps extends WithStyles<typeof AppStyle> { }

function App(props: AppProps) {
  console.log('Init App');
  console.log('Mode', process.env.NODE_ENV);
  console.log('BaseUrl', UrlHelper.getBaseUrl());
  console.log('ApiUrl', UrlHelper.getApiUrl());

  //const classes = props.classes;

  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <TenantContextProvider>
          <AccountContextProvider>
            <Router basename={process.env.PUBLIC_URL}>
              <Switch>
                <Route path="/" exact>
                  <DefaultContainer><Welcome /></DefaultContainer>
                </Route>
                <Route path="/mytest" exact>
                  <Test />
                </Route>

                <Route path={`/${RouteAccount}/:path?`} exact>
                  <DefaultContainer>
                    <Switch>
                      <Route path={Routes.Account.Login}>
                        <OidcLogin />
                      </Route>
                      <Route path={Routes.Account.LoginOidcCallback}>
                        <OidcLoginCallback />
                      </Route>
                      <Route path={Routes.Account.Logout}>
                        <OidcLogout />
                      </Route>
                      <Route path={Routes.Account.LoggedOut}>
                        <OidcLoggedOut />
                      </Route>
                    </Switch>
                  </DefaultContainer>
                </Route>

                <Route path={`/${RoutePortal}/*`} exact>
                  <PortalContainer>
                    <Switch>
                      <PrivateRoute
                        path={Routes.Portal.Account.Profile}
                        title="app:nav.account.profile">
                        <Profile />
                      </PrivateRoute>
                      <PrivateRoute
                        path={Routes.Portal.Account.ChangePassword}
                        title="app:nav.account.changePassword"
                        isNavBackVisible={true}>
                        <ChangePassword />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Manage.UserDetails}/:subjectId/:tab?`}
                        title="app:nav.manage.userDetails"
                        setRouteTitle={false}
                        isNavBackVisible={true}>
                        <UserDetails />
                      </PrivateRoute>
                      <PrivateRoute
                        path={Routes.Portal.Manage.UserCreate}
                        title="app:nav.manage.userCreate"
                        isNavBackVisible={true}>
                        <UserCreate />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Manage.GroupDetails}/:groupId`}
                        title="app:nav.manage.groupDetails"
                        setRouteTitle={false}
                        isNavBackVisible={true}>
                        <GroupDetails />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Manage.GroupRightsConfig}/:groupId/:groupName?`}
                        title="app:nav.manage.groupRightsConfig"
                        setRouteTitle={false}
                        isNavBackVisible={true}>
                        <GroupRightsConfig />
                      </PrivateRoute>
                      <PrivateRoute
                        path={Routes.Portal.Manage.GroupCreate}
                        title="app:nav.manage.groupCreate"
                        isNavBackVisible={true}>
                        <GroupCreate />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Manage.RightDetails}/:rightId/:rightName?`}
                        title="app:nav.manage.rightDetails"
                        setRouteOptions={false}>
                        <RightDetails />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Manage.ManageUsers}/*`} exact
                        title="app:nav.manage.users">
                        <ManageUsers />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Company.Files}/:folderKey?`}
                        title="app:nav.company.files">
                        <CompanyFiles />
                      </PrivateRoute>
                      <PrivateRoute
                        path={Routes.Portal.Company.Tenants}
                        title="app:nav.company.tenants">
                        <Tenants />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Company.TenantDetails}/:tenantId/:tenantName?`}
                        title="app:nav.company.tenantDetails"
                        setRouteOptions={false}>
                        <TenantDetails />
                      </PrivateRoute>
                      <PrivateRoute
                        path={Routes.Portal.Company.TenantCreate}
                        title="app:nav.company.tenantCreate"
                        isNavBackVisible={true}>
                        <TenantCreate />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Company.UserDetails}/:subjectId/:tab?`}
                        title="app:nav.company.userDetails"
                        setRouteTitle={false}
                        isNavBackVisible={true}>
                        <CompanyUserDetails />
                      </PrivateRoute>
                      <PrivateRoute
                        path={Routes.Portal.Company.UserCreate}
                        title="app:nav.company.userCreate"
                        isNavBackVisible={true}>
                        <CompanyUserCreate />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Company.GroupDetails}/:groupId`}
                        title="app:nav.company.groupDetails"
                        setRouteTitle={false}
                        isNavBackVisible={true}>
                        <CompanyGroupDetails />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Company.GroupRightsConfig}/:groupId/:groupName?`}
                        title="app:nav.company.groupRightsConfig"
                        setRouteTitle={false}
                        isNavBackVisible={true}>
                        <CompanyGroupRightsConfig />
                      </PrivateRoute>
                      <PrivateRoute
                        path={Routes.Portal.Company.GroupCreate}
                        title="app:nav.company.groupCreate"
                        isNavBackVisible={true}>
                        <CompanyGroupCreate />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Company.RightDetails}/:rightId/:rightName?`}
                        title="app:nav.company.rightDetails"
                        setRouteOptions={false}>
                        <CompanyRightDetails />
                      </PrivateRoute>
                      <PrivateRoute
                        path={`${Routes.Portal.Company.CompanyUsers}/*`}
                        title="app:nav.company.users">
                        <CompanyUsers />
                      </PrivateRoute>
                      <PrivateRoute
                        path={Routes.Portal.General.Home}
                        title="app:nav.home">
                        <Home />
                      </PrivateRoute>
                    </Switch>
                  </PortalContainer>
                </Route>

                <Route path={`/${RouteCompany}/:path?`} exact>
                  <DefaultContainer>
                    <Switch>
                      <Route path={Routes.Company.TenantEmailConfirm}>
                        <TenantEmailConfirm />
                      </Route>
                    </Switch>
                  </DefaultContainer>
                </Route>

                <Route path={`/${RouteIdServer}/:path?`} exact>
                  <IdServerContainer>
                    <Switch>
                      <Route path={Routes.IdServer.Login}>
                        <IdServerLogin />
                      </Route>
                      <Route path={Routes.IdServer.Logout}>
                        <IdServerLogout />
                      </Route>
                      <Route path={Routes.IdServer.Error}>
                        <IdServerError />
                      </Route>
                      <Route path={Routes.IdServer.SignUp}>
                        <IdServerSignUp role="Consumer" />
                      </Route>
                      <Route path={Routes.IdServer.SignUpCompany}>
                        <IdServerSignUp role="Company" />
                      </Route>
                      <Route path={Routes.IdServer.SignUpConfirm}>
                        <IdServerSignUpConfirm />
                      </Route>
                      <Route path={Routes.IdServer.PasswordReset}>
                        <IdServerPasswordReset />
                      </Route>
                      <Route path={Routes.IdServer.PasswordResetConfirm}>
                        <IdServerPasswordResetConfirm />
                      </Route>
                    </Switch>
                  </IdServerContainer>
                </Route>
              </Switch>
            </Router>
          </AccountContextProvider>
        </TenantContextProvider>
      </Suspense>
    </div>
  );
}

export default withStyles(AppStyle)(App);
