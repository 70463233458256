import React from 'react';
import { Container, CircularProgress } from '@material-ui/core';
import DefaultPaper from 'general/components/container/DefaultPaper';

function Loading() {
	return (
		<Container component="main">
			<DefaultPaper>
				<CircularProgress />
			</DefaultPaper>
		</Container>
	);
}

export default Loading;