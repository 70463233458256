import React from 'react';
import { Box } from '@material-ui/core';
import { AlignX, AlignY, CustomTable, CustomTableCell, CustomTableHeadCell, CustomTableHeadRow, CustomTableRow } from 'general/components/customTable';

/*
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
, makeStyles
const useStyles = makeStyles({
  tableMainContainer: {
    padding: '1rem',
  },
  tableContainer: {
    //display: 'flex', flex: 1, flexDirection: 'row',
    //backgroundColor: 'grey',
    //overflowX: 'auto',
    //overflowY: 'hidden', 
    //padding: '1rem'
  },
  table: {
    //display: 'flex', 
    //flex: 1, flexDirection: 'row',
    //border: '1px solid', 
    //minWidth: '240px'

    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflowX: 'auto',
  },
  headerRowScroller: {
    position: 'sticky',
    top: '0px',

    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflowX: 'auto',
  },
  headerRow: {
    //position: 'sticky',
    //top: '0px',
    //display: 'flex', flex: 1, flexDirection: 'row',

    position: 'relative',
    verticalAlign: 'middle',
    minWidth: '240px',
    display: 'flex',
    flex: '1 0 auto',
  },
  headerCell: {
    display: 'flex', flex: '253 0 auto', justifyContent: 'flex-start', alignItems: 'flex-start',
    border: '1px solid',
    minWidth: '30px',
    //position: 'relative', 
    verticalAlign: 'inherit',
    width: '253px',
    borderColor: 'blue',
    backgroundColor: 'lightgrey',

  },
  tableBody: {
    //display: 'flex', flex: '1 0 auto', flexDirection: 'column',
    display: 'block',
    //border: '1px solid',
  },
  row: {
    display: 'flex', flex: '1 0 auto',
    minWidth: '240px', verticalAlign: 'middle',
    borderColor: 'green'
  },
  cell: {
    display: 'flex', flex: '253 0 auto', justifyContent: 'flex-start', alignItems: 'flex-start',
    border: '1px solid',
    minWidth: '30px', position: 'relative', verticalAlign: 'inherit',
    width: '253px',
    height: '700px',
    borderColor: 'black'
  },
});
*/

function Test() {
  const columns = {
    column1: {
      alignX: AlignX.left,
      alignY: AlignY.top,
      width: 160
    },
    column2: {
      alignX: AlignX.center,
      alignY: AlignY.center,
    },
    column3: {
      alignX: AlignX.right,
      alignY: AlignY.bottom,
    },
  };

  return (
    <>
      <Box>
        <CustomTable
          isLoading={false}
          //fixedTableHeader={true}
          fixedTableHeader={{ topPosition: 0 }}
          headerChildren={
            <CustomTableHeadRow>
              <CustomTableHeadCell alignX={columns.column1.alignX} alignY={columns.column1.alignY} width={columns.column1.width}>Header 1</CustomTableHeadCell>
              <CustomTableHeadCell alignX={columns.column2.alignX} alignY={columns.column2.alignY}>Header 2</CustomTableHeadCell>
              <CustomTableHeadCell alignX={columns.column3.alignX} alignY={columns.column3.alignY}>Header 3</CustomTableHeadCell>
              <CustomTableHeadCell>Header Looooooooongggggggg</CustomTableHeadCell>
            </CustomTableHeadRow>}>
          <CustomTableRow>
            <CustomTableCell alignX={columns.column1.alignX} alignY={columns.column1.alignY} width={columns.column1.width}>Cell 1</CustomTableCell>
            <CustomTableCell alignX={columns.column2.alignX} alignY={columns.column2.alignY}>Cell 2</CustomTableCell>
            <CustomTableCell alignX={columns.column3.alignX} alignY={columns.column3.alignY}>Cell 33333333333333333</CustomTableCell>
            <CustomTableCell>Cell 4</CustomTableCell>
          </CustomTableRow>
          <CustomTableRow>
            <CustomTableCell width={columns.column1.width}>Cell 11</CustomTableCell>
            <CustomTableCell>Cell 22</CustomTableCell>
            <CustomTableCell alignX={columns.column3.alignX} alignY={columns.column3.alignY}>Cell 33</CustomTableCell>
            <CustomTableCell>Cell 44</CustomTableCell>
          </CustomTableRow>
          <CustomTableRow>
            <CustomTableCell width={columns.column1.width}>Cell 11</CustomTableCell>
            <CustomTableCell>Cell 22</CustomTableCell>
            <CustomTableCell>Cell 33</CustomTableCell>
            <CustomTableCell>Cell 44</CustomTableCell>
          </CustomTableRow>
          <CustomTableRow>
            <CustomTableCell width={columns.column1.width}>Cell 11</CustomTableCell>
            <CustomTableCell>Cell 22</CustomTableCell>
            <CustomTableCell>Cell 33</CustomTableCell>
            <CustomTableCell>Cell 44</CustomTableCell>
          </CustomTableRow>
          <CustomTableRow>
            <CustomTableCell width={columns.column1.width}>Cell 11</CustomTableCell>
            <CustomTableCell>Cell 22</CustomTableCell>
            <CustomTableCell>Cell 33</CustomTableCell>
            <CustomTableCell>Cell 44</CustomTableCell>
          </CustomTableRow>
          <CustomTableRow>
            <CustomTableCell width={columns.column1.width}>Cell 11</CustomTableCell>
            <CustomTableCell>Cell 22</CustomTableCell>
            <CustomTableCell>Cell 33</CustomTableCell>
            <CustomTableCell>Cell 44</CustomTableCell>
          </CustomTableRow>
        </CustomTable>
      </Box>
      <Box>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
        <p>Text ABC dlkjfls jsfljdsf sldfjlsdjf s jsdlfjlsdj f sljflsdf</p>
      </Box>
    </>
  );
}


/*
// https://www.npmjs.com/package/sync-scroll
 //const headerRef  = React.useRef<HTMLDivElement>(null)
  //const bodyRef  = React.useRef<HTMLDivElement>(null)
  const classes = useStyles();
  //useSyncScroll([headerRef, bodyRef]);
  //<div className={classes.headerRow}>
  //<div className={classes.tableContainer}>

<div className={classes.tableMainContainer}>
      <ScrollSync>
        <div>
          <ScrollSyncPane>
            <div className={classes.headerRowScroller}>
              <div className={classes.tableBody}>
                <div className={classes.headerRow}>
                  <div className={classes.headerCell}>
                    <b>Column 1</b>
                  </div>
                  <div className={classes.headerCell}>
                    <b>Column 2</b>
                  </div>
                  <div className={classes.headerCell}>
                    <b>Column 3</b>
                  </div>
                  <div className={classes.headerCell}>
                    <b>Column 4</b>
                  </div>
                  <div className={classes.headerCell}>
                    <b>Column 5</b>
                  </div>
                </div>
              </div>
            </div>
          </ScrollSyncPane>
          <ScrollSyncPane>
            <div className={classes.table}>
              <div className={classes.tableBody}>
                <div className={classes.row}>
                  <div className={classes.cell}>
                    Value 1
                  </div>
                  <div className={classes.cell}>
                    Value 2
                  </div>
                  <div className={classes.cell}>
                    Value 3
                  </div>
                  <div className={classes.cell}>
                    Value 4
                  </div>
                  <div className={classes.cell}>
                    Value 5
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.cell}>
                    Value 11
                  </div>
                  <div className={classes.cell}>
                    Value 22
                  </div>
                  <div className={classes.cell}>
                    Value 33
                  </div>
                  <div className={classes.cell}>
                    Value 44
                  </div>
                  <div className={classes.cell}>
                    Value 55
                  </div>
                </div>
              </div>
            </div>
          </ScrollSyncPane>
        </div>
      </ScrollSync>
    </div>

 return (
    <div>
      <CssBaseline />
      Test Page
    </div>
  );

const columns = [
  {
    Header: 'Users',
    HideHeader: false,
    columns: [
      {
        Header: 'Id',
        accessor: 'subjectId',
        canResize: false,
      },
      {
        Header: 'Email',
        accessor: 'email',
        canGroupBy: true,
        canResize: false,
      },
      {
        Header: 'Name',
        accessor: 'name',
        canResize: false,
      },
      {
        Header: 'Given name',
        accessor: 'givenName',
        canResize: false,
      },
      {
        Header: 'Family name',
        accessor: 'familyName',
        canResize: false,
      },
      {
        Header: 'Phone number',
        accessor: 'phoneNumber',
        canResize: false,
      },
      {
        Header: 'Account type',
        accessor: 'roles',
        canResize: false,
      },
      {
        Header: 'Registered on',
        accessor: 'createDate',
        canResize: false,
      },

    ]
  },
  {
    Header: 'Info',
    columns: [
      {
        Header: 'Id2',
        accessor: 'subjectId2',
        canResize: false,
      },
      {
        Header: 'Email2',
        accessor: 'email2',
        canGroupBy: true,
        canResize: false,
      },
    ]
  },
];


{
        Header: 'Activated',
        accessor: 'isActive',
        canResize: false,
        Cell: ({ value }: any) =>
          (
            <Checkbox checked={value} color="default" />
          )
        ,
      },
      {
        Header: 'Email verified',
        accessor: 'isEmailVerified',
        canResize: false,
        Cell: ({ value }: any) =>
          (
            <Checkbox checked={value} disabled />
          )
      },
      
      <div style={{
        display: 'flex',
        //overflowX: 'auto',
      }}>
        <div style={{
          flex: '1 0 auto',
          minWidth: '150px',
          maxWidth: '190px',
        }}>
          <div>Sidebar</div>
        </div>
        <div style={{
          flex: '1 1 auto',
          minWidth: 0,
          // OR overflow: 'hidden',
          background: '#bcd8f5' // light blue
        }}>
          <CustomDataGrid<ManageUser>
            name={'testTable'}
            columns={columns}
            data={users}
            //defaultCanGroupBy={true}
            //disableRowSelection={false}
          />
        </div>
      </div>

const users = [
  { subjectId: '1', email: 'abc@web.de', givenName: '', familyName: '', name: 'abc gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '2', email: 'asd@web.de', givenName: '', familyName: '', name: 'asd gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: false },
  { subjectId: '3', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: false, isEmailVerified: true },
  { subjectId: '4', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: false },
  { subjectId: '5', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '6', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '7', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '8', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '9', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '10', email: 'ccc@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '11', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '12', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '13', email: 'ggg@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '14', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '15', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '16', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '17', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '18', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '19', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '20', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '21', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
  { subjectId: '22', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true, isEmailVerified: true },
] as ManageUser[];
*/


/*
  const [tabValue, setTabValue] = useState(1);

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: any) {
    setTabValue(newValue);
  }

import { AppBar, CssBaseline, Tab, Tabs } from '@material-ui/core';
import TabPanel from 'general/components/container/TabPanel';
import PersonIcon from '@material-ui/icons/Person';

<div style={{
        flexGrow: 1,
        width: '100%',
      }}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          //scrollButtons="on"
          >
            <Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> Tabpanel 1 </div>} value={1} />
            <Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> Tabpanel 2 </div>} value={2} />
            <Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> Tabpanel 3 </div>} value={3} />
            <Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> Tabpanel 1 </div>} value={1} />
            <Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> Tabpanel 2 </div>} value={2} />
            <Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> Tabpanel 3 </div>} value={3} />
            <Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> Tabpanel 1 </div>} value={1} />
            <Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> Tabpanel 2 </div>} value={2} />
            <Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> Tabpanel 3 </div>} value={3} />
          </Tabs>
        </AppBar>
        <TabPanel selectedValue={tabValue} value={1}>
          Tab 1
        </TabPanel>
        <TabPanel selectedValue={tabValue} value={2}>
        Tab 2
        </TabPanel>
        <TabPanel selectedValue={tabValue} value={3}>
        Tab 3
        </TabPanel>
      </div>
      
<div style={{
            display: 'flex',
            //display: 'block',
            //display: 'inline-block',
            overflowX: 'scroll',
          }}>
            <div style={{
              //width: '950px',
              color: 'white',
              background: 'grey',
            }}>
              <div>Content111111111111111111111111111111111111111111111111</div>
              <div>Content222222222222222222222222222222222222222222222222</div>
              <div>Content333333333333333333333333333333333333333333333333</div>
              <div>Content444444444444444444444444444444444444444444444444</div>
              <div>Content555555555555555555555555555555555555555555555555</div>
              <div>Content666666666666666666666666666666666666666666666666</div>
              <div>Content777777777777777777777777777777777777777777777777</div>
              <div>Content888888888888888888888888888888888888888888888888</div>
            </div>
          </div>

<CustomDataGrid<ManageUser>
            name={'testTable'}
            columns={columns}
            data={users}
          //defaultCanGroupBy={true}
          //disableRowSelection={false}
          />

export const useStyles = makeStyles({
  tableContainer: {
    display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: 'grey',
    overflowX: 'auto', overflowY: 'hidden', padding: '1rem'
  },
  table: {
    display: 'flex', flex: 1, flexDirection: 'row',
    //border: '1px solid', 
    minWidth: '240px'
  },
  headerRow: {
    display: 'flex', flex: '1 0 auto',
    minWidth: '240px', position: 'relative', verticalAlign: 'middle',
    borderColor: 'red'
  },
  headerCell: {
    display: 'flex', flex: '253 0 auto', justifyContent: 'flex-start', alignItems: 'flex-start',
    border: '1px solid',
    minWidth: '30px', position: 'relative', verticalAlign: 'inherit',
    width: '253px',
    borderColor: 'blue'
  },
  tableBody: {
    //display: 'flex', flex: '1 0 auto', flexDirection: 'column',
    display: 'block',
    //border: '1px solid',
  },
  row: {
    display: 'flex', flex: '1 0 auto',
    minWidth: '240px', verticalAlign: 'middle',
    borderColor: 'green'
  },
  cell: {
    display: 'flex', flex: '253 0 auto', justifyContent: 'flex-start', alignItems: 'flex-start',
    border: '1px solid',
    minWidth: '30px', position: 'relative', verticalAlign: 'inherit',
    width: '253px',
    borderColor: 'yellow'
  },
});

function Test() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          <div>
            <div className={classes.headerRow}>
              <div className={classes.headerCell}>
                <b>Column 1</b>
              </div>
              <div className={classes.headerCell}>
                <b>Column 2</b>
              </div>
              <div className={classes.headerCell}>
                <b>Column 3</b>
              </div>
              <div className={classes.headerCell}>
                <b>Column 4</b>
              </div>
              <div className={classes.headerCell}>
                <b>Column 5</b>
              </div>
            </div>
            <div className={classes.tableBody}>
              <div className={classes.row}>
                <div className={classes.cell}>
                  Value 1
                </div>
                <div className={classes.cell}>
                  Value 2
                </div>
                <div className={classes.cell}>
                  Value 3
                </div>
                <div className={classes.cell}>
                  Value 4
                </div>
                <div className={classes.cell}>
                  Value 5
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  Value 11
                </div>
                <div className={classes.cell}>
                  Value 22
                </div>
                <div className={classes.cell}>
                  Value 33
                </div>
                <div className={classes.cell}>
                  Value 44
                </div>
                <div className={classes.cell}>
                  Value 55
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



      <CssBaseline />
      <CustomDataGrid<ManageUser>
        name={'testTable'}
        columns={columns}
        data={users}
      //defaultCanGroupBy={true}
      //disableRowSelection={false}
      />

      <div style={{ display: 'flex', flex: '1 0 auto', flexDirection: 'column' }}>

        <Grid container>
          <Grid item xs={12}>
            <CustomDataGrid<ManageUser>
              name={'testTable'}
              columns={columns}
              data={users}
            //defaultCanGroupBy={true}
            //disableRowSelection={false}

            />
          </Grid>
        </Grid>

 onAdd={dummy}
        onEdit={dummy}
        onDelete={dummy}

import {Box, Grid, Typography} from '@material-ui/core';
import {ColDef, DataGrid, RowData} from '@material-ui/data-grid';
import {Alert} from '@material-ui/lab';

      <div style={{
          display: 'flex',
          minHeight: '100vh'
        }}>
          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div style={{
              flex: 1,
              background: '#bcd8f5'
            }}>

              <Typography component="h1" variant="h5">
                Test Header
          </Typography>

              <Grid container>
                <Grid item xs={12}>
                  <Alert severity="error">
                    Error Message Test.
              </Alert>
                </Grid>

                <Grid item xs={12}>
                  <div style={{ height: 400, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                      <div style={{ flexGrow: 1 }}>
                        <DataGrid
                          autoHeight={true}
                          pageSize={10}
                          rowsPerPageOptions={[10, 25, 50, 100, 200]}
                          rows={[
                            { id: '1', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '2', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '3', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '4', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '5', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '6', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '7', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '8', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '9', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '10', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '11', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '12', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '13', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '14', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '15', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '16', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '17', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '18', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '19', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '20', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '21', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                            { id: '22', email: 'asd@web.de', givenName: '', familyName: '', name: 'xyz gmbh', phoneNumber: '', roles: '', isActive: true },
                          ] as RowData[]}
                          columns={[
                            { field: 'id', hide: true },
                            { field: 'email', headerName: 'email' },
                            { field: 'givenName', headerName: 'givenName' },
                            { field: 'familyName', headerName: 'familyName' },
                            { field: 'name', headerName: 'name' },
                            { field: 'phoneNumber', headerName: 'phoneNumber' },
                            { field: 'roles', headerName: 'roles' },
                            { field: 'isActive', headerName: 'isActive' }
                          ] as ColDef[]} />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>

            </div>
            <div style={{
              flex: 1
            }}>
              <Box p={2} display="flex" justifyContent="center"
                style={{ color: 'white', backgroundColor: 'grey' }}>
                Footer
          </Box>
            </div>
          </div>
        </div>
*/

export default Test;