import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import RouteContext from 'general/RouteContext';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { Menu as MenuIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppHeaderStyle from 'general/styles/AppHeaderStyle';
import LanguageMenu from 'general/components/app/LanguageMenu';
import AccountElement from 'general/components/app/AccountElement';
import { ScrollTopButtonAnchorId } from 'general/components/app/ScrollTopButton';
import ElevationScroll from 'general/components/app/ElevationScroll';
import { Helmet } from 'react-helmet';

export interface AppHeaderProps extends WithStyles<typeof AppHeaderStyle> {
  onDrawerToggle: () => void;
}

function AppHeader(props: AppHeaderProps) {
  const classes = props.classes;
  const { t } = useTranslation();
  const routeContext = useContext(RouteContext);
  const routeHistory = useHistory();

  function handleNavigateBack() {
    routeHistory.goBack();
  }

  function OpenDrawerMenuButton() {
    return (
      <Hidden mdUp>
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.onDrawerToggle}
            className={classes.navigationButton}>
            <MenuIcon />
          </IconButton>
        </Grid>
      </Hidden>);
  }

  function PrimaryBar() {
    return (
      <Hidden smDown> { /* Show PrimaryBar only on larger devices */}
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <OpenDrawerMenuButton />
            <Grid item xs />
            <Grid item>
              <LanguageMenu />
            </Grid>
            <Grid item>
              <AccountElement />
            </Grid>
          </Grid>
        </Toolbar>
      </Hidden>);
  }

  function SecondaryBar() {
    return (
      <Toolbar>
        <Grid container alignItems="center" spacing={1}>
          {routeContext?.isNavigateBackVisible ?
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="back"
                onClick={handleNavigateBack}
                className={classes.navigationButton}>
                <ArrowBackIcon />
              </IconButton>
            </Grid> :
            <OpenDrawerMenuButton />}
          <Grid item xs>
            <Typography color="inherit" variant="h6" component="h1">
              {t(routeContext.title)}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>);
  }

  return (
    <>
      <ElevationScroll>
        <AppBar color="primary" position="sticky" elevation={0}>
          <Helmet>
            <title>{t(routeContext.title)}</title>
          </Helmet>
          <PrimaryBar />
          <SecondaryBar />
        </AppBar>
      </ElevationScroll>
      <div id={ScrollTopButtonAnchorId} />
    </>
  );
}

export default withStyles(AppHeaderStyle)(AppHeader);