import React from 'react';
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import AppStyle from "general/styles/AppStyle";
import { useTranslation } from 'react-i18next';
import { WebAssetOutlined as WebAssetIcon, SettingsOutlined as SettingsIcon,
	 PaletteOutlined as PaletteIcon } from '@material-ui/icons';

export enum TenantPaperTitleOption {
	basicData,
	email,
	design,
}

export interface TenantPaperTitleProps extends WithStyles<typeof AppStyle> {
	option: TenantPaperTitleOption
}

function TenantPaperTitle(props: TenantPaperTitleProps) {
	const { classes, option } = props;
	const { t } = useTranslation(['company']);

	function IconElement() {
		switch (option) {
			case TenantPaperTitleOption.basicData: {
				return (<WebAssetIcon className={classes.titleAvatar} />);
			}
			case TenantPaperTitleOption.email: {
				return (<SettingsIcon className={classes.titleAvatar} />);
			}
			case TenantPaperTitleOption.design: {
				return (<PaletteIcon className={classes.titleAvatar} />);
			}
			default: {
				return (null);
			}
		}
	}

	function TitleElement() {
		let titleKey: string;
		switch (option) {
			case TenantPaperTitleOption.basicData: {
				titleKey = 'company:tenantDetails.tenantTab.paperBasicDataTitle';
				break;
			}
			case TenantPaperTitleOption.email: {
				titleKey = 'company:tenantDetails.emailTab.paperSettingsTitle';
				break;
			}
			case TenantPaperTitleOption.design: {
				titleKey = 'company:tenantDetails.designTab.paperDesignTitle';
				break;
			}
			default: {
				titleKey = 'paperNoTitle';
				break;
			}
		}
		return (
			<Typography component="h2" variant="h6" >
				{t(titleKey)}
			</Typography>);
	}

	return (
		<>
			<IconElement />
			<TitleElement />
		</>
	);
}

export default withStyles(AppStyle)(TenantPaperTitle);