import React, { useEffect, useContext, useRef, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import ApiHelper from 'general/helpers/api/ApiHelper';
import { useTranslation } from 'react-i18next';
import Routes from 'general/helpers/Routes';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Container, Avatar, TextField, Button, Grid, Box, InputAdornment, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PortalPaper from 'general/components/container/PortalPaper';
import RouteContext from 'general/RouteContext';

interface ChangePasswordProps extends WithStyles<typeof AppStyle> { }

function ChangePassword(props: ChangePasswordProps) {
	enum viewMode { form, success }
	const classes = props.classes;
	const { t } = useTranslation(['account', 'validation']);
	const [currentViewMode, setcurrentViewMode] = useState(viewMode.form);
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls

	useEffect(() => {
	}, [initRouteContext]);

	function SuccessView() {
		return (
			<PortalPaper>
				<Container>
					<Box mt={1} mb={1}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Alert severity="success">
									{t('account:changePassword.textSuccess')}
								</Alert>
							</Grid>
							<Grid item xs={12}>
								<Button variant="contained" color="default"
									component={Link} to={Routes.Portal.Account.Profile}>
									{t('account:changePassword.linkBack')}
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</PortalPaper>
		);
	}

	function showSuccessView() {
		setcurrentViewMode(viewMode.success);
	}

	return (
		<div>
			{currentViewMode === viewMode.form ?
				<ChangePasswordFormView showSuccessView={showSuccessView} classes={classes} /> :
				<SuccessView />}
		</div>
	);
}

type ShowSuccessViewCallback = () => void;
interface ChangePasswordFormViewProps extends ChangePasswordProps {
	showSuccessView: ShowSuccessViewCallback
}

function ChangePasswordFormView(props: ChangePasswordFormViewProps) {
	const classes = props.classes;
	const { t } = useTranslation(['common', 'account', 'validation']);
	const [errorMessage, setErrorMessage] = useState('');
	const [resetLoading, setResetLoading] = useState(false);
	const [showOldPassword, setshowOldPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
	const routeHistory = useHistory();
	const { register, handleSubmit, errors, watch } = useForm();

	async function onSubmit(data: any) {
		setErrorMessage('');
		const oldPassword = data.oldPassword;
		const password = data.password;
		const passwordRepeat = data.passwordRepeat;

		try {
			setResetLoading(true);
			const apiHelper = new ApiHelper();
			await (await apiHelper.useAuth()).patch('/apiidserver/password/change',
				JSON.stringify({
					oldPassword,
					password,
					passwordRepeat
				}));
			props.showSuccessView();
		} catch (error) {
			if ((error?.response?.status ?? 0) === 400) {
				setErrorMessage(t(`account:changePassword.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
			setResetLoading(false);
		};
	}

	const valRequired = t('validation:required');
	const valMinLength8 = t('validation:minLength', { minLength: 8 });
	const valMaxLength30 = t('validation:maxLength', { maxLength: 30 });
	const valpasswordsNotMatch = t('validation:passwordsNotMatch');
	return (
		<PortalPaper>
			<Container maxWidth="xs">
				<Box mb={1}>
					<Box display="flex" justifyContent="center">
						<Avatar className={classes.defaultAvatar}>
							<LockOutlinedIcon />
						</Avatar>
					</Box>
					<form className={classes.defaultForm} onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									margin="none"
									fullWidth
									label={t('account:changePassword.labelOldPassword')}
									name="oldPassword"
									autoComplete="current-password"
									autoFocus
									type={showOldPassword ? "text" : "password"}
									inputRef={register({
										required: valRequired,
										minLength: { value: 8, message: valMinLength8 },
										maxLength: { value: 30, message: valMaxLength30 }
									})}
									error={!!errors.oldPassword}
									helperText={errors.oldPassword ? errors.oldPassword.message : ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setshowOldPassword(!showOldPassword)}
													onMouseDown={() => setshowOldPassword(!showOldPassword)}
													tabIndex={-1}
												>
													{showOldPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									margin="none"
									fullWidth
									label={t('account:changePassword.labelPassword')}
									name="password"
									autoComplete="new-password"
									type={showPassword ? "text" : "password"}
									inputRef={register({
										required: valRequired,
										minLength: { value: 8, message: valMinLength8 },
										maxLength: { value: 30, message: valMaxLength30 }
									})}
									error={!!errors.password}
									helperText={errors.password ? errors.password.message : ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowPassword(!showPassword)}
													onMouseDown={() => setShowPassword(!showPassword)}
													tabIndex={-1}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									margin="none"
									fullWidth
									label={t('account:changePassword.labelPasswordRepeat')}
									name="passwordRepeat"
									type={showPasswordRepeat ? "text" : "password"}
									inputRef={register({
										required: valRequired,
										minLength: { value: 8, message: valMinLength8 },
										maxLength: { value: 30, message: valMaxLength30 },
										validate: (value) => value === watch('password') || valpasswordsNotMatch
									})}
									error={!!errors.passwordRepeat}
									helperText={errors.passwordRepeat ? errors.passwordRepeat.message : ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
													onMouseDown={() => setShowPasswordRepeat(!showPasswordRepeat)}
													tabIndex={-1}
												>
													{showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									disabled={resetLoading}
								>
									{resetLoading ?
										t('loading') :
										t('account:changePassword.buttonChangePassword')}
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Button fullWidth color="default" onClick={() => routeHistory.goBack()}>
									{t('common:button.cancel')}
								</Button>
							</Grid>
						</Grid>
					</form>

					{errorMessage !== '' ?
						<Grid container>
							<Grid item xs={12}>
								<Box my={2}>
									<Alert severity="error">
										{errorMessage}
									</Alert>
								</Box>
							</Grid>
						</Grid> : null}
				</Box>
			</Container>
		</PortalPaper>
	);
}

export default withStyles(AppStyle)(ChangePassword);