import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, WithStyles, withStyles } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import Users from 'manage/users/views/Users';
import Groups from 'manage/users/views/Groups';
import Rights from 'manage/users/views/Rights';
import Routes from 'general/helpers/Routes';
import TabPanel from 'general/components/container/TabPanel';
import AppStyle from 'general/styles/AppStyle';
import ManageUsersState from 'manage/users/models/ManageUsersState';
import { useRight, right_Manage_Users_Users_View, right_Manage_Users_Groups_View, right_Manage_Users_Rights_View } from 'account/models/Rights';
import AccountContext from 'account/AccountContext';

export enum ManageUsersTabs {
	users = 0,
	groups = 1,
	rights = 2
}

export interface ManageUsersProps extends WithStyles<typeof AppStyle> { }

function ManageUsers(props: ManageUsersProps) {
	//const classes = props.classes;
	const { t } = useTranslation();
	const [tabValue, setTabValue] = useState<ManageUsersTabs | undefined>(undefined);
	const location = useLocation();
	const accountContext = useContext(AccountContext);
	const hasUsersViewRight = useRight(right_Manage_Users_Users_View, accountContext);
	const hasGroupsViewRight = useRight(right_Manage_Users_Groups_View, accountContext);
	const hasRightsViewRight = useRight(right_Manage_Users_Rights_View, accountContext);

	useEffect(() => {
		const savedState = location?.state as ManageUsersState;
		console.log('ManageUsers.savedState', savedState);
		if (savedState?.selectedTab !== undefined) {
			// State saved => load saved tab for go back navigation
			setTabValue(savedState.selectedTab);
		} else {
			// No state saved => load tab by path
			switch (location?.pathname) {
				case Routes.Portal.Manage.Users:
					setTabValue(ManageUsersTabs.users);
					break;
				case Routes.Portal.Manage.Groups:
					setTabValue(ManageUsersTabs.groups);
					break;
				case Routes.Portal.Manage.Rights:
					setTabValue(ManageUsersTabs.rights);
					break;
			}
		}
	}, [location]);

	function handleTabChange(event: React.ChangeEvent<{}>, newValue: ManageUsersTabs) {
		setTabValue(newValue);
	}

	return (
		<>
			{tabValue !== undefined ?
				<>
					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						variant="scrollable"
						scrollButtons="auto"
					//scrollButtons="on"
					//className={classes.fixedTabs}
					>
						{hasUsersViewRight ?
							<Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> {t('nav.manage.users')} </div>} value={ManageUsersTabs.users} /> : null}
						{hasGroupsViewRight ?
							<Tab label={<div><GroupIcon style={{ verticalAlign: 'middle' }} /> {t('nav.manage.groups')} </div>} value={ManageUsersTabs.groups} /> : null}
						{hasRightsViewRight ?
							<Tab label={<div><LockIcon style={{ verticalAlign: 'middle' }} /> {t('nav.manage.rights')} </div>} value={ManageUsersTabs.rights} /> : null}
					</Tabs>
					{hasUsersViewRight ?
						<TabPanel selectedValue={tabValue} value={ManageUsersTabs.users}>
							<Users />
						</TabPanel> : null}
					{hasGroupsViewRight ?
						<TabPanel selectedValue={tabValue} value={ManageUsersTabs.groups}>
							<Groups />
						</TabPanel> : null}
					{hasRightsViewRight ?
						<TabPanel selectedValue={tabValue} value={ManageUsersTabs.rights}>
							<Rights />
						</TabPanel> : null}
				</> : null}
		</>
	);
}

export default withStyles(AppStyle)(ManageUsers);