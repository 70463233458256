class BaseResponse {
  code: number | undefined;
  message: string | undefined;

  constructor(dataResponse?: any) {
    if (dataResponse) {
      this.code = dataResponse.code;
      this.message = dataResponse.message;
    }
  }
}

export default BaseResponse;