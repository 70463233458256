import React, { useMemo, useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Paper, withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';
import { useHistory } from 'react-router-dom';
import { useScroll } from 'general/helpers/ui/ScrollHelper';
import { CompanyRightGroup } from 'company/users/models/CompanyGroup';
import CompanyRightsService from 'company/users/helpers/CompanyRightsService';
import { BooleanFilterKey, CheckBoxColumnFilter } from 'general/components/customDataGrid/filters';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import SearchBar from 'general/components/common/SearchBar';
import BoxedAlert from 'general/components/common/BoxedAlert';
import { CustomCheckboxStyle, CustomTableRowCheckbox } from 'general/components/customTable';
import { RightDetailsState } from 'company/users/models/CompanyUsersState';
import { RightDetailsTabs } from 'company/users/views/RightDetails';
import BaseState from 'general/models/BaseState';
import { Row } from 'react-table';
import StringHelper from 'general/helpers/types/StringHelper';
import Routes from 'general/helpers/Routes';
import TenantContext, { TenantContextType } from 'idserver/TenantContext';

interface RightDetailsGroupsProps extends WithStyles<typeof AppStyle> {
  rightId: string;
}

function RightDetailsGroups(props: RightDetailsGroupsProps) {
  const { classes, rightId } = props;
  const { t, i18n } = useTranslation(['company']);
  const routeHistory = useHistory();
  const [setScrollToY, raiseScrollToY] = useScroll();
  const [tableLoadPageIndex, setTableLoadPageIndex] = useState<boolean | undefined>();
  const [groupRows, setGroupRows] = useState(new Array<CompanyRightGroup>());
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchText, setSearchText] = useState<string | undefined>();
  const rightsService = useRef(new CompanyRightsService());
  const tenantContext = useContext(TenantContext);

  useEffect(() => {
    console.log('Init load right groups');
    const savedState = routeHistory.location?.state as RightDetailsState
    if (savedState?.groups) {
      // Backup state
      const { scrollY, searchText } = savedState.groups;

      // Clean up state
      savedState.groups = undefined;
      routeHistory.replace(routeHistory.location.pathname, savedState);

      // Set saved state
      setScrollToY(scrollY);
      setTableLoadPageIndex(true);
      setSearchText(searchText);

    } else {
      // Load groups
      const lang = i18n.language;
      getGroupsAsync(rightId, searchText, lang, tenantContext, raiseScrollToY);
    }
  }, [rightId, searchText, routeHistory, i18n.language, tenantContext, setScrollToY, raiseScrollToY]);

  async function getGroupsAsync(
    paramRightId: string,
    paramSearchText: string | undefined,
    paramLang: string,
    tenantContext: TenantContextType,
    raiseScrollToY: () => void) {
    setErrorMessage('');
    setIsLoading(true);
    try {
      let groups: CompanyRightGroup[];
      if (tenantContext.tenant.isMainTenant) {
        groups = await rightsService.current
          .getGroupsHasRight(paramRightId, paramSearchText ?? '', paramLang);
      } else {
        groups = await rightsService.current
          .getTenantGroupsHasRight(tenantContext.tenant.tenantId ?? '', paramRightId, paramSearchText ?? '', paramLang);
      }
      setGroupRows(groups);
    } catch (error) {
      console.error(error);
      setErrorMessage(error?.message ?? '');
    } finally {
      // Reset
      setIsLoading(false);
      setTableLoadPageIndex(undefined);
      raiseScrollToY();
    }
  }

  function saveCurrentRouteState() {
    const currentState = new RightDetailsState();
    currentState.selectedTab = RightDetailsTabs.groups;
    currentState.groups = new BaseState();
    currentState.groups.scrollY = window.scrollY;
    currentState.groups.searchText = searchText ?? '';
    routeHistory.replace(routeHistory.location.pathname, currentState);
  }

  function rowClicked(row: Row<CompanyRightGroup>) {
    const selectedGroupId = row?.original?.groupId ?? '';
    if (!StringHelper.empty(selectedGroupId)) {
      // Save current state before redirect
      saveCurrentRouteState();

      // Redirect
      routeHistory.push(`${Routes.Portal.Company.GroupDetails}/${selectedGroupId}`);
    }
  }

  const groupColumns = useMemo(() => {
    console.log('Init right group columns');
    return [
      {
        Header: 'companyRightGroups',
        HideHeader: true,
        columns: [
          {
            Header: t('company:companyRight:isAllowed'),
            accessor: 'isAllowed',
            minWidth: 90,
            width: 100,
            align: 'center',
            Cell: ({ value }: any) =>
            (
              <CustomTableRowCheckbox
                checked={value ?? undefined}
                color="default"
                customStyle={{ useCustomColor: true } as CustomCheckboxStyle}
                className={classes.displayCheckbox} />
            ),
            Filter: CheckBoxColumnFilter,
            filter: BooleanFilterKey,
          },
          {
            Header: t('company:companyGroup:groupOrder'),
            accessor: 'groupOrder',
            width: 120,
          },
          {
            Header: t('company:companyGroup:groupName'),
            accessor: 'groupName',
            width: 300,
          }
        ]
      },
    ];
  }, [t, classes]);

  return (
    <>
      <Grid container>
        <BoxedAlert message={errorMessage} container="gridItem"
          severity="error" variant="filled" />

        <Grid item xs={12} className={classes.defaultSearchBarGridItem}>
          <Box marginY={1}>
            <SearchBar
              placeholder={t('company:rightDetails.groupsTab.searchBar.placeholder')}
              onSearchTextChanged={(value: string) => setSearchText(value)}
              value={searchText}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <CustomDataGrid<CompanyRightGroup>
              name="companyRightGroups"
              isLoading={isLoading}
              columns={groupColumns}
              data={groupRows}
              loadLastPageIndex={tableLoadPageIndex}
              fixedTableHeader={true}
              onClick={rowClicked}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(AppStyle)(RightDetailsGroups);