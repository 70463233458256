import { Box, BoxProps, CircularProgress } from "@material-ui/core";
import React from "react";

interface CircularProgressBoxProps extends BoxProps {
}
function CircularProgressBox(props: CircularProgressBoxProps) {
  let ownBoxProps: BoxProps = {
    display: 'flex',
    justifyContent: 'center'
  }

  return (
    <Box {...props} {...ownBoxProps}>
      <CircularProgress />
    </Box>
  );
}

export default CircularProgressBox;