import React, { useState } from 'react';
import AppStyle, { AppDrawerWidth } from 'general/styles/AppStyle';
import AppNavDrawer from 'general/components/app/AppNavDrawer';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import BoolHelper from 'general/helpers/types/BoolHelper';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppHeader from 'general/components/app/AppHeader';
import AppFooter from 'general/components/app/AppFooter';

export interface AppPortalContainerProps extends WithStyles<typeof AppStyle> {
  children: React.ReactNode
}

function AppPortalContainer(props: AppPortalContainerProps) {
  const keyIsDrawerOpen = 'app.IsDrawerOpen';
  const classes = props.classes;
  const [open, setOpen] = useState(BoolHelper.Convert(localStorage.getItem(keyIsDrawerOpen), true));
  const [openSmallDevice, setOpenSmallDevice] = useState(false);

  const handleDrawerToggle = () => {
    console.log('handleDrawerToggle');
    setOpen(!open);
    localStorage.setItem(keyIsDrawerOpen, (!open) ? 'true' : 'false');
  };

  const handleDrawerToggleSmallDevice = () => {
    console.log('handleDrawerToggleSmallDevice');
    setOpenSmallDevice(!openSmallDevice);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav>
        <Hidden mdUp implementation="js">
          <AppNavDrawer
            PaperProps={{ style: { width: AppDrawerWidth } }}
            variant="temporary"
            open={openSmallDevice}
            onClose={handleDrawerToggleSmallDevice}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <AppNavDrawer
            open={open}
            onClose={handleDrawerToggle}
          />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <AppHeader onDrawerToggle={handleDrawerToggleSmallDevice} />
        <main className={classes.main}>
          <div className="App-body">
            {props.children}
          </div>
        </main>
        <AppFooter classes={classes} />
      </div>
    </div>
  );
}

export default withStyles(AppStyle)(AppPortalContainer);