import React from 'react';
import { FormControlLabel, Checkbox, withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';

interface FormDisplayCheckBoxProps extends WithStyles<typeof AppStyle> {
  label: React.ReactNode;
  checked?: boolean;
}

function FormDisplayCheckBox(props: FormDisplayCheckBoxProps) {
  const { classes, label, checked } = props;
  return (
    <FormControlLabel
      className={classes.formDisplayCheckboxLabel}
      label={label}
      control={
        <Checkbox checked={checked}
          color="default"
          className={classes.formDisplayCheckbox} />}
    />
  );
}

export default withStyles(AppStyle)(FormDisplayCheckBox);