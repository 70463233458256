import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import RouteContext, { RouteContextType } from 'general/RouteContext';
import PortalPaper from 'general/components/container/PortalPaper';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Settings as SettingsIcons } from '@material-ui/icons';
import { Grid, Box } from '@material-ui/core';
import FormDisplay from 'general/components/common/FormDisplay';
import ManageGroup from 'manage/users/models/ManageGroup';
import ManageGroupsService from 'manage/users/helpers/ManageGroupsService';
import CircularProgressBox from 'general/components/common/CircularProgressBox';
import moment from 'moment';
import EditActionButtonsGroup from 'general/components/common/EditActionButtonsGroup';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import AlertDialog, { AlertDialogResult } from 'general/components/common/AlertDialog';
import Routes from 'general/helpers/Routes';
import { GroupsRouteState } from 'manage/users/views/Groups';
import GroupEdit from 'manage/users/views/GroupEdit';
import GroupPaperTitle, { GroupPaperTitleOption } from 'manage/users/components/GroupPaperTitle';
import { CustomTable, CustomTableHeadRow, CustomTableHeadCell, CustomTableRow, CustomTableCell, AlignX, CustomTableRowCheckbox, CustomCheckboxStyle } from 'general/components/customTable';
import { ManageGroupHasRight } from 'manage/users/models/ManageRight';
import BoxedAlert from 'general/components/common/BoxedAlert';
import IconActionButton from 'general/components/common/IconActionButton';
import AccountContext from 'account/AccountContext';
import {
	useRight,
	right_Manage_Users_Groups_Edit, right_Manage_Users_Groups_Delete,
	right_Manage_Users_Rights_View, right_Manage_Users_Rights_Edit
} from 'account/models/Rights';
import FormDisplayCheckBox from 'general/components/common/FormDisplayCheckBox';

interface GroupDetailsParams {
	groupId: string;
}

export type SuccessGroupCreatedAlert = {
	visible: boolean;
	groupName?: string;
}

export type GroupDetailsRouteState = {
	successGroupCreatedAlert?: SuccessGroupCreatedAlert;
}

export interface GroupDetailsProps extends WithStyles<typeof AppStyle> { }

function GroupDetails(props: GroupDetailsProps) {
	enum viewMode { view, edit }
	const classes = props.classes;
	const { t, i18n } = useTranslation(['manage']);
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
	const [isLoading, setIsLoading] = useState(true);
	const [deleteIsLoading, setDeleteIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [group, setGroup] = useState<ManageGroup | undefined>(undefined);
	const [currentViewMode, setCurrentViewMode] = useState(viewMode.view);
	const [showSuccessGroupSaved, setShowSuccessGroupSaved] = useState(false);
	const [successGroupCreatedAlert, setSuccessGroupCreatedAlert] = useState<SuccessGroupCreatedAlert | undefined>(undefined);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const routeHistory = useHistory();
	const groupsService = useRef(new ManageGroupsService());
	const currentLang = useRef('');
	const { groupId } = useParams<GroupDetailsParams>();
	const accountContext = useContext(AccountContext);
	const hasGroupsEditRight = useRight(right_Manage_Users_Groups_Edit, accountContext);
	const hasGroupsDeleteRight = useRight(right_Manage_Users_Groups_Delete, accountContext);
	const hasRightsViewRight = useRight(right_Manage_Users_Rights_View, accountContext);
	const hasRightsEditRight = useRight(right_Manage_Users_Rights_Edit, accountContext);

	useEffect(() => {
		const lang = i18n.language;
		getGroupAsync(groupId, lang, group, initRouteContext.current);

		// Show group created success message if redirect from groupCreate view
		const contextState = initRouteContext.current.routeStates[Routes.Portal.Manage.GroupDetails] as GroupDetailsRouteState;
		if (contextState) {
			// Set saved RouteContext state
			setSuccessGroupCreatedAlert(contextState?.successGroupCreatedAlert);
		}

		// Clean up RouteContext state
		initRouteContext.current.clearRouteState(Routes.Portal.Manage.GroupDetails);

	}, [groupId, i18n.language, group, initRouteContext]);

	async function getGroupAsync(groupId: string, lang: string,
		currentGroup: ManageGroup | undefined, routeContext: RouteContextType) {
		if (!currentGroup || currentGroup?.groupId !== groupId || currentLang.current !== lang) {
			currentLang.current = lang;
			setErrorMessage('');
			setIsLoading(true);
			try {
				console.log('Load group', groupId);

				// Load group data
				const group = await groupsService.current.getGroup(groupId, lang);
				setGroup(group);

				// Set HeaderText and display GoBackButton
				routeContext.setTitle(group?.groupName ?? '', true);

			} catch (error) {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			} finally {
				setIsLoading(false);
			}
		}
	}

	function editButtonClick() {
		setCurrentViewMode(viewMode.edit);
	}

	function deleteButtonClick() {
		setShowDeleteDialog(true);
	}

	async function handleDeleteDialog(dialogResult: AlertDialogResult) {
		// Close dialog
		setShowDeleteDialog(false);

		if (dialogResult === AlertDialogResult.yes) {
			setErrorMessage('');
			setDeleteIsLoading(true);
			try {
				await groupsService.current.deleteGroup(group?.groupId ?? '');

				// Set specific state for success message
				const groupsState: GroupsRouteState = {};
				groupsState.successGroupDeletedAlert = {
					visible: true,
					groupName: group?.groupName
				};
				initRouteContext.current.setRouteState(Routes.Portal.Manage.Groups, groupsState);

				// Go back to groups list
				routeHistory.goBack();

			} catch (error) {
				if ((error?.response?.status ?? 0) === 400) {
					setErrorMessage(t(`manage:groupDetails.error.${error?.response?.data?.code}`));
				} else {
					console.error(error);
					setErrorMessage(error?.message ?? '');
				}
				setDeleteIsLoading(false);
			}
		}
	}

	function saved(changedGroup: ManageGroup) {
		setCurrentViewMode(viewMode.view);

		// Trigger group reload
		setGroup(undefined);

		// Show info
		setShowSuccessGroupSaved(true);
	}

	function canceled() {
		setCurrentViewMode(viewMode.view);
	}

	function rightsConfigClick() {
		// Redirect
		routeHistory.push(`${Routes.Portal.Manage.GroupRightsConfig}/${groupId}/${group?.groupName}`);
	}

	function GroupView() {
		return (
			<>
				<Box className={classes.portalPaperTitleBox}>
					<GroupPaperTitle option={GroupPaperTitleOption.basicData} />
					<EditActionButtonsGroup
						editMode={false}
						onEditButtonClick={editButtonClick}
						onDeleteButtonClick={deleteButtonClick}
						disabled={isLoading || deleteIsLoading}
						disabledEdit={!hasGroupsEditRight}
						disabledDelete={!hasGroupsDeleteRight}
					/>
				</Box>
				<Box className={classes.portalPaperContentBox}>
					<BoxedAlert message={errorMessage} severity="error" />
					{isLoading ?
						<CircularProgressBox py={5} /> :
						<Grid container spacing={2} className={classes.defaultFormGrid}>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageGroup.groupOrder')} value={group?.groupOrder} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormDisplay label={t('manage:manageGroup.groupName')} value={group?.groupName} />
							</Grid>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageGroup.tenants')} value={group?.tenants} />
							</Grid>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageGroup.createDate')} value={moment(group?.createDate as Date).format('L LT')} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormDisplayCheckBox label={t('manage:manageGroup.isRoleGroup')} checked={group?.isRoleGroup ?? false} />
							</Grid>
						</Grid>}
				</Box>

				{hasRightsViewRight ?
					<>
						<Box className={classes.portalPaperTitleBox}>
							<GroupPaperTitle option={GroupPaperTitleOption.rights} />
							<Box className={classes.portalPaperActionButtonsBox}>
								<IconActionButton label={t('common:button:config')} icon={<SettingsIcons />}
									onClick={rightsConfigClick} disabled={!hasGroupsEditRight || !hasRightsEditRight} />
							</Box>
						</Box>
						<Box className={classes.portalPaperContentBox}>
							<RightsTable />
						</Box>
					</> : null}
			</>
		);
	}

	function RightsTable() {
		const columns = {
			name: {
				width: 200,
			},
			isAllowed: {
				width: 110,
				alignX: AlignX.center,
			},
			description: {
				width: 600,
			},
		};

		return (<CustomTable
			isLoading={isLoading}
			fixedTableHeader={true}
			headerChildren={
				<CustomTableHeadRow>
					<CustomTableHeadCell width={columns.name.width}>{t('manage:manageRight.name')}</CustomTableHeadCell>
					<CustomTableHeadCell width={columns.isAllowed.width} alignX={columns.isAllowed.alignX}>{t('manage:manageRight.isAllowed')}</CustomTableHeadCell>
					<CustomTableHeadCell width={columns.description.width}>{t('manage:manageRight.description')}</CustomTableHeadCell>
				</CustomTableHeadRow>}>
			{group?.rights?.map((groupHasRight: ManageGroupHasRight, index: number) => {
				return (
					<CustomTableRow key={`customTableRow-right-${index}`} id={groupHasRight?.right?.rightId ?? ''}>
						<CustomTableCell width={columns.name.width}>{groupHasRight?.right?.name}</CustomTableCell>
						<CustomTableCell width={columns.isAllowed.width} alignX={columns.isAllowed.alignX} isCheckbox={true}>
							<CustomTableRowCheckbox
								checked={groupHasRight?.isAllowed ?? undefined}
								indeterminate={groupHasRight?.isAllowed === null}
								color="default"
								customStyle={{ useCustomColor: true } as CustomCheckboxStyle}
								className={classes.displayCheckbox} />
						</CustomTableCell>
						<CustomTableCell width={columns.description.width}>{groupHasRight?.right?.description}</CustomTableCell>
					</CustomTableRow>);
			})}
		</CustomTable>);
	}

	return (
		<>
			<PortalPaper>
				{(currentViewMode === viewMode.view) ?
					<GroupView /> :
					<GroupEdit
						{...props}
						group={group}
						groupsService={groupsService.current}
						onSaved={saved}
						onCanceled={canceled} />}
			</PortalPaper>

			<AlertDialog open={showDeleteDialog} onClose={handleDeleteDialog}
				title={t('manage:groupDetails.deleteGroupDialog.title')}
				content={
					<Trans i18nKey="manage:groupDetails.deleteGroupDialog.content">
						The group <strong>{{ groupName: group?.groupName ?? '' }}</strong> is then completely deleted and cannot be restored.
					</Trans>}
				dialogType="delete" />

			<SnackbarAlert open={showSuccessGroupSaved} onClose={() => setShowSuccessGroupSaved(false)}
				message={t('manage:groupDetails.successGroupSaved')} severity="success" />

			<SnackbarAlert open={successGroupCreatedAlert?.visible ?? false} onClose={() => setSuccessGroupCreatedAlert(undefined)}
				message={
					<Trans i18nKey="manage:groupDetails.successGroupCreated">
						Group <strong>{{ groupName: successGroupCreatedAlert?.groupName }}</strong> created successfully!
					</Trans>}
				severity="success" />
		</>
	);
}

export default withStyles(AppStyle)(GroupDetails);