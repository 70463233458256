import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import AccountContext from 'account/AccountContext';
import { useTranslation } from 'react-i18next';
import Routes from 'general/helpers/Routes';
import PortalPaper from 'general/components/container/PortalPaper';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Button, Grid, Box, Typography } from '@material-ui/core';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import FormDisplay from 'general/components/common/FormDisplay';

interface ProfileProps extends WithStyles<typeof AppStyle> { }

function Profile(props: ProfileProps) {
	const classes = props.classes;
	const { t } = useTranslation(['account', 'idserver']);
	const accountContext = useContext(AccountContext);

	function UserElement() {
		const user = accountContext?.user;
		if (!user.isAuthenticated) return null;
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormDisplay label={t('account:profile.labelUsername')} value={user.preferred_username} />
				</Grid>
				<Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
					<FormDisplay label={t('account:profile.labelGivenName')} value={user.given_name} />
				</Grid>
				<Grid item xs={12} sm={7} md={8} lg={9} xl={10}>
					<FormDisplay label={t('account:profile.labelFamilyName')} value={user.family_name} />
				</Grid>
				<Grid item xs={12}>
					<FormDisplay label={t('account:profile.labelName')} value={user.name} />
				</Grid>
				<Grid item xs={12}>
					<FormDisplay label={t('account:profile.labelEMail')} value={user.email} />
				</Grid>
				<Grid item xs={12}>
					<FormDisplay label={t('account:profile.labelPhoneNumber')} value={user.phone_number} />
				</Grid>
				<Grid item xs={12}>
					<FormDisplay label={t('account:profile.labelRoles')} value={user.getRolesText(t)} />
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" color="default"
						component={Link} to={Routes.Portal.Account.ChangePassword}>
						{t('account:profile.linkChangePassword')}
					</Button>
				</Grid>
			</Grid>
		);
	}

	return (
		<PortalPaper>
			<Box className={classes.portalPaperTitleBox}>
				<AccountCircleOutlined className={classes.titleAvatar} />
				<Typography component="h2" variant="h6" >
					{t('account:profile.title')}
				</Typography>
			</Box>
			<Box className={classes.portalPaperContentBox}>
				<UserElement />
			</Box>
		</PortalPaper>
	);
}

export default withStyles(AppStyle)(Profile);