import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Paper, withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';
import { Alert } from '@material-ui/lab';
import SearchBar from 'general/components/common/SearchBar';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import CompanyRight from 'company/users/models/CompanyRight';
import CompanyRightsService from 'company/users/helpers/CompanyRightsService';
import { useScroll } from 'general/helpers/ui/ScrollHelper';
import CompanyUsersState from 'company/users/models/CompanyUsersState';
import { Row } from 'react-table';
import StringHelper from 'general/helpers/types/StringHelper';
import { CompanyUsersTabs } from 'company/users/views/CompanyUsers';
import BaseState from 'general/models/BaseState';
import Routes from 'general/helpers/Routes';

interface RightsProps extends WithStyles<typeof AppStyle> { }

function Rights(props: RightsProps) {
	const classes = props.classes;
	const { t, i18n } = useTranslation(['company']);
	const routeHistory = useHistory();
	const [rightRows, setRightRows] = useState(new Array<CompanyRight>());
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [searchText, setSearchText] = useState<string | undefined>();
	const [tableLoadPageIndex, setTableLoadPageIndex] = useState<boolean | undefined>();
	const [setScrollToY, raiseScrollToY] = useScroll();
	const rightsService = useRef(new CompanyRightsService());

	useEffect(() => {
		console.log('Init load rights');
		const savedState = routeHistory.location?.state as CompanyUsersState
		if (savedState?.rights) {
			// Backup state
			const { scrollY, searchText } = savedState.rights; 

			// Clean up state
			savedState.rights = undefined;
			routeHistory.replace(routeHistory.location.pathname, savedState);

			// Set saved state
			setScrollToY(scrollY);
			setTableLoadPageIndex(true);
			setSearchText(searchText);

		} else {
			// Load rights
			const lang = i18n.language;
			getRightsAsync(searchText, lang, raiseScrollToY);
		}
	}, [searchText, i18n.language, routeHistory, setScrollToY, raiseScrollToY]);

	async function getRightsAsync(paramSearchText: string | undefined,
		lang: string, raiseScrollToY: () => void) {
		setErrorMessage('');
		setIsLoading(true);
		try {
			const rights =
				await rightsService.current.getRights(paramSearchText ?? '', lang);
			setRightRows(rights);
		} catch (error) {
			console.error(error);
			setErrorMessage(error?.message ?? '');
		} finally {
			// Reset
			setIsLoading(false);
			setTableLoadPageIndex(undefined);
			raiseScrollToY();
		}
	}

	function searchTextChanged(value: string) {
		setSearchText(value);
	}

	function saveCurrentRouteState() {
		const currentState = new CompanyUsersState();
		currentState.selectedTab = CompanyUsersTabs.rights;
		currentState.rights = new BaseState();
		currentState.rights.scrollY = window.scrollY;
		currentState.rights.searchText = searchText ?? '';
		routeHistory.replace(routeHistory.location.pathname, currentState);
	}

	function rowClicked(row: Row<CompanyRight>) {
		const selectedRightId = row?.original?.rightId ?? '';
		const selectedRightName = row?.original?.name ?? '';

		if (!StringHelper.empty(selectedRightId)) {
			// Save current state before redirect
			saveCurrentRouteState();

			// Redirect
			routeHistory.push(`${Routes.Portal.Company.RightDetails}/${selectedRightId}/${selectedRightName}`);
		}
	}

	const rightColumns = useMemo(() => {
		console.log('Init right columns');
		return [
			{
				Header: 'companyRights',
				HideHeader: true,
				columns: [
					{
						Header: t('company:companyRight:name'),
						accessor: 'name',
						width: 300,
					},
					{
						Header: t('company:companyRight:description'),
						accessor: 'description',
						width: 900,
					},
				]
			},
		];
	}, [t]);

	return (
		<div>
			<Grid container>
				{errorMessage !== '' ?
					<Grid item xs={12}>
						<Box paddingBottom={1}>
							<Alert severity="error" variant="filled">
								{errorMessage}
							</Alert>
						</Box>
					</Grid> : null}

				<Grid item xs={12} className={classes.defaultSearchBarGridItem}>
					<Box marginY={1}>
						<SearchBar
							placeholder={t('company:rights.searchBar.placeholder')}
							onSearchTextChanged={searchTextChanged}
							value={searchText}
						/>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Paper>
						<CustomDataGrid<CompanyRight>
							name="companyRights"
							isLoading={isLoading}
							columns={rightColumns}
							data={rightRows}
							loadLastPageIndex={tableLoadPageIndex}
							fixedTableHeader={true}
							onClick={rowClicked}
						/>
					</Paper>
				</Grid>
			</Grid>
		</div >
	);
}

export default withStyles(AppStyle)(Rights);