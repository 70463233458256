import React, { CSSProperties } from "react";
import { PropsWithChildren, ReactElement } from "react";
import cx from 'classnames'
import CircularProgressBox from "general/components/common/CircularProgressBox";
import { useStyles } from 'general/components/customDataGrid/styles/TableStyles'
import { CustomFixedTableHeader, useFixedTableHeader, useFixedTableHeaderStyle } from "general/components/customDataGrid/CustomDataGrid";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

export interface CustomTableProps {
  isLoading?: boolean;
  fixedTableHeader?: CustomFixedTableHeader | boolean;
  headerChildren?: React.ReactNode;
}

export function CustomTable(props: PropsWithChildren<CustomTableProps>): ReactElement {
  return (props.isLoading ?
    <CircularProgressBox pt={10} pb={12} /> :
    <CustomTableInternal {...props} />
  );
}

interface CustomTableInternalProps extends PropsWithChildren<CustomTableProps> { }

function CustomTableInternal(props: CustomTableInternalProps): ReactElement {
  const { children, headerChildren, fixedTableHeader } = props;
  const classes = useStyles();

  const tableContainerStyle = {
    marginLeft: '0px', // Overwrite tableContainer class style => better all margins 0px
    marginRight: '0px',
  } as CSSProperties

  return (
    <>
      <div style={tableContainerStyle} className={classes.tableContainer}>
        <ScrollSync proportional={false}>
          <div>
            <ScrollSyncPane>
              <div className={cx(classes.tableHeadContainer, { fixedTableHeader: useFixedTableHeader(fixedTableHeader) })}
                style={useFixedTableHeaderStyle(fixedTableHeader)}>
                <div>
                  {headerChildren}
                </div>
              </div>
            </ScrollSyncPane>
            <ScrollSyncPane>
              <div className={classes.tableBodyContainer}>
                <div className={classes.tableBody}>
                  {children}
                </div>
              </div>
            </ScrollSyncPane>
          </div>
        </ScrollSync>
      </div>
    </>
  );
}