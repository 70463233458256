import { createStyles, Theme } from '@material-ui/core/styles';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const AppHeaderStyle = (theme: Theme) =>
	createStyles({
		secondaryBar: {
			zIndex: 0,
		},
		navigationButton: {
			marginLeft: -theme.spacing(1),
		},
		iconButtonAvatar: {
			padding: 4,
		},
		link: {
			textDecoration: 'none',
			color: lightColor,
			'&:hover': {
				color: theme.palette.common.white,
			},
		},
		button: {
			borderColor: lightColor,
		},
	});

export default AppHeaderStyle;