class CompanyTenantEmailSetting {
  tenantId: string | undefined;
  signature: string | undefined;
  ownerEmail: string | undefined;
  email: string | undefined;
  emailToVerify: string | undefined;
  emailVerificationExpirationDate: Date | undefined;

  constructor(dataEmailSetting?: any) {
    if (dataEmailSetting) {
      this.tenantId = dataEmailSetting.tenantId;
      this.signature = dataEmailSetting.signature;
      this.ownerEmail = dataEmailSetting.ownerEmail;
      this.email = dataEmailSetting.email;
      this.emailToVerify = dataEmailSetting.emailToVerify;
      this.emailVerificationExpirationDate = dataEmailSetting.emailVerificationExpirationDate;
    }
  }
}

class CompanyTenantEmailVerification {
  tenantId: string | undefined;
  emailToVerify: string | undefined;
  lang: string | undefined;
}

class CompanyTenantEmailConfirmRequest {
  token: string | undefined;
}

export default CompanyTenantEmailSetting;
export { CompanyTenantEmailVerification, CompanyTenantEmailConfirmRequest };