import React, { useContext, useEffect, useRef } from 'react';
import { Route, Redirect, RouteProps } from "react-router-dom";
import RouteContext from 'general/RouteContext';
import AccountContext from 'account/AccountContext';
import AccountProvider from 'account/AccountProvider';
import Routes from 'general/helpers/Routes';

interface PrivateRouteProps extends RouteProps {
  title: string;
  setRouteOptions?: boolean;
  setRouteTitle?: boolean;
  isNavBackVisible?: boolean;
}

function PrivateRoute(props: PrivateRouteProps) {
  const { title, setRouteOptions, setRouteTitle, isNavBackVisible } = props;
  const routeContext = useRef(useContext(RouteContext));
  const accountContext = useContext(AccountContext);

  useEffect(() => {
    if (setRouteOptions ?? true) {
      routeContext?.current?.setTitle((setRouteTitle ?? true) ? title : '', isNavBackVisible ?? false);
    }
  }, [title, setRouteOptions, setRouteTitle, isNavBackVisible]);

  function LoginRedirect(location: any) {
    // Set path for callback routing 
    // to navigate the user to the prefered url
    AccountProvider.CurrentAfterLoginRedirectUrl = props.location?.pathname ?? undefined;

    // Changed to location.pathname otherwise * paths will not go
    /*
    if (typeof props.path === 'string') {
        AccountProvider.CurrentAfterLoginRedirectUrl = props.path ?? undefined;
    } else if (props.path && props.path.length > 0) {
        AccountProvider.CurrentAfterLoginRedirectUrl = props.path[0] ?? undefined;
    } else {
        AccountProvider.CurrentAfterLoginRedirectUrl = undefined;
    }
    */

    console.log('CurrentAfterLoginRedirectUrl', AccountProvider.CurrentAfterLoginRedirectUrl);

    return (
      <Redirect
        to={{
          pathname: Routes.Account.Login,
          state: { from: location }
        }}
      />);
  }

  return (
    accountContext?.isInitialized ? (
      <Route
        location={props.location}
        component={props.component}
        children={undefined}
        path={props.path}
        exact={props.exact}
        sensitive={props.sensitive}
        strict={props.strict}
        render={({ location }) =>
          accountContext?.user?.isAuthenticated ? (
            props.children
          ) : (LoginRedirect(location))
        }
      />) : null
  );
}

export default PrivateRoute;
