import BaseGroup, { BaseRightGroup } from "company/users/models/BaseGroup";

class ManageGroup extends BaseGroup {

  constructor(dataGroup?: any) {
    if (dataGroup) {
      super(dataGroup);
    } else {
      super();
    }
  }
}

class ManageRightGroup extends BaseRightGroup {

  constructor(dataRightGroup?: any) {
    if (dataRightGroup) {
      super(dataRightGroup);
    } else {
      super();
    }
  }
}

export default ManageGroup;
export { ManageRightGroup };