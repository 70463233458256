import BaseService from "general/helpers/api/BaseService";
import { ManageUserHasRight } from "manage/users/models/ManageRight";
import ManageUserHasRightsView from "manage/users/models/ManageUserHasRightsView";

class ManageUserRightsService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/manage/users`;

  constructor(subjectId: string) {
    super();
    this._serviceUrl = `${this._serviceUrl}/${subjectId}/rights`;
  }

  public async getRightsView(lang: string): Promise<ManageUserHasRightsView> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}?lang=${lang}`);
    return new ManageUserHasRightsView(resp.data);
  }

  public async createOrUpdateRights(userRights: Array<ManageUserHasRight>): Promise<void> {
    await (await this.useApiWithAuth())
      .post(this._serviceUrl,
        JSON.stringify(userRights));
  }
}

export default ManageUserRightsService;