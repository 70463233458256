import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { Tab, Tabs, withStyles, WithStyles } from "@material-ui/core";
import { Person as PersonIcon, Group as GroupIcon } from '@material-ui/icons';
import AppStyle from 'general/styles/AppStyle';
import { useTranslation } from 'react-i18next';
import { right_Company_Users_Groups_View, right_Company_Users_Users_View, useRight } from 'account/models/Rights';
import AccountContext from 'account/AccountContext';
import TabPanel from 'general/components/container/TabPanel';
import RouteContext from 'general/RouteContext';
import RightDetailsUsers from 'company/users/views/RightDetailsUsers';
import RightDetailsGroups from 'company/users/views/RightDetailsGroups';
import { RightDetailsState } from 'company/users/models/CompanyUsersState';

export enum RightDetailsTabs {
	users = 0,
	groups = 1,
}

interface RightDetailsParams {
	rightId: string;
	rightName: string;
}

export interface RightDetailsProps extends WithStyles<typeof AppStyle> { }

function RightDetails(props: RightDetailsProps) {
	//const classes = props.classes;
	const { rightId, rightName } = useParams<RightDetailsParams>();
	const { t } = useTranslation(['company']);
	const location = useLocation();
	const [tabValue, setTabValue] = useState<RightDetailsTabs | undefined>(undefined);
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
	const accountContext = useContext(AccountContext);
	const hasUsersViewRight = useRight(right_Company_Users_Users_View, accountContext);
	const hasGroupsViewRight = useRight(right_Company_Users_Groups_View, accountContext);

	useEffect(() => {
		// Set HeaderText and display GoBackButton
		initRouteContext.current.setTitle(rightName, true);

		const savedState = location?.state as RightDetailsState;
		console.log('RightDetails.savedState', savedState);

		if (savedState?.selectedTab !== undefined) {
			// State saved => load saved tab for go back navigation
			setTabValue(savedState.selectedTab);
		} else {
			// No state saved => load default tab
			setTabValue(RightDetailsTabs.users);
		}
	}, [rightName, location, initRouteContext]);

	function handleTabChange(event: React.ChangeEvent<{}>, newValue: RightDetailsTabs) {
		setTabValue(newValue);
	}

	return (
		<>
			{tabValue !== undefined ?
				<>
					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						variant="scrollable"
						scrollButtons="auto"
					>
						{hasUsersViewRight ?
							<Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> {t('company:rightDetails.tabs.users')} </div>} value={RightDetailsTabs.users} /> : null}
						{hasGroupsViewRight ?
							<Tab label={<div><GroupIcon style={{ verticalAlign: 'middle' }} /> {t('company:rightDetails.tabs.groups')} </div>} value={RightDetailsTabs.groups} /> : null}
					</Tabs>
					{hasUsersViewRight ?
						<TabPanel selectedValue={tabValue} value={RightDetailsTabs.users}>
							<RightDetailsUsers rightId={rightId} />
						</TabPanel> : null}
					{hasGroupsViewRight ?
						<TabPanel selectedValue={tabValue} value={RightDetailsTabs.groups}>
							<RightDetailsGroups rightId={rightId} />
						</TabPanel> : null}
				</> : null}
		</>
	);
}

export default withStyles(AppStyle)(RightDetails);