import BaseService from "general/helpers/api/BaseService";
import ManageRight from "manage/users/models/ManageRight";
import { ManageRightUser } from "manage/users/models/ManageUser";
import { ManageRightGroup } from "manage/users/models/ManageGroup";

class ManageRightsService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/manage/rights`;

  public async getRights(searchText: string, lang: string): Promise<Array<ManageRight>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new ManageRight(data);
    });
  }

  public async getUsersHasRight(rightId: string, searchText: string, lang: string): Promise<Array<ManageRightUser>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${rightId}/users/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new ManageRightUser(data);
    });
  }

  public async getGroupsHasRight(rightId: string, searchText: string, lang: string): Promise<Array<ManageRightGroup>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${rightId}/groups/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new ManageRightGroup(data);
    });
  }
}

export default ManageRightsService;