import React, { useState } from 'react';
import { Grid, Box, TextField } from '@material-ui/core';
import BoxedAlert from 'general/components/common/BoxedAlert';
import EditActionButtonsGroup from 'general/components/common/EditActionButtonsGroup';
import TenantPaperTitle, { TenantPaperTitleOption } from 'company/tenants/components/TenantPaperTitle';
import { TenantDetailsProps } from './TenantDetails';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CompanyTenantEmailSettingsService from '../helpers/CompanyTenantEmailSettingsService';
import CompanyTenantEmailSetting from '../models/CompanyTenantEmailSetting';

export interface TenantDetailsEmailEditProps extends TenantDetailsProps {
  tenantEmailSetting: CompanyTenantEmailSetting | undefined;
  tenantEmailSettingsService: CompanyTenantEmailSettingsService;
  onSaved: () => void;
  onCanceled: () => void;
}

function TenantDetailsEmailEdit(props: TenantDetailsEmailEditProps) {
  const { classes, tenantEmailSetting, tenantEmailSettingsService, onSaved, onCanceled } = props;
  const { t } = useTranslation(['company']);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { register, getValues, trigger, errors } =
    useForm({
      defaultValues: {
        signature: tenantEmailSetting?.signature,
      }
    });

  async function saveAsync() {
    setErrorMessage('');

    if (!await trigger()) return;

    const data = getValues();
    const changedSetting = new CompanyTenantEmailSetting();
    changedSetting.tenantId = tenantEmailSetting?.tenantId;
    changedSetting.signature = data.signature;

    try {
      setSaveIsLoading(true);

      // Send changed data
      await tenantEmailSettingsService.updateTenantEmailSetting(changedSetting);

      // Go back to view mode
      onSaved();

    } catch (error) {
      console.error(error);
      setErrorMessage(error?.message ?? '');
      setSaveIsLoading(false);
    };
  }

	const valMaxLength500 = t('validation:maxLength', { maxLength: 500 });
  return (
    <>
      <Box className={classes.portalPaperTitleBox}>
        <TenantPaperTitle option={TenantPaperTitleOption.email} />
        <EditActionButtonsGroup
          editMode={true}
          onSaveButtonClick={saveAsync}
          onCancelButtonClick={onCanceled}
          disabled={saveIsLoading}
        />
      </Box>
      <Box className={classes.portalPaperContentBox}>
        <BoxedAlert message={errorMessage} severity="error" />
        <Grid container spacing={2} className={classes.defaultFormGrid}>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={6}
              rowsMax={20}
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyTenantEmailSetting.signature')}
              name="signature"
              autoComplete="signature"
              inputRef={register({ maxLength: { value: 500, message: valMaxLength500 } })}
              error={!!errors.signature}
              helperText={errors.signature ? errors.signature.message : ''}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default TenantDetailsEmailEdit;