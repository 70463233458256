import React, { useMemo, useEffect, useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, Paper, withStyles, WithStyles } from '@material-ui/core';
import SearchBar from 'general/components/common/SearchBar';
import { Alert } from '@material-ui/lab';
import ManageUser from 'manage/users/models/ManageUser';
import AppStyle from 'general/styles/AppStyle';
import RolesHelper from 'idserver/models/Roles';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import { Row, TableInstance } from 'react-table';
import Routes from 'general/helpers/Routes';
import ManageUsersService from 'manage/users/helpers/ManageUsersService';
import { ManageUsersTabs } from 'manage/users/views/ManageUsers';
import ManageUsersState from 'manage/users/models/ManageUsersState';
import { useScroll } from 'general/helpers/ui/ScrollHelper';
import { RowCheckbox } from 'general/components/customDataGrid/styles/TableStyles';
import { BooleanFilterKey, CheckBoxColumnFilter, DateColumnFilter, FilterSelectOptionsProp, SelectColumFilter, SelectFilterKey } from 'general/components/customDataGrid/filters';
import RouteContext from 'general/RouteContext';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import BaseState from 'general/models/BaseState';
import AccountContext from 'account/AccountContext';
import { useRight, right_Manage_Users_Users_Edit } from 'account/models/Rights';
import StringHelper from 'general/helpers/types/StringHelper';

export type SuccessUserDeletedAlert = {
	visible: boolean;
	username?: string;
}

export type UsersRouteState = {
	successUserDeletedAlert?: SuccessUserDeletedAlert;
}

interface UsersProps extends WithStyles<typeof AppStyle> { }

function Users(props: UsersProps) {
	const classes = props.classes;
	const { t } = useTranslation(['common', 'manage', 'idserver']);
	const routeHistory = useHistory();
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
	const [userRows, setUserRows] = useState(new Array<ManageUser>());
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [successUserDeletedAlert, setSuccessUserDeletedAlert] = useState<SuccessUserDeletedAlert | undefined>(undefined);
	const [searchText, setSearchText] = useState<string | undefined>();
	const [tableLoadPageIndex, setTableLoadPageIndex] = useState<boolean | undefined>();
	const [setScrollToY, raiseScrollToY] = useScroll();
	const usersService = useRef(new ManageUsersService());
	const accountContext = useContext(AccountContext);
	const hasUsersEditRight = useRight(right_Manage_Users_Users_Edit, accountContext);

	useEffect(() => {
		console.log('Init load users');
		const savedState = routeHistory.location?.state as ManageUsersState
		if (savedState?.users) {
			// Backup state
			const { scrollY, searchText } = savedState.users;

			// Clean up state
			savedState.users = undefined;
			routeHistory.replace(routeHistory.location.pathname, savedState);

			// Set saved state
			setScrollToY(scrollY);
			setTableLoadPageIndex(true);
			setSearchText(searchText);

			// Read RouteContext state (Custom state that is set by goBack navigation and can't handle by routeHistory)
			const contextState = initRouteContext.current.routeStates[Routes.Portal.Manage.Users] as UsersRouteState;
			if (contextState) {
				// Set saved RouteContext state
				setSuccessUserDeletedAlert(contextState?.successUserDeletedAlert);
			}

		} else {
			// Load users
			getUsersAsync(searchText, raiseScrollToY);
		}

		// Clean up RouteContext state
		initRouteContext.current.clearRouteState(Routes.Portal.Manage.Users);

	}, [searchText, routeHistory, initRouteContext, setScrollToY, raiseScrollToY]);

	async function getUsersAsync(paramSearchText: string | undefined,
		raiseScrollToY: () => void) {
		setErrorMessage('');
		setIsLoading(true);
		try {
			const users = await usersService.current.getUsers(paramSearchText ?? '');
			setUserRows(users);
		} catch (error) {
			console.error(error);
			setErrorMessage(error?.message ?? '');
		} finally {
			// Reset
			setIsLoading(false);
			setTableLoadPageIndex(undefined);
			raiseScrollToY();
		}
	}

	function searchTextChanged(value: string) {
		setSearchText(value);
	}

	function saveCurrentRouteState() {
		const currentState = new ManageUsersState();
		currentState.selectedTab = ManageUsersTabs.users;
		currentState.users = new BaseState();
		currentState.users.scrollY = window.scrollY;
		currentState.users.searchText = searchText ?? '';
		routeHistory.replace(routeHistory.location.pathname, currentState);
	}

	function rowClicked(row: Row<ManageUser>) {
		const selectedSubjectId = row?.original?.subjectId ?? '';
		if (!StringHelper.empty(selectedSubjectId)) {
			// Save current state before redirect
			saveCurrentRouteState();

			// Redirect
			routeHistory.push(`${Routes.Portal.Manage.UserDetails}/${selectedSubjectId}`);
		}
	}

	const tableOnAdd = (instance: TableInstance<ManageUser> | undefined) => () => {
		// Save current state before redirect
		saveCurrentRouteState();

		// Redirect
		routeHistory.push(Routes.Portal.Manage.UserCreate);
	};

	const userColumns = useMemo(() => {
		console.log('Init user columns');
		return [
			{
				Header: 'manageUsers',
				HideHeader: true,
				columns: [
					{
						Header: t('manage:manageUser:email'),
						accessor: 'email',
						width: 200,
					},
					{
						Header: t('manage:manageUser:givenName'),
						accessor: 'givenName',
						width: 150,
					},
					{
						Header: t('manage:manageUser:familyName'),
						accessor: 'familyName',
						width: 180,
					},
					{
						Header: t('manage:manageUser:name'),
						accessor: 'name',
						width: 180,
					},
					{
						Header: t('manage:manageUser:phoneNumber'),
						accessor: 'phoneNumber',
						width: 150,
					},
					{
						Header: t('manage:manageUser:roles'),
						accessor: 'rolesList',
						width: 150,
						//canGroupBy: true,
						Cell: ({ value }: any) =>
						(
							<>{RolesHelper.getRolesText((value as string[]), t)}</>
						),
						Filter: SelectColumFilter,
						[FilterSelectOptionsProp]: RolesHelper.getRolesSelectOptions(t),
						filter: SelectFilterKey,
					},
					{
						Header: t('manage:manageUser:tenants'),
						accessor: 'tenants',
						width: 150,
					},
					{
						Header: t('manage:manageUser:createDate'),
						accessor: 'createDate',
						width: 180,
						Cell: ({ value }: any) =>
						(
							<>{moment(value as Date).format('L LT')}</>
						),
						Filter: DateColumnFilter,
					},
					{
						Header: t('manage:manageUser:lastSignInDate'),
						accessor: 'lastSignInDate',
						width: 180,
						Cell: ({ value }: any) =>
						(
							<>{value ? moment(value as Date).format('L LT') : ''}</>
						),
						Filter: DateColumnFilter,
					},
					{
						Header: t('manage:manageUser:isActive'),
						accessor: 'isActive',
						minWidth: 90,
						width: 100,
						align: 'center',
						Cell: ({ value }: any) =>
						(
							<RowCheckbox checked={value} color="default" className={classes.displayCheckbox} />
						),
						Filter: CheckBoxColumnFilter,
						filter: BooleanFilterKey,
					},
					{
						Header: t('manage:manageUser:isEmailVerified'),
						accessor: 'isEmailVerified',
						minWidth: 90,
						width: 150,
						align: 'center',
						Cell: ({ value }: any) =>
						(
							<RowCheckbox checked={value} color="default" className={classes.displayCheckbox} />
						),
						Filter: CheckBoxColumnFilter,
						filter: BooleanFilterKey,
					},
				]
			},
		];
	}, [t, classes]);

	return (
		<div>
			<Grid container>
				{errorMessage !== '' ?
					<Grid item xs={12}>
						<Box paddingBottom={1}>
							<Alert severity="error" variant="filled">
								{errorMessage}
							</Alert>
						</Box>
					</Grid> : null}

				<Grid item xs={12} className={classes.defaultSearchBarGridItem}>
					<Box marginY={1}>
						<SearchBar
							placeholder={t('manage:users.searchBar.placeholder')}
							onSearchTextChanged={searchTextChanged}
							value={searchText}
						//raiseSearchTextChangedOnLetter={3}
						/>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Paper>
						<CustomDataGrid<ManageUser>
							name="manageUsers"
							isLoading={isLoading}
							columns={userColumns}
							data={userRows}
							//disableRowSelection={false}
							loadLastPageIndex={tableLoadPageIndex}
							fixedTableHeader={true}
							//fixedTableHeader={{disabled: true, topPosition: 140}}
							//fixedTableHeader={{topPosition: (useMediaQuery(useTheme().breakpoints.up('md')) ? 48 * 2 : 48)}}
							//fixedTableHeader={{topPosition: (useMediaQuery(useTheme().breakpoints.up('md')) ? 48 * 3 : 48 * 2)}}
							onClick={rowClicked}
							onAdd={tableOnAdd}
							onAddDisabled={!hasUsersEditRight}
						//onEdit={dummy}
						//onDelete={dummy}
						/>
					</Paper>
				</Grid>
			</Grid>

			<SnackbarAlert open={successUserDeletedAlert?.visible ?? false} onClose={() => setSuccessUserDeletedAlert(undefined)}
				message={
					<Trans i18nKey="manage:users.successUserDeleted">
						User <strong>{{ username: successUserDeletedAlert?.username }}</strong> deleted successfully!
					</Trans>}
				severity="success" />
		</div >
	);
}

export default withStyles(AppStyle)(Users);