import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PortalPaper from 'general/components/container/PortalPaper';
import { Box } from '@material-ui/core';
import CompanyUser from 'company/users/models/CompanyUser';
import CompanyUserRightsService from 'company/users/helpers/CompanyUserRightsService';
import { UserDetailsProps } from "company/users/views/UserDetails";
import UserRightsEdit from 'company/users/views/UserRightsEdit';
import UserPaperTitle, { UserPaperTitleOption } from 'company/users/components/UserPaperTitle';
import EditActionButtonsGroup from 'general/components/common/EditActionButtonsGroup';
import CompanyUserHasRightsView, { CompanyUserHasRightsViewRow } from 'company/users/models/CompanyUserHasRightsView';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import { CustomTable, CustomTableHeadRow, CustomTableHeadCell, CustomTableRow, CustomTableCell, AlignX, CustomTableRowCheckbox, CustomCheckboxStyle } from 'general/components/customTable';
import CompanyGroup from 'company/users/models/CompanyGroup';
import BoxedAlert from 'general/components/common/BoxedAlert';
import AccountContext from 'account/AccountContext';
import { right_Company_Users_Rights_Edit } from 'account/models/Rights';

export interface UserRightsProps extends UserDetailsProps {
	user: CompanyUser | undefined;
}

function UserRights(props: UserRightsProps) {
	enum viewMode { view, edit }
	const { classes, user } = props;
	const { t, i18n } = useTranslation(['company']);
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [currentViewMode, setCurrentViewMode] = useState(viewMode.view);
	const [showSuccessRightsSaved, setShowSuccessRightsSaved] = useState(false);
	const [rightsData, setRightsData] = useState<CompanyUserHasRightsView | undefined>(undefined);
	const userRightsService = useMemo(() => new CompanyUserRightsService(user?.subjectId ?? ''), [user]);
	const accountContext = useContext(AccountContext);
	const hasEditRight = useMemo(() => accountContext?.user?.hasRight(right_Company_Users_Rights_Edit), [accountContext]);

	useEffect(() => {
		const lang = i18n.language;
		getUserRightsAsync(user, rightsData, userRightsService, lang);
	}, [user, rightsData, userRightsService, i18n.language]);

	async function getUserRightsAsync(
		currentUser: CompanyUser | undefined,
		currentRightsData: CompanyUserHasRightsView | undefined,
		currentService: CompanyUserRightsService,
		lang: string) {
		if (currentUser && currentRightsData === undefined) {
			setErrorMessage('');
			setIsLoading(true);
			try {
				console.log('Load rights', currentUser?.subjectId);

				// Load rights data
				const viewData = await currentService.getRightsView(lang);
				setRightsData(viewData);

			} catch (error) {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			} finally {
				setIsLoading(false);
			}
		}
	}

	function saved() {
		setCurrentViewMode(viewMode.view);

		// Trigger rights reload
		setRightsData(undefined);

		// Show info
		setShowSuccessRightsSaved(true);
	}

	function canceled() {
		setCurrentViewMode(viewMode.view);
	}

	function editButtonClick() {
		setCurrentViewMode(viewMode.edit);
	}

	function RightsTable() {
		const columns = {
			name: {
				width: 200,
			},
			isAllowed: {
				width: 110,
				alignX: AlignX.center,
			},
			userIsAllowed: {
				width: 110,
				alignX: AlignX.center,
			},
			groupsIsAllowed: {
				width: 110,
				alignX: AlignX.center,
			},
			description: {
				width: 600
			},
		};

		return (<CustomTable
			isLoading={isLoading}
			fixedTableHeader={true}
			headerChildren={
				<CustomTableHeadRow>
					<CustomTableHeadCell width={columns.name.width}>{t('company:companyRight.name')}</CustomTableHeadCell>
					<CustomTableHeadCell width={columns.isAllowed.width} alignX={columns.isAllowed.alignX}>{t('company:companyRight.isAllowed')}</CustomTableHeadCell>
					<CustomTableHeadCell width={columns.userIsAllowed.width} alignX={columns.userIsAllowed.alignX}>{t('company:userDetails.rightsTab.columnUserIsAllowedTitle')}</CustomTableHeadCell>
					<CustomTableHeadCell width={columns.groupsIsAllowed.width} alignX={columns.groupsIsAllowed.alignX}>{t('company:userDetails.rightsTab.columnGroupsIsAllowedTitle')}</CustomTableHeadCell>
					{rightsData?.groups?.map((group: CompanyGroup, indexGroup: number) => {
						return (<CustomTableHeadCell
							key={`customTableHeadCell-groupRight-${indexGroup}`}
							width={columns.groupsIsAllowed.width}
							alignX={columns.groupsIsAllowed.alignX}>
							{`${t('company:companyRight.isAllowed')} ${group.groupName}`}
						</CustomTableHeadCell>);
					})}
					<CustomTableHeadCell width={columns.description.width}>{t('company:companyRight.description')}</CustomTableHeadCell>
				</CustomTableHeadRow>}>
			{rightsData?.rights?.map((row: CompanyUserHasRightsViewRow, index: number) => {
				return (
					<CustomTableRow key={`customTableRow-right-${index}`} id={row?.right?.rightId ?? ''}>
						<CustomTableCell width={columns.name.width}>{row?.right?.name}</CustomTableCell>
						<CustomTableCell width={columns.isAllowed.width} alignX={columns.isAllowed.alignX} isCheckbox={true}>
							<CustomTableRowCheckbox
								checked={row?.isAllowed ?? undefined}
								indeterminate={row?.isAllowed === null}
								color="default"
								customStyle={{useCustomColor: true} as CustomCheckboxStyle}
								className={classes.displayCheckbox} />
						</CustomTableCell>
						<CustomTableCell width={columns.userIsAllowed.width} alignX={columns.userIsAllowed.alignX} isCheckbox={true}>
							<CustomTableRowCheckbox
								checked={row?.userIsAllowed ?? undefined}
								indeterminate={row?.userIsAllowed === null}
								color="default"
								className={classes.displayCheckbox} />
						</CustomTableCell>
						<CustomTableCell width={columns.groupsIsAllowed.width} alignX={columns.groupsIsAllowed.alignX} isCheckbox={true}>
							<CustomTableRowCheckbox
								checked={row?.groupsIsAllowed ?? undefined}
								indeterminate={row?.groupsIsAllowed === null}
								color="default"
								className={classes.displayCheckbox} />
						</CustomTableCell>
						{rightsData?.groups?.map((group: CompanyGroup, indexGroup: number) => {
							const groupRight = row?.getGroupRight(group?.groupId ?? '');
							return (<CustomTableCell
								key={`customTableCell-groupRight-${index}-${indexGroup}`}
								width={columns.groupsIsAllowed.width}
								alignX={columns.groupsIsAllowed.alignX}>
								<CustomTableRowCheckbox
									checked={groupRight.isAllowed ?? undefined}
									indeterminate={groupRight.isAllowed === null}
									color="default"
									className={classes.displayCheckbox} />
							</CustomTableCell>);
						})}
						<CustomTableCell width={columns.description.width}>{row?.right?.description}</CustomTableCell>
					</CustomTableRow>);
			})}
		</CustomTable>);
	}

	function RightsView() {
		return (
			<>
				<Box className={classes.portalPaperTitleBox}>
					<UserPaperTitle option={UserPaperTitleOption.rights} />
					<EditActionButtonsGroup
						editMode={false}
						onEditButtonClick={editButtonClick}
						disabled={isLoading}
						disabledEdit={!hasEditRight}
					/>
				</Box>
				<Box className={classes.portalPaperContentBox}>
					<BoxedAlert message={errorMessage} severity="error" />
					<RightsTable />
				</Box>
			</>
		);
	}
	
	return (
		<>
			<Box mt={1}>
				<PortalPaper>
					{(currentViewMode === viewMode.view) ?
						<RightsView /> :
						<UserRightsEdit
							{...props}
							rightsData={rightsData}
							userRightsService={userRightsService}
							onSaved={saved}
							onCanceled={canceled} />}
				</PortalPaper>
			</Box>

			<SnackbarAlert open={showSuccessRightsSaved} onClose={() => setShowSuccessRightsSaved(false)}
				message={t('company:userDetails.rightsTab.successRightsSaved')} severity="success" />
		</>
	);
}

export default UserRights;