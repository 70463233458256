import BaseService from "general/helpers/api/BaseService";
import CompanyGroup from "company/users/models/CompanyGroup";

class CompanyGroupsService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/company/groups`;

  public async getGroups(searchText: string, lang: string): Promise<Array<CompanyGroup>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new CompanyGroup(data);
    });
  }

  public async getTenantGroups(tenantId: string, searchText: string, lang: string): Promise<Array<CompanyGroup>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${tenantId}/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new CompanyGroup(data);
    });
  }

  public async getGroup(groupId: string, lang: string): Promise<CompanyGroup> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${groupId}?lang=${lang}`);
    return new CompanyGroup(resp.data);
  }

  public async createGroup(group: CompanyGroup): Promise<CompanyGroup> {
    const resp = await (await this.useApiWithAuth())
      .post(this._serviceUrl,
        JSON.stringify(group));
    return new CompanyGroup(resp.data);
  }

  public async updateGroup(group: CompanyGroup): Promise<void>{
    await (await this.useApiWithAuth())
      .put(`${this._serviceUrl}/${group.groupId}`,
        JSON.stringify(group));
  }

  public async deleteGroup(groupId: string): Promise<void>{
    await (await this.useApiWithAuth())
      .delete(`${this._serviceUrl}/${groupId}`);
  }
}

export default CompanyGroupsService;