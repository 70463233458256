import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { Box, Tab, Tabs, withStyles, WithStyles } from "@material-ui/core";
import { Business as BusinessIcon, Email as EmailIcon, Palette as PaletteIcon } from '@material-ui/icons';
import AppStyle from 'general/styles/AppStyle';
import { Trans, useTranslation } from 'react-i18next';
import { right_Company_Tenants_View, useRight } from 'account/models/Rights';
import AccountContext from 'account/AccountContext';
import TabPanel from 'general/components/container/TabPanel';
import RouteContext from 'general/RouteContext';
import { TenantDetailsState } from '../models/CompanyTenantsState';
import Routes from 'general/helpers/Routes';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import PortalPaper from 'general/components/container/PortalPaper';
import TenantDetailsTenantView from './TenantDetailsTenantView';
import CompanyTenant, { CompanyTenantFavicon } from '../models/CompanyTenant';
import CompanyTenantsService from '../helpers/CompanyTenantsService';
import TenantDetailsTenantEdit from './TenantDetailsTenantEdit';
import CircularProgressBox from 'general/components/common/CircularProgressBox';
import BoxedAlert from 'general/components/common/BoxedAlert';
import TenantDetailsEmailView from './TenantDetailsEmailView';
import TenantDetailsEmailEdit from './TenantDetailsEmailEdit';
import CompanyTenantEmailSetting from '../models/CompanyTenantEmailSetting';
import CompanyTenantEmailSettingsService from '../helpers/CompanyTenantEmailSettingsService';
import TenantDetailsDesign from './TenantDetailsDesign';

export enum TenantDetailsTabs {
	tenant = 0,
	email = 1,
	design = 2,
}

interface TenantDetailsParams {
	tenantId: string;
	tenantName: string;
}

export type SuccessTenantCreatedAlert = {
	visible: boolean;
	name?: string;
}

export type TenantDetailsRouteState = {
	successTenantCreatedAlert?: SuccessTenantCreatedAlert;
}

export interface TenantDetailsProps extends WithStyles<typeof AppStyle> { }

function TenantDetails(props: TenantDetailsProps) {
	enum viewMode { view, edit }
	//const classes = props.classes;
	const { tenantId, tenantName } = useParams<TenantDetailsParams>();
	const { t } = useTranslation(['company']);
	const location = useLocation();
	const [tabValue, setTabValue] = useState<TenantDetailsTabs | undefined>(TenantDetailsTabs.tenant);
	const [currentViewMode, setCurrentViewMode] = useState(viewMode.view);
	const [currentViewModeEmailSettings, setCurrentViewModeEmailSettings] = useState(viewMode.view);
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [tenant, setTenant] = useState<CompanyTenant | undefined>(undefined);
	const [tenantEmailSetting, setTenantEmailSetting] = useState<CompanyTenantEmailSetting | undefined>(undefined);
	const [showSuccessTenantSaved, setShowSuccessTenantSaved] = useState(false);
	const [successTenantCreatedAlert, setSuccessTenantCreatedAlert] = useState<SuccessTenantCreatedAlert | undefined>(undefined);
	const [showSuccessEmailSent, setShowSuccessEmailSent] = useState(false);
	const initRouteContext = useRef(useContext(RouteContext));
	const tenantsService = useRef(new CompanyTenantsService());
	const tenantEmailSettingsService = useRef(new CompanyTenantEmailSettingsService());
	const accountContext = useContext(AccountContext);
	const hasTenantsViewRight = useRight(right_Company_Tenants_View, accountContext);

	useEffect(() => {
		getTenantAsync(tenantId, tenant);

		// Set HeaderText and display GoBackButton
		initRouteContext.current.setTitle(tenantName, true);

		const savedState = location?.state as TenantDetailsState;
		console.log('TenantDetails.savedState', savedState);

		if (savedState?.selectedTab !== undefined) {
			// State saved => load saved tab for go back navigation
			setTabValue(savedState.selectedTab);

		}

		// Show tennt created success message if redirect from TenantCreate view
		const contextState = initRouteContext.current.routeStates[Routes.Portal.Company.TenantDetails] as TenantDetailsRouteState;
		if (contextState) {
			// Set saved RouteContext state
			setSuccessTenantCreatedAlert(contextState?.successTenantCreatedAlert);
		}

		// Clean up RouteContext state
		initRouteContext.current.clearRouteState(Routes.Portal.Company.TenantDetails);

	}, [tenantId, tenantName, tenant, location, initRouteContext]);

	async function getTenantAsync(tenantId: string,
		currentTenant: CompanyTenant | undefined) {
		if (!currentTenant || currentTenant?.tenantId !== tenantId) {
			setErrorMessage('');
			setIsLoading(true);
			try {
				// Load tenant data
				console.log('Load tenant', tenantId);
				const tenant = await tenantsService.current.getTenant(tenantId);

				// Load tenant email setting
				console.log('Load tenantEmailSetting', tenantId);
				const emailSetting = await
					tenantEmailSettingsService.current.getTenantEmailSetting(tenantId ?? '');

				setTenant(tenant);
				setTenantEmailSetting(emailSetting);

			} catch (error) {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			} finally {
				setIsLoading(false);
			}
		}
	}

	function handleTabChange(event: React.ChangeEvent<{}>, newValue: TenantDetailsTabs) {
		setTabValue(newValue);
	}

	function handleTenantSelectedThemeChanged(selectedThemeId: string) {
		if (!tenant) return;
		tenant.selectedThemeId = selectedThemeId;
		setTenant(tenant);
	}

	function handleTenantFaviconChanged(favicon: CompanyTenantFavicon) {
		if (!tenant) return;
		tenant.favicon = favicon;
		setTenant(tenant);
	}

	function tenantSaved() {
		setCurrentViewMode(viewMode.view);

		// Trigger reload
		setTenant(undefined);

		// Show info
		setShowSuccessTenantSaved(true);

		// Set tenant tab
		setTabValue(TenantDetailsTabs.tenant);
	}

	function tenantEmailSettingsSaved() {
		setCurrentViewModeEmailSettings(viewMode.view);

		// Trigger reload
		//setTenantEmailSetting(undefined); Not checked on reload
		setTenant(undefined);

		// Show info
		setShowSuccessTenantSaved(true);

		// Set email tab
		setTabValue(TenantDetailsTabs.email);
	}

	function tenantVerificatinEmailSent() {
		// Trigger reload
		setTenant(undefined);

		// Show info
		setShowSuccessEmailSent(true);

		// Set email tab
		setTabValue(TenantDetailsTabs.email);
	}

	return (
		<>
			<BoxedAlert message={errorMessage} severity="error" variant="filled" />

			{isLoading ?
				<CircularProgressBox py={5} /> :
				<>
					{tabValue !== undefined && hasTenantsViewRight ?
						<>
							<Tabs
								value={tabValue}
								onChange={handleTabChange}
								variant="scrollable"
								scrollButtons="auto"
							>
								<Tab label={<div><BusinessIcon style={{ verticalAlign: 'middle' }} /> {t('company:tenantDetails.tabs.tenant')} </div>} value={TenantDetailsTabs.tenant} />
								<Tab label={<div><EmailIcon style={{ verticalAlign: 'middle' }} /> {t('company:tenantDetails.tabs.email')} </div>} value={TenantDetailsTabs.email} />
								<Tab label={<div><PaletteIcon style={{ verticalAlign: 'middle' }} /> {t('company:tenantDetails.tabs.design')} </div>} value={TenantDetailsTabs.design} />
							</Tabs>
							<TabPanel selectedValue={tabValue} value={TenantDetailsTabs.tenant}>
								<Box mt={1}>
									<PortalPaper>
										{(currentViewMode === viewMode.view) ?
											<TenantDetailsTenantView
												{...props}
												tenant={tenant}
												tenantsService={tenantsService.current}
												onEdit={() => setCurrentViewMode(viewMode.edit)}
												onTenantFaviconChanged={handleTenantFaviconChanged}
											/> :
											<TenantDetailsTenantEdit
												{...props}
												tenant={tenant}
												tenantsService={tenantsService.current}
												onSaved={tenantSaved}
												onCanceled={() => setCurrentViewMode(viewMode.view)} />}
									</PortalPaper>
								</Box>
							</TabPanel>
							<TabPanel selectedValue={tabValue} value={TenantDetailsTabs.email}>
								<Box mt={1}>
									<PortalPaper>
										{(currentViewModeEmailSettings === viewMode.view) ?
											<TenantDetailsEmailView
												{...props}
												tenantEmailSetting={tenantEmailSetting}
												tenantEmailSettingsService={tenantEmailSettingsService.current}
												onEdit={() => setCurrentViewModeEmailSettings(viewMode.edit)}
												onEmailSent={tenantVerificatinEmailSent}
											/> :
											<TenantDetailsEmailEdit
												{...props}
												tenantEmailSetting={tenantEmailSetting}
												tenantEmailSettingsService={tenantEmailSettingsService.current}
												onSaved={tenantEmailSettingsSaved}
												onCanceled={() => setCurrentViewModeEmailSettings(viewMode.view)} />}
									</PortalPaper>
								</Box>
							</TabPanel>
							<TabPanel selectedValue={tabValue} value={TenantDetailsTabs.design}>
								<TenantDetailsDesign 
									{...props}
									tenant={tenant}
									onTenantSelectedThemeChanged={handleTenantSelectedThemeChanged} />
							</TabPanel>
						</> : null}

					<SnackbarAlert open={showSuccessTenantSaved} onClose={() => setShowSuccessTenantSaved(false)}
						message={t('company:tenantDetails.successSaved')} severity="success" />

					<SnackbarAlert open={successTenantCreatedAlert?.visible ?? false} onClose={() => setSuccessTenantCreatedAlert(undefined)}
						message={
							<Trans i18nKey="company:tenantDetails.successCreated">
								Brand <strong>{{ name: successTenantCreatedAlert?.name }}</strong> created successfully!
							</Trans>}
						severity="success" />

					<SnackbarAlert open={showSuccessEmailSent} onClose={() => setShowSuccessEmailSent(false)}
						message={
							<Trans i18nKey="company:tenantDetails.emailTab.successSendEmailVerification">
								A link to activate your new email address has been sent to the email address <strong>{{ emailSendTo: tenantEmailSetting?.emailToVerify }}</strong>.
							</Trans>}
						severity="success"
						autoHideDuration={null} />
				</>}
		</>
	);
}

export default withStyles(AppStyle)(TenantDetails);