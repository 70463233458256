export const folder_tenantowner_icons: string = "tenantowner_icons";

export function getUploadAccept(folderKey: string | undefined): string | undefined {
  switch (folderKey) {
    case folder_tenantowner_icons: {
      return '.ico';
    }
    default: {
      return undefined;
    }
  }
}