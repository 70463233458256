import React, { ReactNode } from "react";
import { Box, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface BoxedAlertProps {
  message: string | ReactNode | undefined;
  severity: "error" | "success" | "info" | "warning" | undefined;
  variant?: "outlined" | "filled" | "standard" | undefined;
  container?: "grid" | "gridItem";
  paddingBottom?: number;
}

function BoxedAlert(props: BoxedAlertProps) {
  const { message, severity, variant, container, paddingBottom } = props;
  const paddingBottomValue = (paddingBottom ?? 0);

  function GridItem() {
    return (
      <Grid item xs={12}>
        <Box paddingBottom={container === "gridItem" ? paddingBottomValue : (paddingBottomValue + 2)}>
          <Alert severity={severity} variant={variant}>
            {message}
          </Alert>
        </Box>
      </Grid>
    );
  }

  return (
    <>
      {message ?
        (container === "gridItem") ?
          <GridItem /> :
          <Grid container><GridItem /></Grid> : null}
    </>
  );
}

export default BoxedAlert;