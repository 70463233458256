import React from "react";
import { FilterProps, FilterValue, IdType, Row } from "react-table";
import { Checkbox, FormControlLabel } from "@material-ui/core";

function CheckBoxColumnFilter<T extends object>({
  column: { id, index, filterValue, setFilter, render, parent },
}: FilterProps<T>) {
  return (
    <FormControlLabel
      control={
        <Checkbox checked={filterValue ?? false}
          indeterminate={filterValue === undefined}
          onChange={(e) => setFilter(e.target.checked)} />}
      label={render('Header')} />
  )
}

// If filterValue has a value, then an equals comparison is performed. If undefinded, there is no filtering
function booleanFilter<T extends object>(rows: Array<Row<T>>, id: Array<IdType<T>>, filterValue: FilterValue) {
  if (filterValue === undefined)
    return rows;
  else
    return rows.filter((row) => {
      return (row.values[id[0]] ?? false) === filterValue
    });
}

// Let the table remove the filter if the value is undefined
booleanFilter.autoRemove = (value: any) => value === undefined

const BooleanFilterKey = 'boolean';

export { CheckBoxColumnFilter, booleanFilter, BooleanFilterKey };