import BaseService from "general/helpers/api/BaseService";
import CompanyTenant from "company/tenants/models/CompanyTenant";

class CompanyTenantsService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/company/tenants`;

  public async getTenants(): Promise<Array<CompanyTenant>> {
    const resp = await (await this.useApiWithAuth())
      .get(this._serviceUrl);
    return resp.data.map((data: any) => {
      return new CompanyTenant(data);
    });
  }

  public async getTenant(tenantId: string): Promise<CompanyTenant> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${tenantId}`);
    return new CompanyTenant(resp.data);
  }

  public async createTenant(tenant: CompanyTenant): Promise<CompanyTenant> {
    const resp = await (await this.useApiWithAuth())
      .post(this._serviceUrl,
        JSON.stringify(tenant));
    return new CompanyTenant(resp.data);
  }

  public async updateTenant(tenant: CompanyTenant): Promise<void>{
    await (await this.useApiWithAuth())
      .put(`${this._serviceUrl}/${tenant.tenantId}`,
        JSON.stringify(tenant));
  }

  public async updateTenantSelectedTheme(tenantId: string, themeId: string): Promise<void>{
    await (await this.useApiWithAuth())
      .patch(`${this._serviceUrl}/${tenantId}/theme/${themeId}`);
  }

  public async updateTenantFavicon(tenantId: string, faviconFileId: string): Promise<void>{
    await (await this.useApiWithAuth())
      .patch(`${this._serviceUrl}/${tenantId}/favicon/${faviconFileId}`);
  }

  public async deleteTenant(tenantId: string): Promise<void>{
    await (await this.useApiWithAuth())
      .delete(`${this._serviceUrl}/${tenantId}`);
  }
}

export default CompanyTenantsService;