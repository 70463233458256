import React from 'react'
import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core'
import { CustomTableRowCheckboxStyle } from 'general/components/customTable/styles/TableStyles'
import { green, red } from '@material-ui/core/colors'

const areEqual = (prevProps: any, nextProps: any) =>
  prevProps.checked === nextProps.checked && prevProps.indeterminate === nextProps.indeterminate

export type CustomCheckboxStyle = {
  useCustomColor: boolean | undefined;
  colorChecked: string | undefined;
  colorUnChecked: string | undefined;
  colorIndeterminate: string | undefined;
}

export interface CustomTableRowCheckboxProps extends CheckboxProps {
  customStyle?: CustomCheckboxStyle;
}

export function CustomTableRowCheckbox(props: CustomTableRowCheckboxProps) {
  const { customStyle, ...checkboxProps} = props;
  let styles: any;

  if (customStyle?.useCustomColor ?? false) {
    styles = {
      root: {
        ...CustomTableRowCheckboxStyle,
        color: customStyle?.colorUnChecked ?? red[600],
        '&$checked': {
          color: customStyle?.colorChecked ?? green[600],
        },
        '&$indeterminate': {
          color: customStyle?.colorIndeterminate ?? '#000000',
        },
      },
      checked: {},
      indeterminate: {},
    };
  }
  else {
    styles = {
      root: {
        ...CustomTableRowCheckboxStyle,
      },
    };
  }

  const CurrentCheckBox = React.memo(
    withStyles(styles)((props: CheckboxProps) => <Checkbox {...props} />),
    areEqual
  );

  return (<CurrentCheckBox {...checkboxProps} />);
}