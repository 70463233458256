import React from "react";
import { FilterProps, FilterValue, IdType, Row } from "react-table";
import { MenuItem, TextField } from "@material-ui/core";
import { SelectOptions } from "general/models/SelectOption";

function SelectColumFilter<T extends object>(filterProps: FilterProps<T>) {
  const {
    column: { filterValue, setFilter, render }
  } = filterProps;
  const filterOptions = getFilterSelectOptions(filterProps.column);
  return (
    <TextField
      select
      label={render('Header')}
      value={filterValue || ''}
      variant="outlined"
      size="small"
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <MenuItem value={undefined}>&nbsp;</MenuItem>
      {filterOptions && filterOptions.options &&
        filterOptions.options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {filterOptions.useDisplayValue ?
              option.displayValue : option.value}
          </MenuItem>
        ))}
    </TextField>
  )
}

function selectFilter<T extends object>(rows: Array<Row<T>>, id: Array<IdType<T>>, filterValue: FilterValue) {
  if (filterValue === undefined)
    return rows;
  else
    //console.log('filterValue', filterValue);
    return rows.filter((row) => {
      const valueObj = (row.values[id[0]]);
      // Value can also be an array 
      // In this case, look for a match within the array
      if (Array.isArray(valueObj)) {
        const filterHits = valueObj.filter((value) => {
          return (value === filterValue);
        });
        if (filterHits.length > 0)
          return true;
        else
          return false;
      } else {
        return (valueObj === filterValue);
      }
    });
}

// Let the table remove the filter if the value is undefined
selectFilter.autoRemove = (value: any) => value === undefined

function getFilterSelectOptions(column: any) {
  if (column && column.hasOwnProperty(FilterSelectOptionsProp)) {
    return column[FilterSelectOptionsProp] as SelectOptions;
  } else {
    console.warn('SelectColumnFilter.getFilterSelectOptions', `Property ${FilterSelectOptionsProp} not found on column`, column);
    return undefined;
  }
}

const FilterSelectOptionsProp = 'FilterSelectOptions';
const SelectFilterKey = 'select';

export { SelectColumFilter, selectFilter, getFilterSelectOptions, FilterSelectOptionsProp, SelectFilterKey };