import BaseService from "general/helpers/api/BaseService";
import CompanyUser, { CompanyUserCreateRequest, CompanyUserChangePasswordRequest } from "../models/CompanyUser";

class CompanyUsersService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/company/users`;

  public async getUsers(searchText: string): Promise<Array<CompanyUser>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/search/${searchText}`);
    return resp.data.map((userData: any) => {
      return new CompanyUser(userData);
    });
  }

  public async getTenantUsers(tenantId: string, searchText: string): Promise<Array<CompanyUser>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${tenantId}/search/${searchText}`);
    return resp.data.map((userData: any) => {
      return new CompanyUser(userData);
    });
  }

  public async getUser(subjectId: string): Promise<CompanyUser> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${subjectId}`);
    return new CompanyUser(resp.data);
  }

  public async createUser(userCreateRequest: CompanyUserCreateRequest): Promise<CompanyUser> {
    const resp = await (await this.useApiWithAuth())
      .post(this._serviceUrl,
        JSON.stringify(userCreateRequest));
    return new CompanyUser(resp.data);
  }

  public async updateUser(user: CompanyUser): Promise<void>{
    await (await this.useApiWithAuth())
      .put(`${this._serviceUrl}/${user.subjectId}`,
        JSON.stringify(user));
  }

  public async changePassword(changeRequest: CompanyUserChangePasswordRequest): Promise<void> {
    await (await this.useApiWithAuth())
      .patch(`${this._serviceUrl}/${changeRequest.subjectId}/changePassword`,
        JSON.stringify(changeRequest));
  }

  public async deleteUser(subjectId: string): Promise<void> {
    await (await this.useApiWithAuth())
      .delete(`${this._serviceUrl}/${subjectId}`);
  }
}

export default CompanyUsersService;