import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PortalPaper from 'general/components/container/PortalPaper';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Grid, Box, TextField, Button, } from '@material-ui/core';
import BoxedAlert from 'general/components/common/BoxedAlert';
import RouteContext from 'general/RouteContext';
import { TenantDetailsRouteState } from 'company/tenants/views/TenantDetails';
import TenantPaperTitle, { TenantPaperTitleOption } from 'company/tenants/components/TenantPaperTitle';
import Routes from 'general/helpers/Routes';
import CompanyTenantsService from '../helpers/CompanyTenantsService';
import CompanyTenant from '../models/CompanyTenant';
import FormCheckBox from 'general/components/common/FormCheckBox';
import { regExpSubDomain } from 'general/helpers/ui/ValidationHelper';

export interface TenantCreateProps extends WithStyles<typeof AppStyle> { }

function TenantCreate(props: TenantCreateProps) {
	const classes = props.classes;
	const { t } = useTranslation(['common', 'company']);
	const [createIsLoading, setCreateIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const routeHistory = useHistory();
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
	const tenantsService = useRef(new CompanyTenantsService());
	const { register, handleSubmit, errors, control } =
		useForm({
			defaultValues: {
				name: '',
				description: '',
				subDomain: '',
				//domain: '',
				isActive: true,
			}
		});

	useEffect(() => {
	}, [initRouteContext]);

	async function onSubmit(data: any) {
		try {
			setErrorMessage(undefined);
			setCreateIsLoading(true);

			const newTenant = new CompanyTenant();
			newTenant.name = data.name;
			newTenant.description = data.description;
			newTenant.subDomain = data.subDomain;
			//newTenant.domain = data.domain;
			newTenant.isActive = data.isActive;

			// Send data
			const createdTenant = await tenantsService.current.createTenant(newTenant);

			// Set specific state for success message
			const routeState: TenantDetailsRouteState = {
				successTenantCreatedAlert: {
					visible: true,
					name: createdTenant?.name
				}
			};
			initRouteContext.current.setRouteState(Routes.Portal.Company.TenantDetails, routeState);

			//Redirect to newly created tenant with REPLACE to remove CreateTenant route for goBack navigation in TenantDetails view
			routeHistory.replace(`${Routes.Portal.Company.TenantDetails}/${createdTenant.tenantId}/${createdTenant.name}`);
		} catch (error) {
			if ((error?.response?.status ?? 0) === 400) {
				setErrorMessage(t(`company:tenantCreate.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
			setCreateIsLoading(false);
		};
	}

	const valRequired = t('validation:required');
	const valInvalidSubDomain = t('validation:invalidSubDomain');
	const valMinLength3 = t('validation:minLength', { minLength: 3 });
	const valMaxLength60 = t('validation:maxLength', { maxLength: 60 });
	const valMaxLength100 = t('validation:maxLength', { maxLength: 100 });
	const valMaxLength300 = t('validation:maxLength', { maxLength: 300 });
	return (
		<PortalPaper>
			<Box className={classes.portalPaperTitleBox}>
				<TenantPaperTitle option={TenantPaperTitleOption.basicData} />
			</Box>
			<Box className={classes.portalPaperContentBox}>
				<form className={classes.defaultForm} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2} className={classes.defaultFormGrid}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('company:companyTenant.name')}
								name="name"
								autoComplete="name"
								inputRef={register({ required: valRequired, maxLength: { value: 100, message: valMaxLength100 } })}
								error={!!errors.name}
								helperText={errors.name ? errors.name.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('company:companyTenant.subDomain')}
								name="subDomain"
								autoComplete="subDomain"
								inputRef={register({
									required: valRequired,
									minLength: { value: 3, message: valMinLength3 },
									maxLength: { value: 60, message: valMaxLength60 },
									pattern: { value: regExpSubDomain, message: valInvalidSubDomain }
								})}
								error={!!errors.subDomain}
								helperText={errors.subDomain ? errors.subDomain.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								multiline
								rows={3}
								rowsMax={6}
								variant="outlined"
								margin="none"
								fullWidth
								label={t('company:companyTenant.description')}
								name="description"
								autoComplete="description"
								inputRef={register({ required: valRequired, maxLength: { value: 300, message: valMaxLength300 } })}
								error={!!errors.description}
								helperText={errors.description ? errors.description.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormCheckBox label={t('company:companyTenant.isActive')} name="isActive" control={control} />
						</Grid>
						{/* 
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									margin="none"
									fullWidth
									label={t('company:companyTenant.domain')}
									name="domain"
									autoComplete="domain"
									inputRef={register({ maxLength: { value: 255, message: valMaxLength255 } })}
									error={!!errors.domain}
									helperText={errors.domain ? errors.domain.message : ''}
								/>
							</Grid>				
						*/}
						<BoxedAlert message={errorMessage} severity="error" container="gridItem" />
						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={createIsLoading}
							>
								{createIsLoading ?
									t('loading') :
									t('common:button.create')}
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button fullWidth color="default" onClick={() => routeHistory.goBack()}>
								{t('common:button.cancel')}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</PortalPaper>
	);
}

export default withStyles(AppStyle)(TenantCreate);