import { AxiosRequestConfig } from "axios";
import { ApiConfigParams } from "general/helpers/api/ApiHelper";
import BaseService from "general/helpers/api/BaseService";
import ContentFile, { ContentFileResult } from "../models/ContentFile";

export const uploadFile_ParallelLimit: number = 10;
export const uploadFile_SizeLimit: number = 10 * 1024 * 1024; // 10MB

class CompanyFilesService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/company/files`;

  constructor() {
    super({ useJson: false } as ApiConfigParams);
  }

  public async getFiles(folderKey: string, searchText: string): Promise<Array<ContentFile>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${folderKey === '' ? '' : 'folder/' + folderKey + '/'}search/${searchText}`);
    return resp.data.map((data: any) => {
      return new ContentFile(data);
    });
  }

  public async uploadFiles(
    file: FormData,
    onUploadProgress?: (progressEvent: any) => void): Promise<Array<ContentFileResult>> {
    const resp = await (await this.useApiWithAuth())
      .post(this._serviceUrl,
        file,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: onUploadProgress
        } as AxiosRequestConfig);
    return resp.data.map((data: any) => {
      return new ContentFileResult(data);
    });
  }

  public async deleteFiles(
    files: Array<ContentFile>,
    folderKey: string | undefined): Promise<Array<ContentFileResult>> {
    const resp = await (await this.useApiWithAuth())
      .delete(`${this._serviceUrl}${(folderKey ?? '') === '' ? '' : '/folder/' + folderKey}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(files)
        } as AxiosRequestConfig);
    return resp.data.map((data: any) => {
      return new ContentFileResult(data);
    });
  }
}

export default CompanyFilesService;