import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AppStyle from 'general/styles/AppStyle';
import AppDefaultContainer from './AppDefaultContainer';
import { RouteContextProvider } from 'general/RouteContext';
import AppPortalContainer from './AppPortalContainer';
import TenantThemeProvider from 'idserver/helpers/TenantThemeProvider';

interface ContainerProps extends WithStyles<typeof AppStyle> {
  children: React.ReactNode
}

function DefaultContainerIntern(props: ContainerProps) {
  const { classes, children } = props;
  return (
    <TenantThemeProvider>
      <AppDefaultContainer
        classes={classes}
        children={children} />
    </TenantThemeProvider>
  );
}

function PortalContainerIntern(props: ContainerProps) {
  const { classes, children } = props;

  return (
    <RouteContextProvider>
      <TenantThemeProvider>
        <AppPortalContainer
          classes={classes}
          children={children} />
      </TenantThemeProvider>
    </RouteContextProvider>
  );
}

function IdServerContainerIntern(props: ContainerProps) {
  const { classes, children } = props;
  return (
    <TenantThemeProvider>
      <AppDefaultContainer
        classes={classes}
        children={children} />
    </TenantThemeProvider>
  );
}

const DefaultContainer = withStyles(AppStyle)(DefaultContainerIntern);
const PortalContainer = withStyles(AppStyle)(PortalContainerIntern);
const IdServerContainer = withStyles(AppStyle)(IdServerContainerIntern);
export { DefaultContainer, PortalContainer, IdServerContainer };