import React, { CSSProperties } from "react";
import { PropsWithChildren, ReactElement } from "react";
import { useStyles } from 'general/components/customDataGrid/styles/TableStyles';
import { AlignX, AlignY, useAlignX, useAlignY, useTextAlignX, useFlex, useWidth } from "general/components/customTable/utils";

export interface CustomTableCellProps {
  alignX?: AlignX;
  alignY?: AlignY;
  width?: number;
  isCheckbox?: boolean;
}

export function CustomTableCell(props: PropsWithChildren<CustomTableCellProps>): ReactElement {
  const { children, alignX, alignY, width, isCheckbox } = props;
  const classes = useStyles();

  const cellStyle = {
    boxSizing: 'border-box',
    flex: useFlex(width),
    minWidth: '100px',
    width: useWidth(width),
    justifyContent: useAlignX(alignX),
    alignItems: useAlignY(alignY),
    display: 'flex',
  } as CSSProperties

  const labelStyle = {
    textAlign: useTextAlignX(alignX),
    overflow: 'hidden',
    //whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  } as CSSProperties

  return (
    <div style={cellStyle} className={classes.tableCell}>
      {(isCheckbox ?? false) ?
        <>{children}</> :
        <div style={labelStyle}>
          <span>{children}</span>
        </div>}
    </div>
  );
}