import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ApiHelper from 'general/helpers/api/ApiHelper';
import UrlHelper from 'general/helpers/UrlHelper';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Routes from 'general/helpers/Routes';
import DefaultPaper from 'general/components/container/DefaultPaper';
import { Container, Box, Typography, CircularProgress, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

function SignUpConfirm() {
	const { t } = useTranslation(['idserver', 'app']);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		console.log('signUpConfirm triggered');
		confirmAsync(t);
	}, [t]);

	async function confirmAsync(ti: TFunction) {
		try {
			setLoading(true);
			const token = UrlHelper.getQueryVariable(window, 'token');
			const apiHelper = new ApiHelper();
			await apiHelper.Api.patch('/apiidserver/signup/confirm',
				JSON.stringify({
					token
				}));
		} catch (error) {
			if ((error?.response?.status ?? 0) === 400) {
				setErrorMessage(ti(`idserver:signUpConfirm.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
		} finally {
			setLoading(false);
		}
	};

	function ConfirmedView() {
		return (
			<React.Fragment>
				<Box margin={2}>
					<Alert severity="success">
						{t('idserver:signUpConfirm.textEmailConfirmed')}
					</Alert>
				</Box>
				<Button variant="contained" color="default"
					component={Link} to={Routes.Account.Login}>
					{t('app:nav.account.login')}
				</Button>
			</React.Fragment>
		);
	}

	return (
		<Container component="main" maxWidth="xs">
			<DefaultPaper>
				<Typography component="h1" variant="h5">
					{t('idserver:signUpConfirm.title')}
				</Typography>
				{loading ? <CircularProgress /> :
					errorMessage === '' ? <ConfirmedView /> : null}
				{errorMessage !== '' ? <Box margin={2}><Alert severity="error">{errorMessage}</Alert></Box> : null}
			</DefaultPaper>
		</Container>
	);
}

export default SignUpConfirm;