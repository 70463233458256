import StringHelper from "general/helpers/types/StringHelper";
import { SelectOption, SelectOptions } from "general/models/SelectOption";
import { TFunction } from "i18next";

export const roleAdmin: string = "Admin";
export const roleContentManager: string = "ContentManager";
export const roleCompany: string = "Company";
export const roleCompanyEmployee: string = "CompanyEmployee";
export const roleConsumer: string = "Consumer";

class RolesHelper {
  public static getRoles(): string[] {
    return [
      roleAdmin, roleContentManager, 
      roleCompany, roleCompanyEmployee,
      roleConsumer
    ];
  }

  public static getRolesSelectOptions(translationIdServer?: TFunction): SelectOptions {
    const options = RolesHelper.getRoles().map<SelectOption>((value: string) => {
      return {
        value: value,
        displayValue: RolesHelper.getRoleText(value, translationIdServer)
      } as SelectOption
    });
    return {
      options: options,
      useDisplayValue: true,
    } as SelectOptions
  }

  public static getRoleText(role: string | undefined, translationIdServer?: TFunction) {
    if (role && translationIdServer) {
      return translationIdServer(`idserver:roles.${role}`, { defaultValue: role});
    }
    return role;
  }

  public static getRolesText(roles: string[] | undefined, translationIdServer?: TFunction) {
    let text = '';
    roles?.forEach(role => {
        if (translationIdServer) {
            role = RolesHelper.getRoleText(role, translationIdServer) ?? '';
        } 
        text += ', ' + role;
    });
    return StringHelper.right(text, text.length - 2);
  }
}

export default RolesHelper;