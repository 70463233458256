import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import ApiHelper from 'general/helpers/api/ApiHelper';
import UrlHelper from 'general/helpers/UrlHelper';
import { useTranslation } from 'react-i18next';
import AppStyle from 'general/styles/AppStyle';
import DefaultPaper from 'general/components/container/DefaultPaper';
import Copyright from 'general/components/app/Copyright';
import Routes from 'general/helpers/Routes';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Container, Avatar, Typography, TextField, Button, Grid, Box, InputAdornment, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface PasswordResetConfirmProps extends WithStyles<typeof AppStyle> { }

function PasswordResetConfirm(props: PasswordResetConfirmProps) {
	enum viewMode { form, success }
	const classes = props.classes;
	const { t } = useTranslation(['idserver', 'validation']);
	const [currentViewMode, setcurrentViewMode] = useState(viewMode.form);

	function SuccessView() {
		return (
			<React.Fragment>
				<Box margin={2}>
					<Alert severity="success">
						{t('idserver:passwordResetConfirm.textSuccess')}
					</Alert>
				</Box>
				<Button variant="contained" color="default"
					component={Link} to={Routes.Account.Login}>
					{t('idserver:passwordResetConfirm.linkLogin')}
				</Button>
			</React.Fragment>
		);
	}

	function showSuccessView() {
		setcurrentViewMode(viewMode.success);
	}

	return (
		<Container component="main" maxWidth="xs">
			<DefaultPaper>
				{currentViewMode === viewMode.form ?
					<PasswordResetConfirmFormView showSuccessView={showSuccessView} classes={classes} /> :
					<SuccessView />}
			</DefaultPaper>
		</Container>
	);
}

type ShowSuccessViewCallback = () => void;
interface PasswordResetConfirmFormViewProps extends PasswordResetConfirmProps {
	showSuccessView: ShowSuccessViewCallback
}

function PasswordResetConfirmFormView(props: PasswordResetConfirmFormViewProps) {
	const classes = props.classes;
	const { t } = useTranslation(['idserver', 'validation']);
	const [errorMessage, setErrorMessage] = useState('');
	const [resetLoading, setResetLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
	const { register, handleSubmit, errors, watch } = useForm();

	async function onSubmit(data: any) {
		setErrorMessage('');
		const password = data.password;
		const passwordRepeat = data.passwordRepeat;

		try {
			setResetLoading(true);
			const token = UrlHelper.getQueryVariable(window, 'token');
			const apiHelper = new ApiHelper();
			await apiHelper.Api.patch('/apiidserver/password/resetConfirm',
				JSON.stringify({
					token,
					password,
					passwordRepeat
				}));
			props.showSuccessView();
		} catch (error) {
			if ((error?.response?.status ?? 0) === 400) {
				setErrorMessage(t(`idserver:passwordResetConfirm.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
			setResetLoading(false);
		};
	}

	const valRequired = t('validation:required');
	const valMinLength8 = t('validation:minLength', { minLength: 8 });
	const valMaxLength30 = t('validation:maxLength', { maxLength: 30 });
	const valpasswordsNotMatch = t('validation:passwordsNotMatch');
	return (
		<React.Fragment>
			<Avatar className={classes.defaultAvatar}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5" className={classes.defaultFormTitle}>
				{t('idserver:passwordResetConfirm.title')}
			</Typography>
			<form className={classes.defaultForm} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							margin="none"
							fullWidth
							label={t('idserver:passwordResetConfirm.labelPassword')}
							name="password"
							autoComplete="current-password"
							type={showPassword ? "text" : "password"}
							inputRef={register({
								required: valRequired,
								minLength: { value: 8, message: valMinLength8 },
								maxLength: { value: 30, message: valMaxLength30 }
							})}
							error={!!errors.password}
							helperText={errors.password ? errors.password.message : ''}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setShowPassword(!showPassword)}
											onMouseDown={() => setShowPassword(!showPassword)}
											tabIndex={-1}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							margin="none"
							fullWidth
							label={t('idserver:passwordResetConfirm.labelPasswordRepeat')}
							name="passwordRepeat"
							type={showPasswordRepeat ? "text" : "password"}
							inputRef={register({
								required: valRequired,
								minLength: { value: 8, message: valMinLength8 },
								maxLength: { value: 30, message: valMaxLength30 },
								validate: (value) => value === watch('password') || valpasswordsNotMatch
							})}
							error={!!errors.passwordRepeat}
							helperText={errors.passwordRepeat ? errors.passwordRepeat.message : ''}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
											onMouseDown={() => setShowPasswordRepeat(!showPasswordRepeat)}
											tabIndex={-1}
										>
											{showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={resetLoading}
							className={classes.defaultFormSubmitButton}
						>
							{resetLoading ?
								t('loading') :
								t('idserver:passwordResetConfirm.buttonChangePassword')}
						</Button>
					</Grid>
				</Grid>
			</form>

			{errorMessage !== '' ?
				<Grid container>
					<Grid item xs={12}>
						<Alert severity="error">
							{errorMessage}
						</Alert>
						<br />
					</Grid>
				</Grid> : null}

			<Box mt={6}>
				<Copyright />
			</Box>
		</React.Fragment>
	);
}

export default withStyles(AppStyle)(PasswordResetConfirm);