class CompanyTenantTheme {
  themeId: string | undefined;
  tenantId: string | undefined;
  name: string | undefined;
  createDate: Date | undefined;
  isPublic: boolean | undefined;
  isDefault: boolean | undefined;
  primaryColor: string | undefined;
  secondaryColor: string | undefined;
  backgroundPaperColor: string | undefined;
  backgroundDefaultColor: string | undefined;
  portalBackgroundColor: string | undefined;
  errorColor: string | undefined;
  warningColor: string | undefined;
  infoColor: string | undefined;
  successColor: string | undefined;

  constructor(dataTheme?: any) {
    if (dataTheme) {
      this.themeId = dataTheme.themeId;
      this.tenantId = dataTheme.tenantId;
      this.name = dataTheme.name;
      this.createDate = dataTheme.createDate;
      this.isPublic = dataTheme.isPublic;
      this.isDefault = dataTheme.isDefault;
      this.primaryColor = dataTheme.primaryColor;
      this.secondaryColor = dataTheme.secondaryColor;
      this.backgroundPaperColor = dataTheme.backgroundPaperColor;
      this.backgroundDefaultColor = dataTheme.backgroundDefaultColor;
      this.portalBackgroundColor = dataTheme.portalBackgroundColor;
      this.errorColor = dataTheme.errorColor;
      this.warningColor = dataTheme.warningColor;
      this.infoColor = dataTheme.infoColor;
      this.successColor = dataTheme.successColor;
    }
  }

  copyTheme(copyName: string): CompanyTenantTheme {
   const copy = new CompanyTenantTheme();
   copy.name = copyName;
   copy.isPublic = false;
   copy.isDefault = false;
   copy.primaryColor = this.primaryColor;
   copy.secondaryColor = this.secondaryColor;
   copy.backgroundPaperColor = this.backgroundPaperColor;
   copy.backgroundDefaultColor = this.backgroundDefaultColor;
   copy.portalBackgroundColor = this.portalBackgroundColor;
   copy.errorColor = this.errorColor;
   copy.warningColor = this.warningColor;
   copy.infoColor = this.infoColor;
   copy.successColor = this.successColor;
   return copy;
  } 
}

export default CompanyTenantTheme;