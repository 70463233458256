import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';
import IconActionButton from 'general/components/common/IconActionButton';
import { Edit as EditIcon, Delete as DeleteIcon, SaveOutlined as SaveIcon, CancelOutlined as CancelIcon } from '@material-ui/icons';

export interface EditActionButtonsGroupProps extends WithStyles<typeof AppStyle> {
  editMode: boolean;
  children?: React.ReactNode;
  childrenEditMode?: React.ReactNode;
  disabled?: boolean;
  disabledEdit?: boolean;
  disabledDelete?: boolean;
  saveButtonType?: "button" | "reset" | "submit" | undefined;
  onSaveButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onCancelButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onEditButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onDeleteButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}

function EditActionButtonsGroup(props: EditActionButtonsGroupProps) {
  const { classes, editMode, children, childrenEditMode, disabled, disabledEdit, disabledDelete,
    saveButtonType, onSaveButtonClick, onCancelButtonClick, onEditButtonClick, onDeleteButtonClick } = props;
  const { t } = useTranslation(['common']);

  return (
    <Box className={classes.portalPaperActionButtonsBox}>
      {(editMode) ?
        <>
          <IconActionButton label={t('common:button:save')} icon={<SaveIcon />} disabled={disabled || disabledEdit} type={saveButtonType} onClick={onSaveButtonClick} />
          <IconActionButton label={t('common:button:cancel')} icon={<CancelIcon />} disabled={disabled || disabledEdit} onClick={onCancelButtonClick} />
          {childrenEditMode}
        </>
        :
        <>
          <IconActionButton label={t('common:button:edit')} icon={<EditIcon />} disabled={disabled || disabledEdit} onClick={onEditButtonClick} />
          {onDeleteButtonClick ? <IconActionButton label={t('common:button:delete')} disabled={disabled || disabledDelete} icon={<DeleteIcon />} onClick={onDeleteButtonClick} /> : null}
          {children}
        </>
      }
    </Box>
  );
}

export default withStyles(AppStyle)(EditActionButtonsGroup);
