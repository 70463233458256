class ClientConfig {
  clientHostUrl: ClientHostUrl | undefined;
  isCompanySignUpAllowed: boolean | undefined;
  isConsumerSignUpAllowed: boolean | undefined;

  constructor(dataClientConfig?: any) {
    if (dataClientConfig) {
      this.clientHostUrl = new ClientHostUrl(dataClientConfig.clientHostUrl);
      this.isCompanySignUpAllowed = dataClientConfig.isCompanySignUpAllowed;
      this.isConsumerSignUpAllowed = dataClientConfig.isConsumerSignUpAllowed;
    }
  }
}

class ClientHostUrl {
  scheme: string | undefined;
  authority: string | undefined;
  port: string | undefined;
  isDefaultPort: boolean | undefined;
  absolutePath: string | undefined;
 
  constructor(dataClientHostUrl?: any) {
    if (dataClientHostUrl) {
      this.scheme = dataClientHostUrl.scheme;
      this.authority = dataClientHostUrl.authority;
      this.port = dataClientHostUrl.port;
      this.isDefaultPort = dataClientHostUrl.isDefaultPort;
      this.absolutePath = dataClientHostUrl.absolutePath;
    }
  }

  get portUrlPart(): string {
		let port = '';
    if (!(this.isDefaultPort ?? true) &&
    this?.port !== undefined) {
      port = `:${this.port}`;
    }
    return port;
	}

  get absolutePathDiplay(): string | undefined {
    if ((this.absolutePath ?? '') === '/') {
      return '';
    }
    return this.absolutePath;
	}
}

export default ClientConfig;
export { ClientHostUrl };