import React, { ReactNode } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

export enum AlertDialogResult {
  cancel = -1,
  no = 0,
  yes = 1,
}

interface AlertDialogProps {
  open: boolean;
  onClose: (dialogResult: AlertDialogResult) => void;
  title?: ReactNode;
  content?: ReactNode;
  severity?: "error" | "success" | "info" | "warning";
  buttonYesContent?: ReactNode;
  buttonNoContent?: ReactNode;
  dialogType?: "info" | "delete";
}

function AlertDialog(props: AlertDialogProps) {
  const { open, onClose, title, content, dialogType } = props;
  let { severity, buttonYesContent, buttonNoContent } = props;
  const { t } = useTranslation(['common']);

  switch (dialogType) {
    case "info": {
      severity = "info";
      buttonYesContent = t('common:button.ok');
      buttonNoContent = undefined;
      break;
    }
    case "delete": {
      severity = "warning";
      buttonYesContent = t('common:button.delete');
      buttonNoContent = t('common:button.doNotDelete');
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose(AlertDialogResult.cancel)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && !severity ?
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}
      {content ?
        <DialogContent>
          {severity ?
            <Alert severity={severity}>
              {title ?
                <AlertTitle>{title}</AlertTitle> : null}
              {content}
            </Alert> :
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>}
        </DialogContent> : null}
      <DialogActions>
        {buttonNoContent ?
          <Button onClick={() => onClose(AlertDialogResult.no)} variant="contained" color="default">
            {buttonNoContent}
          </Button> : null}
        <Button onClick={() => onClose(AlertDialogResult.yes)} variant="contained" color="default">
          {buttonYesContent ? buttonYesContent : t('common:button.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;