import BaseUserHasRightsView, { BaseUserHasRightsViewRow, BaseUserHasRightsViewGroupRight } from "./BaseUserHasRightsView";
import CompanyRight from "./CompanyRight";
import CompanyGroup from "./CompanyGroup";

class CompanyUserHasRightsView  extends BaseUserHasRightsView<CompanyRight, CompanyGroup, CompanyUserHasRightsViewGroupRight, CompanyUserHasRightsViewRow> {

  constructor(dataView?: any) {
    if (dataView) {
      super(dataView);
    } else {
      super();
    }
  }
}

class CompanyUserHasRightsViewRow extends BaseUserHasRightsViewRow<CompanyRight, CompanyUserHasRightsViewGroupRight>{

  constructor(dataRow?: any) {
    if (dataRow) {
      super(dataRow);
    } else {
      super();
    }
  }
}

class CompanyUserHasRightsViewGroupRight extends BaseUserHasRightsViewGroupRight {

  constructor(groupRight?: any) {
    if (groupRight) {
      super(groupRight);
    } else {
      super();
    }
  }
}

export default CompanyUserHasRightsView;
export { CompanyUserHasRightsViewRow, CompanyUserHasRightsViewGroupRight };