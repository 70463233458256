import React from 'react';
import { TextField } from '@material-ui/core';
import StringHelper from 'general/helpers/types/StringHelper';

interface FormDisplayMultilineProps {
  label: React.ReactNode;
  value?:string | undefined;
  rowsMax?: string | number;
}

function FormDisplayMultiline(props: FormDisplayMultilineProps) {
  const { label, value, rowsMax } = props;
  return (
    <TextField
      multiline
      rowsMax={rowsMax}
      variant="standard"
      margin="none"
      fullWidth
      label={label}
      //defaultValue={value}
      value={!StringHelper.empty(value) ? value : ' '} // fixes label problem on empty values
      InputProps={{
        readOnly: true,
      }}
    />
  );
}

export default FormDisplayMultiline;