import StringHelper from "./types/StringHelper";

class UrlHelper {
	public static getBaseUrl() {
		return window.location.origin + process.env.PUBLIC_URL;
	}

	public static getApiUrl() {
		return process.env.REACT_APP_API_URL?.replace('[hostname]', window.location.hostname);
	}

	public static getApiUrlPortal() {
		return UrlHelper.getApiUrl() + '/apiportal';
	}

	public static getApiApplicationRootPath() {
		let appPath = '';
		let appPathParts = false;
		let apiUrl = UrlHelper.getApiUrl();
		const urlElems = apiUrl?.split('/');
		if (urlElems) {
			const hostname = window.location.hostname;
			for (let elem of urlElems) {
				if (appPathParts) {
					appPath += '/' + elem;
				} else if (StringHelper.left(elem, hostname.length) === hostname) {
					// Hostname part reached => next ApplicationRootPath parts
					appPathParts = true;
				}
			}
		}
		return appPath;
	}

	public static getQueryVariable(window: Window & typeof globalThis, variable: string) {
		const variableLower = variable.toLowerCase();
		const query = window.location.search.substring(1);
		const vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split('=');
			if (decodeURIComponent(pair[0]).toLowerCase() === variableLower) {
				return decodeURIComponent(pair[1]);
			}
		}
	};

	private static removeSlashAtEnd(url: string) {
		if (url && url.length > 0) {
			if (url.substring(url.length - 1, url.length) === '/') {
				url = url.substring(0, url.length - 1);
			}
		}
		return url;
	}

	public static openInNewTab(url: string): boolean {
		const win = window.open(url, '_blank');
		if (win !== null) {
			win.focus();
			return true;
		} else {
			console.error('Open window is blocked!');
			return false;
		}
	}
}

export default UrlHelper;