import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, TableInstance } from 'react-table';
import { useTranslation } from 'react-i18next';
import RouteContext from 'general/RouteContext';
import { Box, withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';
import PortalPaper from 'general/components/container/PortalPaper';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import ManageRight from 'manage/users/models/ManageRight';
import BoxedAlert from 'general/components/common/BoxedAlert';
import GroupPaperTitle, { GroupPaperTitleOption } from 'manage/users/components/GroupPaperTitle';
import ManageGroupRightsConfigService from 'manage/users/helpers/ManageGroupRightsConfigService';
import { DialogResult } from 'general/components/common/ResponsiveDialog';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import GroupAddRightsDialog from 'manage/users/components/GroupAddRightsDialog';

interface GroupRightsConfigParams {
  groupId: string;
  groupName: string;
}

export interface GroupRightsConfigProps extends WithStyles<typeof AppStyle> { }

function GroupRightsConfig(props: GroupRightsConfigProps) {
  const { classes } = props;
  const { groupId, groupName } = useParams<GroupRightsConfigParams>();
  const { t, i18n } = useTranslation(['manage']);
  const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
  const [rightRows, setRightRows] = useState<Array<ManageRight> | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showSuccessRightsSaved, setShowSuccessRightsSaved] = useState(false);
  const rightsConfigService = useMemo(() => new ManageGroupRightsConfigService(groupId ?? ''), [groupId]);

  useEffect(() => {
    // Set HeaderText and display GoBackButton
    initRouteContext.current.setTitle(groupName, true);

    // Load rights
    const lang = i18n.language;
    getRightsAsync(rightRows, rightsConfigService, lang);
  }, [groupName, rightRows, rightsConfigService, initRouteContext, i18n.language]);

  async function getRightsAsync(
    groupRows: Array<ManageRight> | undefined,
    currentService: ManageGroupRightsConfigService,
    lang: string) {
    if (currentService.groupId && groupRows === undefined) {
      console.log('Load group rights config');
      setErrorMessage('');
      setIsLoading(true);
      try {
        const rights = await currentService.getRights(true, lang);
        setRightRows(rights);
      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      } finally {
        // Reset
        setIsLoading(false);
      }
    }
  }

  async function handleAddDialogClose(
    dialogResult: DialogResult,
    selectedGroups: Array<ManageRight> | undefined) {
    // Close dialog
    setShowAddDialog(false);

    if (dialogResult === DialogResult.ok) {
      // Add Rights
      const rightIds = selectedGroups?.map((right: ManageRight) => {
        return right.rightId ?? '';
      });
      if (rightIds) {
        setErrorMessage('');
        setIsLoading(true);
        try {
          await rightsConfigService.addRights(rightIds);

          // Refresh rights list
          setRightRows(undefined)

          // Show info
          setShowSuccessRightsSaved(true);

        } catch (error) {
          if ((error?.response?.status ?? 0) === 400) {
            setErrorMessage(t(`manage:groupRightsConfig.error.${error?.response?.data?.code}`));
          } else {
            console.error(error);
            setErrorMessage(error?.message ?? '');
          }
        } finally {
          // Reset
          setIsLoading(false);
        }
      }
    }
  }

  const tableOnAdd = (instance: TableInstance<ManageRight> | undefined) => () => {
    setShowAddDialog(true);
  };

  const tableOnDelete = (instance: TableInstance<ManageRight> | undefined) => async () => {
    // Remove selected rights
    const rightIds = instance?.selectedFlatRows?.map((rightRow: Row<ManageRight>) => {
      return rightRow.original.rightId ?? '';
    });
    if (rightIds) {
      setErrorMessage('');
      setIsLoading(true);
      try {
        await rightsConfigService.removeRights(rightIds);

        // Refresh rights list
        setRightRows(undefined)

        // Show info
        setShowSuccessRightsSaved(true);

      } catch (error) {
        if ((error?.response?.status ?? 0) === 400) {
          setErrorMessage(t(`manage:groupRightsConfig.error.${error?.response?.data?.code}`));
        } else {
          console.error(error);
          setErrorMessage(error?.message ?? '');
        }
      } finally {
        // Reset
        setIsLoading(false);
      }
    }
  };

  const rightColumns = useMemo(() => {
    console.log('Init group rights config columns');
    return [
      {
        Header: 'manageGroupRightsConfig',
        HideHeader: true,
        columns: [
          {
            Header: t('manage:manageRight:name'),
            accessor: 'name',
            width: 300,
          },
          {
            Header: t('manage:manageRight:description'),
            accessor: 'description',
            width: 900,
          },
        ]
      },
    ];
  }, [t]);

  return (
    <>
      <Box mt={1}>
        <PortalPaper>
          <Box className={classes.portalPaperTitleBox}>
            <GroupPaperTitle option={GroupPaperTitleOption.rightsConfig} />
          </Box>
          <Box>
            <BoxedAlert message={errorMessage} severity="error" />
            <CustomDataGrid<ManageRight>
              name="manageGroupRightsConfig"
              isLoading={isLoading}
              columns={rightColumns}
              data={rightRows ?? new Array<ManageRight>()}
              fixedTableHeader={true}
              disableRowSelection={false}
              onAdd={tableOnAdd}
              onDelete={tableOnDelete}
            />
          </Box>
        </PortalPaper>
      </Box>

      <GroupAddRightsDialog
        open={showAddDialog}
        onClose={handleAddDialogClose}
        rightsConfigService={rightsConfigService} />

      <SnackbarAlert open={showSuccessRightsSaved} onClose={() => setShowSuccessRightsSaved(false)}
        message={t('manage:groupRightsConfig.successRightsSaved')} severity="success" />
    </>
  );
}

export default withStyles(AppStyle)(GroupRightsConfig);
