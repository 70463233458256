import React from 'react';
import AppStyle from 'general/styles/AppStyle';
import { Control, Controller } from 'react-hook-form';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface FormCheckBoxProps extends WithStyles<typeof AppStyle> {
  label: string;
  name: string;
  control: Control<any>;
}

function FormCheckBox(props: FormCheckBoxProps) {
  const { classes, label, name, control } = props;

  return (
    <FormControlLabel
      label={label}
      control={
        <Controller
          name={name}
          control={control}
          render={(props: any) => (
            <Checkbox
              color="default"
              className={classes.formCheckbox}
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
            />
          )}
        />}
    />
  );
}

export default withStyles(AppStyle)(FormCheckBox);