import React, { useEffect, useState } from 'react';
import ApiHelper from 'general/helpers/api/ApiHelper';
import DefaultPaper from 'general/components/container/DefaultPaper';
import AppStyle from 'general/styles/AppStyle';
import { useTranslation } from 'react-i18next';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

interface ErrorProps extends WithStyles<typeof AppStyle> { }

function Error(props: ErrorProps) {
	const classes = props.classes;
	const [errorMessage, setErrorMessage] = useState('');
	const { t } = useTranslation(['common']);

	useEffect(() => {
		console.log('Init idserver error');
		getError();
	}, []);

	async function getError() {
		try {
			const query = window.location.search;
			const errorIdQuery = query && query.toLowerCase().indexOf('?errorid=') === 0 && query;

			const apiHelper = new ApiHelper({ withCredentials: true });
			const resp = await apiHelper.Api.get(`/apiidserver/authenticate/error${errorIdQuery}`);

			if (resp.data) {
				setErrorMessage(JSON.stringify(resp.data));
			} else {
				setErrorMessage('Error response is empty!');
			}
		} catch (err) {
			console.error(err);
			setErrorMessage(err?.message ?? '');
		}
	};

	return (
		<Container component="main">
			<DefaultPaper>
				<Typography component="h1" variant="h5" className={classes.defaultFormTitle}>
					IdServer {t('common:alertTitle.error')}
				</Typography>
				{errorMessage !== '' ?
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Alert severity="error">
								<AlertTitle>{t('common:alertTitle.error')}</AlertTitle>
								{errorMessage}
							</Alert>
							<br />
						</Grid>
					</Grid> : null}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h6">
							Development Mode
            </Typography>
						<Box mt={1}>
							Swapping to <strong>Development</strong> environment will display more detailed information about the error that occurred.
            </Box>
						<Box mt={1}>
							<strong>Development environment should not be enabled in deployed applications</strong>, as
							it can result in sensitive information from exceptions being displayed to end users. For local
							debugging, development environment can be enabled by setting the <strong>ASPNETCORE_ENVIRONMENT</strong> environment variable
							to <strong>Development</strong>, and restarting the application.
            </Box>
					</Grid>
				</Grid>
			</DefaultPaper>
		</Container>
	);
}

export default withStyles(AppStyle)(Error);