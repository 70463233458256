import { useCallback, useRef } from "react";

/* Helps saving scroll state and scroll only if scrollTo set
   and scrolls only once. */
function useScroll(): [(value: number | undefined) => void, () => void] {
  const scrollToY = useRef<number | undefined>();

  const setScrollToY = useCallback(
    (value: number | undefined) => {
      scrollToY.current = value;
    }, []);

  const raiseScrollToY = useCallback(
    () => {
      if (scrollToY.current) {
        window.scrollTo(0, scrollToY.current);
        scrollToY.current = undefined;
      }
    }, []);

  return [setScrollToY, raiseScrollToY];
}

export { useScroll };