import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, Paper, withStyles, WithStyles } from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon, Delete as DeleteIcon,
  Folder as FolderIcon, InsertDriveFileOutlined as InsertDriveFileOutlinedIcon
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import AppStyle from 'general/styles/AppStyle';
import BoxedAlert from 'general/components/common/BoxedAlert';
import IconActionButton from 'general/components/common/IconActionButton';
import CompanyFilesService, { uploadFile_ParallelLimit, uploadFile_SizeLimit } from '../helpers/CompanyFilesService';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import LinearProgressBoxWithLabel from 'general/components/common/LinearProgressBoxWithLabel';
import SearchBar from 'general/components/common/SearchBar';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import ContentFile, { ContentFilePreview } from '../models/ContentFile';
import { DateColumnFilter } from 'general/components/customDataGrid/filters';
import moment from 'moment';
import ResponsiveDialog, { DialogResult } from 'general/components/common/ResponsiveDialog';
import { Row, TableInstance } from 'react-table';
import { getUploadAccept } from '../helpers/Folders';
import { formatFileSize } from '../helpers/Files';
import UrlHelper from 'general/helpers/UrlHelper';
import { right_Company_Files_Delete, useRight } from 'account/models/Rights';
import AccountContext from 'account/AccountContext';
import AlertDialog, { AlertDialogResult } from 'general/components/common/AlertDialog';

export interface FilesParams {
  folderKey?: string;
}

interface FilesProps extends WithStyles<typeof AppStyle> { }

function Files(props: FilesProps) {
  const params = useParams<FilesParams>();

  return (
    <FilesView {...props} params={params} isDialog={false} />
  );
}

interface FilesViewBaseProps extends FilesProps {
  params: FilesParams;
}

interface FilesDialogProps extends FilesViewBaseProps {
  open: boolean;
  onClose: (dialogResult: DialogResult, selectedFiles: Array<ContentFile> | undefined) => void;
}

function FilesDialog(props: FilesDialogProps) {
  const { open, onClose } = props;
  const { t } = useTranslation(['company']);
  const tableInstance = useRef<TableInstance<ContentFile> | undefined>(undefined);

  async function handleDialogClose(dialogResult: DialogResult) {
    if (dialogResult === DialogResult.ok) {
      const selectedFiles = new Array<ContentFile>();
      const selectedRows = tableInstance?.current?.selectedFlatRows;
      if (selectedRows) {
        selectedRows.forEach((rightRow: Row<ContentFile>) => {
          selectedFiles.push(new ContentFile(rightRow.original));
        });
      }
      onClose(dialogResult, selectedFiles);
    }
    else {
      onClose(dialogResult, undefined);
    }
  }

  return (
    <ResponsiveDialog
      open={open}
      onClose={handleDialogClose}
      title={t('company:filesDialog.title')}
      buttonOkContent={t('company:filesDialog.buttonOk')}
      maxWidth="xl"
      fullWidth={true}
      backgroundColorType="portalBackground">
      <FilesView {...props} isDialog={true} tableRef={tableInstance} />
    </ResponsiveDialog>
  );
}

interface FilesViewProps extends FilesViewBaseProps {
  isDialog: boolean;
  tableRef?: React.MutableRefObject<TableInstance<ContentFile> | undefined>;
}

function FilesView(props: FilesViewProps) {
  const classes = props.classes;
  const { folderKey } = props.params;
  const { isDialog, tableRef } = props;
  const { t } = useTranslation(['company']);
  const [fileUploadIsLoading, setFileUploadIsLoading] = useState(false);
  const [filesIsLoading, setFilesIsLoading] = useState(true);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [warningMessage, setWarningMessage] = useState<string | ReactNode | undefined>(undefined);
  const [showSuccessFileUploaded, setShowSuccessFileUploaded] = useState(false);
  const [showSuccessFileDeleted, setShowSuccessFileDeleted] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedFilesCount, setSelectedFilesCount] = useState(0);
  const [selectedFilenames, setSelectedFilenames] = useState('');
  const [searchText, setSearchText] = useState<string | undefined>();
  const [tableLoadPageIndex, setTableLoadPageIndex] = useState<boolean | undefined>();
  const [fileRows, setFileRows] = useState(new Array<ContentFile>());
  const filesService = useRef(new CompanyFilesService());
  const tableInstance = tableRef ?? useRef<TableInstance<ContentFile> | undefined>(undefined);
  const selectedFiles = useRef(new Array<ContentFile>());
  const accountContext = useContext(AccountContext);
  const hasFilesDeleteRight = useRight(right_Company_Files_Delete, accountContext);

  useEffect(() => {
    console.log('Init load files');
    console.log('folderKey', folderKey);
    getFilesAsync(folderKey, searchText);
  }, [folderKey, searchText]);

  async function getFilesAsync(
    folderKey: string | undefined,
    paramSearchText: string | undefined) {
    setErrorMessage('');
    setFilesIsLoading(true);
    try {
      const filesList = await filesService.current.getFiles(folderKey ?? '', paramSearchText ?? '');
      setFileRows(filesList);
    } catch (error) {
      console.error(error);
      setErrorMessage(error?.message ?? '');
    } finally {
      // Reset
      setFilesIsLoading(false);
      setTableLoadPageIndex(undefined);
    }
  }

  async function loadFilesListAsync() {
    getFilesAsync(folderKey, searchText);
  }

  function resetView() {
    setErrorMessage(undefined);
    setWarningMessage(undefined);
    setUploadPercentage(0);
    setSelectedFilesCount(0);
    setSelectedFilenames('');
  }

  async function handleFileUpload(e: any) {
    const filesList = e.target.files;
    console.log('files', filesList)

    // Reset
    resetView();

    // Check file-dialog cancled
    if ((filesList?.length ?? 0) <= 0) return;

    // Check files count limit
    if (filesList.length > uploadFile_ParallelLimit) {
      setWarningMessage(
        <Trans i18nKey="company:files.warningFilesCount">
          It is allowed to upload a maximum of <strong>{{ maxFiles: uploadFile_ParallelLimit }}</strong> files in parallel!
        </Trans>);
      return;
    }

    try {
      const firstFile = e.target.files[0];
      setFileUploadIsLoading(true);
      setSelectedFilesCount(filesList.length);
      setSelectedFilenames((firstFile?.name ?? '') + (filesList.length > 1 ? '...' : ''));

      const formData = new FormData();
      for (let i = 0; i < filesList.length; i++) {
        let file = filesList[i];
        if (file.size > uploadFile_SizeLimit) {
          const maxFileSize = `${(uploadFile_SizeLimit / 1024) / 1024}MB`;
          setWarningMessage(
            <Trans i18nKey="company:files.warningFileSize">
              Maximum file size of <strong>{{ maxFileSize: maxFileSize }}</strong> exceeded for file <strong>{{ fileName: file.name }}</strong>!
            </Trans>);
          return;
        } else {
          formData.append("files", file);
        }
      }
      formData.append("folderKey", folderKey ?? '');

      // Send data
      const uploadedFiles =
        await filesService.current.uploadFiles(formData, (progressEvent: any) => {
          if ((progressEvent?.total ?? 0) <= 0) return;
          setUploadPercentage(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        });

      // Check result 
      if (uploadedFiles === undefined || uploadedFiles.length <= 0)
        throw new Error('No files uploaded. Result is empty!');

      uploadedFiles.forEach(resultFile => {
        if ((resultFile?.response?.code ?? 0) > 0) {
          throw new Error(`${resultFile?.name}: ${resultFile?.response?.message}`);
        }
      });

      // Refresh files list
      loadFilesListAsync();

      // Show success 
      setShowSuccessFileUploaded(true);

    } catch (error) {
      if ((error?.response?.status ?? 0) === 400) {
        setErrorMessage(t(`company:files.errorUpload.${error?.response?.data?.code}`));
      } else {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      }
    } finally {
      setFileUploadIsLoading(false);
      e.target.value = null; // Fix onChange not triggerd same file selection
    };
  }

  function deleteButtonClick() {
    // Reset
    resetView();

    // Open confirmation dialog
    selectedFiles.current = getSelectedFiles(true);
    if (selectedFiles.current.length > 0) {
      setSelectedFilesCount(selectedFiles.current.length);
      setSelectedFilenames((selectedFiles.current[0]?.name ?? '') + (selectedFiles.current.length > 1 ? '...' : ''));
      setShowDeleteDialog(true);
    } else {
      setWarningMessage(t('company:files.deleteWarningNoFileSelected'));
    }
  }

  async function handleDeleteDialog(dialogResult: AlertDialogResult) {
    // Close dialog
    setShowDeleteDialog(false);

    if (dialogResult === AlertDialogResult.yes) {
      setDeleteIsLoading(true);
      try {
        // Send data
        const deletedFiles =
          await filesService.current.deleteFiles(selectedFiles.current, folderKey);

        // Check result 
        if (deletedFiles === undefined)
          throw new Error('No files deleted. Result is empty!');

        deletedFiles.forEach(resultFile => {
          if ((resultFile?.response?.code ?? 0) > 0) {
            throw new Error(`${resultFile?.name}: ${resultFile?.response?.message}`);
          }
        });

        // Refresh files list
        loadFilesListAsync();

        // Show success 
        setShowSuccessFileDeleted(true);

      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      } finally {
        setDeleteIsLoading(false);
      };
    }
  }

  function getSelectedFiles(fillOnlyIdsAndName?: boolean): Array<ContentFile> {
    const selectedFiles = new Array<ContentFile>();
    const selectedRows = tableInstance?.current?.selectedFlatRows;
    if (selectedRows) {
      selectedRows.forEach((fileRow: Row<ContentFile>) => {
        if (fillOnlyIdsAndName ?? false) {
          const cf = new ContentFile();
          cf.fileId = fileRow.original?.fileId;
          cf.tenantId = fileRow.original?.tenantId;
          cf.name = fileRow.original?.name;
          selectedFiles.push(cf);
        } else {
          selectedFiles.push(new ContentFile(fileRow.original));
        }   
      });
    }
    return selectedFiles;
  }

  function searchTextChanged(value: string) {
    setSearchText(value);
  }

  function rowClicked(row: Row<ContentFile>) {
    setErrorMessage(undefined);
    const selectedFile = row?.original;
    if (selectedFile) {
      if (selectedFile.isDir) {
        // Todo: Load files directory
      } else {
        const downloadUrl = row?.original?.downloadUrl;
        if (downloadUrl) {
          const ok = UrlHelper.openInNewTab(downloadUrl);
          if (!ok) setErrorMessage('Open window is blocked!');
        } else {
          setErrorMessage('Files downloadUrl is empty!');
        }
      }
    } else {
      setErrorMessage('Files file is empty!');
    }
  }

  function PreviewColumn({ value }: { value: ContentFilePreview }) {
    if (value.imageUrl) {
      return (
        <>
          <img src={value.imageUrl} alt={value.imageAlt} width="24" height="24" />
        </>
      );
    } else if (value.iconKey) {
      const iconStyle = { color: grey[600], fontSize: 24 };
      switch (value.iconKey) {
        case 'Folder': return <FolderIcon style={iconStyle} />
        case 'InsertDriveFileOutlined': return <InsertDriveFileOutlinedIcon style={iconStyle} />
      }
    }
    return null;
  }

  const fileColumns = useMemo(() => {
    return [
      {
        Header: 'companyFiles',
        HideHeader: true,
        columns: [
          {
            Header: t('company:contentFile:preview'),
            accessor: 'preview',
            width: 60,
            align: 'center',
            Cell: ({ value }: any) =>
            (
              <PreviewColumn value={value} />
            ),
          },
          {
            Header: t('company:contentFile:name'),
            accessor: 'name',
            width: 200,
          },
          {
            Header: t('company:contentFile:createDate'),
            accessor: 'createDate',
            width: 180,
            Cell: ({ value }: any) =>
            (
              <>{moment(value as Date).format('L LT')}</>
            ),
            Filter: DateColumnFilter,
          },
          {
            Header: t('company:contentFile:modificationDate'),
            accessor: 'modificationDate',
            width: 180,
            Cell: ({ value }: any) =>
            (
              <>{moment(value as Date).format('L LT')}</>
            ),
            Filter: DateColumnFilter,
          },
          {
            Header: t('company:contentFile:owner.name'),
            accessor: 'owner.name',
            width: 180,
          },
          {
            Header: t('company:contentFile:fileSize'),
            accessor: 'fileSize',
            width: 120,
            align: 'right',
            Cell: ({ value }: any) =>
            (
              <>{formatFileSize(value)}</>
            ),
          },
          {
            Header: t('company:contentFile:storageFileSize'),
            accessor: 'storageFileSize',
            width: 150,
            align: 'right',
            Cell: ({ value }: any) =>
            (
              <>{formatFileSize(value)}</>
            ),
          },
        ]
      },
    ];
  }, [t]);

  const actionIsLoading = (fileUploadIsLoading || deleteIsLoading);
  return (
    <>
      <Grid container>
        <BoxedAlert message={errorMessage}
          container="gridItem" paddingBottom={1}
          severity="error" variant="filled" />

        <BoxedAlert message={warningMessage}
          container="gridItem" paddingBottom={1}
          severity="warning" variant="filled" />

        <Grid item xs={12} className={classes.defaultSearchBarGridItem}>
          <Box marginY={1}>
            <SearchBar
              placeholder={t('company:files.searchBar.placeholder')}
              onSearchTextChanged={searchTextChanged}
              value={searchText}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Box className={classes.portalPaperTitleBox}>
              <IconActionButton label={t('company:files.buttonUploadFiles')}
                disabled={actionIsLoading}
                component="label">
                <input hidden
                  name="files"
                  accept={getUploadAccept(folderKey)}
                  type="file"
                  multiple={true}
                  onChange={handleFileUpload} />
                <CloudUploadIcon />
              </IconActionButton>

              <Box className={classes.portalPaperActionButtonsBox} pr={1}>
                <IconActionButton label={t('common:button:delete')}
                  disabled={actionIsLoading || !hasFilesDeleteRight}
                  icon={<DeleteIcon />} onClick={deleteButtonClick} />
              </Box>
            </Box>

            <LinearProgressBoxWithLabel value={uploadPercentage} visible={uploadPercentage > 0 && uploadPercentage < 100} px={1} />
            {/*<LinearProgressBoxWithLabel value={uploadPercentage} visible={true} px={1} />*/}

            <CustomDataGrid<ContentFile>
              name="companyFiles"
              isLoading={filesIsLoading}
              columns={fileColumns}
              data={fileRows}
              loadLastPageIndex={tableLoadPageIndex}
              fixedTableHeader={!isDialog}
              disableRowSelection={false}
              onInitialized={(instance: TableInstance<ContentFile> | undefined) => tableInstance.current = instance}
              onClick={rowClicked}
            //onAdd={(tenantContext?.tenant?.isMainTenant ?? true) ? undefined : tableOnAdd}
            //onAddDisabled={!hasUsersEditRight}
            />

          </Paper>
        </Grid>
      </Grid>

      <SnackbarAlert open={showSuccessFileUploaded} onClose={() => setShowSuccessFileUploaded(false)}
        message={
          <Trans i18nKey="company:files.successFileUploaded" count={selectedFilesCount}>
            File <strong>{{ fileName: selectedFilenames }}</strong> uploaded successfully!
          </Trans>}
        severity="success" />

      <SnackbarAlert open={showSuccessFileDeleted} onClose={() => setShowSuccessFileDeleted(false)}
        message={
          <Trans i18nKey="company:files.successFileDeleted" count={selectedFilesCount}>
            File <strong>{{ fileName: selectedFilenames }}</strong> deleted successfully!
          </Trans>}
        severity="success" />

      <AlertDialog open={showDeleteDialog} onClose={handleDeleteDialog}
        title={t('company:files.deleteDialog.title', { count: selectedFilesCount })}
        content={
          <Trans i18nKey="company:files.deleteDialog.content" count={selectedFilesCount}>
            The file <strong>{{ fileName: selectedFilenames }}</strong> is then completely deleted and cannot be restored..
          </Trans>}
        dialogType="delete" />
    </>
  );
}

export default withStyles(AppStyle)(Files);
export { FilesDialog }