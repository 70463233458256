import StringHelper from "general/helpers/types/StringHelper";
import { TFunction } from "i18next";
import RolesHelper, { roleAdmin, roleCompany, roleCompanyEmployee, roleContentManager } from "idserver/models/Roles";
import { right_wildcard, right_group_separator } from "account/models/Rights";

class AccountUser {
	sub: string | undefined;
	name: string | undefined;
	given_name: string | undefined;
	family_name: string | undefined;
	email: string | undefined;
	preferred_username: string | undefined;
	phone_number: string | undefined;
	roles: string[] | undefined;
	rights: string[] | undefined;

	get initials(): string | undefined {
		let text: string | undefined = undefined;
		const givenName = this.given_name ?? '';
		const familyName = this.family_name ?? '';
		const name = this.name ?? '';

		if (givenName.length > 0 && familyName.length > 0) {
			text = StringHelper.left(givenName, 1) + StringHelper.left(familyName, 1);
		} else if (givenName.length > 0) {
			text = StringHelper.left(givenName, 1)
		} else if (familyName.length > 0) {
			text = StringHelper.left(familyName, 1)
		} else if (name.length > 0) {
			text = StringHelper.left(name, 1)
		}

		const textStr = text ?? '';
		if (textStr.length > 0) {
			return textStr.toLocaleUpperCase();
		}

		return undefined;
	}

	get isAuthenticated(): boolean {
		if ((this.sub) !== undefined) {
			return true;
		}
		return false;
	}

	get hasContentManagerRights(): boolean {
		if ((this.sub) !== undefined) {
			const rolesArray = this.roles ?? [];
			if ((rolesArray.indexOf(roleAdmin) > -1) || (rolesArray.indexOf(roleContentManager) > -1)) {
				return true;
			}
		}
		return false;
	}

	get hasCompanyEmployeeRights(): boolean {
		if ((this.sub) !== undefined) {
			const rolesArray = this.roles ?? [];
			if ((rolesArray.indexOf(roleAdmin) > -1) || (rolesArray.indexOf(roleContentManager) > -1) ||
				(rolesArray.indexOf(roleCompany) > -1) || (rolesArray.indexOf(roleCompanyEmployee) > -1)) {
				return true;
			}
		}
		return false;
	}

	get rolesText(): string {
		return RolesHelper.getRolesText(this.roles);
	}

	getRolesText(translationIdServer?: TFunction) {
		return RolesHelper.getRolesText(this.roles, translationIdServer);
	}

	hasRight(rightKey: string): boolean {
		if (this.rights !== undefined && this.rights.length > 0) {
			// Check exact match
			if (this.rights.indexOf(rightKey) > -1) return true;

			// Check group key match e.g. GroupName_* OR GroupName_GroupPart_*
			let groupKey = '';
			const rightElems = rightKey.split(right_group_separator);
			for (var g = 0; g < rightElems.length - 1; g++) {
				groupKey = `${groupKey}${rightElems[g]}${right_group_separator}`;

				let groupKeyWildcard = `${groupKey}${right_wildcard}`;
				if (this.rights !== undefined && this.rights.indexOf(groupKeyWildcard) > -1) return true;
			}
		};
		return false;
	}
};

export default AccountUser;