import React from 'react';
import { Box } from '@material-ui/core';

interface TabPanelProps {
	children?: React.ReactNode;
	selectedValue: any;
  value: any;
  padding?: any; 
}

function TabPanel(props: TabPanelProps) {
	const { children, selectedValue, value, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={selectedValue !== value}
			id={`simple-tabpanel-${value}`}
			aria-labelledby={`simple-tab-${value}`}
			{...other}
		>
			{selectedValue === value && (
				<Box p={props.padding ?? 1}>{children}</Box>
			)}
		</div>
	);
}

export default TabPanel;