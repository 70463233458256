const RouteAccount: string = "account";
const RoutePortal: string = "portal";
const RoutePortalAccount: string = "account";
const RoutePortalManage: string = "manage";
const RoutePortalManageUsers: string = "users";
const RoutePortalCompany: string = "company";
const RoutePortalCompanyUsers: string = "users";
const RouteCompany: string = "company";
const RouteIdServer: string = "idserver";

const Routes = {
    Account: {
        Login: `/${RouteAccount}/login`,
        LoginOidcCallback: `/${RouteAccount}/login-oidc-callback`,
        Logout: `/${RouteAccount}/logout`,
        LoggedOut: `/${RouteAccount}/loggedOut`,
    },
    Portal: {
        Account: {
            Profile: `/${RoutePortal}/${RoutePortalAccount}/profile`,
            ChangePassword: `/${RoutePortal}/${RoutePortalAccount}/changePassword`,
        },
        General: {
            Home: `/${RoutePortal}/`,
        },
        Manage: {
            ManageUsers: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}`,
            Users: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}/users`,
            UserDetails: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}/userDetails`, // :subjectId/:tab?
            UserCreate: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}/userCreate`,
            Groups: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}/groups`,
            GroupDetails: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}/groupDetails`, // /:groupId
            GroupRightsConfig: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}/groupRightsConfig`, // /:groupId/:groupName?
            GroupCreate: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}/groupCreate`,
            Rights: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}/rights`,
            RightDetails: `/${RoutePortal}/${RoutePortalManage}/${RoutePortalManageUsers}/rightDetails`, // /:rightId/:rightName?
        },
        Company: {
            Files: `/${RoutePortal}/${RoutePortalCompany}/files`,  // /:folderKey?
            Tenants: `/${RoutePortal}/${RoutePortalCompany}/tenants`,
            TenantDetails: `/${RoutePortal}/${RoutePortalCompany}/tenantDetails`, // /:tenantId/:tenantName?
            TenantCreate: `/${RoutePortal}/${RoutePortalCompany}/tenantCreate`,
            CompanyUsers: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}`,
            Users: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}/users`,
            UserDetails: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}/userDetails`, // :subjectId/:tab?
            UserCreate: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}/userCreate`,
            Groups: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}/groups`,
            GroupDetails: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}/groupDetails`, // /:groupId
            GroupRightsConfig: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}/groupRightsConfig`, // /:groupId/:groupName?
            GroupCreate: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}/groupCreate`,
            Rights: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}/rights`,
            RightDetails: `/${RoutePortal}/${RoutePortalCompany}/${RoutePortalCompanyUsers}/rightDetails`, // /:rightId/:rightName?
        }
    },
    Company: {
        TenantEmailConfirm: `/${RouteCompany}/tenantEmailConfirm`,
    },
    IdServer: {
        Login: `/${RouteIdServer}/login`,
        Logout: `/${RouteIdServer}/logout`,
        Error: `/${RouteIdServer}/error`,
        SignUp: `/${RouteIdServer}/signUp`,
        SignUpCompany: `/${RouteIdServer}/signUpCompany`,
        SignUpConfirm: `/${RouteIdServer}/signUpConfirm`,
        PasswordReset: `/${RouteIdServer}/passwordReset`,
        PasswordResetConfirm: `/${RouteIdServer}/passwordResetConfirm`,
    },
}

export default Routes;
export { RouteAccount, RoutePortal, RouteCompany, RouteIdServer };
