import BaseUser from "company/users/models/BaseUser";

class ManageUser extends BaseUser {
  constructor(dataUser?: any) {
    if (dataUser) {
      super(dataUser);
    } else {
      super();
    }
  }
}

class ManageUserCreateRequest extends ManageUser {
  password: string | undefined;
  passwordRepeat: string | undefined;
}

class ManageUserChangePasswordRequest {
  subjectId: string | undefined;
  password: string | undefined;
  passwordRepeat: string | undefined;
}

class ManageRightUser extends ManageUser {
  isAllowed: boolean | null | undefined;

  constructor(dataRightUser?: any) {
    super(dataRightUser);
    if (dataRightUser) {
      this.isAllowed = dataRightUser.isAllowed;
    }
  }
}

export default ManageUser;
export { ManageUserCreateRequest, ManageUserChangePasswordRequest, ManageRightUser };