import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiHelper from 'general/helpers/api/ApiHelper';
import { useTranslation, Trans } from 'react-i18next';
import DefaultPaper from 'general/components/container/DefaultPaper';
import Copyright from 'general/components/app/Copyright';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Container, Avatar, Typography, TextField, Button, Grid, Box } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert, AlertTitle } from '@material-ui/lab';

interface PasswordResetProps extends WithStyles<typeof AppStyle> { }

function PasswordReset(props: PasswordResetProps) {
	enum viewMode { form, success }
	const classes = props.classes;
	const { t } = useTranslation(['idserver', 'validation', 'common']);
	const [emailSendTo, setEmailSendTo] = useState('');
	const [currentViewMode, setcurrentViewMode] = useState(viewMode.form);

	function SuccessView() {
		return (
			<Container component="main">
				<DefaultPaper>
					<Alert severity="info">
						<AlertTitle>{t('common:alertTitle.info')}</AlertTitle>
						<p>
							<Trans i18nKey="idserver:passwordReset.textEmailSendTo">
								A password recovery link has been sent to email address <strong>{{ emailSendTo }}</strong>.
              </Trans>
						</p>
						<p>{t('idserver:email.textMailBoxCheck')}</p>
						<p>{t('idserver:passwordReset.textNoEmailContact')}</p>
					</Alert>
				</DefaultPaper>
			</Container>
		);
	}

	function showSuccessView(emailSendTo: string) {
		setcurrentViewMode(viewMode.success);
		setEmailSendTo(emailSendTo);
	}

	return (
		<div>
			{currentViewMode === viewMode.form ?
				<PasswordResetFormView showSuccessView={showSuccessView} classes={classes} /> :
				<SuccessView />}
		</div>
	);
}

type ShowSuccessViewCallback = (emailSendTo: string) => void;
interface PasswordReseFormViewtProps extends PasswordResetProps {
	showSuccessView: ShowSuccessViewCallback
}

function PasswordResetFormView(props: PasswordReseFormViewtProps) {
	const classes = props.classes;
	const { t } = useTranslation(['idserver', 'validation']);
	const [errorMessage, setErrorMessage] = useState('');
	const [resetLoading, setResetLoading] = useState(false);
	const { register, handleSubmit, errors } = useForm();

	async function onSubmit(data: any) {
		setErrorMessage('');
		const email = data.email;

		try {
			setResetLoading(true);
			const apiHelper = new ApiHelper();
			await apiHelper.Api.post('/apiidserver/password/reset',
				JSON.stringify({
					email
				}));
			props.showSuccessView(email);
		} catch (error) {
			if ((error?.response?.status ?? 0) === 400) {
				setErrorMessage(t(`idserver:passwordReset.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
			setResetLoading(false);
		};
	}

	const valRequired = t('validation:required');
	const valMaxLengthExceeded = t('validation:maxLengthExceeded');
	return (
		<Container component="main" maxWidth="xs">
			<DefaultPaper>
				<Avatar className={classes.defaultAvatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5" className={classes.defaultFormTitle}>
					{t('idserver:passwordReset.title')}
				</Typography>
				<form className={classes.defaultForm} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('idserver:passwordReset.labelEmail')}
								name="email"
								autoComplete="email"
								autoFocus
								inputRef={register({ required: valRequired, maxLength: { value: 320, message: valMaxLengthExceeded } })}
								error={!!errors.email}
								helperText={errors.email ? errors.email.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={resetLoading}
								className={classes.defaultFormSubmitButton}
							>
								{resetLoading ?
									t('loading') :
									t('idserver:passwordReset.buttonSendRequest')}
							</Button>
						</Grid>
					</Grid>
				</form>

				{errorMessage !== '' ?
					<Grid container>
						<Grid item xs={12}>
							<Alert severity="error">
								{errorMessage}
							</Alert>
							<br />
						</Grid>
					</Grid> : null}

			</DefaultPaper>
			<Box mt={6}>
				<Copyright />
			</Box>
		</Container>
	);
}

export default withStyles(AppStyle)(PasswordReset);