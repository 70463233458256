// Source: https://github.com/ggascoigne/react-table-example
//         https://codesandbox.io/s/github/ggascoigne/react-table-example
//
// Based on: https://react-table.tanstack.com/
//           https://github.com/tannerlinsley/react-table
//
// Responsive: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/full-width-table?from-embed=&file=/src/App.js
//             https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/full-width-resizable-table?from-embed=&file=/src/App.js
//
// Sticky-Headers: https://medium.com/@elad/css-position-sticky-how-it-really-works-54cd01dc2d46
//                 https://uxdesign.cc/position-stuck-96c9f55d9526

import { Box, TableSortLabel } from '@material-ui/core'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import cx from 'classnames'
import React, { CSSProperties, PropsWithChildren, ReactElement, useEffect } from 'react'
import {
  Cell,
  CellProps,
  Column,
  HeaderGroup,
  HeaderProps,
  Hooks,
  Meta,
  Row,
  TableInstance,
  TableOptions,
  TableProps,
  TableRowProps,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import { camelToWords, useDebounce, useLocalStorage } from './utils'
import { FilterChipBar } from './FilterChipBar'
import { fuzzyTextFilter, numericTextFilter, booleanFilter, BooleanFilterKey, DefaultColumnFilter, selectFilter, SelectFilterKey } from './filters'
import { ResizeHandle } from './ResizeHandle'
import { TablePagination } from './TablePagination'
import { HeaderCheckbox, RowCheckbox, useStyles } from './styles/TableStyles'
import { TableToolbar, TableToolbarNoData } from './TableToolbar'
import { TooltipCell } from './TooltipCell'
import CircularProgressBox from 'general/components/common/CircularProgressBox'
import { TFunction } from 'i18next'
import { TableMouseEventHandler } from './types/react-table-config'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import { useTranslation } from 'react-i18next'

export type CustomFixedTableHeader = {
  disabled?: boolean;
  topPosition?: string | number;
}

export interface CustomDataGridProps<T extends object = {}> extends TableOptions<T> {
  name: string;
  isLoading?: boolean;
  disableRowSelection?: boolean;
  loadLastPageIndex?: boolean;
  fixedTableHeader?: CustomFixedTableHeader | boolean;
  onInitialized?: (instance: TableInstance<T>) => void;
  onAdd?: TableMouseEventHandler;
  onDelete?: TableMouseEventHandler;
  onEdit?: TableMouseEventHandler;
  onAddDisabled?: boolean;
  onDeleteDisabled?: boolean;
  onEditDisabled?: boolean;
  onClick?: (row: Row<T>) => void;
}

const DefaultHeader: React.FC<HeaderProps<any>> = ({ column }) => (
  <>{column.id.startsWith('_') ? null : camelToWords(column.id)}</>
)

const getStyles = <T extends object>(props: any, disableResizing = false, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : (align === 'center' ? 'center' : 'flex-start'),
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
]

export const SelectorColumnId = '_selector';
const selectionHook = (hooks: Hooks<any>) => {
  hooks.allColumns.push((columns) => [
    // Let's make a column for selection
    {
      id: SelectorColumnId,
      disableResizing: true,
      disableGroupBy: true,
      minWidth: 45,
      width: 45,
      maxWidth: 45,
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
        <HeaderCheckbox {...getToggleAllRowsSelectedProps()} title="" />
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }: CellProps<any>) => <RowCheckbox {...row.getToggleRowSelectedProps()} title="" />,
    },
    ...columns,
  ])
  hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
    // fix the parent group of the selection button to not be resizable
    const selectionGroupHeader = headerGroups[0].headers[0]
    selectionGroupHeader.canResize = false
  })
}

const getSelectionHook = (disableRowSelection?: boolean): ((hooks: Hooks<any>) => void) => {
  if (disableRowSelection ?? true) { // Default => RowSelection disabled
    // Return emtpy function
    return (hooks: Hooks<any>) => { };
  }
  // Return selection row and column initializer
  return selectionHook;
}

const tableProps = <T extends object>(props: Partial<TableProps>, meta: Meta<T>) => [
  props,
  {
    style: {
      // Remove 925px react-table useflexLayout() style => problem with hover and selection 
      // using horizontal scrollbar
      minWidth: 'none',
    },
  },
]

const rowProps = <T extends object>(props: Partial<TableRowProps>, meta: Meta<T>) => [
  props,
  {
    style: {
      // Remove 925px react-table useflexLayout() style => not needed
      minWidth: 'none',
    },
  },
]

const headerProps = <T extends object>(props: any, { column }: Meta<T, { column: HeaderGroup<T> }>) =>
  getStyles(props, column && column.disableResizing, column && column.align)

const cellProps = <T extends object>(props: any, { cell }: Meta<T, { cell: Cell<T> }>) =>
  getStyles(props, cell.column && cell.column.disableResizing, cell.column && cell.column.align)

const getDefaultColumn = <T extends object>(props: PropsWithChildren<CustomDataGridProps<T>>) => {
  return {
    Filter: DefaultColumnFilter,
    Cell: TooltipCell,
    Header: DefaultHeader,
    canGroupBy: props.defaultCanGroupBy ?? false,
    // When using the useFlexLayout:
    minWidth: 100, // minWidth is only used as a limit for resizing
    width: 150, // width is used for both the flex-basis and flex-grow
    maxWidth: 1000, // maxWidth is only used as a limit for resizing
  } as Partial<Column<T>>;
}

const hooks = [
  useColumnOrder,
  useFilters,
  useGroupBy,
  useSortBy,
  useExpanded,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
]

const filterTypes = {
  fuzzyText: fuzzyTextFilter,
  numeric: numericTextFilter,
  [BooleanFilterKey]: booleanFilter,
  [SelectFilterKey]: selectFilter,
}

export const useFixedTableHeader = (fixedTableHeader: boolean | CustomFixedTableHeader | undefined) => {
  if (fixedTableHeader === undefined)
    // If no fixedTableHeader option defined, then it is disabled
    return false;
  else if (typeof fixedTableHeader === 'boolean')
    // Direct enabled/disabled definition
    return fixedTableHeader;
  else
    // If fixedTableHeader option is defined, then check disabled option.
    // Default is enabled if it is definied.
    return !(fixedTableHeader.disabled ?? false);
}

export const useFixedTableHeaderStyle = (fixedTableHeader: boolean | CustomFixedTableHeader | undefined) => {
  if (fixedTableHeader === undefined ||
    typeof fixedTableHeader === 'boolean')
    return undefined;
  else if (fixedTableHeader.topPosition === undefined)
    return undefined;
  else
    return {
      top: fixedTableHeader.topPosition
    } as CSSProperties
}

export function CustomDataGrid<T extends object>(props: PropsWithChildren<CustomDataGridProps<T>>): ReactElement {
  const { onAdd, onAddDisabled } = props;
  const { t } = useTranslation(['common']);

  return (props.isLoading ?
    <CircularProgressBox pt={10} pb={12} /> :
    ((props?.data?.length ?? 0) <= 0 ?
      <>
        <TableToolbarNoData translation={t} onAdd={onAdd} onAddDisabled={onAddDisabled} />
        <Box display="flex" justifyContent="center" pt={10} pb={12}>
          {t ? t('common:customDataGrid.noDataText') : 'No data'}
        </Box>
      </> :
      <CustomDataGridInternal {...props} translation={t} />
    )
  );
}

interface CustomDataGridInternalProps<T extends object = {}> extends PropsWithChildren<CustomDataGridProps<T>> {
  translation?: TFunction;
}

function CustomDataGridInternal<T extends object>(props: CustomDataGridInternalProps<T>): ReactElement {
  const { name, columns, loadLastPageIndex, disableRowSelection, fixedTableHeader, onInitialized,
    onAdd, onDelete, onEdit, onAddDisabled, onDeleteDisabled, onEditDisabled, onClick, translation } = props;
  const classes = useStyles();

  const [initialState, setInitialState] = useLocalStorage(`app.CustomDataGrid.${name}`, {})
  const savedColumnResizing = initialState.columnResizing

  const instance = useTable<T>(
    {
      ...props,
      columns,
      filterTypes,
      initialState,
      defaultColumn: getDefaultColumn(props),
    },
    ...hooks,
    getSelectionHook(disableRowSelection),
  )

  const { getTableProps, headerGroups, getTableBodyProps, page, prepareRow, state } = instance

  const debouncedState = useDebounce(state, 500)

  useEffect(() => {
    const { sortBy, filters, pageSize, pageIndex, lastPageIndex, columnResizing, hiddenColumns } = debouncedState
    const val = {
      sortBy,
      filters,
      pageSize,
      columnResizing,
      hiddenColumns,
      lastPageIndex: pageIndex,
    }

    // Fix useTable() hook problem 
    // Reloading same page (e.g. changeLang or route link) returns empty columnResizing object
    if (savedColumnResizing &&
      ((Object.keys(savedColumnResizing.columnWidths)?.length ?? 0) > 0) &&
      (Object.keys(columnResizing.columnWidths)?.length ?? 0) <= 0) {
      // Saved columnWidths exists (has properties) and returned columnWidths is empty
      // => Reset zu saved columnWidths
      val.columnResizing = savedColumnResizing;
      debouncedState.columnResizing = savedColumnResizing;
      console.log('CustomDataGrid fixed savedColumnWidths');
    }

    // Load lastPageIndex (e.g. goBack navigation)
    if (loadLastPageIndex) {
      console.log('CustomDataGrid load lastPageIndex:', lastPageIndex);
      val.lastPageIndex = lastPageIndex;
      instance.gotoPage(lastPageIndex);
    }

    setInitialState(val)

    // Raise callback event
    if (onInitialized) onInitialized(instance);

  }, [setInitialState, debouncedState, savedColumnResizing, loadLastPageIndex, instance, onInitialized])

  const cellClickHandler = (cell: Cell<T>) => () => {
    return onClick && cell.column.id !== SelectorColumnId && onClick(cell.row)
  }

  const customCellStyle = (cell: Cell<T>, currentStyle: CSSProperties | undefined): CSSProperties => {
    let cursorProp = {};
    if (onClick && cell.column.id !== SelectorColumnId) {
      cursorProp = {
        cursor: 'pointer',
      };
    }
    return {
      ...currentStyle,
      ...cursorProp
    };
  }

  return (
    <>
      <TableToolbar instance={instance} {...{ translation, onAdd, onDelete, onEdit, onAddDisabled, onDeleteDisabled, onEditDisabled }} />
      <FilterChipBar<T> instance={instance} {...{ translation }} />
      <div className={classes.tableContainer}>
        <ScrollSync proportional={false}>
          <div>
            <ScrollSyncPane>
              <div className={cx(classes.tableHeadContainer, { fixedTableHeader: useFixedTableHeader(fixedTableHeader) })}
                style={useFixedTableHeaderStyle(fixedTableHeader)}>
                <div>
                  {headerGroups.map((headerGroup) => {
                    let currentHeader: any = headerGroup.headers[0];
                    if (headerGroup.headers.length > 1) {
                      // First header is SortHeader => Use second
                      currentHeader = headerGroup.headers[1];
                    }
                    if (!currentHeader?.HideHeader ?? false) { // If a header line has the HideHeader flag, then hide the first header line for all columns
                      return (
                        <div {...headerGroup.getHeaderGroupProps()} className={classes.tableHeadRow}>
                          {headerGroup.headers.map((column) => {
                            const style = {
                              textAlign: column.align ? column.align : 'left ',
                            } as CSSProperties
                            return (
                              <div {...column.getHeaderProps(headerProps)} className={classes.tableHeadCell}>
                                {column.canGroupBy && column.id !== SelectorColumnId && (
                                  <TableSortLabel
                                    active
                                    direction={column.isGrouped ? 'desc' : 'asc'}
                                    IconComponent={KeyboardArrowRight}
                                    {...column.getGroupByToggleProps()}
                                    className={classes.headerIcon}
                                  />
                                )}
                                {column.canSort ? (
                                  <TableSortLabel
                                    active={column.isSorted}
                                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                                    {...column.getSortByToggleProps()}
                                    className={classes.tableSortLabel}
                                    style={style}
                                  >
                                    {column.render('Header')}
                                  </TableSortLabel>
                                ) : (
                                    <div style={style} className={classes.tableLabel}>
                                      {column.render('Header')}
                                    </div>
                                  )}
                                {column.canResize && <ResizeHandle column={column} />}
                              </div>
                            )
                          })}
                        </div>
                      )
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            </ScrollSyncPane>
            <ScrollSyncPane>
              <div className={classes.tableBodyContainer} {...getTableProps(tableProps)}>
                <div {...getTableBodyProps()} className={classes.tableBody}>
                  {page.map((row) => {
                    prepareRow(row)
                    return (
                      <div {...row.getRowProps(rowProps)} className={cx(classes.tableRow, { rowSelected: row.isSelected })}>
                        {row.cells.map((cell) => {
                          const newCellProps = cell.getCellProps(cellProps);
                          const { style } = newCellProps;
                          return (
                            <div
                              {...newCellProps}
                              onClick={cellClickHandler(cell)}
                              style={customCellStyle(cell, style)}
                              className={classes.tableCell}
                            >
                              {cell.isGrouped ? (
                                <>
                                  <TableSortLabel
                                    classes={{
                                      iconDirectionAsc: classes.iconDirectionAsc,
                                      iconDirectionDesc: classes.iconDirectionDesc,
                                    }}
                                    active
                                    direction={row.isExpanded ? 'desc' : 'asc'}
                                    IconComponent={KeyboardArrowUp}
                                    {...row.getToggleRowExpandedProps()}
                                    className={classes.cellIcon}
                                  />{' '}
                                  {cell.render('Cell')} ({row.subRows.length})
                                </>
                              ) : cell.isAggregated ? (
                                cell.render('Aggregated')
                              ) : cell.isPlaceholder ? null : (
                                cell.render('Cell')
                              )}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </ScrollSyncPane>
          </div>
        </ScrollSync>
      </div>
      <TablePagination<T> instance={instance} {...{ translation }} />
    </>
  )
}