import BaseService from "general/helpers/api/BaseService";
import CompanyTenantTheme from "company/tenants/models/CompanyTenantTheme";

class CompanyTenantThemesService extends BaseService {
  private _tenantId: string | undefined;
  private _serviceUrl: string = '';

  constructor(tenantId?: string | undefined) {
    super();
    this.tenantId = tenantId;
  }

  get tenantId(): string | undefined {
    return this._tenantId;
  }

  set tenantId(value: string | undefined) {
    this._tenantId = value;
    this._serviceUrl = `${this._baseUrlApiPortal}/company/tenants/${value ?? ''}/themes`;
  }

  public async getThemes(): Promise<Array<CompanyTenantTheme>> {
    const resp = await (await this.useApiWithAuth())
      .get(this._serviceUrl);
    return resp.data.map((data: any) => {
      return new CompanyTenantTheme(data);
    });
  }

  public async getTheme(themeId: string): Promise<CompanyTenantTheme> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${themeId}`);
    return new CompanyTenantTheme(resp.data);
  }

  public async createTheme(theme: CompanyTenantTheme): Promise<CompanyTenantTheme> {
    theme.tenantId = this.tenantId;
    const resp = await (await this.useApiWithAuth())
      .post(this._serviceUrl,
        JSON.stringify(theme));
    return new CompanyTenantTheme(resp.data);
  }

  public async updateTheme(theme: CompanyTenantTheme): Promise<void> {
    await (await this.useApiWithAuth())
      .put(`${this._serviceUrl}/${theme.themeId}`,
        JSON.stringify(theme));
  }

  public async deleteTheme(themeId: string): Promise<void> {
    await (await this.useApiWithAuth())
      .delete(`${this._serviceUrl}/${themeId}`);
  }
}

export default CompanyTenantThemesService;