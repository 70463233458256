import React from 'react'
import { Checkbox, Theme, createStyles, makeStyles, styled } from '@material-ui/core'

const tableBorderColor: string = 'rgba(224, 224, 224, 1)';
const tableBorderStyle: string = `1px solid ${tableBorderColor}`;
const tableRowHoverColor: string = 'rgba(0, 0, 0, 0.07)';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    tableHeadContainer: {
      display: 'flex',
      overflowX: 'hidden',
      borderSpacing: 0,

      '&.fixedTableHeader': {
        position: 'sticky',

        /* MediumUp layout has two toolbars und SmallDown layout has one toolbar */
        top: '48px',
        [theme.breakpoints.up('md')]: {
          top: '96px',
        },

        zIndex: 1, // Fix transparent backgroundColor on sticky header
      },
    },
    tableHeadRow: {
      outline: 0,
      verticalAlign: 'middle',
      color: theme.palette.text.primary,
      fontWeight: 500,
      lineHeight: '1.5rem',
      position: 'relative',
      '&:hover $resizeHandle': {
        opacity: 1,
      },

      '&:first-child $tableHeadCell': {
        borderTop: tableBorderStyle,
      },
    },
    tableHeadCell: {
      padding: '16px 1px 16px 16px',
      fontSize: '0.875rem',
      textAlign: 'left',
      verticalAlign: 'inherit',
      color: theme.palette.text.primary,
      fontWeight: 500,
      lineHeight: '1.5rem',

      borderBottom: tableBorderStyle,
      borderLeft: tableBorderStyle,
      '&:last-child': {
        borderRight: tableBorderStyle,
      },

      backgroundColor: theme.palette.grey[100],

      /* In this example we use an absolutely position resizer,
         so this is required. */
      position: 'relative',
    },
    resizeHandle: {
      position: 'absolute',
      cursor: 'col-resize',
      zIndex: 100,
      opacity: 0,
      borderLeft: `1px solid ${theme.palette.primary.light}`,
      borderRight: `1px solid ${theme.palette.primary.light}`,
      height: '50%',
      top: '25%',
      transition: 'all linear 100ms',
      right: -2,
      width: 3,
      '&.handleActive': {
        opacity: '1',
        border: 'none',
        backgroundColor: theme.palette.primary.light,
        height: 'calc(100% - 4px)',
        top: '2px',
        right: -1,
        width: 1,
      },

      /* prevents from scrolling while dragging on touch devices */
      touchAction: 'none',
    },
    tableBodyContainer: {
      display: 'flex',
      overflowX: 'auto',
      borderSpacing: 0,
    },
    tableBody: {},
    tableRow: {
      color: 'inherit',
      outline: 0,
      verticalAlign: 'middle',
      '&:hover': {
        backgroundColor: tableRowHoverColor,
      },
      '&.rowSelected': {
        backgroundColor: tableRowHoverColor,
        '&:hover': {
          backgroundColor: tableRowHoverColor,
        },
      },
    },
    tableCell: {
      padding: 16,
      fontSize: '0.875rem',
      textAlign: 'left',
      fontWeight: 300,
      lineHeight: 1.43,
      verticalAlign: 'inherit',
      color: theme.palette.text.primary,

      borderBottom: tableBorderStyle,
      borderLeft: tableBorderStyle,
      '&:last-child': {
        borderRight: tableBorderStyle,
      },

      /* In this example we use an absolutely position resizer,
         so this is required. */
      position: 'relative',
    },
    tableSortLabel: {
      '& svg': {
        width: 16,
        height: 16,
        marginTop: 0,
        marginLeft: 2,
      },
    },
    headerIcon: {
      '& svg': {
        width: 16,
        height: 16,
        marginTop: 4,
        marginRight: 0,
      },
    },
    iconDirectionAsc: {
      transform: 'rotate(90deg)',
    },
    iconDirectionDesc: {
      transform: 'rotate(180deg)',
    },
    tableLabel: {},
    cellIcon: {
      '& svg': {
        width: 16,
        height: 16,
        marginTop: 3,
      },
    },
    tablePagination: {
      /* Orient PaginationControl in a vertical line to the upper toolbar (ColumnFilter). 
         The media breakpoint defined by the MaterialUI is observed. */
      '& .MuiTablePagination-toolbar': {
        paddingRight: '8px', // Default: '16px',
        [theme.breakpoints.up('sm')]: {
          paddingRight: '16px', // Default: '24px',
        }
      },
    }
  })
)

const areEqual = (prevProps: any, nextProps: any) =>
  prevProps.checked === nextProps.checked && prevProps.indeterminate === nextProps.indeterminate

export const HeaderCheckbox = React.memo(
  styled(Checkbox)({
    fontSize: '1rem',
    margin: '-8px 0 -8px -15px',
    padding: '8px 9px',
    '& svg': {
      width: '24px',
      height: '24px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  areEqual
)

export const RowCheckbox = React.memo(
  styled(Checkbox)({
    fontSize: '14px',
    margin: '-9px 0 -8px -15px',
    padding: '8px 9px 9px 9px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      width: 24,
      height: 24,
    },
  }),
  areEqual
)
