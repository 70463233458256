import BaseState from "general/models/BaseState";
import { ManageUsersTabs } from "manage/users/views/ManageUsers";
import { RightDetailsTabs } from "manage/users/views/RightDetails";

class ManageUsersState {
  selectedTab: ManageUsersTabs | undefined;
  users: BaseState | undefined;
  groups: BaseState | undefined;
  rights: BaseState | undefined;
}

class RightDetailsState {
  selectedTab: RightDetailsTabs | undefined;
  users: BaseState | undefined;
  groups: BaseState | undefined;
}

export default ManageUsersState;
export { RightDetailsState };