import StringHelper from "general/helpers/types/StringHelper";
import { TFunction } from "i18next";
import RolesHelper, { roleAdmin, roleContentManager, roleCompany, roleCompanyEmployee, roleConsumer } from "idserver/models/Roles";

class BaseUser {
  subjectId: string | undefined;
  createDate: Date | undefined;
  isActive: boolean | undefined;
  email: string | undefined;
  isEmailVerified: boolean | undefined;
  name: string | undefined;
  givenName: string | undefined;
  familyName: string | undefined;
  phoneNumber: string | undefined;
  languageCode: string | undefined;
  rolesList: string[] | undefined;
  lastSignInDate: Date | undefined;
  tenants: string | undefined;

  constructor(dataUser?: any) {
    if (dataUser) {
      this.subjectId = dataUser.subjectId;
      this.email = dataUser.email;
      this.isEmailVerified = dataUser.isEmailVerified;
      this.givenName = dataUser.givenName;
      this.familyName = dataUser.familyName;
      this.name = dataUser.name;
      this.phoneNumber = dataUser.phoneNumber;
      this.languageCode = dataUser.languageCode;
      this.rolesList = dataUser.rolesList;
      this.isActive = dataUser.isActive;
      this.createDate = dataUser.createDate;
      this.lastSignInDate = dataUser.lastSignInDate;
      this.tenants = dataUser.tenants;
    }
  }

  getRolesText(translationIdServer?: TFunction): string {
    return RolesHelper.getRolesText(this.rolesList, translationIdServer);
  }

  getDisplayName(): string {
    if (!StringHelper.empty(this.givenName) &&
      !StringHelper.empty(this.familyName))
      return `${this.givenName} ${this.familyName}`;
    if (!StringHelper.empty(this.givenName))
      return this.givenName ?? '';
    if (!StringHelper.empty(this.familyName))
      return this.familyName ?? '';
    if (!StringHelper.empty(this.name))
      return this.name ?? '';
    return this.email ?? '';
  }

  isRole(role: string): boolean {
    if (this.rolesList) {
      return (this.rolesList.indexOf(role) > -1);
    }
    return false;
  }

  setRole(role: string, value: boolean) {
    if (!this.rolesList) this.rolesList = [];
    if (value) {
      this.rolesList.push(role);
    }
  }

  get isRoleAdmin(): boolean {
    return this.isRole(roleAdmin);
  }

  set isRoleAdmin(value: boolean) {
    this.setRole(roleAdmin, value);
  }

  get isRoleContentManager(): boolean {
    return this.isRole(roleContentManager);
  }

  set isRoleContentManager(value: boolean) {
    this.setRole(roleContentManager, value);
  }

  get isRoleCompany(): boolean {
    return this.isRole(roleCompany);
  }

  set isRoleCompany(value: boolean) {
    this.setRole(roleCompany, value);
  }

  get isRoleCompanyEmployee(): boolean {
    return this.isRole(roleCompanyEmployee);
  }

  set isRoleCompanyEmployee(value: boolean) {
    this.setRole(roleCompanyEmployee, value);
  }

  get isRoleConsumer(): boolean {
    return this.isRole(roleConsumer);
  }

  set isRoleConsumer(value: boolean) {
    this.setRole(roleConsumer, value);
  }
}

export default BaseUser;
