import React, { useEffect } from 'react';
import AccountProvider from 'account/AccountProvider';
import Loading from 'general/views/Loading';

function OidcLogout() {
    useEffect(() => {
        console.log('oidc logout');
        AccountProvider.logoutAsync();
    }, []);

    return (
        <Loading />
    );
}

export default OidcLogout;