import BaseService from "general/helpers/api/BaseService";
import UrlHelper from "general/helpers/UrlHelper";
import Tenant from 'idserver/models/Tenant';

class TenantsService extends BaseService {
  private _serviceUrl: string;

  public constructor() {
    super(undefined, '/apiidserver');
    this._serviceUrl = `${this._baseUrlApiPortal}/tenants`
  }

  public async getTenant(): Promise<Tenant> {
    const resp = await (await this.useApi())
      .get(`${this._serviceUrl}/origin`);
    return new Tenant(resp.data);
  }

  public getFaviconUrl(faviconFileKey: string | undefined): string {
    return `${UrlHelper.getApiUrl()}${this._serviceUrl}/favicon${faviconFileKey ? '/' + faviconFileKey : '' }`;
  }
}

export default TenantsService;