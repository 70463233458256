export enum AlignX {
  left,
  center,
  right
}

export enum AlignY {
  top,
  center,
  bottom
}