import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Row, TableInstance } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import PortalPaper from 'general/components/container/PortalPaper';
import CompanyUser from 'company/users/models/CompanyUser';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import { UserDetailsProps } from "company/users/views/UserDetails";
import CompanyGroup from 'company/users/models/CompanyGroup';
import BoxedAlert from 'general/components/common/BoxedAlert';
import UserPaperTitle, { UserPaperTitleOption } from 'company/users/components/UserPaperTitle';
import CompanyUserGroupsService from 'company/users/helpers/CompanyUserGroupsService';
import { DialogResult } from 'general/components/common/ResponsiveDialog';
import UserGroupsAddDialog from 'company/users/components/UserGroupsAddDialog';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import { useRight, right_Company_Users_Rights_Edit } from 'account/models/Rights';
import AccountContext from 'account/AccountContext';

export interface UserGroupsProps extends UserDetailsProps {
  user: CompanyUser | undefined;
}

function UserGroups(props: UserGroupsProps) {
  const { classes, user } = props;
  const { t } = useTranslation(['company']);
  const [groupRows, setGroupRows] = useState<Array<CompanyGroup> | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showSuccessUserGroupsSaved, setShowSuccessUserGroupsSaved] = useState(false);
  const userGroupsService = useMemo(() => new CompanyUserGroupsService(user?.subjectId ?? ''), [user]);
  const accountContext = useContext(AccountContext);
  const hasEditRight = useRight(right_Company_Users_Rights_Edit, accountContext);

  useEffect(() => {
    // Load groups
    getGroupsAsync(groupRows, userGroupsService);
  }, [groupRows, userGroupsService]);

  async function getGroupsAsync(
    groupRows: Array<CompanyGroup> | undefined,
    currentService: CompanyUserGroupsService) {
    if (currentService.subjectId && groupRows === undefined) {
      console.log('Load user groups');
      setErrorMessage('');
      setIsLoading(true);
      try {
        const groups = await currentService.getGroups(true);
        setGroupRows(groups);
      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      } finally {
        // Reset
        setIsLoading(false);
      }
    }
  }

  async function handleAddDialogClose(
    dialogResult: DialogResult,
    selectedGroups: Array<CompanyGroup> | undefined) {
    // Close dialog
    setShowAddDialog(false);

    if (dialogResult === DialogResult.ok) {
      console.log('dialogResult selectedGroups', selectedGroups);

      // Add groups
      const groupIds = selectedGroups?.map((group: CompanyGroup) => {
        return group.groupId ?? '';
      });
      if (groupIds) {
        setErrorMessage('');
        setIsLoading(true);
        try {
          await userGroupsService.addGroups(groupIds);

          // Refresh groups list
          setGroupRows(undefined);

          // Show info
          setShowSuccessUserGroupsSaved(true);

        } catch (error) {
          if ((error?.response?.status ?? 0) === 400) {
            setErrorMessage(t(`company:userDetails.groupsTab.error.${error?.response?.data?.code}`));
          } else {
            console.error(error);
            setErrorMessage(error?.message ?? '');
          }
        } finally {
          // Reset
          setIsLoading(false);
        }
      }
    }
  }

  const tableOnAdd = (instance: TableInstance<CompanyGroup> | undefined) => () => {
    setShowAddDialog(true);
  };

  const tableOnDelete = (instance: TableInstance<CompanyGroup> | undefined) => async () => {
    console.log('tableOnDelete');
    console.log('selectedFlatRows', instance?.selectedFlatRows);

    // Remove selected groups
    const groupIds = instance?.selectedFlatRows?.map((groupRow: Row<CompanyGroup>) => {
      return groupRow.original.groupId ?? '';
    });
    if (groupIds) {
      setErrorMessage('');
      setIsLoading(true);
      try {
        await userGroupsService.removeGroups(groupIds);

        // Refresh groups list
        setGroupRows(undefined);

        // Show info
        setShowSuccessUserGroupsSaved(true);

      } catch (error) {
        if ((error?.response?.status ?? 0) === 400) {
          setErrorMessage(t(`company:userDetails.groupsTab.error.${error?.response?.data?.code}`));
        } else {
          console.error(error);
          setErrorMessage(error?.message ?? '');
        }
      } finally {
        // Reset
        setIsLoading(false);
      }
    }
  };

  const groupColumns = useMemo(() => {
    console.log('Init group columns');
    return [
      {
        Header: 'companyUserGroups',
        HideHeader: true,
        columns: [
          {
            Header: t('company:companyGroup:groupOrder'),
            accessor: 'groupOrder',
            width: 120,
          },
          {
            Header: t('company:companyGroup:groupName'),
            accessor: 'groupName',
            width: 300,
          },
          {
						Header: t('company:companyGroup:tenants'),
						accessor: 'tenants',
						width: 150,
					},
        ]
      },
    ];
  }, [t]);

  return (
    <>
      <Box mt={1}>
        <PortalPaper>
          <Box className={classes.portalPaperTitleBox}>
            <UserPaperTitle option={UserPaperTitleOption.groups} />
          </Box>
          <Box>
            <BoxedAlert message={errorMessage} severity="error" />
            <CustomDataGrid<CompanyGroup>
              name="companyUserGroups"
              isLoading={isLoading}
              columns={groupColumns}
              data={groupRows ?? new Array<CompanyGroup>()}
              fixedTableHeader={true}
              disableRowSelection={!hasEditRight}
              onAdd={tableOnAdd}
              onDelete={tableOnDelete}
              onAddDisabled={!hasEditRight}
              onDeleteDisabled={!hasEditRight}
            />
          </Box>
        </PortalPaper>
      </Box>

      <UserGroupsAddDialog
        open={showAddDialog}
        onClose={handleAddDialogClose}
        userGroupsService={userGroupsService} />

      <SnackbarAlert open={showSuccessUserGroupsSaved} onClose={() => setShowSuccessUserGroupsSaved(false)}
        message={t('company:userDetails.groupsTab.successUserGroupsSaved')} severity="success" />
    </>
  );
}

export default UserGroups;
