import BaseService from "general/helpers/api/BaseService";
import CompanyTenantEmailSetting, { CompanyTenantEmailVerification } from "company/tenants/models/CompanyTenantEmailSetting";

class CompanyTenantEmailSettingsService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/company/tenantEmailSettings`;

  public async getTenantEmailSetting(tenantId: string): Promise<CompanyTenantEmailSetting> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${tenantId}`);
    return new CompanyTenantEmailSetting(resp.data);
  }

  public async updateTenantEmailSetting(emailSetting: CompanyTenantEmailSetting): Promise<void>{
    await (await this.useApiWithAuth())
      .put(`${this._serviceUrl}/${emailSetting.tenantId}`,
        JSON.stringify(emailSetting));
  }
  
  public async sendTenantEmailVerification(emailVerification: CompanyTenantEmailVerification): Promise<void>{
    await (await this.useApiWithAuth())
      .post(`${this._serviceUrl}/${emailVerification.tenantId}/emailVerification`,
        JSON.stringify(emailVerification));
  }
}

export default CompanyTenantEmailSettingsService;