import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { Grid, Box, Button } from '@material-ui/core';
import BoxedAlert from 'general/components/common/BoxedAlert';
import EditActionButtonsGroup from 'general/components/common/EditActionButtonsGroup';
import TenantPaperTitle, { TenantPaperTitleOption } from 'company/tenants/components/TenantPaperTitle';
import FormDisplay, { FormDisplayType } from 'general/components/common/FormDisplay';
import { useRight, right_Company_Tenants_Edit, right_Company_Tenants_Delete } from 'account/models/Rights';
import AccountContext from 'account/AccountContext';
import CompanyTenant, { CompanyTenantFavicon } from '../models/CompanyTenant';
import AlertDialog, { AlertDialogResult } from 'general/components/common/AlertDialog';
import CompanyTenantsService from '../helpers/CompanyTenantsService';
import { TenantsRouteState } from './Tenants';
import Routes from 'general/helpers/Routes';
import RouteContext from 'general/RouteContext';
import { TenantDetailsProps } from './TenantDetails';
import FormDisplayMultiline from 'general/components/common/FormDisplayMultiline';
import FormDisplayCheckBox from 'general/components/common/FormDisplayCheckBox';
import TenantContext from 'idserver/TenantContext';
import { FilesDialog } from 'company/files/views/Files';
import { DialogResult } from 'general/components/common/ResponsiveDialog';
import ContentFile from 'company/files/models/ContentFile';
import { folder_tenantowner_icons } from 'company/files/helpers/Folders';
import SnackbarAlert from 'general/components/common/SnackbarAlert';

export interface TenantDetailsTenanViewProps extends TenantDetailsProps {
  tenant: CompanyTenant | undefined;
  tenantsService: CompanyTenantsService;
  onEdit: () => void;
  onTenantFaviconChanged: (favicon: CompanyTenantFavicon) => void;
}

function TenantDetailsTenantView(props: TenantDetailsTenanViewProps) {
  const { classes, tenant, tenantsService, onEdit, onTenantFaviconChanged } = props;
  const { t } = useTranslation(['company']);
  const routeHistory = useHistory();
  const initRouteContext = useRef(useContext(RouteContext));
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [showSelectFaviconDialog, setShowSelectFaviconDialog] = useState(false);
  const [showSuccessFaviconSaved, setShowSuccessFaviconSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const accountContext = useContext(AccountContext);
  const tenantContext = useContext(TenantContext);
  const hasTenantsEditRight = useRight(right_Company_Tenants_Edit, accountContext);
  const hasTenantsDeleteRight = useRight(right_Company_Tenants_Delete, accountContext);

  function deleteButtonClick() {
    setShowDeleteDialog(true);
  }

  async function handleDeleteDialog(dialogResult: AlertDialogResult) {
    // Close dialog
    setShowDeleteDialog(false);

    if (dialogResult === AlertDialogResult.yes) {
      setErrorMessage('');
      setDeleteIsLoading(true);
      try {
        await tenantsService.deleteTenant(tenant?.tenantId ?? '');

        // Set specific state for success message
        const tenantsState: TenantsRouteState = {};
        tenantsState.successTenantDeletedAlert = {
          visible: true,
          name: tenant?.name
        };
        initRouteContext.current.setRouteState(Routes.Portal.Company.Tenants, tenantsState);

        // Go back to tenants list
        routeHistory.goBack();

      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
        setDeleteIsLoading(false);
      }
    }
  }

  function selectFaviconClicked() {
    setShowSelectFaviconDialog(true);
  }

  async function handleSelectFaviconDialog(
    dialogResult: DialogResult, selectedFiles: Array<ContentFile> | undefined) {
    // Close dialog
    setShowSelectFaviconDialog(false);

    if (dialogResult === DialogResult.ok &&
      selectedFiles !== undefined && selectedFiles.length > 0) {

      setErrorMessage('');
      setSaveIsLoading(true);
      try {
        const selectedFavicon = new CompanyTenantFavicon();
        selectedFavicon.fileId = selectedFiles[0].fileId;
        selectedFavicon.name = selectedFiles[0].name;
        await tenantsService.updateTenantFavicon(tenant?.tenantId ?? '', selectedFavicon.fileId  ?? '');

        // Refresh selected favicon for tenant
        onTenantFaviconChanged(selectedFavicon);

        // Show info
        setShowSuccessFaviconSaved(true);

      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
        setSaveIsLoading(false);
      }
      console.log('handleSelectFaviconDialog.selectedFiles', selectedFiles);
    }
  }

  const clientHostUrl = tenantContext?.tenant?.clientConfig?.clientHostUrl;
  const tenantSubDomainUrl = tenant?.getSubDomainUrl(clientHostUrl);
  const tenantSubDomainDisplayUrl = tenant?.getSubDomainDisplayUrl(clientHostUrl);
  const tenantDomainUrl = tenant?.getDomainUrl(clientHostUrl);
  const tenantDomainDisplayUrl = tenant?.getDomainDisplayUrl(clientHostUrl);
  return (
    <>
      <Box className={classes.portalPaperTitleBox}>
        <TenantPaperTitle option={TenantPaperTitleOption.basicData} />
        <EditActionButtonsGroup
          editMode={false}
          onEditButtonClick={onEdit}
          onDeleteButtonClick={deleteButtonClick}
          disabled={deleteIsLoading || saveIsLoading}
          disabledEdit={!hasTenantsEditRight}
          disabledDelete={!hasTenantsDeleteRight || (tenant?.isMainTenant ?? false)}
        />
      </Box>
      <Box className={classes.portalPaperContentBox}>
        <BoxedAlert message={errorMessage} severity="error" />

        <Grid container spacing={2} className={classes.defaultFormGrid}>
          <Grid item xs={12}>
            <FormDisplay label={t('company:companyTenant.name')} value={tenant?.name} />
          </Grid>
          <Grid item xs={12}>
            <FormDisplay
              label={t('company:companyTenant.subDomain')}
              value={tenantSubDomainDisplayUrl}
              displayType={FormDisplayType.link}
              linkHref={tenantSubDomainUrl}
              linkTarget="_blank" />
          </Grid>
          <Grid item xs={12}>
            <FormDisplay
              label={t('company:companyTenant.domain')}
              value={tenantDomainDisplayUrl}
              displayType={FormDisplayType.link}
              linkHref={tenantDomainUrl}
              linkTarget="_blank" />
          </Grid>
          <Grid item xs={12}>
            <FormDisplay
              label={t('company:companyTenant.favicon')}
              value={tenant?.favicon?.name} />
            <Button variant="contained" color="default"
              disabled={tenant === undefined || !hasTenantsEditRight}
              onClick={selectFaviconClicked}>
              {t('company:tenantDetails.tenantTab.buttonSelectFavicon')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormDisplay label={t('company:companyTenant.createDate')} value={moment(tenant?.createDate as Date).format('L LT')} />
          </Grid>
          <Grid item xs={12}>
            <FormDisplayMultiline label={t('company:companyTenant.description')} value={tenant?.description} rowsMax={6} />
          </Grid>
          <Grid item xs={12}>
            <FormDisplayCheckBox label={t('company:tenantDetails.tenantTab.tenantIsSignUpAllowedDescription')} checked={tenant?.isSignUpAllowed ?? false} />
          </Grid>
          <Grid item xs={12}>
            <FormDisplayCheckBox label={t('company:companyTenant.isActive')} checked={tenant?.isActive ?? false} />
          </Grid>
        </Grid>
      </Box>

      <SnackbarAlert open={showSuccessFaviconSaved} onClose={() => setShowSuccessFaviconSaved(false)}
        message={t('company:tenantDetails.tenantTab.successFaviconSaved')} severity="success" />

      <AlertDialog open={showDeleteDialog} onClose={handleDeleteDialog}
        title={t('company:tenantDetails.tenantTab.deleteDialog.title')}
        content={
          <Trans i18nKey="company:tenantDetails.tenantTab.deleteDialog.content">
            The brand <strong>{{ name: tenant?.name }}</strong> is then completely deleted and cannot be restored.
          </Trans>}
        dialogType="delete" />

      <FilesDialog
        open={showSelectFaviconDialog}
        onClose={handleSelectFaviconDialog}
        classes={classes}
        params={{ folderKey: folder_tenantowner_icons }} />
    </>
  );
}

export default TenantDetailsTenantView;