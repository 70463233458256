import BaseUserHasRightsView, { BaseUserHasRightsViewRow, BaseUserHasRightsViewGroupRight } from "company/users/models/BaseUserHasRightsView";
import ManageRight from "manage/users/models/ManageRight";
import ManageGroup from "manage/users/models/ManageGroup";

class ManageUserHasRightsView  extends BaseUserHasRightsView<ManageRight, ManageGroup, ManageUserHasRightsViewGroupRight, ManageUserHasRightsViewRow> {

  constructor(dataView?: any) {
    if (dataView) {
      super(dataView);
    } else {
      super();
    }
  }
}

class ManageUserHasRightsViewRow extends BaseUserHasRightsViewRow<ManageRight, ManageUserHasRightsViewGroupRight>{

  constructor(dataRow?: any) {
    if (dataRow) {
      super(dataRow);
    } else {
      super();
    }
  }
}

class ManageUserHasRightsViewGroupRight extends BaseUserHasRightsViewGroupRight {

  constructor(groupRight?: any) {
    if (groupRight) {
      super(groupRight);
    } else {
      super();
    }
  }
}

export default ManageUserHasRightsView;
export { ManageUserHasRightsViewRow, ManageUserHasRightsViewGroupRight };