import React, { useState } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { UserDetailsProps } from "company/users/views/UserDetails";
import CompanyUser from "company/users/models/CompanyUser";
import CompanyUsersService from "company/users/helpers/CompanyUsersService";
import EditActionButtonsGroup from "general/components/common/EditActionButtonsGroup";
import { regExpEmail } from "general/helpers/ui/ValidationHelper";
import BoxedAlert from "general/components/common/BoxedAlert";
import FormCheckBox from "general/components/common/FormCheckBox";
import GroupBox from "general/components/container/GroupBox";
import UserPaperTitle, { UserPaperTitleOption } from "company/users/components/UserPaperTitle";

interface UserEditProps extends UserDetailsProps {
  user: CompanyUser | undefined;
  usersService: CompanyUsersService;
  onSaved: (user: CompanyUser) => void;
  onCanceled: () => void;
}

function UserEdit(props: UserEditProps) {
  const { classes, user, usersService, onSaved, onCanceled } = props;
  const { t } = useTranslation(['company']);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { register, getValues, trigger, errors, control } =
    useForm({
      defaultValues: {
        email: user?.email,
        givenName: user?.givenName,
        familyName: user?.familyName,
        name: user?.name,
        phoneNumber: user?.phoneNumber,
        isRoleCompany: user?.isRoleCompany,
        isRoleCompanyEmployee: user?.isRoleCompanyEmployee,
        isRoleConsumer: user?.isRoleConsumer,
        isActive: user?.isActive,
        isEmailVerified: user?.isEmailVerified,
      }
    });

  async function saveAsync() {
    setErrorMessage('');

    if (!await trigger()) return;

    const data = getValues();
    const changedUser = new CompanyUser();
    changedUser.subjectId = user?.subjectId;
    changedUser.email = data.email;
    changedUser.givenName = data.givenName;
    changedUser.familyName = data.familyName;
    changedUser.name = data.name;
    changedUser.phoneNumber = data.phoneNumber;
    changedUser.isRoleCompanyEmployee = data.isRoleCompanyEmployee ?? false;
    changedUser.isRoleConsumer = data.isRoleConsumer ?? false;
    changedUser.isActive = data.isActive;
    changedUser.isEmailVerified = data.isEmailVerified;

    try {
      setSaveIsLoading(true);

      // Send changed data
      await usersService.updateUser(changedUser);

      // Go back to view mode
      onSaved(changedUser);
    } catch (error) {
      if ((error?.response?.status ?? 0) === 400) {
        setErrorMessage(t(`company:userEdit.error.${error?.response?.data?.code}`));
      } else {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      }
      setSaveIsLoading(false);
    };
  }

  const valRequired = t('validation:required');
  const valMaxLengthExceeded = t('validation:maxLengthExceeded');
  const valInvalidEmail = t('validation:invalidEmail');
  const valMaxLength80 = t('validation:maxLength', { maxLength: 80 });
  const valMaxLength100 = t('validation:maxLength', { maxLength: 100 });
  return (
    <>
      <Box className={classes.portalPaperTitleBox}>
        <UserPaperTitle option={UserPaperTitleOption.user} />
        <EditActionButtonsGroup
          editMode={true}
          onSaveButtonClick={saveAsync}
          onCancelButtonClick={onCanceled}
          disabled={saveIsLoading}
        />
      </Box>
      <Box className={classes.portalPaperContentBox}>
        <BoxedAlert message={errorMessage} severity="error" />
        <Grid container spacing={2} className={classes.defaultFormGrid}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyUser.email')}
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={register({
                required: valRequired,
                maxLength: { value: 320, message: valMaxLengthExceeded },
                pattern: { value: regExpEmail, message: valInvalidEmail }
              })}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyUser.givenName')}
              name="givenName"
              autoComplete="givenName"
              inputRef={register({ required: valRequired, maxLength: { value: 100, message: valMaxLength100 } })}
              error={!!errors.givenName}
              helperText={errors.givenName ? errors.givenName.message : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyUser.familyName')}
              name="familyName"
              autoComplete="familyName"
              inputRef={register({ required: valRequired, maxLength: { value: 100, message: valMaxLength100 } })}
              error={!!errors.familyName}
              helperText={errors.familyName ? errors.familyName.message : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyUser.name')}
              name="name"
              autoComplete="name"
              inputRef={register({ required: false, maxLength: { value: 100, message: valMaxLength100 } })}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyUser.phoneNumber')}
              name="phoneNumber"
              autoComplete="phoneNumber"
              inputRef={register({ required: false, maxLength: { value: 80, message: valMaxLength80 } })}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupBox label={t('company:companyUser.roles')}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <FormCheckBox label={t('idserver:roles.CompanyEmployee')} name="isRoleCompanyEmployee" control={control} />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckBox label={t('idserver:roles.Consumer')} name="isRoleConsumer" control={control} />
                </Grid>
              </Grid>
            </GroupBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormCheckBox label={t('company:companyUser.isActive')} name="isActive" control={control} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormCheckBox label={t('company:companyUser.isEmailVerified')} name="isEmailVerified" control={control} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default UserEdit;