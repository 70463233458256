import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { CustomCheckboxStyle, CustomTableRowCheckbox } from 'general/components/customTable/components/CustomTableRowCheckbox';
import { withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';

interface CustomTableEditRowCheckboxProps extends WithStyles<typeof AppStyle> {
  name: string;
  control: Control<any>;
  defaultValue?: unknown;
  isIndeterminateEnabled?: boolean;
  customStyle?: CustomCheckboxStyle;
  disabled?: boolean;
}

function CustomTableEditRowCheckbox(props: CustomTableEditRowCheckboxProps) {
  const { classes, name, control, defaultValue, isIndeterminateEnabled, customStyle, disabled } = props;
  const disabledValue = (disabled ?? false);

  // Checked state
  const [checked, setChecked] = useState<boolean>(
    control &&
      typeof defaultValue === 'boolean' ? defaultValue : false);

  // Indeterminate state
  const [indeterminate, setIndeterminate] = useState<boolean | undefined>(
    control && isIndeterminateEnabled &&
      (defaultValue === undefined || defaultValue === null) ? true : false);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange }) => (
        <CustomTableRowCheckbox
          className={(disabledValue) ? classes.displayCheckbox : undefined}
          color={(disabledValue) ? 'default' : undefined}
          customStyle={(disabledValue) ? undefined : customStyle}
          checked={checked}
          indeterminate={indeterminate}
          onChange={(e) => {
            if (disabledValue) return;

            if (isIndeterminateEnabled) {
              // Handle change steps:
              // true => null (indeterminate) => false => true
              if (checked) {
                setChecked(false)
                setIndeterminate(true)
                onChange(null);
              } else if (indeterminate) {
                setChecked(false)
                setIndeterminate(false)
                onChange(false);
              } else {
                setChecked(true)
                setIndeterminate(false)
                onChange(true);
              }
            } else {
              setChecked(!checked);
              onChange(!checked);
            }
          }}
        />
      )}
    />
  );
}

const CustomTableEditRowCheckboxStyled = withStyles(AppStyle)(CustomTableEditRowCheckbox);
export { CustomTableEditRowCheckboxStyled as CustomTableEditRowCheckbox };