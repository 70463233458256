import axios, { AxiosInstance } from 'axios';
import AccountProvider from 'account/AccountProvider';
import UrlHelper from 'general/helpers/UrlHelper';

export interface ApiConfigParams {
  useJson?: boolean,
  withCredentials?: boolean
}

class ApiHelper {
  private _api: AxiosInstance
  public get Api(): AxiosInstance {
    return this._api;
  }

  constructor(config?: ApiConfigParams) {
    this._api = axios.create({
      baseURL: UrlHelper.getApiUrl(),
      withCredentials: config?.withCredentials ?? false
    });

    if (config?.useJson ?? true) {
      this.useJson();
    }
  }

  public useJson(): AxiosInstance {
    this._api.defaults.headers.post['Content-Type'] = 'application/json';
    this._api.defaults.headers.put['Content-Type'] = 'application/json';
    this._api.defaults.headers.patch['Content-Type'] = 'application/json';
    return this._api;
  }

  public async useAuth(): Promise<AxiosInstance> {
    const access_token = await AccountProvider.getAccessTokenAsync();
    if (!access_token) {
      console.error('access_token is empty or expired');
      throw new Error('Unauthorized')
    }
    this._api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    return this._api;
  }
}

export default ApiHelper;