import React, { CSSProperties } from "react";
import { PropsWithChildren, ReactElement } from "react";
import { useStyles } from 'general/components/customDataGrid/styles/TableStyles'
import { AlignX, AlignY, useAlignX, useAlignY, useTextAlignX, useFlex, useWidth } from "general/components/customTable/utils";

export interface CustomTableHeadCellProps {
  alignX?: AlignX;
  alignY?: AlignY;
  width?: number;
}

export function CustomTableHeadCell(props: PropsWithChildren<CustomTableHeadCellProps>): ReactElement {
  const { children, alignX, alignY, width } = props;
  const classes = useStyles();

  const headCellStyle = {
    boxSizing: 'border-box',
    flex: useFlex(width),
    minWidth: '100px',
    width: useWidth(width),
    position: 'relative',
    justifyContent: useAlignX(alignX),
    alignItems: useAlignY(alignY),
    display: 'flex',
    paddingRight: '16px', // Needed for correct alignX
  } as CSSProperties

  const headLabelStyle = {
    textAlign: useTextAlignX(alignX),
    overflow: 'hidden',
    //whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  } as CSSProperties

  return (
    <div style={headCellStyle} className={classes.tableHeadCell}>
      <div style={headLabelStyle} className={classes.tableLabel}>
        {children}
      </div>
    </div>
  );
}