import ClientConfig from "./ClientConfig";

class Tenant {
  tenantId: string | undefined;
  name: string | undefined;
  isMainTenant: boolean | undefined;
  clientConfig: ClientConfig | undefined;
  theme: BaseTenantTheme | undefined;
  faviconFileKey: string | undefined; 

  constructor(dataTenant?: any) {
    if (dataTenant) {
      this.tenantId = dataTenant.tenantId;
      this.name = dataTenant.name;
      this.isMainTenant = dataTenant.isMainTenant;
      this.clientConfig = new ClientConfig(dataTenant.clientConfig);
      this.theme = new BaseTenantTheme(dataTenant.theme);
      this.faviconFileKey = dataTenant.faviconFileKey;
    }
  }
}

class BaseTenantTheme {
  themeId: string | undefined;
  name: string | undefined;
  primaryColor: string | undefined;
  secondaryColor: string | undefined;
  backgroundPaperColor: string | undefined;
  backgroundDefaultColor: string | undefined;
  portalBackgroundColor: string | undefined;
  errorColor: string | undefined;
  warningColor: string | undefined;
  infoColor: string | undefined;
  successColor: string | undefined;

  constructor(dataTheme?: any) {
    if (dataTheme) {
      this.themeId = dataTheme.themeId;
      this.name = dataTheme.name;
      this.primaryColor = dataTheme.primaryColor;
      this.secondaryColor = dataTheme.secondaryColor;
      this.backgroundPaperColor = dataTheme.backgroundPaperColor;
      this.backgroundDefaultColor = dataTheme.backgroundDefaultColor;
      this.portalBackgroundColor = dataTheme.portalBackgroundColor;
      this.errorColor = dataTheme.errorColor;
      this.warningColor = dataTheme.warningColor;
      this.infoColor = dataTheme.infoColor;
      this.successColor = dataTheme.successColor;
    }
  }
}

export default Tenant;
export { BaseTenantTheme }