import BaseService from "general/helpers/api/BaseService";
import { CompanyTenantEmailConfirmRequest } from "company/tenants/models/CompanyTenantEmailSetting";

class CompanyTenantEmailConfirmService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/company/tenantEmailConfirm`;

  public async confirmTenantEmail(emailConfirmRequest: CompanyTenantEmailConfirmRequest): Promise<void> {
    await (await this.useApi())
      .patch(this._serviceUrl,
        JSON.stringify(emailConfirmRequest));
  }
}

export default CompanyTenantEmailConfirmService;