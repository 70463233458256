import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AppStyle from 'general/styles/AppStyle';
import Copyright, { CopyrightColorOption } from 'general/components/app/Copyright';
import ScrollTopButton from 'general/components/app/ScrollTopButton';

export interface AppFooterProps extends WithStyles<typeof AppStyle> { }

function AppFooter(props: AppFooterProps) {
  const classes = props.classes;

  return (
    <footer className={classes.footer}>
      <Copyright colorOption={CopyrightColorOption.contrastPortalBackground} />
      <ScrollTopButton />
    </footer>
  );
}

export default withStyles(AppStyle)(AppFooter);