import React from "react";
import { useTranslation } from 'react-i18next';
import PortalPaper from "general/components/container/PortalPaper";
import { Container, Typography } from "@material-ui/core";


/* // Style Tests
import UrlHelper from 'general/helpers/UrlHelper';
import BoxedAlert from "general/components/common/BoxedAlert";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
*/

function Home() {
	const { t } = useTranslation();
	return (
		<PortalPaper>
			<Container>
				<Typography variant="h5">
					{t('general:home.paperTitle')}
				</Typography>
				
				<small>{t('general:home.InfoText')}</small><br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				
				
				{/* // Style Tests
				<br />
				<small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small><br />
				<small>BaseUrl: <b>{UrlHelper.getBaseUrl()}</b></small><br />
				<small>ApiUrl: <b>{UrlHelper.getApiUrl()}</b></small><br />
				<br />
				<br />
				<BoxedAlert message="Error standard" severity="error" variant="standard" />
				<BoxedAlert message="Error filled" severity="error" variant="filled" />
				<BoxedAlert message="Error outlined" severity="error" variant="outlined" />
				<br />
				<BoxedAlert message="Warning standard" severity="warning" variant="standard" />
				<BoxedAlert message="Warning filled" severity="warning" variant="filled" />
				<BoxedAlert message="Warning outlined" severity="warning" variant="outlined" />
				<br />
				<BoxedAlert message="Info standard" severity="info" variant="standard" />
				<BoxedAlert message="Info filled" severity="info" variant="filled" />
				<BoxedAlert message="Info outlined" severity="info" variant="outlined" />
				<br />
				<BoxedAlert message="Success standard" severity="success" variant="standard" />
				<BoxedAlert message="Success filled" severity="success" variant="filled" />
				<BoxedAlert message="Success outlined" severity="success" variant="outlined" />
				<br />

			<br />
			<br />
			<Fab color="primary" aria-label="add">
				<AddIcon />
			</Fab>
			<span> </span>
			<Fab color="secondary" aria-label="edit">
				<EditIcon />
			</Fab>

			<br />
			<br />
			<AppBar position="static">
				<Tabs value={0} aria-label="simple tabs example">
					<Tab label="Item One" />
					<Tab label="Item Two" />
					<Tab label="Item Three" />
				</Tabs>
			</AppBar>
			*/}
			</Container>
		</PortalPaper>
	);
}

export default Home;