import React, { useState } from 'react';
import { Grid, Box, TextField } from '@material-ui/core';
import BoxedAlert from 'general/components/common/BoxedAlert';
import EditActionButtonsGroup from 'general/components/common/EditActionButtonsGroup';
import TenantPaperTitle, { TenantPaperTitleOption } from 'company/tenants/components/TenantPaperTitle';
import CompanyTenant from '../models/CompanyTenant';
import CompanyTenantsService from '../helpers/CompanyTenantsService';
import { TenantDetailsProps } from './TenantDetails';
import { useForm } from 'react-hook-form';
import FormCheckBox from 'general/components/common/FormCheckBox';
import { regExpSubDomain } from 'general/helpers/ui/ValidationHelper';
import { useTranslation } from 'react-i18next';

export interface TenantDetailsTenantEditProps extends TenantDetailsProps {
  tenant: CompanyTenant | undefined;
  tenantsService: CompanyTenantsService;
  onSaved: () => void;
  onCanceled: () => void;
}

function TenantDetailsTenantEdit(props: TenantDetailsTenantEditProps) {
  const { classes, tenant, tenantsService, onSaved, onCanceled } = props;
  const { t } = useTranslation(['company']);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { register, getValues, trigger, errors, control } =
    useForm({
      defaultValues: {
        name: tenant?.name,
        description: tenant?.description,
        subDomain: tenant?.subDomain,
        //domain: tenant?.domain,
        isSignUpAllowed: tenant?.isSignUpAllowed,
        isActive: tenant?.isActive,
      }
    });

  async function saveAsync() {
    setErrorMessage('');

    if (!await trigger()) return;

    const data = getValues();
    const changedTenant = new CompanyTenant();
    changedTenant.tenantId = tenant?.tenantId;
    changedTenant.name = data.name;
    changedTenant.description = data.description;
    changedTenant.subDomain = data.subDomain;
    //changedTenant.domain = data.domain;
    changedTenant.isSignUpAllowed = data.isSignUpAllowed;
    changedTenant.isActive = data.isActive;

    try {
      setSaveIsLoading(true);

      // Send changed data
      await tenantsService.updateTenant(changedTenant);

      // Go back to view mode
      onSaved();

    } catch (error) {
      if ((error?.response?.status ?? 0) === 400) {
        setErrorMessage(t(`company:tenantDetails.tenantTab.errorEdit.${error?.response?.data?.code}`));
      } else {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      }
      setSaveIsLoading(false);
    };
  }

  const valRequired = t('validation:required');
  const valInvalidSubDomain = t('validation:invalidSubDomain');
  const valMinLength3 = t('validation:minLength', { minLength: 3 });
  const valMaxLength60 = t('validation:maxLength', { maxLength: 60 });
  const valMaxLength100 = t('validation:maxLength', { maxLength: 100 });
  //const valMaxLength255 = t('validation:maxLength', { maxLength: 255 });
  const valMaxLength300 = t('validation:maxLength', { maxLength: 300 });
  return (
    <>
      <Box className={classes.portalPaperTitleBox}>
        <TenantPaperTitle option={TenantPaperTitleOption.basicData} />
        <EditActionButtonsGroup
          editMode={true}
          onSaveButtonClick={saveAsync}
          onCancelButtonClick={onCanceled}
          disabled={saveIsLoading}
        />
      </Box>
      <Box className={classes.portalPaperContentBox}>
        <BoxedAlert message={errorMessage} severity="error" />
        <Grid container spacing={2} className={classes.defaultFormGrid}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyTenant.name')}
              name="name"
              autoComplete="name"
              inputRef={register({ required: valRequired, maxLength: { value: 100, message: valMaxLength100 } })}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyTenant.subDomain')}
              name="subDomain"
              autoComplete="subDomain"
              inputRef={register({
                required: valRequired,
                minLength: { value: 3, message: valMinLength3 },
                maxLength: { value: 60, message: valMaxLength60 },
                pattern: { value: regExpSubDomain, message: valInvalidSubDomain }
              })}
              error={!!errors.subDomain}
              helperText={errors.subDomain ? errors.subDomain.message : ''}
            />
          </Grid>
          {/* 
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyTenant.domain')}
              name="domain"
              autoComplete="domain"
              inputRef={register({ maxLength: { value: 255, message: valMaxLength255 } })}
              error={!!errors.domain}
              helperText={errors.domain ? errors.domain.message : ''}
            />
          </Grid>
          */}
          <Grid item xs={12}>
            <TextField
              multiline
              rows={3}
              rowsMax={6}
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyTenant.description')}
              name="description"
              autoComplete="description"
              inputRef={register({ required: valRequired, maxLength: { value: 300, message: valMaxLength300 } })}
              error={!!errors.description}
              helperText={errors.description ? errors.description.message : ''}
            />
          </Grid>
          <Grid item xs={12}>
              <FormCheckBox label={t('company:tenantDetails.tenantTab.tenantIsSignUpAllowedDescription')} name="isSignUpAllowed" control={control} />
            </Grid>
          {(tenant?.isMainTenant ?? false) === false ?
            <Grid item xs={12}>
              <FormCheckBox label={t('company:companyTenant.isActive')} name="isActive" control={control} />
            </Grid> : null}
        </Grid>
      </Box>
    </>
  );
}

export default TenantDetailsTenantEdit;