class StringHelper {
    public static empty(value: string | undefined): boolean {
        if (value && value.length > 0) {
            return false;
        }
        return true;
    }

    public static left(value: string, length: number): string {
        if (value && value.length > length) {
            value = value.substring(0, length);
        }
        return value;
    }

    public static right(value: string, length: number): string {
        if (value && value.length > length) {
            value = value.substring(value.length - length, value.length);
        }
        return value;
    }
}

export default StringHelper;