import React, { ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";

interface GroupBoxProps {
  label: string | undefined;
  children: ReactNode;
}

function GroupBox(props: GroupBoxProps) {
  const { label, children } = props;
  return (
    <Box border={1} borderColor="grey.400" borderRadius="borderRadius" p={1}>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <Box px={2}>
        {children}
      </Box>
    </Box>
  );
}

export default GroupBox;