class BaseRight {
  rightId: string | undefined;
  rightKeyName: string | undefined;
  rightKeyOrder: number | undefined;
  name: string | undefined;
  description: string | undefined;

  constructor(dataRight?: any) {
    if (dataRight) {
      this.rightId = dataRight.rightId;
      this.rightKeyName = dataRight.rightKeyName;
      this.rightKeyOrder = dataRight.rightKeyOrder;
      this.name = dataRight.name;
      this.description = dataRight.description;
    }
  }
}

class BaseUserHasRight {
  subjectId: string | undefined;
  right: BaseRight | undefined;
  isAllowed: boolean | null | undefined;

  constructor(dataUserRight?: any) {
    if (dataUserRight) {
      this.subjectId = dataUserRight.subjectId;
      this.right = new BaseRight(dataUserRight.right);
      this.isAllowed = dataUserRight.isAllowed;
    }
  }
}

class BaseGroupHasRight {
  groupId: string | undefined;
  right: BaseRight | undefined;
  isAllowed: boolean | null | undefined;

  constructor(dataGroupRight?: any) {
    if (dataGroupRight) {
      this.groupId = dataGroupRight.groupId;
      this.right = new BaseRight(dataGroupRight.right);
      this.isAllowed = dataGroupRight.isAllowed;
    }
  }
}

export default BaseRight;
export { BaseUserHasRight, BaseGroupHasRight };