import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { createStyles, makeStyles, TextField } from '@material-ui/core';
import { ColorPicker, Color, createColor } from 'material-ui-color';

interface FormColorPickerProps {
  label: string;
  name: string;
  control: Control<any>;
  onColorChange?: (color: Color) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    formColorPickerTextField: {
      width: '150px',
    },
    formColorPickerButton: {
      display: 'inline-flex',
      marginTop: '10px',
      marginLeft: '5x',
    }
  }),
);

function FormColorPicker(props: FormColorPickerProps) {
  const { label, name, control, onColorChange } = props;
  const classesElement = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={(props: any) => (
        <>
          <TextField
            variant="outlined"
            margin="none"
            fullWidth
            label={label}
            value={props.value}
            onChange={(e) => {
              const color = createColor(e.target.value);
              props.onChange(`#${color?.hex}`);
              if (onColorChange) onColorChange(color);
            }}
            className={classesElement.formColorPickerTextField}
          />
          <div className={classesElement.formColorPickerButton}>
            <ColorPicker
              value={createColor(props.value)}
              onChange={(color: Color) => {
                props.onChange(`#${color?.hex}`);
                if (onColorChange) onColorChange(color);
              }}
              hideTextfield={true}
              disableAlpha={true}
            />
          </div>
        </>
      )}
    />
  );
}

export default FormColorPicker;