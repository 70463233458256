import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import StringHelper from 'general/helpers/types/StringHelper';
import { makeStyles } from '@material-ui/core';
import { drawerItemHoverFocus } from 'general/styles/AppNavDrawerStyle';

const useStylesElement = makeStyles({
	buttonLanguageMenu: {
		'&:hover,&:focus': drawerItemHoverFocus,
		paddingTop: '8px',
		paddingBottom: '8px',
	},
});

export interface LanguageMenuProps {
	useDrawerStyle?: boolean;
}

function LanguageMenu(props: LanguageMenuProps) {
	const classesElement = useStylesElement();
	const { i18n } = useTranslation();
	const currentLang = StringHelper.left(i18n.language, 2).toLowerCase();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	function handleMenu(event: React.MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	function changeLanguage(lang: string) {
		i18n.changeLanguage(lang);
		handleClose();
	}

	return (
		<div>
			<Button
				color="inherit"
				className={clsx({
					[classesElement.buttonLanguageMenu]: (props.useDrawerStyle ?? false),
				})}
				onClick={handleMenu}
				endIcon={<ArrowDropDownIcon />}>
				{currentLang === 'de' ? 'DE' : 'EN'}
			</Button>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={() => changeLanguage('de')}>DE - Deutsch</MenuItem>
				<MenuItem onClick={() => changeLanguage('en')}>EN - English</MenuItem>
			</Menu>
		</div>
	);
}

export default LanguageMenu;