import { Button, Popover, Typography, createStyles, makeStyles, Box } from '@material-ui/core'
import { TFunction } from 'i18next'
import React, { FormEvent, ReactElement, useCallback } from 'react'
import { TableInstance } from 'react-table'

const useStyles = makeStyles(
  createStyles({
    columnsPopOver: {
      padding: 24,
    },
    /*
    filtersResetButton: {
      position: 'absolute',
      top: 18,
      right: 21,
    },
    */
    popoverTitle: {
      fontWeight: 500,
      padding: '0 24px 24px 0',
      //textTransform: 'uppercase',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 218px)',
      '@media (max-width: 600px)': {
        gridTemplateColumns: 'repeat(1, 180px)',
      },
      gridColumnGap: 12,
      gridRowGap: 12,
    },
    cell: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    /*
    hidden: {
      display: 'none',
    },
    */
  })
)

type FilterPage<T extends object> = {
  instance: TableInstance<T>;
  anchorEl?: Element;
  onClose: () => void;
  show: boolean;
  translation?: TFunction;
}

export function FilterPage<T extends object>(
  {
    instance,
    anchorEl,
    onClose,
    show,
    translation,
  }: FilterPage<T>): ReactElement {
  const classes = useStyles({})
  const t = translation;
  const { allColumns, setAllFilters } = instance

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onClose()
    },
    [onClose]
  )

  const resetFilters = useCallback(() => {
    setAllFilters([])
  }, [setAllFilters])

  return (
    <div>
      <Popover
        anchorEl={anchorEl}
        id={'popover-filters'}
        onClose={onClose}
        open={show}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.columnsPopOver}>
          <Typography className={classes.popoverTitle}>
            {t ? t('common:customDataGrid.filterPage.title') : 'Filters'}
          </Typography>
          <form onSubmit={onSubmit}>
            <div className={classes.grid}>
              {allColumns
                .filter((it) => it.canFilter)
                .map((column) => (
                  <div key={column.id} className={classes.cell}>
                    {column.render('Filter')}
                  </div>
                ))}
            </div>
            <Box mt={2} display='flex' justifyContent='flex-end'>
              <Button 
                variant="contained" onClick={resetFilters}>
                {t ? t('common:customDataGrid.filterPage.buttonReset') : 'Reset'}
              </Button>
              <Box ml={1}>
                <Button type={'submit'} variant="contained" color='primary'>
                  {t ? t('common:customDataGrid.filterPage.buttonFilter') : 'Filter'}
                </Button>
              </Box>
            </Box>
          </form>
        </div>
      </Popover>
    </div>
  )
}
