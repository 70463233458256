import BaseService from "general/helpers/api/BaseService";
import ManageGroup from "manage/users/models/ManageGroup";

class ManageUserGroupsService extends BaseService {
  private _subjectId: string | undefined;
  private _serviceUrl = `${this._baseUrlApiPortal}/manage/users`;

  constructor(subjectId: string) {
    super();
    this._subjectId = subjectId;
    this._serviceUrl = `${this._serviceUrl}/${subjectId}/groups`;
  }

  get subjectId(): string | undefined {
    return this._subjectId;
  }

  public async getGroups(inGroup: boolean): Promise<Array<ManageGroup>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}?inGroup=${inGroup ? 'true' : 'false'}`);
    return resp.data.map((data: any) => {
      return new ManageGroup(data);
    });
  }

  public async addGroups(groupIds: Array<string>): Promise<void> {
    await (await this.useApiWithAuth())
      .post(this._serviceUrl, JSON.stringify(groupIds));
  }

  public async removeGroups(groupIds: Array<string>): Promise<void> {
    await (await this.useApiWithAuth())
      .put(this._serviceUrl, JSON.stringify(groupIds));
  }
}

export default ManageUserGroupsService;