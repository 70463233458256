import React, { useEffect } from 'react';
import AccountProvider from 'account/AccountProvider';
import Loading from 'general/views/Loading';

function OidcLogin() {
	useEffect(() => {
		AccountProvider.loginAsync();
	}, []);

	return (
		<Loading />
	);
}

export default OidcLogin;