import React from 'react';
import { Typography, Box, Link, makeStyles, createStyles, Theme } from '@material-ui/core';
import StringHelper from 'general/helpers/types/StringHelper';
import { useTheme } from '@material-ui/core/styles';
import { ColorButton } from 'material-ui-color';

// TypeScript: Add exisiting missing option for ColorButton
declare module 'material-ui-color' {
  interface ColorButtonProps {
    disabled?: boolean;
  }
}

export enum FormDisplayType {
  text = 0,
  link = 1,
  color = 2,
  colorAndText = 3
}

interface FormDisplayProps {
  label: React.ReactNode;
  value?: React.ReactNode;
  children?: React.ReactNode;
  emtypValuePlaceholder?: string;
  displayType?: FormDisplayType;
  linkHref?: string;
  linkTarget?: string;
  labelColor?:
  | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error';
  valueColor?:
  | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    displayColorText: {
      marginLeft: theme.spacing(1),
    },
  }),
);

function FormDisplay(props: FormDisplayProps) {
  const displayValue = props.children ? props.children : (props.value ? props.value :
    (props.emtypValuePlaceholder ? props.emtypValuePlaceholder : '-'));
  const labelColor = props.labelColor ?? "textSecondary";
  const valueColor = props.valueColor ?? undefined;
  const displayType = props.displayType ?? FormDisplayType.text;
  const theme = useTheme();
  const classesElement = useStyles();

  function DisplayText() {
    return (
      <Typography display="initial" variant="body1" color={valueColor}>
        {displayValue}
      </Typography>
    );
  }

  function DisplayLink() {
    return (
      <Link href={props.linkHref ?? ''} target={props.linkTarget} variant="body1" color={valueColor}>
        {displayValue}
      </Link>
    );
  }

  function DisplayColor() {
    return (
      <>
        <ColorButton color={String(displayValue)} disabled={true} />
        {displayType === FormDisplayType.colorAndText ?
          <Typography display="inline" variant="body1" color={valueColor} className={classesElement.displayColorText}>
            {displayValue}
          </Typography> : null}
      </>
    );
  }

  return (
    <>
      <Typography variant="caption" display="block" color={labelColor}>
        {props.label}
      </Typography>
      <Box minHeight={theme.spacing(3)}>
        {displayType === FormDisplayType.link &&
          !StringHelper.empty(props.linkHref) ?
          <DisplayLink /> :
          (displayType === FormDisplayType.color || displayType === FormDisplayType.colorAndText ?
            <DisplayColor /> :
            <DisplayText />)
        }
      </Box>
    </>
  );
}

export default FormDisplay;