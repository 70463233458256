import React from 'react';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';

interface IconActionButtonProps extends WithStyles<typeof AppStyle> {
  label: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  type?: "button" | "reset" | "submit" | undefined;
  component?: any;
  disabled?: boolean;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}

function IconActionButton(props: IconActionButtonProps) {
  const { classes, label, icon, children, type, component, onClick, disabled } = props;

  return (
    <Tooltip title={label} aria-label={label}>
      <span>
        <IconButton
          className={classes.iconActionButton}
          type={type}
          onClick={onClick}
          disabled={disabled}
          component={component}
        >
           {children ? children : icon}
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default withStyles(AppStyle)(IconActionButton);