import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Routes from 'general/helpers/Routes';
import { Container, Typography, Button, Box } from '@material-ui/core';
import DefaultPaper from 'general/components/container/DefaultPaper';

function OidcLoggedOut() {
	const { t } = useTranslation(['account']);

	return (
		<Container component="main" maxWidth="xs">
			<DefaultPaper>
				<Typography component="h1" variant="h5">
					{t('app:nav.account.loggedOut')}
				</Typography>
				<Box margin={2}>
					<Button variant="contained" color="default"
						component={Link} to={Routes.Account.Login}>
						{t('account:loggedOut.linkSignInAgain')}
					</Button>
				</Box>
			</DefaultPaper>
		</Container>
	);
}

export default OidcLoggedOut;