import React, { createContext, Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Tenant from 'idserver/models/Tenant';
import TenantsService from 'idserver/helpers/TenantsService';
import CircularProgressBox from 'general/components/common/CircularProgressBox';
import BoxedAlert from 'general/components/common/BoxedAlert';
import StringHelper from 'general/helpers/types/StringHelper';
import { Container } from '@material-ui/core';
import { DefaultContainer } from 'general/components/app/AppThemeContainers';
import { Helmet } from 'react-helmet';

export type TenantContextType = {
	isInitialized: boolean;
	tenant: Tenant;
	tenantTheme: TenantTheme;
	setTenantTheme: (tenantTheme: TenantTheme) => void;
	resetTenantTheme: () => void;
}

export type TenantTheme = {
	primary?: string;
	secondary?: string;
	background?: {
		paper: string;
		default: string;
	},
	portalBackground?: string;
	error?: string;
	warning?: string;
	info?: string;
	success?: string;
}

const defaultTenantTheme = {
	primary: '#009be5',
	secondary: '#18202c',
	background: {
		paper: '#fff',
		default: '#fff',
	},
	portalBackground: '#eaeff1',
	error: '#d32f2f',
	warning: '#ed6c02',
	info: '#0288d1',
	success: '#2e7d32',
} as TenantTheme;

const TenantContext = createContext({
	isInitialized: false,
	tenant: new Tenant(),
	tenantTheme: defaultTenantTheme,
	setTenantTheme: (tenantTheme: TenantTheme) => { },
	resetTenantTheme: () => { },
} as TenantContextType);

class TenantContextProviderIntern extends Component<WithTranslation> {
	state = {
		isInitialized: false,
		tenant: new Tenant(),
		tenantTheme: defaultTenantTheme,
		backupTenantTheme: defaultTenantTheme,
		tenantNotExists: false,
		faviconUrl: '',
		errorMessage: '',
		isLoading: true
	}

	async componentDidMount() {
		console.log('Load tenant');

		let { tenant, tenantNotExists, faviconUrl, errorMessage, isLoading } = this.state;
		tenantNotExists = false;
		faviconUrl = '';
		errorMessage = '';
		isLoading = true;

		try {
			const tenantsService = new TenantsService();
			tenant = await tenantsService.getTenant();
			faviconUrl = tenantsService.getFaviconUrl(tenant.faviconFileKey);
		} catch (error) {
			if ((error?.response?.status ?? 0) === 404) {
				// Requested brand not exists
				tenantNotExists = true;
			} else {
				console.error(error);
				errorMessage = error?.message ?? '';
			}
		} finally {
			isLoading = false;
		}

		if (!tenant.tenantId) {
			// No tenant found => set default brand
			tenant.name = process.env.REACT_APP_BRAND;
		}

		const theme = {
			primary: tenant?.theme?.primaryColor,
			secondary: tenant?.theme?.secondaryColor,
			background: {
				paper: tenant?.theme?.backgroundPaperColor,
				default: tenant?.theme?.backgroundDefaultColor,
			},
			portalBackground: tenant?.theme?.portalBackgroundColor,
			error: tenant?.theme?.errorColor,
			warning: tenant?.theme?.warningColor,
			info: tenant?.theme?.infoColor,
			success: tenant?.theme?.successColor,
		} as TenantTheme;

		this.setState({
			isInitialized: true,
			tenant: tenant,
			tenantTheme: theme,
			backupTenantTheme: theme,
			tenantNotExists: tenantNotExists,
			faviconUrl: faviconUrl,
			errorMessage: errorMessage,
			isLoading: isLoading
		});
	}

	setTenantTheme = (tenantTheme: TenantTheme) => {
		const { backupTenantTheme } = this.state;
		tenantTheme.background = tenantTheme.background ?? backupTenantTheme.background;
		tenantTheme.error = tenantTheme.error ?? backupTenantTheme.error;
		tenantTheme.warning = tenantTheme.warning ?? backupTenantTheme.warning;
		tenantTheme.info = tenantTheme.info ?? backupTenantTheme.info;
		tenantTheme.success = tenantTheme.success ?? backupTenantTheme.success;
		this.setState({ tenantTheme: tenantTheme });
	}

	resetTenantTheme = () => {
		const { backupTenantTheme } = this.state;
		this.setState({ tenantTheme: backupTenantTheme });
	}

	render() {
		const { tenantNotExists, errorMessage, isLoading } = this.state;
		const { t } = this.props;

		const MessageContainer = () => {
			return (
				<DefaultContainer>
					<Container maxWidth="md">
						{tenantNotExists ?
							<BoxedAlert message={t('idserver:tenantOrigin.notExistsMessage')} severity="warning" /> :
							<BoxedAlert message={errorMessage} severity="error" />}
					</Container>
				</DefaultContainer>
			);
		};

		return (
			<TenantContext.Provider
				value={{
					...this.state,
					setTenantTheme: this.setTenantTheme,
					resetTenantTheme: this.resetTenantTheme,
				}}
			>
				{isLoading ?
					<CircularProgressBox py={5} /> :
					tenantNotExists || !StringHelper.empty(errorMessage) ?
						<MessageContainer /> :
						<>
							<Helmet 
								defaultTitle={this.state.tenant?.name}
								titleTemplate={`%s - ${this.state.tenant?.name}`}>
									<link rel="icon" href={this.state.faviconUrl} />
							</Helmet>
							{this.props.children}
						</>}
			</TenantContext.Provider>
		);
	}
}

const TenantContextProvider = withTranslation()(TenantContextProviderIntern);

export default TenantContext;
export { TenantContextProvider };