import BaseGroup from "company/users/models/BaseGroup";
import BaseRight from "company/users/models/BaseRight";

class BaseUserHasRightsView<R extends BaseRight, G extends BaseGroup, GR extends BaseUserHasRightsViewGroupRight, RR extends BaseUserHasRightsViewRow<R, GR>> {
  subjectId: string | undefined;
  groups: Array<G> | undefined;
  rights: Array<RR> | undefined;

  constructor(dataView?: any) {
    if (dataView) {
      this.subjectId = dataView.subjectId;
      this.groups = dataView.groups?.map((dataGroup: any) => {
        return new BaseGroup(dataGroup);
      });
      this.rights = dataView.rights?.map((dataRow: any) => {
        return new BaseUserHasRightsViewRow(dataRow);
      });
    }
  }
}

class BaseUserHasRightsViewRow<R extends BaseRight, GR extends BaseUserHasRightsViewGroupRight> {
  right: R | undefined;
  isAllowed: boolean | null | undefined;
  userIsAllowed: boolean | null | undefined;
  groupsIsAllowed: boolean | null | undefined;
  groupRights: Array<GR> | undefined;

  constructor(dataRow?: any) {
    if (dataRow) {
      this.right = new BaseRight(dataRow.right) as R;
      this.isAllowed = dataRow.isAllowed;
      this.userIsAllowed = dataRow.userIsAllowed;
      this.groupsIsAllowed = dataRow.groupsIsAllowed;
      this.groupRights = dataRow.groupRights?.map((groupRight: any) => {
        return new BaseUserHasRightsViewGroupRight(groupRight);
      });
    }
  }

  getGroupRight(groupId: string): GR {
    // Search in groupRights
    let foundGroupRight: GR | undefined = undefined;
    this.groupRights?.forEach((gr: GR) => {
      if (gr.groupId === groupId) {
        foundGroupRight = gr;
        return;
      }
    });

    // Return if found
    if (foundGroupRight !== undefined) {
      return foundGroupRight;
    }

    // Return default with no specific value
    const notDefined = new BaseUserHasRightsViewGroupRight() as GR;
    notDefined.groupId = groupId;
    notDefined.isAllowed = null;
    return notDefined;
  }
}

class BaseUserHasRightsViewGroupRight {
  groupId: string | undefined;
  isAllowed: boolean | null | undefined;

  constructor(groupRight?: any) {
    if (groupRight) {
      this.groupId = groupRight.groupId;
      this.isAllowed = groupRight.isAllowed;
    }
  }
}

export default BaseUserHasRightsView;
export { BaseUserHasRightsViewRow, BaseUserHasRightsViewGroupRight };