import * as H from 'history';
import BaseService from "general/helpers/api/BaseService";
import { ApiConfigParams } from "general/helpers/api/ApiHelper";
import AccountProvider, { manageUserBackupKey } from "account/AccountProvider";
import Routes from "general/helpers/Routes";

export enum AuthenticateManageServiceOption {
	manage,
	company,
}

class AuthenticateManageService extends BaseService {
  private _serviceUrl: string;

  public constructor(serviceOption: AuthenticateManageServiceOption) {
    super({ withCredentials: true } as ApiConfigParams, '/apiidserver');

    if (serviceOption === AuthenticateManageServiceOption.manage) {
      this._serviceUrl = `${this._baseUrlApiPortal}/authenticateManage`;
    } else {
      this._serviceUrl = `${this._baseUrlApiPortal}/authenticateCompany`;
    }
  }

  public async loginAs(subjectId: string, backupCurrentUser: boolean, routeHistory: H.History<unknown>): Promise<void> {
    // Call IdServerApi
    await (await this.useApiWithAuth())
      .post(`${this._serviceUrl}/loginAs/${subjectId}`);

    // => Response automatically sets cookie in browser for IdServer

    // Backup current login data for re-login
    if (backupCurrentUser) await AccountProvider.backupCurrentUserAsync(manageUserBackupKey);

    // Delete current login data
    await AccountProvider.removeCurrentUserAsync();

    // Redirect to login => Getting new login data automatically
    routeHistory.push(Routes.Account.Login);
  }
}

export default AuthenticateManageService;