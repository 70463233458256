import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, Paper, withStyles, WithStyles } from '@material-ui/core';
import moment from 'moment';
import AppStyle from 'general/styles/AppStyle';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import SearchBar from 'general/components/common/SearchBar';
import { Alert } from '@material-ui/lab';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import CompanyGroup from 'company/users/models/CompanyGroup';
import CompanyGroupsService from 'company/users/helpers/CompanyGroupsService';
import { DateColumnFilter } from 'general/components/customDataGrid/filters';
import { useScroll } from 'general/helpers/ui/ScrollHelper';
import CompanyUsersState from 'company/users/models/CompanyUsersState';
import RouteContext from 'general/RouteContext';
import { Row, TableInstance } from 'react-table';
import Routes from 'general/helpers/Routes';
import { CompanyUsersTabs } from 'company/users/views/CompanyUsers';
import BaseState from 'general/models/BaseState';
import AccountContext from 'account/AccountContext';
import TenantContext, { TenantContextType } from 'idserver/TenantContext';
import { useRight, right_Company_Users_Groups_Edit } from 'account/models/Rights';
import StringHelper from 'general/helpers/types/StringHelper';

export type SuccessGroupDeletedAlert = {
	visible: boolean;
	groupName?: string;
}

export type GroupsRouteState = {
	successGroupDeletedAlert?: SuccessGroupDeletedAlert;
}

interface GroupsProps extends WithStyles<typeof AppStyle> { }

function Groups(props: GroupsProps) {
	const classes = props.classes;
	const { t, i18n } = useTranslation(['company']);
	const routeHistory = useHistory();
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
	const [groupRows, setGroupRows] = useState(new Array<CompanyGroup>());
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [successGroupDeletedAlert, setSuccessGroupDeletedAlert] = useState<SuccessGroupDeletedAlert | undefined>(undefined);
	const [searchText, setSearchText] = useState<string | undefined>();
	const [tableLoadPageIndex, setTableLoadPageIndex] = useState<boolean | undefined>();
	const [setScrollToY, raiseScrollToY] = useScroll();
	const groupsService = useRef(new CompanyGroupsService());
	const accountContext = useContext(AccountContext);
	const tenantContext = useContext(TenantContext);
	const hasGroupsEditRight = useRight(right_Company_Users_Groups_Edit, accountContext);

	useEffect(() => {
		console.log('Init load groups');
		const savedState = routeHistory.location?.state as CompanyUsersState
		if (savedState?.groups) {
			// Backup state
			const { scrollY, searchText } = savedState.groups;

			// Clean up state
			savedState.groups = undefined;
			routeHistory.replace(routeHistory.location.pathname, savedState);

			// Set saved state
			setScrollToY(scrollY);
			setTableLoadPageIndex(true);
			setSearchText(searchText);

			// Read RouteContext state (Custom state that is set by goBack navigation and can't handle by routeHistory)
			const contextState = initRouteContext.current.routeStates[Routes.Portal.Company.Groups] as GroupsRouteState;
			if (contextState) {
				// Set saved RouteContext state
				setSuccessGroupDeletedAlert(contextState?.successGroupDeletedAlert);
			}

		} else {
			// Load groups
			const lang = i18n.language;
			getGroupsAsync(searchText, lang, tenantContext, raiseScrollToY);
		}

		// Clean up RouteContext state
		initRouteContext.current.clearRouteState(Routes.Portal.Company.Groups);

	}, [searchText, i18n.language, tenantContext, routeHistory, initRouteContext, setScrollToY, raiseScrollToY]);

	async function getGroupsAsync(paramSearchText: string | undefined,
		lang: string, tenantContext: TenantContextType, raiseScrollToY: () => void) {
		setErrorMessage('');
		setIsLoading(true);
		try {
			let groups: CompanyGroup[];
			if (tenantContext.tenant.isMainTenant) {
				groups = await groupsService.current.getGroups(paramSearchText ?? '', lang);
			} else {
				groups = await groupsService.current.getTenantGroups(tenantContext.tenant.tenantId ?? '', paramSearchText ?? '', lang);
			}
			setGroupRows(groups);
		} catch (error) {
			console.error(error);
			setErrorMessage(error?.message ?? '');
		} finally {
			// Reset
			setIsLoading(false);
			setTableLoadPageIndex(undefined);
			raiseScrollToY();
		}
	}

	function searchTextChanged(value: string) {
		setSearchText(value);
	}

	function saveCurrentRouteState() {
		const currentState = new CompanyUsersState();
		currentState.selectedTab = CompanyUsersTabs.groups;
		currentState.groups = new BaseState();
		currentState.groups.scrollY = window.scrollY;
		currentState.groups.searchText = searchText ?? '';
		routeHistory.replace(routeHistory.location.pathname, currentState);
	}

	function rowClicked(row: Row<CompanyGroup>) {
		const selectedGroupId = row?.original?.groupId ?? '';
		if (!StringHelper.empty(selectedGroupId)) {
			// Save current state before redirect
			saveCurrentRouteState();

			// Redirect
			routeHistory.push(`${Routes.Portal.Company.GroupDetails}/${selectedGroupId}`);
		}
	}

	const tableOnAdd = (instance: TableInstance<CompanyGroup> | undefined) => () => {
		// Save current state before redirect
		saveCurrentRouteState();

		// Redirect
		routeHistory.push(Routes.Portal.Company.GroupCreate);
	};

	const groupColumns = useMemo(() => {
		console.log('Init group columns');
		return [
			{
				Header: 'companyGroups',
				HideHeader: true,
				columns: [
					{
						Header: t('company:companyGroup:groupOrder'),
						accessor: 'groupOrder',
						width: 120,
					},
					{
						Header: t('company:companyGroup:groupName'),
						accessor: 'groupName',
						width: 300,
					},
					{
						Header: t('company:companyGroup:createDate'),
						accessor: 'createDate',
						width: 180,
						Cell: ({ value }: any) =>
						(
							<>{moment(value as Date).format('L LT')}</>
						),
						Filter: DateColumnFilter,
					},
					{
						Header: t('company:companyGroup:tenants'),
						accessor: 'tenants',
						width: 150,
					},
				]
			},
		];
	}, [t]);

	return (
		<div>
			<Grid container>
				{errorMessage !== '' ?
					<Grid item xs={12}>
						<Box paddingBottom={1}>
							<Alert severity="error" variant="filled">
								{errorMessage}
							</Alert>
						</Box>
					</Grid> : null}

				<Grid item xs={12} className={classes.defaultSearchBarGridItem}>
					<Box marginY={1}>
						<SearchBar
							placeholder={t('company:groups.searchBar.placeholder')}
							onSearchTextChanged={searchTextChanged}
							value={searchText}
						/>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Paper>
						<CustomDataGrid<CompanyGroup>
							name="companyGroups"
							isLoading={isLoading}
							columns={groupColumns}
							data={groupRows}
							loadLastPageIndex={tableLoadPageIndex}
							fixedTableHeader={true}
							onClick={rowClicked}
							onAdd={(tenantContext?.tenant?.isMainTenant ?? true) ? undefined : tableOnAdd}
							onAddDisabled={!hasGroupsEditRight}
						/>
					</Paper>
				</Grid>
			</Grid>

			<SnackbarAlert open={successGroupDeletedAlert?.visible ?? false} onClose={() => setSuccessGroupDeletedAlert(undefined)}
				message={
					<Trans i18nKey="company:groups.successGroupDeleted">
						Group <strong>{{ groupName: successGroupDeletedAlert?.groupName }}</strong> deleted successfully!
					</Trans>}
				severity="success" />
		</div >
	);
}

export default withStyles(AppStyle)(Groups);