import React, { ReactNode } from "react";
import {
  AppBar, Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
  IconButton, makeStyles, Theme, Toolbar, Typography, useMediaQuery, useTheme, withStyles, WithStyles
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from "react-i18next";
import AppHeaderStyle from "general/styles/AppHeaderStyle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    responsiveDialogContentPortalBackground: {
      backgroundColor: theme.palette.portalBackground?.main,
    },
  }),
);

export enum DialogResult {
  cancel = 0,
  ok = 1,
}

interface ResponsiveDialogBaseContentElementProps {
  backgroundColorType?: 'default' | 'portalBackground';
}

interface ResponsiveDialogProps extends WithStyles<typeof AppHeaderStyle>, ResponsiveDialogBaseContentElementProps {
  open: boolean;
  onClose: (dialogResult: DialogResult) => void;
  title?: ReactNode;
  children: ReactNode;
  buttonOkContent?: ReactNode;
  buttonOkDisabled?: boolean;
  buttonCancelContent?: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
}

function ResponsiveDialog(props: ResponsiveDialogProps) {
  const { classes, open, onClose, title, children, buttonOkContent, buttonOkDisabled, buttonCancelContent,
    maxWidth, fullWidth, backgroundColorType } = props;
  const customClasses = useStyles();
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const scroll = (fullScreen ? 'body' : 'paper');
  const paperPropsClassName = (backgroundColorType ?? 'default') === 'portalBackground' ? customClasses.responsiveDialogContentPortalBackground : undefined;

  return (
    <Dialog
      open={open}
      onClose={() => onClose(DialogResult.cancel)}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      scroll={scroll}
      aria-labelledby="dialog-title"
      PaperProps={{
        className: paperPropsClassName
      }}
    >
      {fullScreen ?
        <>
          <AppBar color="primary" position="sticky" elevation={0}>
            <Toolbar>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="close"
                    onClick={() => onClose(DialogResult.cancel)}
                    className={classes.navigationButton}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                {title ?
                  <Grid item xs>
                    <Typography color="inherit" variant="h6" component="h1">
                      {title}
                    </Typography>
                  </Grid> : null}
                <Grid item>
                  <Button autoFocus color="inherit"
                    onClick={() => onClose(DialogResult.ok)}
                    disabled={buttonOkDisabled}>
                    {buttonOkContent ? buttonOkContent : t('common:button.ok')}
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Box>
            <ContentElement children={children} scroll={scroll} backgroundColorType={backgroundColorType} />
          </Box>
        </>
        :
        <>
          {title ?
            <DialogTitle id="dialog-title">{title}</DialogTitle> : null}
          <ContentElement children={children} scroll={scroll} backgroundColorType={backgroundColorType} />
          <DialogActions>
            <Button onClick={() => onClose(DialogResult.cancel)} variant="contained" color="default">
              {buttonCancelContent ? buttonCancelContent : t('common:button.cancel')}
            </Button>
            <Button onClick={() => onClose(DialogResult.ok)} variant="contained" color="primary" disabled={buttonOkDisabled}>
              {buttonOkContent ? buttonOkContent : t('common:button.ok')}
            </Button>
          </DialogActions>
        </>
      }
    </Dialog >
  );
}

interface ContentElementProps extends ResponsiveDialogBaseContentElementProps {
  children: ReactNode;
  scroll: 'body' | 'paper';
}

function ContentElement(props: ContentElementProps) {
  const { children, scroll } = props;

  return (
    <>
      {children ?
        <DialogContent
          dividers={scroll === 'paper'}
        //className={(backgroundColorType ?? 'default') === 'portalBackground' ? customClasses.responsiveDialogContentPortalBackground : undefined}
        >
          {children}
        </DialogContent> : null}
    </>
  );
}

export default withStyles(AppHeaderStyle)(ResponsiveDialog);