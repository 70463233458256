import StringHelper from "general/helpers/types/StringHelper";
import { roleAdmin } from "idserver/models/Roles";
import { BaseGroupHasRight } from "company/users/models/BaseRight";

export const adminGroupOrder: number = 90000;
export const customGroupOrderStart: number = 20000;
export const customGroupOrderEnd: number = 80000;

class BaseGroup {
  groupId: string | undefined;
  createDate: Date | undefined;
  groupOrder: number | undefined;
  groupName: string | undefined;
  role: BaseRole | undefined;
  rights: Array<BaseGroupHasRight> | undefined;
  tenants: string | undefined;

  constructor(dataGroup?: any) {
    if (dataGroup) {
      this.groupId = dataGroup.groupId;
      this.createDate = dataGroup.createDate;
      this.groupOrder = dataGroup.groupOrder;
      this.groupName = dataGroup.groupName;
      this.role = new BaseRole(dataGroup.role);
      this.tenants = dataGroup.tenants;
      this.rights = dataGroup.rights?.map((dataRight: any) => {
        return new BaseGroupHasRight(dataRight);
      });
    }
  }

  get isRoleGroup(): boolean {
    return (!StringHelper.empty(this.role?.roleId));
  }

  get isRoleAdminGroup(): boolean {
    return ((this?.role?.roleName ?? '') === roleAdmin);
  }
}

class BaseRole {
  roleId: string | undefined;
  roleName: string | undefined;

  constructor(dataRole?: any) {
    if (dataRole) {
      this.roleId = dataRole.roleId;
      this.roleName = dataRole.roleName;
    }
  }
}

class BaseRightGroup extends BaseGroup {
  isAllowed: boolean | null | undefined;

  constructor(dataRightGroup?: any) {
    super(dataRightGroup);
    if (dataRightGroup) {
      this.isAllowed = dataRightGroup.isAllowed;
    }
  }
}

export default BaseGroup;
export { BaseRole, BaseRightGroup };