import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import clsx from 'clsx';
import { withStyles, WithStyles, useTheme } from '@material-ui/core/styles';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Home as HomeIcon,
  Person as PersonIcon, Business as BusinessIcon, FolderOutlined as FolderOutlinedIcon //, Group as GroupIcon, Lock as LockIcon
} from '@material-ui/icons';
import AccountContext from 'account/AccountContext';
import { useTranslation } from 'react-i18next';
import Routes from 'general/helpers/Routes';
import Page, { PageCategory } from 'general/models/Page';
import AppNavDrawerStyle from 'general/styles/AppNavDrawerStyle';
import { Box, Hidden } from '@material-ui/core';
import AccountElement from 'general/components/app/AccountElement';
import LanguageMenu from 'general/components/app/LanguageMenu';
import {
  useRight, right_Manage_Users_Groups_View, right_Manage_Users_Rights_View, right_Manage_Users_Users_View,
  right_Company_Tenants_View, right_Company_Users_Users_View, right_Company_Files_View
} from 'account/models/Rights';
import TenantContext from 'idserver/TenantContext';

export interface AppNavDrawerProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof AppNavDrawerStyle> { }

function AppNavDrawer(props: AppNavDrawerProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { classes, ...other } = props;
  const [categories, setCategories] = useState(new Array<PageCategory>());
  const tenantContext = useContext(TenantContext);
  const accountContext = useContext(AccountContext);
  const hasUsersViewRight = useRight(right_Manage_Users_Users_View, accountContext);
  const hasGroupsViewRight = useRight(right_Manage_Users_Groups_View, accountContext);
  const hasRightsViewRight = useRight(right_Manage_Users_Rights_View, accountContext);
  const hasTenantsViewRight = useRight(right_Company_Tenants_View, accountContext);
  const hasCompanyUsersViewRight = useRight(right_Company_Users_Users_View, accountContext);
  const hasCompanyFilesViewRight = useRight(right_Company_Files_View, accountContext);

  useEffect(() => {
    const newCategories = new Array<PageCategory>();

    // Main-Administration
    if (accountContext?.user?.hasContentManagerRights) {
      const mainAdmin = new PageCategory();
      mainAdmin.name = t('nav.manage.groupTitle');
      mainAdmin.children = new Array<Page>();
      newCategories.push(mainAdmin);

      if (hasUsersViewRight) {
        const adminUsers = new Page();
        adminUsers.name = t('nav.manage.users');
        adminUsers.icon = <PersonIcon />;
        adminUsers.route = Routes.Portal.Manage.Users;
        mainAdmin.children.push(adminUsers);
      }

      /*
      if (hasGroupsViewRight) {
        const adminGroups = new Page();
        adminGroups.name = t('nav.manage.groups');
        adminGroups.icon = <GroupIcon />;
        adminGroups.route = Routes.Portal.Manage.Groups;
        mainAdmin.children.push(adminGroups);
      }

      if (hasRightsViewRight) {
        const adminRights = new Page();
        adminRights.name = t('nav.manage.rights');
        adminRights.icon = <LockIcon />;
        adminRights.route = Routes.Portal.Manage.Rights;
        mainAdmin.children.push(adminRights);
      }
      */
    }

    // Administration
    if (accountContext?.user?.hasCompanyEmployeeRights) {
      const admin = new PageCategory();
      admin.name = t('nav.company.groupTitle');
      admin.children = new Array<Page>();
      newCategories.push(admin);

      if (hasTenantsViewRight && tenantContext.tenant.isMainTenant) {
        const tenants = new Page();
        tenants.name = t('nav.company.tenants');
        tenants.icon = <BusinessIcon />;
        tenants.route = Routes.Portal.Company.Tenants;
        admin.children.push(tenants);
      }

      if (hasCompanyUsersViewRight) {
        const users = new Page();
        users.name = t('nav.company.users');
        users.icon = <PersonIcon />;
        users.route = Routes.Portal.Company.Users;
        admin.children.push(users);
      }

      if (hasCompanyFilesViewRight) {
        const files = new Page();
        files.name = t('nav.company.files');
        files.icon = <FolderOutlinedIcon />;
        files.route = Routes.Portal.Company.Files;
        admin.children.push(files);
      }
    }

    // Test
    /*
    const test = new PageCategory();
    test.name = 'Test';
    newCategories.push(test);
    */

    setCategories(newCategories);

  }, [accountContext, tenantContext, t,
    hasUsersViewRight, hasGroupsViewRight, hasRightsViewRight,
    hasTenantsViewRight, hasCompanyUsersViewRight, hasCompanyFilesViewRight]);

  function toggleDrawer(e: any) {
    if (props.onClose !== undefined)
      props.onClose(e, 'backdropClick');
  }

  function drawerNotFixedClose(e: any) {
    if (props.variant === 'temporary' && props.open)
      toggleDrawer(e);
  }

  function TitleElement() {
    if (props.open)
      return (
        <div className={classes.drawerBrandContainer}>
          <div className={classes.drawerBrandText}>
            {tenantContext.tenant.name}
          </div>
          <div className={classes.drawerCloseButtonContainer}>
            <IconButton
              className={classes.drawerToggleButton}
              onClick={(e) => toggleDrawer(e)}
            >
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
        </div>
      );
    else
      return (
        <React.Fragment>
          <div className={classes.drawerOpenButtonContainer}>
            <IconButton
              className={classes.drawerToggleButton}
              onClick={(e) => toggleDrawer(e)}
            >
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
        </React.Fragment>
      );
  }

  function AccountListItem() {
    return (
      <Hidden mdUp>
        <ListItem className={clsx(classes.item, classes.itemCategory, classes.itemAccount)}>
          <AccountElement onActionCallback={drawerNotFixedClose} isUsernameVisible={true} />
          <LanguageMenu useDrawerStyle={true} />
        </ListItem>
      </Hidden>
    )
  }

  function DrawerListItemIcon(itemIconProps: { children: React.ReactNode }) {
    return (
      <ListItemIcon className={clsx({
        [classes.itemIcon]: props.open,
        [classes.itemIconDrawerClose]: !props.open,
      })}>
        {itemIconProps.children}
      </ListItemIcon>
    );
  }

  function DrawerListItemText(itemTextProps: { classesPrimary: string, children: React.ReactNode }) {
    if (props.open)
      return (
        <ListItemText classes={{ primary: itemTextProps.classesPrimary, }}>
          {itemTextProps.children}
        </ListItemText>
      );
    else
      return null;
  }

  function DrawerDivider() {
    return (
      <>
        <Box className={clsx({
          [classes.dividerBox]: props.open,
          [classes.dividerBoxDrawerClose]: !props.open,
        })} />
        <Divider />
      </>
    );
  }

  return (
    <Drawer variant="permanent" {...other}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <TitleElement />
        </ListItem>
        <AccountListItem />
        <ListItem button
          onClick={drawerNotFixedClose}
          component={Link} to={Routes.Portal.General.Home}
          className={clsx(classes.item, classes.itemCategory)}>
          <DrawerListItemIcon>
            <HomeIcon />
          </DrawerListItemIcon>
          <DrawerListItemText classesPrimary={classes.itemPrimary}>
            {t('nav.home')}
          </DrawerListItemText>
        </ListItem>
        {categories.map((pageCategory) => (
          <React.Fragment key={pageCategory.id ?? pageCategory.name}>
            {props.open ?
              <ListItem className={classes.categoryHeader}>
                <DrawerListItemText classesPrimary={classes.categoryHeaderPrimary}>
                  {pageCategory.name}
                </DrawerListItemText>
              </ListItem> : null}
            {pageCategory !== undefined ? pageCategory?.children?.map((page) => (
              <ListItem button={true}
                onClick={drawerNotFixedClose}
                {...{ component: Link }}
                {...{ to: page.route }}
                className={clsx({
                  [classes.item]: props.open,
                  [classes.itemDrawerClose]: !props.open,
                }, page.active && classes.itemActiveItem)}
                key={page.id ?? page.name}
              >
                <DrawerListItemIcon>{page.icon}</DrawerListItemIcon>
                <DrawerListItemText classesPrimary={classes.itemPrimary}>
                  {page.name}
                </DrawerListItemText>
              </ListItem>
            )) : null}
            <DrawerDivider />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default withStyles(AppNavDrawerStyle)(AppNavDrawer);