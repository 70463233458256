class Page {
    id: string | undefined;
    name: string | undefined;
    icon: JSX.Element | undefined;
    active: boolean | undefined;
    route: string | undefined;
}

class PageCategory {
    id: string | undefined;
    name: string | undefined;
    children: Array<Page> | undefined;
}

export default Page;
export { PageCategory };