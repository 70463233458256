import React, { useMemo, useState } from "react";
import { Box, Button, ButtonGroup, Grid, TextField } from "@material-ui/core";
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxBlankIcon, IndeterminateCheckBox as IndeterminateCheckBoxIcon } from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Controller, SetValueConfig, useFieldArray, useForm } from "react-hook-form";
import { GroupDetailsProps } from "company/users/views/GroupDetails";
import GroupPaperTitle, { GroupPaperTitleOption } from 'company/users/components/GroupPaperTitle';
import { adminGroupOrder, customGroupOrderEnd, customGroupOrderStart } from "company/users/models/BaseGroup";
import CompanyGroup from "company/users/models/CompanyGroup";
import CompanyGroupsService from "company/users/helpers/CompanyGroupsService";
import EditActionButtonsGroup from "general/components/common/EditActionButtonsGroup";
import BoxedAlert from "general/components/common/BoxedAlert";
import NumberHelper from "general/helpers/types/NumberHelper";
import { BaseGroupHasRight } from 'company/users/models/BaseRight';
import {
  CustomTable, CustomTableHeadRow, CustomTableHeadCell, CustomTableRow, CustomTableCell, AlignX,
  CustomTableEditRowCheckbox,
  CustomCheckboxStyle
} from 'general/components/customTable';
import ButtonGroupContainer from "general/components/container/ButtonGroupContainer";

interface GroupEditProps extends GroupDetailsProps {
  group: CompanyGroup | undefined;
  groupsService: CompanyGroupsService;
  onSaved: (group: CompanyGroup) => void;
  onCanceled: () => void;
}

function GroupEdit(props: GroupEditProps) {
  const { classes, group, groupsService, onSaved, onCanceled } = props;
  const { t } = useTranslation(['company']);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { register, getValues, setValue, reset, trigger, errors, control } =
    useForm({
      defaultValues: group
    });

  const { fields } = useFieldArray({
    control,
    name: "rights"
  });

  async function saveAsync() {
    setErrorMessage('');

    if (!await trigger()) return;

    const data = getValues();
    const changedGroup = new CompanyGroup();
    changedGroup.groupId = group?.groupId;
    changedGroup.groupOrder = NumberHelper.ConvertInt(data.groupOrder, 0);
    changedGroup.groupName = data.groupName;
    changedGroup.rights = data.rights;
    console.log('data', data);

    try {
      setSaveIsLoading(true);

      // Send changed data
      await groupsService.updateGroup(changedGroup);

      // Go back to view mode
      onSaved(changedGroup);
    } catch (error) {
      if ((error?.response?.status ?? 0) === 400) {
        setErrorMessage(t(`company:groupEdit.error.${error?.response?.data?.code}`));
      } else {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      }
      setSaveIsLoading(false);
    };
  }

  const groupOrderMin = useMemo<number>(() => {
    if(group?.isRoleGroup ?? false) {
      if (group?.isRoleAdminGroup ?? false) {
        return adminGroupOrder;
      }
      return 1;
    }
    return customGroupOrderStart;
  }, [group]);

  const groupOrderMax = useMemo<number>(() => {
    if(group?.isRoleAdminGroup ?? false) {
      return adminGroupOrder;
    }
    return customGroupOrderEnd;
  }, [group]);

  const valRequired = t('validation:required');
  const valMaxLength256 = t('validation:maxLength', { maxLength: 256 });
  const valBetweenGroupOrder = t('validation:between', { min: groupOrderMin, max: groupOrderMax });
  return (
    <>
      <Box className={classes.portalPaperTitleBox}>
        <GroupPaperTitle option={GroupPaperTitleOption.basicData} />
        <EditActionButtonsGroup
          editMode={true}
          onSaveButtonClick={saveAsync}
          onCancelButtonClick={onCanceled}
          disabled={saveIsLoading}
        />
      </Box>
      <Box className={classes.portalPaperContentBox}>
        <BoxedAlert message={errorMessage} severity="error" />
        <Grid container spacing={2} className={classes.defaultFormGrid}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyGroup.groupOrder')}
              name="groupOrder"
              autoComplete="groupOrder"
              autoFocus
              type="number"
              inputRef={register({
                required: valRequired,
                min: groupOrderMin,
                max: groupOrderMax,
              })}
              error={!!errors.groupOrder}
              helperText={errors.groupOrder ? valBetweenGroupOrder : ''}
              disabled={group?.isRoleAdminGroup ?? false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={t('company:companyGroup.groupName')}
              name="groupName"
              autoComplete="groupName"
              inputRef={register({ required: valRequired, maxLength: { value: 256, message: valMaxLength256 } })}
              error={!!errors.groupName}
              helperText={errors.groupName ? errors.groupName.message : ''}
              disabled={group?.isRoleGroup ?? true}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.portalPaperTitleBox}>
        <GroupPaperTitle option={GroupPaperTitleOption.rights} />
      </Box>
      <Box className={classes.portalPaperContentBox}>
        <RightsTable group={group} fields={fields} control={control}
          setValue={setValue} reset={reset} getValues={getValues}
          translation={t} />
      </Box>
    </>
  );
}

interface RightsTableProps {
  group: CompanyGroup | undefined;
  fields: any;
  control: any;
  setValue: (name: string, value: unknown, config?: SetValueConfig) => void;
  reset: any;
  getValues: any;
  translation: TFunction;
}

interface CompanyGroupHasRightRow extends BaseGroupHasRight {
  id: string;
}

function RightsTable(props: RightsTableProps) {
  const { group, fields, control, setValue, getValues, reset } = props;
  const t = props.translation;
  const isAdminGroup = (group?.isRoleAdminGroup ?? false);

  const columns = {
    name: {
      width: 200,
    },
    isAllowed: {
      width: 110,
      alignX: AlignX.center,
    },
    description: {
      width: 600,
    },
  };

  function setIsAllowed(isAllowed: boolean | null) {
    // Set value to all fields
    fields?.forEach((groupHasRight: CompanyGroupHasRightRow, index: number) => {
      setValue(`rights[${index}].isAllowed`, isAllowed);
    });

    // Get current values
    const data = getValues();

    // Rerender view with current values
    reset({ rights: data.rights });
  }

  return (
    <>
      <Box mt={-2} mb={1}>
      <ButtonGroupContainer>
          <ButtonGroup disableElevation variant="contained" color="default" disabled={isAdminGroup}>
            <Button onClick={() => setIsAllowed(true)} startIcon={<CheckBoxIcon />}>
              {t('company:rights.buttons.allowAll')}
            </Button>
            <Button onClick={() => setIsAllowed(null)} startIcon={<IndeterminateCheckBoxIcon />}>
              {t('company:rights.buttons.setAllUndefined')}
            </Button>
            <Button onClick={() => setIsAllowed(false)} startIcon={<CheckBoxBlankIcon />}>
              {t('company:rights.buttons.disallowAll')}
            </Button>
          </ButtonGroup>
        </ButtonGroupContainer>
      </Box>
      {fields !== null ? <CustomTable
        fixedTableHeader={true}
        headerChildren={
          <CustomTableHeadRow>
            <CustomTableHeadCell width={columns.name.width}>{t('company:companyRight.name')}</CustomTableHeadCell>
            <CustomTableHeadCell width={columns.isAllowed.width} alignX={columns.isAllowed.alignX}>{t('company:companyRight.isAllowed')}</CustomTableHeadCell>
            <CustomTableHeadCell width={columns.description.width}>{t('company:companyRight.description')}</CustomTableHeadCell>
          </CustomTableHeadRow>}>
        {fields.map((groupHasRight: CompanyGroupHasRightRow, index: number) => {
          if (index === 0) console.log('Render RightsTable');
          return (
            <CustomTableRow key={groupHasRight?.id}>
              <Controller
                name={`rights[${index}].right.rightId`}
                control={control}
                defaultValue={groupHasRight?.right?.rightId}
                render={() => (<></>)}
              />
              <Controller
                name={`rights[${index}].right.name`}
                control={control}
                defaultValue={groupHasRight?.right?.name}
                render={() => (<CustomTableCell width={columns.name.width}>{groupHasRight?.right?.name}</CustomTableCell>)}
              />
              <CustomTableCell width={columns.isAllowed.width} alignX={columns.isAllowed.alignX} isCheckbox={true}>
                <CustomTableEditRowCheckbox
                  name={`rights[${index}].isAllowed`}
                  control={control}
                  defaultValue={groupHasRight?.isAllowed}
                  isIndeterminateEnabled={true}
                  customStyle={{ useCustomColor: true } as CustomCheckboxStyle}
                  disabled={isAdminGroup}
                />
              </CustomTableCell>
              <Controller
                name={`rights[${index}].right.description`}
                control={control}
                defaultValue={groupHasRight?.right?.description}
                render={() => (<CustomTableCell width={columns.description.width}>{groupHasRight?.right?.description}</CustomTableCell>)}
              />
            </CustomTableRow>);
        })}
      </CustomTable> : null}
    </>
  );
}

export default GroupEdit;