import BaseService from "general/helpers/api/BaseService";
import CompanyRight from "company/users/models/CompanyRight";
import { CompanyRightUser } from "company/users/models/CompanyUser";
import { CompanyRightGroup } from "company/users/models/CompanyGroup";

class CompanyRightsService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/company/rights`;

  public async getRights(searchText: string, lang: string): Promise<Array<CompanyRight>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new CompanyRight(data);
    });
  }

  public async getUsersHasRight(rightId: string, searchText: string, lang: string): Promise<Array<CompanyRightUser>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${rightId}/users/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new CompanyRightUser(data);
    });
  }

  public async getTenantUsersHasRight(tenantId: string, rightId: string, searchText: string, lang: string): Promise<Array<CompanyRightUser>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${rightId}/users/${tenantId}/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new CompanyRightUser(data);
    });
  }

  public async getGroupsHasRight(rightId: string, searchText: string, lang: string): Promise<Array<CompanyRightGroup>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${rightId}/groups/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new CompanyRightGroup(data);
    });
  }

  public async getTenantGroupsHasRight(tenantId: string,rightId: string, searchText: string, lang: string): Promise<Array<CompanyRightGroup>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${rightId}/groups/${tenantId}/search/${searchText}?lang=${lang}`);
    return resp.data.map((data: any) => {
      return new CompanyRightGroup(data);
    });
  }
}

export default CompanyRightsService;