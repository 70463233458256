import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';
import ResponsiveDialog, { DialogResult } from 'general/components/common/ResponsiveDialog';
import ManageGroupRightsConfigService from 'manage/users/helpers/ManageGroupRightsConfigService';
import { Box } from '@material-ui/core';
import BoxedAlert from 'general/components/common/BoxedAlert';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import ManageRight from 'manage/users/models/ManageRight';

export interface GroupAddRightsDialogProps {
  open: boolean;
  onClose: (dialogResult: DialogResult, selectedRights: Array<ManageRight> | undefined) => void;
  rightsConfigService: ManageGroupRightsConfigService;
}

function GroupAddRightsDialog(props: GroupAddRightsDialogProps) {
  const { open, onClose, rightsConfigService } = props;
  const { t, i18n } = useTranslation(['manage']);
  const [rightRows, setRightRows] = useState(new Array<ManageRight>());
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const tableInstance = useRef<TableInstance<ManageRight> | undefined>(undefined);

  useEffect(() => {
    // Load rights
    const lang = i18n.language;
    getRightsAsync(open, rightsConfigService, lang);
  }, [open, rightsConfigService, i18n.language]);

  async function getRightsAsync(
    open: boolean, 
    currentService: ManageGroupRightsConfigService,
    lang: string) {
    if (open && currentService.groupId) {
      console.log('AddDialog load group rights');
      setErrorMessage('');
      setIsLoading(true);
      try {
        const rights = await currentService.getRights(false, lang);
        setRightRows(rights);
      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      } finally {
        // Reset
        setIsLoading(false);
      }
    }
  }

  async function handleDialogClose(dialogResult: DialogResult) {
    if (dialogResult === DialogResult.ok) {
      const selectedRights = new Array<ManageRight>();
      const selectedRows = tableInstance?.current?.selectedFlatRows;
      if (selectedRows) {
        selectedRows.forEach((rightRow: Row<ManageRight>) => {
          selectedRights.push(new ManageRight(rightRow.original));
        });
      }
      onClose(dialogResult, selectedRights);
    }
    else {
      onClose(dialogResult, undefined);
    }
  }

  const rightColumns = useMemo(() => {
    console.log('AddDialog init group rights config columns');
    return [
      {
        Header: 'manageGroupRightsConfigAdd',
        HideHeader: true,
        columns: [
          {
            Header: t('manage:manageRight:name'),
            accessor: 'name',
            width: 300,
          },
          {
            Header: t('manage:manageRight:description'),
            accessor: 'description',
            width: 900,
          },
        ]
      },
    ];
  }, [t]);

  return (
    <ResponsiveDialog
      open={open}
      onClose={handleDialogClose}
      title={t('manage:groupRightsConfig.groupRightsAddDialog.title')}
      buttonOkContent={t('manage:groupRightsConfig.groupRightsAddDialog.buttonOk')}
      maxWidth="xl"
      fullWidth={true}>
      <Box>
        <BoxedAlert message={errorMessage} severity="error" />
        <CustomDataGrid<ManageRight>
          name="manageGroupRightsConfigAdd"
          isLoading={isLoading}
          columns={rightColumns}
          data={rightRows}
          disableRowSelection={false}
          onInitialized={(instance: TableInstance<ManageRight> | undefined) => tableInstance.current = instance}
        />
      </Box>
    </ResponsiveDialog>
  );
}

export default GroupAddRightsDialog;