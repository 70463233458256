import React, { useMemo, useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Paper, withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';
import { useHistory } from 'react-router-dom';
import { useScroll } from 'general/helpers/ui/ScrollHelper';
import { CompanyRightUser } from 'company/users/models/CompanyUser';
import CompanyRightsService from 'company/users/helpers/CompanyRightsService';
import { RowCheckbox } from 'general/components/customDataGrid/styles/TableStyles';
import { BooleanFilterKey, CheckBoxColumnFilter, DateColumnFilter, FilterSelectOptionsProp, SelectColumFilter, SelectFilterKey } from 'general/components/customDataGrid/filters';
import RolesHelper from 'idserver/models/Roles';
import moment from 'moment';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import SearchBar from 'general/components/common/SearchBar';
import BoxedAlert from 'general/components/common/BoxedAlert';
import { CustomCheckboxStyle, CustomTableRowCheckbox } from 'general/components/customTable';
import { RightDetailsState } from 'company/users/models/CompanyUsersState';
import { RightDetailsTabs } from './RightDetails';
import BaseState from 'general/models/BaseState';
import { Row } from 'react-table';
import StringHelper from 'general/helpers/types/StringHelper';
import Routes from 'general/helpers/Routes';
import TenantContext, { TenantContextType } from 'idserver/TenantContext';

interface RightDetailsUsersProps extends WithStyles<typeof AppStyle> {
  rightId: string;
}

function RightDetailsUsers(props: RightDetailsUsersProps) {
  const { classes, rightId } = props;
  const { t, i18n } = useTranslation(['company']);
  const routeHistory = useHistory();
  const [setScrollToY, raiseScrollToY] = useScroll();
  const [tableLoadPageIndex, setTableLoadPageIndex] = useState<boolean | undefined>();
  const [userRows, setUserRows] = useState(new Array<CompanyRightUser>());
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchText, setSearchText] = useState<string | undefined>();
  const rightsService = useRef(new CompanyRightsService());
  const tenantContext = useContext(TenantContext);

  useEffect(() => {
    console.log('Init load right users');
    const savedState = routeHistory.location?.state as RightDetailsState
    if (savedState?.users) {
      // Backup state
      const { scrollY, searchText } = savedState.users;

      // Clean up state
      savedState.users = undefined;
      routeHistory.replace(routeHistory.location.pathname, savedState);

      // Set saved state
      setScrollToY(scrollY);
      setTableLoadPageIndex(true);
      setSearchText(searchText);

    } else {
      // Load users
      const lang = i18n.language;
      getUsersAsync(rightId, searchText, lang, tenantContext, raiseScrollToY);
    }
  }, [rightId, searchText, routeHistory, i18n.language, tenantContext, setScrollToY, raiseScrollToY]);

  async function getUsersAsync(
    paramRightId: string,
    paramSearchText: string | undefined,
    paramLang: string,
    tenantContext: TenantContextType,
    raiseScrollToY: () => void) {
    setErrorMessage('');
    setIsLoading(true);
    try {
      let users: CompanyRightUser[];
      if (tenantContext.tenant.isMainTenant) {
        users = await rightsService.current
          .getUsersHasRight(paramRightId, paramSearchText ?? '', paramLang);
      } else {
        users = await rightsService.current
          .getTenantUsersHasRight(tenantContext.tenant.tenantId ?? '', paramRightId, paramSearchText ?? '', paramLang);
      }
      setUserRows(users);
    } catch (error) {
      console.error(error);
      setErrorMessage(error?.message ?? '');
    } finally {
      // Reset
      setIsLoading(false);
      setTableLoadPageIndex(undefined);
      raiseScrollToY();
    }
  }

  function saveCurrentRouteState() {
    const currentState = new RightDetailsState();
    currentState.selectedTab = RightDetailsTabs.users;
    currentState.users = new BaseState();
    currentState.users.scrollY = window.scrollY;
    currentState.users.searchText = searchText ?? '';
    routeHistory.replace(routeHistory.location.pathname, currentState);
  }

  function rowClicked(row: Row<CompanyRightUser>) {
    const selectedSubjectId = row?.original?.subjectId ?? '';
    if (!StringHelper.empty(selectedSubjectId)) {
      // Save current state before redirect
      saveCurrentRouteState();

      // Redirect to UserDetails rights tab
      routeHistory.push(`${Routes.Portal.Company.UserDetails}/${selectedSubjectId}/rights`);
    }
  }

  const userColumns = useMemo(() => {
    console.log('Init right user columns');
    return [
      {
        Header: 'companyRightUsers',
        HideHeader: true,
        columns: [
          {
            Header: t('company:companyRight:isAllowed'),
            accessor: 'isAllowed',
            minWidth: 90,
            width: 100,
            align: 'center',
            Cell: ({ value }: any) =>
            (
              <CustomTableRowCheckbox
                checked={value ?? undefined}
                color="default"
                customStyle={{ useCustomColor: true } as CustomCheckboxStyle}
                className={classes.displayCheckbox} />
            ),
            Filter: CheckBoxColumnFilter,
            filter: BooleanFilterKey,
          },
          {
            Header: t('company:companyUser:email'),
            accessor: 'email',
            width: 200,
          },
          {
            Header: t('company:companyUser:givenName'),
            accessor: 'givenName',
            width: 150,
          },
          {
            Header: t('company:companyUser:familyName'),
            accessor: 'familyName',
            width: 180,
          },
          {
            Header: t('company:companyUser:name'),
            accessor: 'name',
            width: 180,
          },
          {
            Header: t('company:companyUser:roles'),
            accessor: 'rolesList',
            width: 150,
            Cell: ({ value }: any) =>
            (
              <>{RolesHelper.getRolesText((value as string[]), t)}</>
            ),
            Filter: SelectColumFilter,
            [FilterSelectOptionsProp]: RolesHelper.getRolesSelectOptions(t),
            filter: SelectFilterKey,
          },
          {
            Header: t('company:companyUser:lastSignInDate'),
            accessor: 'lastSignInDate',
            width: 180,
            Cell: ({ value }: any) =>
            (
              <>{value ? moment(value as Date).format('L LT') : ''}</>
            ),
            Filter: DateColumnFilter,
          },
          {
            Header: t('company:companyUser:isActive'),
            accessor: 'isActive',
            minWidth: 90,
            width: 100,
            align: 'center',
            Cell: ({ value }: any) =>
            (
              <RowCheckbox checked={value} color="default" className={classes.displayCheckbox} />
            ),
            Filter: CheckBoxColumnFilter,
            filter: BooleanFilterKey,
          },
          {
            Header: t('company:companyUser:isEmailVerified'),
            accessor: 'isEmailVerified',
            minWidth: 90,
            width: 150,
            align: 'center',
            Cell: ({ value }: any) =>
            (
              <RowCheckbox checked={value} color="default" className={classes.displayCheckbox} />
            ),
            Filter: CheckBoxColumnFilter,
            filter: BooleanFilterKey,
          },
        ]
      },
    ];
  }, [t, classes]);

  return (
    <>
      <Grid container>
        <BoxedAlert message={errorMessage} container="gridItem"
          severity="error" variant="filled" />

        <Grid item xs={12} className={classes.defaultSearchBarGridItem}>
          <Box marginY={1}>
            <SearchBar
              placeholder={t('company:rightDetails.usersTab.searchBar.placeholder')}
              onSearchTextChanged={(value: string) => setSearchText(value)}
              value={searchText}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <CustomDataGrid<CompanyRightUser>
              name="companyRightUsers"
              isLoading={isLoading}
              columns={userColumns}
              data={userRows}
              loadLastPageIndex={tableLoadPageIndex}
              fixedTableHeader={true}
              onClick={rowClicked}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(AppStyle)(RightDetailsUsers);