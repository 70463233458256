import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Button } from '@material-ui/core';
import EditActionButtonsGroup from 'general/components/common/EditActionButtonsGroup';
import TenantPaperTitle, { TenantPaperTitleOption } from 'company/tenants/components/TenantPaperTitle';
import { useRight, right_Company_Tenants_Edit } from 'account/models/Rights';
import AccountContext from 'account/AccountContext';
import { TenantDetailsProps } from './TenantDetails';
import FormDisplayMultiline from 'general/components/common/FormDisplayMultiline';
import CompanyTenantEmailSetting, { CompanyTenantEmailVerification } from '../models/CompanyTenantEmailSetting';
import FormDisplay from 'general/components/common/FormDisplay';
import InputDialog, { InputDialogResult, InputDialogSelection } from 'general/components/common/InputDialog';
import { regExpEmail } from 'general/helpers/ui/ValidationHelper';
import CompanyTenantEmailSettingsService from '../helpers/CompanyTenantEmailSettingsService';
import BoxedAlert from 'general/components/common/BoxedAlert';
import moment from 'moment';

export interface TenantDetailsEmailViewProps extends TenantDetailsProps {
  tenantEmailSetting: CompanyTenantEmailSetting | undefined;
  tenantEmailSettingsService: CompanyTenantEmailSettingsService;
  onEdit: () => void;
  onEmailSent: () => void;
}

function TenantDetailsEmailView(props: TenantDetailsEmailViewProps) {
  const { classes, tenantEmailSetting, tenantEmailSettingsService, onEdit, onEmailSent } = props;
  const { t, i18n } = useTranslation(['company']);
  const accountContext = useContext(AccountContext);
  const [showEmailInputDialog, setShowEmailInputDialog] = useState(false);
  const [sendIsLoading, setSendIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const hasTenantsEditRight = useRight(right_Company_Tenants_Edit, accountContext);

  function changeEmailClicked() {
    setShowEmailInputDialog(true);
  }

  async function emailInputDialogClosed(dialogResult: InputDialogResult) {
    // Close dialog
    setShowEmailInputDialog(false);

    if (dialogResult.buttonSelection === InputDialogSelection.ok) {
      console.log('InputDialog ok', dialogResult.inputText);

      setErrorMessage('');
      setSendIsLoading(true);
      try {
        const emailVerfication = new CompanyTenantEmailVerification();
        emailVerfication.tenantId = tenantEmailSetting?.tenantId;
        emailVerfication.lang = i18n.language;
        emailVerfication.emailToVerify = dialogResult.inputText;

        await tenantEmailSettingsService.sendTenantEmailVerification(emailVerfication);

        // Reload view so that verification fields become visible
        // Display a success message with instructions to the user
        onEmailSent();

      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
        setSendIsLoading(false);
      }
    }
  }

  const valRequired = t('validation:required');
  const valMaxLengthExceeded = t('validation:maxLengthExceeded');
  const valInvalidEmail = i18n.t('validation:invalidEmail');
  return (
    <>
      <Box className={classes.portalPaperTitleBox}>
        <TenantPaperTitle option={TenantPaperTitleOption.email} />
        <EditActionButtonsGroup
          editMode={false}
          onEditButtonClick={onEdit}
          disabled={sendIsLoading}
          disabledEdit={!hasTenantsEditRight}
        />
      </Box>
      <Box className={classes.portalPaperContentBox}>
        <BoxedAlert message={errorMessage} severity="error" />
        <Grid container spacing={2} className={classes.defaultFormGrid}>
          <Grid item xs={12}>
            <FormDisplay
              label={t('company:companyTenantEmailSetting.email')}
              value={tenantEmailSetting?.email}
              emtypValuePlaceholder={tenantEmailSetting?.ownerEmail} />
            {tenantEmailSetting?.emailToVerify ?
              <>
                <FormDisplay
                  label={t('company:companyTenantEmailSetting.emailToVerify')}
                  value={tenantEmailSetting?.emailToVerify}
                  valueColor="error" />
                <FormDisplay
                  label={t('company:companyTenantEmailSetting.emailVerificationExpirtaionDate')}
                  value={moment(tenantEmailSetting?.emailVerificationExpirationDate as Date).format('L LT')}
                  valueColor="error" />
              </>
              : null}
            <Button variant="contained" color="default"
              disabled={sendIsLoading || !hasTenantsEditRight}
              onClick={changeEmailClicked}>
              {t('company:tenantDetails.emailTab.buttonChangeEmail')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormDisplayMultiline label={t('company:companyTenantEmailSetting.signature')} value={tenantEmailSetting?.signature} rowsMax={20} />
          </Grid>
        </Grid>
      </Box>

      <InputDialog open={showEmailInputDialog} onClose={emailInputDialogClosed}
        title={t('company:tenantDetails.emailTab.emailInputDialog.title')}
        textFieldLabel={t('company:companyTenantEmailSetting.email')}
        textFieldRegisterOptions={{
          required: valRequired,
          maxLength: { value: 320, message: valMaxLengthExceeded },
          pattern: { value: regExpEmail, message: valInvalidEmail }
        }}
        fullWidth={true}
        maxWidth='sm' />
    </>
  );
}

export default TenantDetailsEmailView;