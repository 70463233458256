import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import StringHelper from 'general/helpers/types/StringHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      //width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  }),
);


interface SearchBarProps {
  onSearchTextChanged: (value: string) => void;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  raiseSearchTextChangedOnLetter?: number;
}

function SearchBar(this: any, props: SearchBarProps) {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['common', 'validation']);
  const { register, handleSubmit, errors, watch, reset, setValue } = useForm({ defaultValues: { searchText: props.defaultValue } });
  const [isResetButtonVisible, setIsResetButtonVisible] = useState(false);
  const typing = useRef(false);
  const watchSearchText = watch('searchText', '');

  useEffect(() => {
    // Init searchText value
    setValue('searchText', props.value ?? '');

    // Set ResetButton visiblity
    setIsResetButtonVisible(!StringHelper.empty(props.value) || typing.current);

  }, [props.value, setValue, setIsResetButtonVisible]);

  async function onSubmit(data: any) {
    if (StringHelper.empty(watchSearchText)) {
      typing.current = false;
    }
    props.onSearchTextChanged(data.searchText);
  }

  function searchTextOnChanged() {
    setIsResetButtonVisible(!StringHelper.empty(watchSearchText) || typing.current);
    // Instant search option
    if (props.raiseSearchTextChangedOnLetter !== undefined &&
      (watchSearchText ?? '').length >= props.raiseSearchTextChangedOnLetter) {
      typing.current = true;
      props.onSearchTextChanged(watchSearchText ?? '');
    }
  }

  function resetSearchText() {
    // Clear input value
    reset({ searchText: '' });

    // Reset typing state
    typing.current = false;

    // Make reset button invisible
    setIsResetButtonVisible(false);

    // Trigger searchTextChanged event for parent component
    props.onSearchTextChanged('');
  }

  const valMaxLength100 = i18n.t('validation:maxLength', { maxLength: 100 });
  return (
    <Paper component="form" className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <IconButton type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>

      <InputBase
        className={classes.input}
        placeholder={props.placeholder ?? t('common:searchBar.placeholder')}
        inputProps={{ 'aria-label': props.placeholder ?? t('common:searchBar.placeholder') }}
        onChange={searchTextOnChanged}
        name="searchText"
        autoComplete="off"
        inputRef={register({ maxLength: { value: 100, message: valMaxLength100 } })}
        error={!!errors.searchText}
      />

      {errors.searchText ?
        <Box style={{ color: red[500] }}>
          {errors.searchText.message}
        </Box> : null}

      {isResetButtonVisible &&
        <IconButton type="button" className={classes.iconButton} aria-label="clear"
          onClick={resetSearchText}>
          <CloseIcon />
        </IconButton>}
    </Paper>
  );
}

export default SearchBar;
