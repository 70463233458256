import React, { useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Routes from 'general/helpers/Routes';
import AppStyle from 'general/styles/AppStyle';
import ApiHelper from 'general/helpers/api/ApiHelper';
import { regExpEmail } from 'general/helpers/ui/ValidationHelper';
import { useTranslation, Trans } from 'react-i18next';
import { roleCompany } from 'idserver/models/Roles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import Copyright from 'general/components/app/Copyright';
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DefaultPaper from 'general/components/container/DefaultPaper';

interface SignUpProps extends WithStyles<typeof AppStyle> {
	role: string
}

function SignUp(props: SignUpProps) {
	enum viewMode { form, success }
	const { t } = useTranslation(['idserver', 'validation', 'common']);
	const [emailSendTo, setEmailSendTo] = useState('');
	const [currentViewMode, setcurrentViewMode] = useState(viewMode.form);

	function SuccessView() {
		return (
			<Container component="main">
				<DefaultPaper>
					<Alert severity="info">
						<AlertTitle>{t('common:alertTitle.info')}</AlertTitle>
						<p>
							<Trans i18nKey="idserver:signUp.textEmailSendTo">
								A link to activate your account has been sent to email address <strong>{{ emailSendTo }}</strong>.
              </Trans>
						</p>
						<p>{t('idserver:email.textMailBoxCheck')}</p>
						<p>{t('idserver:signUp.textNoEmailContact')}</p>
					</Alert>
				</DefaultPaper>
			</Container>
		);
	}

	function showSuccessView(emailSendTo: string) {
		setcurrentViewMode(viewMode.success);
		setEmailSendTo(emailSendTo);
	}

	return (
		<div>
			{currentViewMode === viewMode.form ?
				<SignUpFormView
					classes={props.classes}
					role={props.role} showSuccessView={showSuccessView} /> :
				<SuccessView />}
		</div>
	);
}

type ShowSuccessViewCallback = (emailSendTo: string) => void;
interface SignUpPropsFormView extends SignUpProps {
	showSuccessView: ShowSuccessViewCallback
}

function SignUpFormView(props: SignUpPropsFormView) {
	const classes = props.classes;
	const { t, i18n } = useTranslation(['idserver', 'validation']);
	const [errorMessage, setErrorMessage] = useState('');
	const [signUpLoading, setSignUpLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
	const { register, handleSubmit, errors, watch } = useForm();

	async function onSubmit(data: any) {
		setErrorMessage('');
		const email = data.email;
		const password = data.password;
		const passwordRepeat = data.passwordRepeat;
		const name = data.name;
		const givenName = data.givenName;
		const familyName = data.familyName;
		const phoneNumber = data.phoneNumber;
		const role = props.role;
		const lang = i18n.language;

		try {
			setSignUpLoading(true);
			const apiHelper = new ApiHelper();
			await apiHelper.Api.post('/apiidserver/signup',
				JSON.stringify({
					email,
					password,
					passwordRepeat,
					name,
					givenName,
					familyName,
					phoneNumber,
					role,
					lang
				}));
			props.showSuccessView(email);
		} catch (error) {
			if ((error?.response?.status ?? 0) === 400) {
				setErrorMessage(t(`idserver:signUp.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
			setSignUpLoading(false);
		};
	}

	const valRequired = t('validation:required');
	const valMinLength8 = i18n.t('validation:minLength', { minLength: 8 });
	const valMaxLengthExceeded = t('validation:maxLengthExceeded');
	const valMaxLength30 = i18n.t('validation:maxLength', { maxLength: 30 });
	const valMaxLength80 = i18n.t('validation:maxLength', { maxLength: 80 });
	const valMaxLength100 = i18n.t('validation:maxLength', { maxLength: 100 });
	const valInvalidEmail = i18n.t('validation:invalidEmail');
	const valpasswordsNotMatch = i18n.t('validation:passwordsNotMatch');
	return (
		<Container component="main" maxWidth="xs">
			<DefaultPaper>
				<Avatar className={classes.defaultAvatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5" className={classes.defaultFormTitle}>
					{t((props.role === roleCompany ? "idserver:signUp.titleCompany" : "idserver:signUp.title"))}
				</Typography>
				<form className={classes.defaultForm} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('idserver:signUp.labelEmail')}
								name="email"
								autoComplete="email"
								autoFocus
								inputRef={register({
									required: valRequired,
									maxLength: { value: 320, message: valMaxLengthExceeded },
									pattern: { value: regExpEmail, message: valInvalidEmail }
								})}
								error={!!errors.email}
								helperText={errors.email ? errors.email.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('idserver:signUp.labelPassword')}
								name="password"
								autoComplete="current-password"
								type={showPassword ? "text" : "password"}
								inputRef={register({
									required: valRequired,
									minLength: { value: 8, message: valMinLength8 },
									maxLength: { value: 30, message: valMaxLength30 }
								})}
								error={!!errors.password}
								helperText={errors.password ? errors.password.message : ''}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												onMouseDown={() => setShowPassword(!showPassword)}
												tabIndex={-1}
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('idserver:signUp.labelPasswordRepeat')}
								name="passwordRepeat"
								type={showPasswordRepeat ? "text" : "password"}
								inputRef={register({
									required: valRequired,
									minLength: { value: 8, message: valMinLength8 },
									maxLength: { value: 30, message: valMaxLength30 },
									validate: (value) => value === watch('password') || valpasswordsNotMatch
								})}
								error={!!errors.passwordRepeat}
								helperText={errors.passwordRepeat ? errors.passwordRepeat.message : ''}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
												onMouseDown={() => setShowPasswordRepeat(!showPasswordRepeat)}
												tabIndex={-1}
											>
												{showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('idserver:signUp.labelGivenName')}
								name="givenName"
								autoComplete="givenName"
								inputRef={register({ required: valRequired, maxLength: { value: 100, message: valMaxLength100 } })}
								error={!!errors.givenName}
								helperText={errors.givenName ? errors.givenName.message : ''}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('idserver:signUp.labelFamilyName')}
								name="familyName"
								autoComplete="familyName"
								inputRef={register({ required: valRequired, maxLength: { value: 100, message: valMaxLength100 } })}
								error={!!errors.familyName}
								helperText={errors.familyName ? errors.familyName.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('idserver:signUp.labelName')}
								name="name"
								autoComplete="name"
								inputRef={register({
									required: (props.role === roleCompany) ? valRequired : false,
									maxLength: { value: 100, message: valMaxLength100 }
								})}
								error={!!errors.name}
								helperText={errors.name ? errors.name.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('idserver:signUp.labelPhoneNumber')}
								name="phoneNumber"
								autoComplete="phoneNumber"
								inputRef={register({ required: false, maxLength: { value: 80, message: valMaxLength80 } })}
								error={!!errors.phoneNumber}
								helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={signUpLoading}
								className={classes.defaultFormSubmitButton}
							>
								{signUpLoading ?
									t('loading') :
									t('idserver:signUp.buttonSignUp')}
							</Button>
						</Grid>
					</Grid>
				</form>

				{errorMessage !== '' ?
					<Grid container>
						<Grid item xs={12}>
							<Alert severity="error">
								{errorMessage}
							</Alert>
							<br />
						</Grid>
					</Grid> : null}

				<Grid container justify="flex-end">
					<Grid item>
						<Link component={RouterLink} to={Routes.Account.Login} variant="body2">
							{t('idserver:signUp.linkAlreadyHaveAnAccount')}
						</Link>
					</Grid>
				</Grid>
			</DefaultPaper>
			<Box mt={6}>
				<Copyright />
			</Box>
		</Container>
	);
}

export default withStyles(AppStyle)(SignUp);