import BaseService from "general/helpers/api/BaseService";
import ManageUser, { ManageUserChangePasswordRequest, ManageUserCreateRequest } from "manage/users/models/ManageUser";

class ManageUsersService extends BaseService {
  private _serviceUrl = `${this._baseUrlApiPortal}/manage/users`;

  public async getUsers(searchText: string): Promise<Array<ManageUser>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/search/${searchText}`);
    return resp.data.map((userData: any) => {
      return new ManageUser(userData);
    });
  }

  public async getUser(subjectId: string): Promise<ManageUser> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}/${subjectId}`);
    return new ManageUser(resp.data);
  }

  public async createUser(userCreateRequest: ManageUserCreateRequest): Promise<ManageUser> {
    const resp = await (await this.useApiWithAuth())
      .post(this._serviceUrl,
        JSON.stringify(userCreateRequest));
    return new ManageUser(resp.data);
  }

  public async updateUser(user: ManageUser): Promise<void>{
    await (await this.useApiWithAuth())
      .put(`${this._serviceUrl}/${user.subjectId}`,
        JSON.stringify(user));
  }

  public async changePassword(changeRequest: ManageUserChangePasswordRequest): Promise<void>{
    await (await this.useApiWithAuth())
      .patch(`${this._serviceUrl}/${changeRequest.subjectId}/changePassword`,
        JSON.stringify(changeRequest));
  }

  public async deleteUser(subjectId: string): Promise<void>{
    await (await this.useApiWithAuth())
      .delete(`${this._serviceUrl}/${subjectId}`);
  }
}

export default ManageUsersService;