import React, { ReactNode } from "react";
import { Snackbar, useMediaQuery, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface SnackbarAlertProps {
  open: boolean | undefined;
  onClose: () => void;
  message: string | ReactNode | undefined;
  severity: "error" | "success" | "info" | "warning" | undefined;
  autoHideDuration?: number | null;
}

function SnackbarAlert(props: SnackbarAlertProps) {
  const { open, onClose, message, severity, autoHideDuration } = props;
  const mediaMdUp = useMediaQuery(useTheme().breakpoints.up('md'));
  const anchorVertical = mediaMdUp ? 'top' : 'bottom';

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration === undefined ? 6000 : autoHideDuration}
      anchorOrigin={{ vertical: anchorVertical, horizontal: 'center' }}
      onClose={onClose}>
      <Alert severity={severity} variant="filled" onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarAlert;