import React, { useContext, useMemo } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createAppTheme } from 'general/styles/AppStyle';
import TenantContext from 'idserver/TenantContext';
import { getPaletteColorOptions } from 'general/helpers/ui/AppStyleHelper';

interface TenantThemeProviderProps {
  children: React.ReactNode
}

function TenantThemeProvider(props: TenantThemeProviderProps) {
  const { children } = props;
  const tenantContext = useContext(TenantContext);
  const appTheme = useMemo(() => {
    console.log('CreateMuiTheme by tenant');
    return createAppTheme({
      primary: {
        main: tenantContext.tenantTheme.primary ?? "#fff"
      },
      secondary: {
        main: tenantContext.tenantTheme.secondary ?? "#fff"
      },
      background: tenantContext.tenantTheme.background,
      portalBackground: getPaletteColorOptions(tenantContext.tenantTheme.portalBackground),
      error: {
        main: tenantContext.tenantTheme.error ?? "#fff"
      },
      warning: {
        main: tenantContext.tenantTheme.warning ?? "#fff"
      },
      info: {
        main: tenantContext.tenantTheme.info ?? "#fff"
      },
      
      success: {
        main: tenantContext.tenantTheme.success ?? "#fff"
      },
      //success: getPaletteColorOptions(tenantContext.tenantTheme.success, 0.9),
    });
  }, [tenantContext.tenantTheme]);

  return (
    <ThemeProvider theme={appTheme}>
      {children}
    </ThemeProvider>
  );
}

export default TenantThemeProvider;