import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Paper, withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';
import { useScroll } from 'general/helpers/ui/ScrollHelper';
import { Row, TableInstance } from 'react-table';
import StringHelper from 'general/helpers/types/StringHelper';
import BaseState from 'general/models/BaseState';
import Routes from 'general/helpers/Routes';
import CompanyTenant from '../models/CompanyTenant';
import CompanyTenantsService from '../helpers/CompanyTenantsService';
import BoxedAlert from 'general/components/common/BoxedAlert';
import { RowCheckbox } from 'general/components/customDataGrid/styles/TableStyles';
import { BooleanFilterKey, CheckBoxColumnFilter, DateColumnFilter } from 'general/components/customDataGrid/filters';
import moment from 'moment';
import { right_Company_Tenants_Edit, useRight } from 'account/models/Rights';
import AccountContext from 'account/AccountContext';
import RouteContext from 'general/RouteContext';
import SnackbarAlert from 'general/components/common/SnackbarAlert';

export type SuccessTenantDeletedAlert = {
	visible: boolean;
	name?: string;
}

export type TenantsRouteState = {
	successTenantDeletedAlert?: SuccessTenantDeletedAlert;
}

interface TenantsProps extends WithStyles<typeof AppStyle> { }

function Tenants(props: TenantsProps) {
	const classes = props.classes;
	const { t } = useTranslation(['company']);
	const routeHistory = useHistory();
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
	const [tenantRows, setTenantRows] = useState(new Array<CompanyTenant>());
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [tableLoadPageIndex, setTableLoadPageIndex] = useState<boolean | undefined>();
	const [successTenantDeletedAlert, setSuccessTenantDeletedAlert] = useState<SuccessTenantDeletedAlert | undefined>(undefined);
	const [setScrollToY, raiseScrollToY] = useScroll();
	const tenantsService = useRef(new CompanyTenantsService());
	const accountContext = useContext(AccountContext);
	const hasTenantsEditRight = useRight(right_Company_Tenants_Edit, accountContext);

	useEffect(() => {
		console.log('Init load tenants');
		let savedState = routeHistory.location?.state as BaseState | undefined
		console.log('Tenants.savedState', savedState);

		if (savedState) {
			// Backup state
			const { scrollY } = savedState;

			// Clean up state
			savedState = undefined;
			routeHistory.replace(routeHistory.location.pathname, savedState);

			// Set saved state
			setScrollToY(scrollY);
			setTableLoadPageIndex(true);

			// Read RouteContext state
			const contextState = initRouteContext.current.routeStates[Routes.Portal.Company.Tenants] as TenantsRouteState;
			if (contextState) {
				// Set saved RouteContext state
				setSuccessTenantDeletedAlert(contextState?.successTenantDeletedAlert);
			}
		}

		// Load tenants
		getTenantsAsync(raiseScrollToY);

	}, [routeHistory, setScrollToY, raiseScrollToY]);

	async function getTenantsAsync(raiseScrollToY: () => void) {
		setErrorMessage('');
		setIsLoading(true);
		try {
			const tenants =
				await tenantsService.current.getTenants();
			setTenantRows(tenants);
		} catch (error) {
			console.error(error);
			setErrorMessage(error?.message ?? '');
		} finally {
			// Reset
			setIsLoading(false);
			setTableLoadPageIndex(undefined);
			raiseScrollToY();
		}
	}

	function saveCurrentRouteState() {
		const currentState = new BaseState();
		currentState.scrollY = window.scrollY;
		routeHistory.replace(routeHistory.location.pathname, currentState);
	}

	function rowClicked(row: Row<CompanyTenant>) {
		const selectedTenantId = row?.original?.tenantId ?? '';
		const selectedTenantName = row?.original?.name ?? '';

		if (!StringHelper.empty(selectedTenantId)) {
			// Save current state before redirect
			saveCurrentRouteState();

			// Redirect
			routeHistory.push(`${Routes.Portal.Company.TenantDetails}/${selectedTenantId}/${selectedTenantName}`);
		}
	}

	const tableOnAdd = (instance: TableInstance<CompanyTenant> | undefined) => () => {
		// Save current state before redirect
		saveCurrentRouteState();

		// Redirect
		routeHistory.push(Routes.Portal.Company.TenantCreate);
	};

	const tenantColumns = useMemo(() => {
		return [
			{
				Header: 'companyTenants',
				HideHeader: true,
				columns: [
					{
						Header: t('company:companyTenant:name'),
						accessor: 'name',
						width: 200,
					},
					{
						Header: t('company:companyTenant:subDomain'),
						accessor: 'subDomain',
						width: 160,
					},
					{
						Header: t('company:companyTenant:domain'),
						accessor: 'domain',
						width: 200,
					},
					{
						Header: t('company:companyTenant:createDate'),
						accessor: 'createDate',
						width: 180,
						Cell: ({ value }: any) =>
						(
							<>{moment(value as Date).format('L LT')}</>
						),
						Filter: DateColumnFilter,
					},
					{
						Header: t('company:companyTenant:isSignUpAllowed'),
						accessor: 'isSignUpAllowed',
						minWidth: 100,
						width: 110,
						align: 'center',
						Cell: ({ value }: any) =>
						(
							<RowCheckbox checked={value} color="default" className={classes.displayCheckbox} />
						),
						Filter: CheckBoxColumnFilter,
						filter: BooleanFilterKey,
					},
					{
						Header: t('company:companyTenant:isActive'),
						accessor: 'isActive',
						minWidth: 90,
						width: 100,
						align: 'center',
						Cell: ({ value }: any) =>
						(
							<RowCheckbox checked={value} color="default" className={classes.displayCheckbox} />
						),
						Filter: CheckBoxColumnFilter,
						filter: BooleanFilterKey,
					},
					{
						Header: t('company:companyTenant:description'),
						accessor: 'description',
						width: 350,
					},
				]
			},
		];
	}, [t, classes]);

	return (
		<div>
			<Grid container>
				<BoxedAlert message={errorMessage}
					container="gridItem" paddingBottom={1}
					severity="error" variant="filled" />

				<Grid item xs={12}>
					<Paper>
						<CustomDataGrid<CompanyTenant>
							name="companyTenants"
							isLoading={isLoading}
							columns={tenantColumns}
							data={tenantRows}
							loadLastPageIndex={tableLoadPageIndex}
							fixedTableHeader={true}
							onClick={rowClicked}
							onAdd={tableOnAdd}
							onAddDisabled={!hasTenantsEditRight}
						/>
					</Paper>
				</Grid>
			</Grid>

			<SnackbarAlert open={successTenantDeletedAlert?.visible ?? false} onClose={() => setSuccessTenantDeletedAlert(undefined)}
				message={
					<Trans i18nKey="company:tenants.successDeleted">
						Brand <strong>{{ name: successTenantDeletedAlert?.name }}</strong> deleted successfully!
					</Trans>}
				severity="success" />
		</div >
	);
}

export default withStyles(AppStyle)(Tenants);