import React, { useContext, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AppStyle from 'general/styles/AppStyle';
import ApiHelper from 'general/helpers/api/ApiHelper';
import UrlHelper from 'general/helpers/UrlHelper';
import { useTranslation } from 'react-i18next';
import Routes from 'general/helpers/Routes';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Copyright from 'general/components/app/Copyright';
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DefaultPaper from 'general/components/container/DefaultPaper';
import TenantContext from 'idserver/TenantContext';

interface LoginProps extends WithStyles<typeof AppStyle> { }

function Login(props: LoginProps) {
  const classes = props.classes;
  const { t, i18n } = useTranslation(['idserver', 'validation']);
  const [errorMessage, setErrorMessage] = useState('');
  const [authLoading, setAuthLoading] = useState(false);
  const [timeoutWaiting, setTimeoutWaiting] = useState(false);
  const [timeoutWaitText, setTimeoutWaitText] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const tenantContext = useContext(TenantContext);
  const { register, handleSubmit, errors } = useForm();

  async function onSubmit(data: any) {
    setErrorMessage('');
    const email = data.email;
    const password = data.password;

    try {
      setAuthLoading(true);

      let returnUrl = UrlHelper.getQueryVariable(window, 'returnUrl');
      returnUrl = fixIdentityServerBasePath(returnUrl);

      const apiHelper = new ApiHelper({ withCredentials: true });
      const resp = await apiHelper.Api.post('/apiidserver/authenticate',
        JSON.stringify({
          email,
          password,
          returnUrl
        }));

      if (resp.data?.ok ?? false) {
        window.location = resp.data.redirectUrl;
      } else {
        setErrorMessage('Unknown response!');
        setAuthLoading(false);
      }
    } catch (error) {
      if ((error?.response?.status ?? 0) === 401) {
        setErrorMessage(t(`idserver:login.error.${error?.response?.data?.code}`));
        const waitTimeInSeconds = error?.response?.data?.waitTimeInSeconds ?? 0;
        if (waitTimeInSeconds > 0) {
          wait(waitTimeInSeconds);
        }
      } else {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      }
      setAuthLoading(false);
    }
  };

  function fixIdentityServerBasePath(returnUrl: any): string {
    // Remove dublicated basePath
    // Issue: https://github.com/IdentityServer/IdentityServer4/issues/5201
    if (typeof returnUrl === 'string') {
      let appPath = UrlHelper.getApiApplicationRootPath();
      console.log('ApiApplicationRootPath', appPath);

      // Replace dublicate
      returnUrl = returnUrl.replace(appPath + appPath, appPath);

      // Return fixed url
      return returnUrl;
    }
    return '';
  }

  function wait(loginWaitTimeInSeconds: number) {
    if (loginWaitTimeInSeconds <= 0) {
      setTimeoutWaiting(false);
    } else {
      loginWaitTimeInSeconds = loginWaitTimeInSeconds - 1;
      setTimeoutWaiting(true);
      setTimeoutWaitText(`${t('idserver:login.labelWaitStill')} ${getWaitTimeText(loginWaitTimeInSeconds)}`)

      setTimeout(() => {
        wait(loginWaitTimeInSeconds);
      }, 1000) // run once in 1 second
    }
  };

  function getWaitTimeText(x: number) {
    const h = Math.floor(x / 3600);
    x = x - (h * 3600);

    const m = Math.floor(x / 60);
    x = x - (m * 60);

    const s = x;

    if (h > 0) {
      return h + 'h ' + m + 'm ' + s + 's';
    } else if (m > 0) {
      return m + 'm ' + s + 's';
    } else {
      return s + 's';
    }
  }

  function SignUpLink() {
    const isCompanySignUpAllowed = (tenantContext.tenant.clientConfig?.isCompanySignUpAllowed ?? false);
    const isConsumerSignUpAllowed = (tenantContext.tenant.clientConfig?.isConsumerSignUpAllowed ?? false);
    return (
      <>
        {isCompanySignUpAllowed || isConsumerSignUpAllowed ?
          <Link component={RouterLink} to={isCompanySignUpAllowed ? Routes.IdServer.SignUpCompany : Routes.IdServer.SignUp} variant="body2">
            {t('idserver:login.linkNoAccount')}
          </Link> : null}
      </>
    );
  }

  const valRequired = t('validation:required');
  const valMaxLengthExceeded = t('validation:maxLengthExceeded');
  const valMaxLength30 = i18n.t('validation:maxLength', { maxLength: 30 });
  return (
    <Container component="main" maxWidth="xs">
      <DefaultPaper>
        <Avatar className={classes.defaultAvatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('idserver:login.title')}
        </Typography>
        <form className={classes.defaultForm} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={t('idserver:login.labelEmail')}
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={register({ required: valRequired, maxLength: { value: 320, message: valMaxLengthExceeded } })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={t('idserver:login.labelPassword')}
            name="password"
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            inputRef={register({ required: valRequired, maxLength: { value: 30, message: valMaxLength30 } })}
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    tabIndex={-1}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={authLoading || timeoutWaiting}
            className={classes.defaultFormSubmitButton}
          >
            {authLoading ?
              t('loading') :
              timeoutWaiting ?
                timeoutWaitText :
                t('idserver:login.buttonLogin')}
          </Button>
        </form>

        {errorMessage !== '' ?
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="error">
                {errorMessage}
              </Alert>
              <br />
            </Grid>
          </Grid> : null}

        <Grid container>
          <Grid item xs>
            <Link component={RouterLink} to={Routes.IdServer.PasswordReset} variant="body2">
              {t('idserver:login.linkForgotPassword')}
            </Link>
          </Grid>
          <Grid item>
            <SignUpLink />
          </Grid>
        </Grid>

        {/* No secial company signUp needed. Automatically changed by tenant!
          <Grid container alignItems="flex-start" justify="flex-end" direction="row">
            <Grid item>
              <Link component={RouterLink} to={Routes.IdServer.SignUpCompany} variant="body2">
                {t('idserver:login.linkNoAccountCompany')}
              </Link>
            </Grid>
          </Grid>
          */}

      </DefaultPaper>
      <Box mt={6}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default withStyles(AppStyle)(Login);