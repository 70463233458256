//import i18next from 'i18next';
//import { i18next } from 'react-i18next';

import UrlHelper from "general/helpers/UrlHelper";

export const imageKey_thumbnail: string = "thumb";
export const imageKey_fullscreen: string = "full";
export const imageKey_original: string = "orig";

export const fileType_dir: string = "DIR"; // Directory
export const fileType_image: string = "IMAGE"; // Images like jpeg, png, ico etc.
export const fileType_bin: string = "BIN"; // Binarys like zip, exe etc.

export function formatFileSize(valueInKiloBytes: number | null | undefined): string {
	const factor = 1024;

	if (valueInKiloBytes === null || valueInKiloBytes === undefined) {
		return '';
	}

	if (valueInKiloBytes < factor) {
		return `${valueInKiloBytes} KB`
	}

	let unit = '';
	let value = 0.0;

	if (valueInKiloBytes >= (factor * factor * factor)) {
		unit = 'TB';
		value = valueInKiloBytes / (factor * factor * factor);
	} else if (valueInKiloBytes >= (factor * factor)) {
		unit = 'GB';
		value = valueInKiloBytes / (factor * factor);
	} else {
		unit = 'MB';
		value = valueInKiloBytes / factor;
	}

	// Round 2 decimals 
	value = Math.round((value + Number.EPSILON) * 100) / 100;

	// Todo: Format language conform
	//       Upgrade i18next >=21.3.0 Upgrade react-i18next?
	//			 Use Intl API: https://www.i18next.com/translation-function/formatting
	//const valueText = i18next.t('intlNumber', { val: value });
	const valueText = value;

	return `${valueText} ${unit}`
}

export function getApiUrlPortalFileShare() {
	return UrlHelper.getApiUrlPortal() + '/company/fileshare';
}