class NumberHelper {
  public static ConvertFloat(value: any, defaultValue: number): number {
      try {
          if (!value) return defaultValue;
          if ((typeof value === 'number')) {
              return value;
          } else if (typeof value === 'string') {
              const valueStr: string = value ?? '';
              if (valueStr.length <= 0) return defaultValue;
              return parseFloat(valueStr);
          }
          return defaultValue;
      } catch (error) {
          console.log("NumberHelper.ConvertFloat.Error", error);
          return defaultValue;
      }
  }

  public static ConvertInt(value: any, defaultValue: number): number {
    try {
        if (!value) return defaultValue;
        if ((typeof value === 'number')) {
            return parseInt(value.toString());
        } else if (typeof value === 'string') {
            const valueStr: string = value ?? '';
            if (valueStr.length <= 0) return defaultValue;
            return parseInt(valueStr);
        }
        return defaultValue;
    } catch (error) {
        console.log("NumberHelper.ConvertInt.Error", error);
        return defaultValue;
    }
}
}

export default NumberHelper;