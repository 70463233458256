import React, { ReactNode } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { RegisterOptions, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";

export enum InputDialogSelection {
  cancel = 0,
  ok = 1,
}

export interface InputDialogResult {
  buttonSelection: InputDialogSelection,
  inputText: string | undefined,
}

interface InputDialogProps {
  open: boolean;
  onClose: (dialogResult: InputDialogResult) => void;
  title?: ReactNode;
  content?: ReactNode;
  textFieldLabel?: ReactNode;
  textFieldDefaultValue?: string;
  textFieldRegisterOptions?: RegisterOptions;
  severity?: "error" | "success" | "info" | "warning";
  buttonCancelContent?: ReactNode;
  buttonOkContent?: ReactNode;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

function InputDialog(props: InputDialogProps) {
  const { open, onClose, title, content, textFieldLabel, textFieldDefaultValue, textFieldRegisterOptions, fullWidth, maxWidth } = props;
  let { severity, buttonCancelContent, buttonOkContent } = props;
  const { t } = useTranslation(['common']);
  const { register, trigger, getValues, errors } =
    useForm({
      defaultValues: {
        inputDialogText: textFieldDefaultValue,
      }
    });

  function cancelClicked() {
    onClose({
      buttonSelection: InputDialogSelection.cancel,
      inputText: undefined
    });
  }

  async function okClickedAsync() {
    if (!await trigger()) return;

    const data = getValues();

    onClose({
      buttonSelection: InputDialogSelection.ok,
      inputText: data?.inputDialogText
    });
  }

  return (
    <Dialog
      open={open}
      onClose={cancelClicked}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby="input-dialog-title"
      aria-describedby="input-dialog-description"
    >
      {title && !severity ?
        <DialogTitle id="input-dialog-title">{title}</DialogTitle> : null}

      <DialogContent dividers>
        {severity ?
          <Box paddingBottom={1}>
            <Alert severity={severity}>
              {title ?
                <AlertTitle>{title}</AlertTitle> : null}
              {content}
            </Alert>
          </Box> :
          <>
            {content ?
              <DialogContentText id="input-dialog-description">
                {content}
              </DialogContentText> : null}
          </>
        }

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              label={textFieldLabel}
              name="inputDialogText"
              inputRef={register(textFieldRegisterOptions)}
              error={!!errors.inputDialogText}
              helperText={errors.inputDialogText ? errors.inputDialogText.message : ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelClicked} variant="contained" color="default">
          {buttonCancelContent ? buttonCancelContent : t('common:button.cancel')}
        </Button>
        <Button onClick={okClickedAsync} variant="contained" color="default">
          {buttonOkContent ? buttonOkContent : t('common:button.ok')}
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export default InputDialog;