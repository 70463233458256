import React, { useEffect, useState } from 'react';
import ApiHelper from 'general/helpers/api/ApiHelper';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

function Logout() {
	const { t } = useTranslation(['idserver']);
	const [errorMessage, setErrorMessage] = useState('');
	const [isByeMessageVisible, setIsByeMessageVisible] = useState(false);
	const [signOutIFrameUrl, setSignOutIFrameUrl] = useState('');

	useEffect(() => {
		console.log('Init idserver logout');
		logMeOutAsync();
	}, []);

	async function logMeOutAsync() {
		try {
			const query = window.location.search;
			const logoutIdQuery = query && query.toLowerCase().indexOf('?logoutid=') === 0 && query;

			const apiHelper = new ApiHelper({ withCredentials: true });
			const resp = await apiHelper.Api.get(`/apiidserver/authenticate/logout${logoutIdQuery}`);

			if (resp.data?.signOutIFrameUrl) {
				setSignOutIFrameUrl(resp.data.signOutIFrameUrl);
			} else {
				console.log('idserver.logout', 'Unkonwn response!');
			}

			if (resp.data?.postLogoutRedirectUri) {
				window.location = resp.data.postLogoutRedirectUri;
			} else {
				setIsByeMessageVisible(true);
			}
		} catch (error) {
			console.error(error);
			setErrorMessage(error?.message ?? '');
		}
	};

	return (
		<div>
			{ /* <h2>{t('idserver:logout.title')}</h2>  *commented out -> flickers when logging out */}
			{errorMessage !== '' ? <div style={{ color: "red" }}>{errorMessage}</div> : null}
			{signOutIFrameUrl !== '' ? <iframe title="SignOut" width="0" height="0" className="signout" src={signOutIFrameUrl}></iframe> : null}
			{isByeMessageVisible ?
				<Box display="flex" justifyContent="center" p={2}>
					<Typography>{t('idserver:logout.byeMessage')}</Typography>
				</Box> : null}
		</div>
	);
}

export default Logout;