import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import RouteContext, { RouteContextType } from 'general/RouteContext';
import PortalPaper from 'general/components/container/PortalPaper';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Grid, Box, Tabs, Tab, Button, ButtonGroup, Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import TabPanel from 'general/components/container/TabPanel';
import FormDisplay from 'general/components/common/FormDisplay';
import ManageUser from 'manage/users/models/ManageUser';
import ManageUsersService from 'manage/users/helpers/ManageUsersService';
import CircularProgressBox from 'general/components/common/CircularProgressBox';
import moment from 'moment';
import UserEdit from 'manage/users/views/UserEdit';
import EditActionButtonsGroup from 'general/components/common/EditActionButtonsGroup';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import AlertDialog, { AlertDialogResult } from 'general/components/common/AlertDialog';
import Routes from 'general/helpers/Routes';
import { UsersRouteState } from 'manage/users/views/Users';
import UserGroups from 'manage/users/views/UserGroups';
import UserRights from 'manage/users/views/UserRights';
import UserPaperTitle, { UserPaperTitleOption } from 'manage/users/components/UserPaperTitle';
import BoxedAlert from 'general/components/common/BoxedAlert';
import AccountContext from 'account/AccountContext';
import {
	useRight,
	right_Manage_Users_Users_View, right_Manage_Users_Users_Edit, right_Manage_Users_Users_Delete,
	right_Manage_Users_Groups_View, right_Manage_Users_Rights_View
} from 'account/models/Rights';
import UserChangePasswordDialog from 'manage/users/components/UserChangePasswordDialog';
import { DialogResult } from 'general/components/common/ResponsiveDialog';
import ButtonGroupContainer from 'general/components/container/ButtonGroupContainer';
import AuthenticateManageService, { AuthenticateManageServiceOption } from 'idserver/helpers/AuthenticateManageService';
import FormDisplayCheckBox from 'general/components/common/FormDisplayCheckBox';

export enum UserDetailsTabs {
	user = 0,
	groups = 1,
	rights = 2
}

interface UserDetailsParams {
	subjectId: string;
	tab?: string;
}

export type SuccessUserCreatedAlert = {
	visible: boolean;
	username?: string;
}

export type UserDetailsRouteState = {
	successUserCreatedAlert?: SuccessUserCreatedAlert;
}

export interface UserDetailsProps extends WithStyles<typeof AppStyle> { }

function UserDetails(props: UserDetailsProps) {
	enum viewMode { view, edit }
	const classes = props.classes;
	const { t } = useTranslation(['manage', 'idserver']);
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
	const [isLoading, setIsLoading] = useState(true);
	const [deleteIsLoading, setDeleteIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [user, setUser] = useState<ManageUser | undefined>(undefined);
	const [currentViewMode, setCurrentViewMode] = useState(viewMode.view);
	const [showSuccessUserSaved, setShowSuccessUserSaved] = useState(false);
	const [successUserCreatedAlert, setSuccessUserCreatedAlert] = useState<SuccessUserCreatedAlert | undefined>(undefined);
	const [showSuccessUserChangedPwd, setShowSuccessUserChangedPwd] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);
	const [tabValue, setTabValue] = useState<UserDetailsTabs | undefined>(undefined);
	const routeHistory = useHistory();
	const usersService = useRef(new ManageUsersService());
	const { subjectId, tab } = useParams<UserDetailsParams>();
	const accountContext = useContext(AccountContext);
	const hasUsersViewRight = useRight(right_Manage_Users_Users_View, accountContext);
	const hasUsersEditRight = useRight(right_Manage_Users_Users_Edit, accountContext);
	const hasUsersDeleteRight = useRight(right_Manage_Users_Users_Delete, accountContext);
	const hasGroupsViewRight = useRight(right_Manage_Users_Groups_View, accountContext);
	const hasRightsViewRight = useRight(right_Manage_Users_Rights_View, accountContext);

	useEffect(() => {
		getUserAsync(subjectId, user, initRouteContext.current);

		// Show user created success message if redirect from userCreate view
		const contextState = initRouteContext.current.routeStates[Routes.Portal.Manage.UserDetails] as UserDetailsRouteState;
		if (contextState) {
			// Set saved RouteContext state
			setSuccessUserCreatedAlert(contextState?.successUserCreatedAlert);
		}

		// Clean up RouteContext state
		initRouteContext.current.clearRouteState(Routes.Portal.Manage.UserDetails);

		// Load default tab or by prameter
		console.log("tab param", tab);
		switch (tab) {
			case UserDetailsTabs[UserDetailsTabs.groups]:
				setTabValue(UserDetailsTabs.groups);
				break;
			case UserDetailsTabs[UserDetailsTabs.rights]:
				setTabValue(UserDetailsTabs.rights);
				break;
			default:
				setTabValue(UserDetailsTabs.user);
		}
	}, [subjectId, tab, user, initRouteContext]);

	async function getUserAsync(subjectId: string,
		currentUser: ManageUser | undefined, routeContext: RouteContextType) {
		if (!currentUser || currentUser?.subjectId !== subjectId) {
			setErrorMessage('');
			setIsLoading(true);
			try {
				console.log('Load user', subjectId);

				// Load user data
				const user = await usersService.current.getUser(subjectId);
				setUser(user);

				// Set HeaderText and display GoBackButton
				routeContext.setTitle(user?.getDisplayName(), true);

			} catch (error) {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			} finally {
				setIsLoading(false);
			}
		}
	}

	function handleTabChange(event: React.ChangeEvent<{}>, newValue: UserDetailsTabs) {
		setTabValue(newValue);
	}

	function editButtonClick() {
		setCurrentViewMode(viewMode.edit);
	}

	function deleteButtonClick() {
		setShowDeleteDialog(true);
	}

	async function handleDeleteDialog(dialogResult: AlertDialogResult) {
		// Close dialog
		setShowDeleteDialog(false);

		if (dialogResult === AlertDialogResult.yes) {
			setErrorMessage('');
			setDeleteIsLoading(true);
			try {
				await usersService.current.deleteUser(user?.subjectId ?? '');

				// Set specific state for success message
				const usersState: UsersRouteState = {};
				usersState.successUserDeletedAlert = {
					visible: true,
					username: user?.getDisplayName()
				};
				initRouteContext.current.setRouteState(Routes.Portal.Manage.Users, usersState);

				// Go back to users list
				routeHistory.goBack();

			} catch (error) {
				if ((error?.response?.status ?? 0) === 400) {
					setErrorMessage(t(`manage:userDetails.userTab.error.${error?.response?.data?.code}`));
				} else {
					console.error(error);
					setErrorMessage(error?.message ?? '');
				}
				setDeleteIsLoading(false);
			}
		}
	}

	async function handleChangePasswordDialogClose(
		dialogResult: DialogResult) {
		// Close dialog
		setShowChangePasswordDialog(false);

		if (dialogResult === DialogResult.ok) {
			// Show info
			setShowSuccessUserChangedPwd(true);
		}
	}

	async function loginAsUser() {
		try {
			setIsLoading(true);

			var authManageService = new AuthenticateManageService(AuthenticateManageServiceOption.manage);
			await authManageService.loginAs(subjectId, true, routeHistory);

		} catch (error) {
			const statusCode = (error?.response?.status ?? 0);
			if (statusCode === 400 || statusCode === 404) {
				setErrorMessage(t(`idserver:loginAs.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
			setIsLoading(false);
		}
	}

	function saved(changedUser: ManageUser) {
		setCurrentViewMode(viewMode.view);

		// Trigger user reload
		setUser(undefined);

		// Show info
		setShowSuccessUserSaved(true);
	}

	function canceled() {
		setCurrentViewMode(viewMode.view);
	}

	const signInDisabled = (!hasUsersEditRight || !(user?.isActive ?? false) || !(user?.isEmailVerified ?? false));
	function UserView() {
		return (
			<>
				<Box className={classes.portalPaperTitleBox}>
					<UserPaperTitle option={UserPaperTitleOption.user} />
					<EditActionButtonsGroup
						editMode={false}
						onEditButtonClick={editButtonClick}
						onDeleteButtonClick={deleteButtonClick}
						disabled={isLoading || deleteIsLoading}
						disabledEdit={!hasUsersEditRight}
						disabledDelete={!hasUsersDeleteRight}
					/>
				</Box>
				<Box className={classes.portalPaperContentBox}>
					<BoxedAlert message={errorMessage} severity="error" />
					{isLoading ?
						<CircularProgressBox py={5} /> :
						<Grid container spacing={2} className={classes.defaultFormGrid}>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageUser.email')} value={user?.email} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormDisplay label={t('manage:manageUser.givenName')} value={user?.givenName} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormDisplay label={t('manage:manageUser.familyName')} value={user?.familyName} />
							</Grid>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageUser.name')} value={user?.name} />
							</Grid>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageUser.phoneNumber')} value={user?.phoneNumber} />
							</Grid>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageUser.languageCode')} value={user?.languageCode} />
							</Grid>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageUser.roles')} value={user?.getRolesText(t)} />
							</Grid>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageUser.tenants')} value={user?.tenants} />
							</Grid>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageUser.createDate')} value={moment(user?.createDate as Date).format('L LT')} />
							</Grid>
							<Grid item xs={12}>
								<FormDisplay label={t('manage:manageUser.lastSignInDate')} value={user?.lastSignInDate ? moment(user?.lastSignInDate as Date).format('L LT') : undefined} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormDisplayCheckBox label={t('manage:manageUser.isActive')} checked={user?.isActive ?? false} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormDisplayCheckBox label={t('manage:manageUser.isEmailVerified')} checked={user?.isEmailVerified ?? false} />
							</Grid>
							<Grid item xs={12}>
								<ButtonGroupContainer>
									<ButtonGroup disableElevation variant="contained" color="default">
										{signInDisabled ?
											<Button variant="contained" color="default" onClick={loginAsUser}
												disabled={signInDisabled}>
												{t('manage:userDetails.userTab.buttonSignIn')}
											</Button> :
											<Tooltip title={`${t('manage:userDetails.userTab.buttonSignInTooltip', { username: user?.getDisplayName() ?? '' })}`}>
												<Button variant="contained" color="default" onClick={loginAsUser}
													disabled={signInDisabled}>
													{t('manage:userDetails.userTab.buttonSignIn')}
												</Button>
											</Tooltip>}
										<Button variant="contained" color="default" onClick={() => setShowChangePasswordDialog(true)} disabled={!hasUsersEditRight}>
											{t('manage:userDetails.userTab.buttonChangePassword')}
										</Button>
									</ButtonGroup>
								</ButtonGroupContainer>
							</Grid>
						</Grid>}
				</Box>
			</>
		);
	}

	return (
		<>
			{tabValue !== undefined ?
				<>
					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						variant="scrollable"
						scrollButtons="auto"
					>
						{hasUsersViewRight ?
							<Tab label={<div><PersonIcon style={{ verticalAlign: 'middle' }} /> {t('manage:userDetails.tabs.user')} </div>} value={UserDetailsTabs.user} /> : null}
						{hasGroupsViewRight ?
							<Tab label={<div><GroupIcon style={{ verticalAlign: 'middle' }} /> {t('manage:userDetails.tabs.groups')} </div>} value={UserDetailsTabs.groups} /> : null}
						{hasRightsViewRight ?
							<Tab label={<div><LockIcon style={{ verticalAlign: 'middle' }} /> {t('manage:userDetails.tabs.rights')} </div>} value={UserDetailsTabs.rights} /> : null}
					</Tabs>
					{hasUsersViewRight ?
						<TabPanel selectedValue={tabValue} value={UserDetailsTabs.user}>
							<Box mt={1}>
								<PortalPaper>
									{(currentViewMode === viewMode.view) ?
										<UserView /> :
										<UserEdit
											{...props}
											user={user}
											usersService={usersService.current}
											onSaved={saved}
											onCanceled={canceled} />}
								</PortalPaper>
							</Box>
						</TabPanel> : null}
					{hasGroupsViewRight ?
						<TabPanel selectedValue={tabValue} value={UserDetailsTabs.groups}>
							<Box mt={1}>
								<UserGroups
									{...props}
									user={user} />
							</Box>
						</TabPanel> : null}
					{hasRightsViewRight ?
						<TabPanel selectedValue={tabValue} value={UserDetailsTabs.rights}>
							<Box mt={1}>
								<UserRights
									{...props}
									user={user} />
							</Box>
						</TabPanel> : null}
				</> : null}

			<AlertDialog open={showDeleteDialog} onClose={handleDeleteDialog}
				title={t('manage:userDetails.userTab.deleteUserDialog.title')}
				content={
					<Trans i18nKey="manage:userDetails.userTab.deleteUserDialog.content">
						The user <strong>{{ username: user?.getDisplayName() }}</strong> is then completely deleted and cannot be restored.
					</Trans>}
				dialogType="delete" />

			<UserChangePasswordDialog
				open={showChangePasswordDialog}
				onClose={handleChangePasswordDialogClose}
				user={user}
				usersService={usersService.current} />

			<SnackbarAlert open={showSuccessUserSaved} onClose={() => setShowSuccessUserSaved(false)}
				message={t('manage:userDetails.userTab.successUserSaved')} severity="success" />

			<SnackbarAlert open={successUserCreatedAlert?.visible ?? false} onClose={() => setSuccessUserCreatedAlert(undefined)}
				message={
					<Trans i18nKey="manage:userDetails.userTab.successUserCreated">
						User <strong>{{ username: successUserCreatedAlert?.username }}</strong> created successfully!
				</Trans>}
				severity="success" />

			<SnackbarAlert open={showSuccessUserChangedPwd} onClose={() => setShowSuccessUserChangedPwd(false)}
				message={t('manage:userDetails.userTab.successUserChangedPassword')} severity="success" />
		</>
	);
}

export default withStyles(AppStyle)(UserDetails);