import BaseState from "general/models/BaseState";
import { CompanyUsersTabs } from "company/users/views/CompanyUsers";
import { RightDetailsTabs } from "company/users/views/RightDetails";

class CompanyUsersState {
  selectedTab: CompanyUsersTabs | undefined;
  users: BaseState | undefined;
  groups: BaseState | undefined;
  rights: BaseState | undefined;
}

class RightDetailsState {
  selectedTab: RightDetailsTabs | undefined;
  users: BaseState | undefined;
  groups: BaseState | undefined;
}

export default CompanyUsersState;
export { RightDetailsState };