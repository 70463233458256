import BaseRight, { BaseGroupHasRight, BaseUserHasRight } from "company/users/models//BaseRight";

class ManageRight extends BaseRight {

  constructor(dataRight?: any) {
    if (dataRight) {
      super(dataRight);
    } else {
      super();
    }
  }
}

class ManageUserHasRight extends BaseUserHasRight {

  constructor(dataUserRight?: any) {
    if (dataUserRight) {
      super(dataUserRight);
    } else {
      super();
    }
  }
}

class ManageGroupHasRight extends BaseGroupHasRight {

  constructor(dataGroupRight?: any) {
    if (dataGroupRight) {
      super(dataGroupRight);
    } else {
      super();
    }
  }
}

export default ManageRight;
export { ManageUserHasRight, ManageGroupHasRight };