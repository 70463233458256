export enum HorizontalAlignment {
  left,
  center,
  right, 
}

export function getJustifyContentValue(align: HorizontalAlignment | undefined) {
  switch(align ?? HorizontalAlignment.left) {
    case HorizontalAlignment.center: return 'center';
    case HorizontalAlignment.right: return 'flex-end';
    default: return 'flex-start';
  }
}