class BoolHelper {
    public static Convert(value: any, defaultValue: boolean): boolean {
        try {
            if (!value) return defaultValue;
            if ((typeof value === 'boolean')) {
                return value;
            } else if (typeof value === 'string') {
                const valueStr: string = value ?? '';
                if (valueStr.length <= 0) return defaultValue;
                if (valueStr.toLowerCase() === 'true') return true;
                if (valueStr.toLowerCase() === 'false') return false;
                if (valueStr.toLowerCase() === '1') return true;
                if (valueStr.toLowerCase() === '-1') return true;
                if (valueStr.toLowerCase() === '0') return false;
            } else if (typeof value === 'number') {
                const valueNum: number = value;
                if (valueNum === 1 || valueNum === -1) return true;
                else return false;
            }
            return defaultValue;
        } catch (error) {
            console.log("BoolHelper.Convert.Error", error);
            return defaultValue;
        }
    }
}

export default BoolHelper;