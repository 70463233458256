import { Chip, createStyles, makeStyles } from '@material-ui/core'
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons'
import { TFunction } from 'i18next'
import React, { useCallback } from 'react'
import { ColumnInstance, FilterValue, IdType, TableInstance } from 'react-table'
import { BooleanFilterKey, getFilterSelectOptions, SelectFilterKey } from './filters'

const useStyles = makeStyles(
  createStyles({
    filtersActiveLabel: {
      color: '#998',
      fontSize: '14px',
      paddingRight: 10,
    },
    chipZone: {
      padding: '5px 6px 5px 18px',
      width: '100%',
    },
    chipLabel: {
      fontWeight: 500,
      marginRight: 5,
    },
    filterChip: {
      marginRight: 4,
      color: '#222',
    },
    filterCheckBoxIcon: {
      width: '15px',
      height: '15px',
      padding: '0px',
      margin: '0px 0px -3px 0px',
    }
  })
)

type FilterChipBar<T extends object> = {
  instance: TableInstance<T>;
  translation?: TFunction;
}

const getFilterValue = (column: ColumnInstance<any>, filterValue: FilterValue, classes: any) => {
  switch (column.filter) {
    case 'between':
      const min = filterValue[0];
      const max = filterValue[1];
      return min ? (max ? `${min}-${max}` : `>=${min}`) : `<=${max}`;
    case BooleanFilterKey:
      return (filterValue ?? false) ?
        <CheckBoxOutlined className={classes.filterCheckBoxIcon} /> :
        <CheckBoxOutlineBlankOutlined className={classes.filterCheckBoxIcon} />;
    case SelectFilterKey:
      const filterOptions = getFilterSelectOptions(column);
      if (filterOptions && filterOptions.options && filterOptions.useDisplayValue) {
        const selectedOption = filterOptions.options.find(option => option.value === filterValue);
        if (selectedOption) {
          return selectedOption.displayValue;
        }
      }
  }
  return filterValue;
}

export function FilterChipBar<T extends object>(
  {
    instance,
    translation,
  }: FilterChipBar<T>) {
  const t = translation;
  const classes = useStyles({})
  const {
    allColumns,
    setFilter,
    state: { filters },
  } = instance
  const handleDelete = useCallback(
    (id: string | number) => {
      setFilter(id as IdType<T>, undefined)
    },
    [setFilter]
  )

  return Object.keys(filters).length > 0 ? (
    <div className={classes.chipZone}>
      <span className={classes.filtersActiveLabel}>
        {t ? t('common:customDataGrid.filterChipBar.labelActiveFilters') : 'Active filters'}
      </span>
      {filters &&
        allColumns.map((column) => {
          const filter = filters.find((f) => f.id === column.id);
          const value = filter && filter.value;
          const hasFilter = (filter?.value !== undefined);
          return (
            hasFilter && (
              <Chip
                className={classes.filterChip}
                key={column.id}
                label={
                  <>
                    <span className={classes.chipLabel}>{column.render('Header')}: </span>
                    {getFilterValue(column, value, classes)}
                  </>
                }
                onDelete={() => handleDelete(column.id)}
                variant='outlined'
              />
            )
          )
        })}
    </div>
  ) : null
}