import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PortalPaper from 'general/components/container/PortalPaper';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Grid, Box, TextField, Button, } from '@material-ui/core';
import CompanyGroup from 'company/users/models/CompanyGroup';
import CompanyGroupsService from 'company/users/helpers/CompanyGroupsService';
import BoxedAlert from 'general/components/common/BoxedAlert';
import RouteContext from 'general/RouteContext';
import { GroupDetailsRouteState } from 'company/users/views/GroupDetails';
import GroupPaperTitle, { GroupPaperTitleOption } from 'company/users/components/GroupPaperTitle';
import Routes from 'general/helpers/Routes';

export interface GroupCreateProps extends WithStyles<typeof AppStyle> { }

function GroupCreate(props: GroupCreateProps) {
	const classes = props.classes;
	const { t } = useTranslation(['common', 'company']);
	const [createIsLoading, setCreateIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const routeHistory = useHistory();
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
	const groupsService = useRef(new CompanyGroupsService());
	const { register, handleSubmit, errors } =
		useForm({
			defaultValues: {
				groupName: '',
			}
		});

	useEffect(() => {
	}, [initRouteContext]);

	async function onSubmit(data: any) {
		setErrorMessage(undefined);
		const newGroup = new CompanyGroup();
		newGroup.groupOrder = 0;
		newGroup.groupName = data.groupName;

		try {
			setCreateIsLoading(true);

			// Send data
			const createdGroup = await groupsService.current.createGroup(newGroup);

			// Set specific state for success message
			const routeState: GroupDetailsRouteState = {
				successGroupCreatedAlert: {
					visible: true,
					groupName: createdGroup?.groupName
				}
			};
			initRouteContext.current.setRouteState(Routes.Portal.Company.GroupDetails, routeState);

			//Redirect to newly created group with REPLACE to remove createGroup route for goBack navigation in groupDetails view
			routeHistory.replace(`${Routes.Portal.Company.GroupDetails}/${createdGroup.groupId}`);
		} catch (error) {
			if ((error?.response?.status ?? 0) === 400) {
				setErrorMessage(t(`company:groupCreate.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
			setCreateIsLoading(false);
		};
	}

	const valRequired = t('validation:required');
	const valMaxLength256 = t('validation:maxLength', { maxLength: 256 });
	return (
		<PortalPaper>
			<Box className={classes.portalPaperTitleBox}>
				<GroupPaperTitle option={GroupPaperTitleOption.basicData} />
			</Box>
			<Box className={classes.portalPaperContentBox}>
				<form className={classes.defaultForm} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2} className={classes.defaultFormGrid}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('company:companyGroup.groupName')}
								name="groupName"
								autoComplete="groupName"
								inputRef={register({ required: valRequired, maxLength: { value: 256, message: valMaxLength256 } })}
								error={!!errors.groupName}
								helperText={errors.groupName ? errors.groupName.message : ''}
							/>
						</Grid>
						<BoxedAlert message={errorMessage} severity="error" container="gridItem" />
						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={createIsLoading}
							>
								{createIsLoading ?
									t('loading') :
									t('common:button.create')}
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button fullWidth color="default" onClick={() => routeHistory.goBack()}>
								{t('common:button.cancel')}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</PortalPaper>
	);
}

export default withStyles(AppStyle)(GroupCreate);