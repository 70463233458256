import { Button, IconButton, Theme, Toolbar, Tooltip, createStyles, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/CreateOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import FilterListIcon from '@material-ui/icons/FilterList'
import ViewColumnsIcon from '@material-ui/icons/ViewColumn'
import classnames from 'classnames'
import React, { MouseEvent, MouseEventHandler, PropsWithChildren, ReactElement, useCallback, useState } from 'react'
import { TableInstance } from 'react-table'

import { TableMouseEventHandler } from './types/react-table-config'
import { ColumnHidePage } from './ColumnHidePage'
import { FilterPage } from './FilterPage'
import { SelectorColumnId } from './CustomDataGrid'
import { TFunction } from 'i18next'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftButtons: {},
    rightButtons: {},
    leftIcons: {
      padding: 12,
      marginTop: '-2px',
      width: 48,
      height: 48,
      '&:first-of-type': {
        marginLeft: -12,
      },
    },
    rightIcons: {
      padding: 12,
      marginTop: '-2px',
      width: 48,
      height: 48,
      '&:last-of-type': {
        marginRight: -12,
      },
    },
  })
)

type InstanceActionButton<T extends object> = {
  instance?: TableInstance<T>
  icon?: JSX.Element
  onClick: TableMouseEventHandler
  enabled?: (instance: TableInstance<T> | undefined) => boolean
  label: string
  variant?: 'right' | 'left'
}

type ActionButton<T extends object> = {
  icon?: JSX.Element
  onClick: MouseEventHandler
  enabled?: boolean
  label: string
  variant?: 'right' | 'left'
}

export const InstanceLabeledActionButton = <T extends object>({
  instance,
  icon,
  onClick,
  label,
  enabled = () => true,
}: InstanceActionButton<T>): ReactElement => {
  return (
    <Button variant='contained' color='primary' onClick={onClick(instance)} disabled={!enabled(instance)}>
      {icon}
      {label}
    </Button>
  )
}

export const LabeledActionButton = <T extends object>({
  icon,
  onClick,
  label,
  enabled = true,
}: ActionButton<T>): ReactElement => {
  return (
    <Button variant='contained' color='primary' onClick={onClick} disabled={!enabled}>
      {icon}
      {label}
    </Button>
  )
}

export const InstanceSmallIconActionButton = <T extends object>({
  instance,
  icon,
  onClick,
  label,
  enabled = () => true,
  variant,
}: InstanceActionButton<T>) => {
  const classes = useStyles({})
  return (
    <Tooltip title={label} aria-label={label}>
      <span>
        <IconButton
          className={classnames({ [classes.rightIcons]: variant === 'right', [classes.leftIcons]: variant === 'left' })}
          onClick={onClick(instance)}
          disabled={!enabled(instance)}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  )
}

export const SmallIconActionButton = <T extends object>({
  icon,
  onClick,
  label,
  enabled = true,
  variant,
}: ActionButton<T>) => {
  const classes = useStyles({})
  return (
    <Tooltip title={label} aria-label={label}>
      <span>
        <IconButton
          className={classnames({ [classes.rightIcons]: variant === 'right', [classes.leftIcons]: variant === 'left' })}
          onClick={onClick}
          disabled={!enabled}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  )
}

type TableToolbar<T extends object> = {
  instance: TableInstance<T>;
  translation?: TFunction;
  onAdd?: TableMouseEventHandler;
  onDelete?: TableMouseEventHandler;
  onEdit?: TableMouseEventHandler;
  onAddDisabled?: boolean;
  onDeleteDisabled?: boolean;
  onEditDisabled?: boolean;
}

export function TableToolbar<T extends object>({
  instance,
  translation,
  onAdd,
  onDelete,
  onEdit,
  onAddDisabled,
  onDeleteDisabled,
  onEditDisabled
}: PropsWithChildren<TableToolbar<T>>): ReactElement | null {
  const { columns } = instance;
  const t = translation;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const hideableColumns = columns.filter((column) => !(column.id === SelectorColumnId));

  const handleColumnsClick = useCallback(
    (event: MouseEvent) => {
      setAnchorEl(event.currentTarget)
      setColumnsOpen(true)
    },
    [setAnchorEl, setColumnsOpen]
  )

  const handleFilterClick = useCallback(
    (event: MouseEvent) => {
      setAnchorEl(event.currentTarget)
      setFilterOpen(true)
    },
    [setAnchorEl, setFilterOpen]
  )

  const handleClose = useCallback(() => {
    setColumnsOpen(false)
    setFilterOpen(false)
    setAnchorEl(undefined)
  }, [])

  // toolbar with add, edit, delete, filter/search column select.
  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.leftButtons}>
        {onAdd && (
          <InstanceSmallIconActionButton<T>
            instance={instance}
            icon={<AddIcon />}
            onClick={onAdd}
            label={t ? t('common:customDataGrid.toolbar.add') : 'Add'}
            enabled={(inst: TableInstance<T> | undefined) =>
              ((!inst?.state.selectedRowIds || Object.keys(inst?.state.selectedRowIds).length === 0) ?? false) &&
              (!(onAddDisabled ?? false))
            }
            variant='left'
          />
        )}
        {onEdit && (
          <InstanceSmallIconActionButton<T>
            instance={instance}
            icon={<CreateIcon />}
            onClick={onEdit}
            label={t ? t('common:customDataGrid.toolbar.edit') : 'Edit'}
            enabled={(inst: TableInstance<T> | undefined) =>
              ((inst?.state.selectedRowIds && Object.keys(inst?.state.selectedRowIds).length === 1) ?? false) &&
              (!(onEditDisabled ?? false))
            }
            variant='left'
          />
        )}
        {onDelete && (
          <InstanceSmallIconActionButton<T>
            instance={instance}
            icon={<DeleteIcon />}
            onClick={onDelete}
            label={t ? t('common:customDataGrid.toolbar.delete') : 'Delete'}
            enabled={(inst: TableInstance<T> | undefined) =>
              ((inst?.state.selectedRowIds && Object.keys(inst?.state.selectedRowIds).length > 0) ?? false) &&
              (!(onDeleteDisabled ?? false))
            }
            variant='left'
          />
        )}
      </div>
      <div className={classes.rightButtons}>
        <ColumnHidePage<T> instance={instance} onClose={handleClose} show={columnsOpen} anchorEl={anchorEl} translation={t} />
        <FilterPage<T> instance={instance} onClose={handleClose} show={filterOpen} anchorEl={anchorEl} translation={t} />
        {hideableColumns.length > 0 && (
          <SmallIconActionButton<T>
            icon={<ViewColumnsIcon />}
            onClick={handleColumnsClick}
            label={t ? t('common:customDataGrid.toolbar.showHideColumns') : 'Show / hide columns'}
            variant='right'
          />
        )}
        <SmallIconActionButton<T>
          icon={<FilterListIcon />}
          onClick={handleFilterClick}
          label={t ? t('common:customDataGrid.toolbar.filterByColumns') : 'Filter by columns'}
          variant='right'
        />
      </div>
    </Toolbar>
  )
}

type TableToolbarNoData<T extends object> = {
  translation?: TFunction;
  onAdd?: TableMouseEventHandler;
  onAddDisabled?: boolean;
}

export function TableToolbarNoData<T extends object>({
  translation,
  onAdd,
  onAddDisabled,
}: PropsWithChildren<TableToolbarNoData<T>>): ReactElement | null {
  const t = translation;
  const classes = useStyles();

  return (
    <>
      {onAdd && (
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftButtons}>
            <InstanceSmallIconActionButton<T>
              instance={undefined}
              icon={<AddIcon />}
              onClick={onAdd}
              label={t ? t('common:customDataGrid.toolbar.add') : 'Add'}
              enabled={(inst: TableInstance<T> | undefined) => !(onAddDisabled ?? false)}
              variant='left'
            />
          </div>
        </Toolbar>
      )}
    </>);
}