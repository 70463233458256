import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Box, FormControl, InputLabel, Select, MenuItem, makeStyles, createStyles, Theme, Chip, Button, ButtonGroup } from '@material-ui/core';
import TenantPaperTitle, { TenantPaperTitleOption } from 'company/tenants/components/TenantPaperTitle';
import { useRight, right_Company_Tenants_Edit } from 'account/models/Rights';
import AccountContext from 'account/AccountContext';
import { TenantDetailsProps } from './TenantDetails';
import BoxedAlert from 'general/components/common/BoxedAlert';
import CompanyTenant from '../models/CompanyTenant';
import PortalPaper from 'general/components/container/PortalPaper';
import InputActionButtonsGroup from 'general/components/common/InputActionButtonsGroup';
import CompanyTenantTheme from '../models/CompanyTenantTheme';
import CompanyTenantThemesService from '../helpers/CompanyTenantThemesService';
import FormDisplay, { FormDisplayType } from 'general/components/common/FormDisplay';
import { ViewMode } from 'general/models/ViewMode';
import TenantDetailsDesignEdit from './TenantDetailsDesignEdit';
import SnackbarAlert from 'general/components/common/SnackbarAlert';
import AlertDialog, { AlertDialogResult } from 'general/components/common/AlertDialog';
import InputDialog, { InputDialogResult, InputDialogSelection } from 'general/components/common/InputDialog';
import CompanyTenantsService from '../helpers/CompanyTenantsService';
import TenantContext, { TenantTheme } from 'idserver/TenantContext';
import ButtonGroupContainer from 'general/components/container/ButtonGroupContainer';

export interface TenantDetailsDesignProps extends TenantDetailsProps {
  tenant: CompanyTenant | undefined;
  onTenantSelectedThemeChanged: (selectedThemeId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeFormControl: {
      width: '100%',
    },
  }),
);

function TenantDetailsDesign(props: TenantDetailsDesignProps) {
  const { classes, tenant, onTenantSelectedThemeChanged } = props;
  const classesElement = useStyles();
  const { t } = useTranslation(['company']);
  const accountContext = useContext(AccountContext);
  const tenantContext = useContext(TenantContext);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [themes, setThemes] = useState<CompanyTenantTheme[] | undefined>(undefined);
  const [theme, setTheme] = useState<CompanyTenantTheme | undefined>(undefined);
  const [selectedThemeId, setSelectedThemeId] = useState<string>('');
  const [currentViewMode, setCurrentViewMode] = useState(ViewMode.view);
  const [showSuccessThemeSaved, setShowSuccessThemeSaved] = useState(false);
  const [showSuccessThemeDeleted, setShowSuccessThemeDeleted] = useState(false);
  const [showSuccessSelectedTheme, setShowSuccessSelectedTheme] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showNameInputDialog, setShowNameInputDialog] = useState(false);
  const [copyTheme, setCopyTheme] = useState<CompanyTenantTheme | undefined>(undefined);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const loadedTenantId = useRef('');
  const deletedThemeName = useRef('');
  const tenantThemesService = useRef(new CompanyTenantThemesService());
  const hasTenantsEditRight = useRight(right_Company_Tenants_Edit, accountContext);

  useEffect(() => {
    const tenantId = tenant?.tenantId ?? '';

    // Init service
    if (tenantThemesService.current.tenantId === undefined) {
      console.log('Init themes service');
      tenantThemesService.current.tenantId = tenantId;
    }

    // Load themes for tenant
    getThemesAsync(tenant, loadedTenantId.current);
    loadedTenantId.current = tenantId;

  }, [tenant]);

  async function getThemesAsync(tenant: CompanyTenant | undefined,
    currentTenantId: string, selectThemeId?: string) {
    if (tenant?.tenantId !== currentTenantId) {
      setErrorMessage('');
      setIsLoading(true);
      try {
        console.log('Load themes', tenant?.tenantId);
        const themes = await tenantThemesService.current.getThemes();
        setThemes(themes);

        // Select the theme chosen by the tenant
        if (themes && themes.length > 0) {
          selectThemeId = selectThemeId ?? tenant?.selectedThemeId ?? '';
          setSelectedThemeId(selectThemeId);
          setTheme(themes.find((t) => (t.themeId === selectThemeId)));
        }

      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function getThemeAsync(themeId: string | undefined,
    currentTheme?: CompanyTenantTheme | undefined) {
    if (!currentTheme || currentTheme?.themeId !== themeId) {
      setErrorMessage('');
      setIsLoading(true);
      try {
        console.log('Load theme', themeId);
        const theme = await tenantThemesService.current.getTheme(themeId ?? '');
        setTheme(theme);
      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleDeleteDialog(dialogResult: AlertDialogResult) {
    // Close dialog
    setShowDeleteDialog(false);

    if (dialogResult === AlertDialogResult.yes) {
      if (!theme || !themes) return;
      setErrorMessage('');
      setIsLoading(true);
      try {
        deletedThemeName.current = theme.name ?? '';
        await tenantThemesService.current.deleteTheme(theme.themeId ?? '');

        // Show success message
        setShowSuccessThemeDeleted(true);

        // First select default theme => Otherwise loading themes would throw an error
        const defaultTheme = getDefaultTheme();
        setSelectedThemeId(defaultTheme?.themeId ?? '');

        // Load themes without the deleted theme
        await getThemesAsync(tenant, ''); // empty string triggers reload for current tenant

      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function nameInputDialogClosed(dialogResult: InputDialogResult) {
    // Close dialog
    setShowNameInputDialog(false);

    if (dialogResult.buttonSelection === InputDialogSelection.ok) {
      console.log('InputDialog ok', dialogResult.inputText);

      setErrorMessage('');
      setIsLoading(true);
      try {
        const templateTheme = copyTheme ? copyTheme : getDefaultTheme();
        if (!templateTheme) return;

        const newTheme = templateTheme.copyTheme(dialogResult.inputText ?? '');
        const createdTheme = await tenantThemesService.current.createTheme(newTheme);

        // Load themes with the new theme
        await getThemesAsync(tenant, '', createdTheme.themeId ?? '');

        // Swich to editMode
        await editThemeAsync(createdTheme.themeId);

      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
        setIsLoading(false);
      }
    }

    // Reset copy
    setCopyTheme(undefined);
  }

  function getDefaultTheme(): CompanyTenantTheme | undefined {
    if (!themes) return undefined;
    return themes.find((t) => (t.isDefault ?? false));
  }

  function getTheme(themeId: string | undefined): CompanyTenantTheme | undefined {
    if (!themeId || !themes) return undefined;
    return themes.find((t) => (t.themeId === themeId));
  }

  function loadTheme(themeId: string | undefined) {
    const foundTheme = getTheme(themeId);
    setTheme(foundTheme);
  }

  function handleAddTheme() {
    console.log('handleAddTheme');
    setShowNameInputDialog(true);
  }

  function handleEditTheme() {
    console.log('handleEditTheme', theme?.name);
    editThemeAsync();
  }

  async function editThemeAsync(editThemeId?: string) {
    // Reload theme first => may have changed in the meantime
    await getThemeAsync(editThemeId ?? selectedThemeId);

    // Switch to edit mode with fresh loaded theme
    setCurrentViewMode(ViewMode.edit);
  }

  function handleSavedTheme(saved: boolean) {
    console.log('handleSavedTheme', saved);
    setCurrentViewMode(ViewMode.view);
    exitPreviewTheme();
    if (saved) {
      setShowSuccessThemeSaved(true);

      // Load themes with changes
      getThemesAsync(tenant, '', selectedThemeId);
    }
  }

  function handleCopyTheme() {
    console.log('handleCopyTheme');
    setCopyTheme(theme);
    setShowNameInputDialog(true);
  }

  function handleDeleteTheme() {
    console.log('handleDeleteTheme');
    setShowDeleteDialog(true);
  }

  function handleThemeChange(e: any) {
    const themeId = e?.target?.value;
    console.log('handleThemeChange', themeId);
    exitPreviewTheme();
    setSelectedThemeId(themeId);
    loadTheme(themeId);
  }

  async function selectThemeClicked() {
    console.log('selectThemeClicked');
    setIsLoading(true);
    setErrorMessage('');
    try {
      const tenantsService = new CompanyTenantsService();
      await tenantsService.updateTenantSelectedTheme(tenant?.tenantId ?? '', selectedThemeId)

      // Refresh tenant and selected theme
      onTenantSelectedThemeChanged(selectedThemeId);

      // Show info
      setShowSuccessSelectedTheme(true);

    } catch (error) {
      console.error(error);
      setErrorMessage(error?.message ?? '');
    };
    setIsLoading(false);
  }

  function previewThemeClicked() {
    setIsPreviewMode(true);
    tenantContext.setTenantTheme({
      primary: theme?.primaryColor,
      secondary: theme?.secondaryColor,
      portalBackground: theme?.portalBackgroundColor,
    } as TenantTheme);
  }

  function exitPreviewTheme() {
    if (isPreviewMode) {
      setIsPreviewMode(false);
      tenantContext.resetTenantTheme();
    }
  }

  function ChipIsPublic() {
    const transKey = (theme?.isPublic ?? true) ?
      'company:tenantDetails.designTab.chipIsPublicTheme.public' :
      'company:tenantDetails.designTab.chipIsPublicTheme.notPublic';
    return (
      <Chip label={t(transKey)} variant="outlined" style={{ marginRight: '3px' }} />
    );
  }

  function ChipIsSelected() {
    return (
      (tenant?.selectedThemeId === theme?.themeId) ?
        <Chip label={t('company:tenantDetails.designTab.chipSelectedTheme.selected')} color="primary" /> :
        <Chip label={t('company:tenantDetails.designTab.chipSelectedTheme.notSelected')} variant="outlined" />
    );
  }

  const valRequired = t('validation:required');
  const valMaxLengthExceeded = t('validation:maxLengthExceeded');
  return (
    <>
      <Box mt={1}>
        <PortalPaper>
          <Box className={classes.portalPaperTitleBox}>
            <TenantPaperTitle option={TenantPaperTitleOption.design} />
          </Box>
          <Box className={classes.portalPaperContentBox}>
            <BoxedAlert message={errorMessage} severity="error" />
            <Grid container spacing={2} className={classes.defaultFormGrid}>
              {(currentViewMode === ViewMode.view) ?
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classesElement.themeFormControl}>
                      <InputLabel id="themeLabel">{t('company:tenantDetails.designTab.themesSelectionLabel')}</InputLabel>
                      <Select
                        labelId="themeLabel"
                        id="themeSelect"
                        value={selectedThemeId}
                        onChange={handleThemeChange}
                        label={t('company:tenantDetails.designTab.themesSelectionLabel')}
                      >
                        {themes ?
                          themes.map((option, i) => (
                            <MenuItem key={i} value={option.themeId}>
                              {option.name}
                            </MenuItem>
                          )) :
                          <MenuItem value="" />}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputActionButtonsGroup
                      editMode={false}
                      onAddButtonClick={handleAddTheme}
                      onEditButtonClick={handleEditTheme}
                      onCopyButtonClick={handleCopyTheme}
                      onDeleteButtonClick={handleDeleteTheme}
                      disabled={isLoading}
                      disabledAdd={!hasTenantsEditRight}
                      disabledEdit={!hasTenantsEditRight || (theme?.isPublic ?? true)}
                      disabledCopy={!hasTenantsEditRight}
                      disabledDelete={!hasTenantsEditRight || (theme?.isPublic ?? true) || (tenant?.selectedThemeId === theme?.themeId)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormDisplay label={t('company:companyTenantTheme.name')} value={theme?.name} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormDisplay label={t('company:companyTenantTheme.primaryColor')} value={theme?.primaryColor} displayType={FormDisplayType.colorAndText} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormDisplay label={t('company:companyTenantTheme.secondaryColor')} value={theme?.secondaryColor} displayType={FormDisplayType.colorAndText} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormDisplay label={t('company:companyTenantTheme.portalBackgroundColor')} value={theme?.portalBackgroundColor} displayType={FormDisplayType.colorAndText} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <ChipIsPublic />
                        <ChipIsSelected />
                      </Grid>
                      <Grid item xs={12}>
                        <ButtonGroupContainer>
                          <ButtonGroup disableElevation variant="contained" color="default">
                            {isPreviewMode ?
                              <Button variant="contained" color="default"
                                onClick={exitPreviewTheme}>
                                {t('company:tenantDetails.designTab.buttonExitPreviewTheme')}
                              </Button> :
                              <Button variant="contained" color="default"
                                onClick={previewThemeClicked}>
                                {t('company:tenantDetails.designTab.buttonPreviewTheme')}
                              </Button>}
                            {(tenant?.selectedThemeId !== theme?.themeId) ?
                              <Button variant="contained" color="default"
                                onClick={selectThemeClicked}>
                                {t('company:tenantDetails.designTab.buttonSelectTheme')}
                              </Button> : null}
                          </ButtonGroup>
                        </ButtonGroupContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </> :
                <TenantDetailsDesignEdit
                  {...props}
                  theme={theme}
                  tenantThemesService={tenantThemesService.current}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setErrorMessage={setErrorMessage}
                  onSaved={handleSavedTheme}
                />}
            </Grid>
          </Box>
        </PortalPaper>
      </Box>

      <SnackbarAlert open={showSuccessThemeSaved} onClose={() => setShowSuccessThemeSaved(false)}
        message={t('company:tenantDetails.designTab.successSaved')} severity="success" />

      <SnackbarAlert open={showSuccessSelectedTheme} onClose={() => setShowSuccessSelectedTheme(false)}
        message={t('company:tenantDetails.designTab.successSelectedTheme')} severity="success" />

      <SnackbarAlert open={showSuccessThemeDeleted} onClose={() => setShowSuccessThemeDeleted(false)}
        message={
          <Trans i18nKey="company:tenantDetails.designTab.successDeleted">
            Design <strong>{{ name: deletedThemeName.current }}</strong> deleted successfully!
          </Trans>} severity="success" />

      <AlertDialog open={showDeleteDialog} onClose={handleDeleteDialog}
        title={t('company:tenantDetails.designTab.deleteDialog.title')}
        content={
          <Trans i18nKey="company:tenantDetails.designTab.deleteDialog.content">
            The design <strong>{{ name: theme?.name }}</strong> is then completely deleted and cannot be restored.
          </Trans>}
        dialogType="delete" />

      <InputDialog open={showNameInputDialog} onClose={nameInputDialogClosed}
        title={copyTheme ?
          `${t('company:tenantDetails.designTab.copyThemeNameTag')} ${copyTheme.name}` :
          t('company:tenantDetails.designTab.nameInputDialog.title')}
        textFieldLabel={t('company:companyTenantTheme.name')}
        textFieldRegisterOptions={{
          required: valRequired,
          maxLength: { value: 100, message: valMaxLengthExceeded }
        }}
        fullWidth={true}
        maxWidth='sm' />
    </>
  );
}

export default TenantDetailsDesign;