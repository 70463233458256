import BaseUser from "./BaseUser";

class CompanyUser extends BaseUser {

  constructor(dataUser?: any) {
    if (dataUser) {
      super(dataUser);
    } else {
      super();
    }
  }
}

class CompanyUserCreateRequest extends CompanyUser {
  password: string | undefined;
  passwordRepeat: string | undefined;
}


class CompanyUserChangePasswordRequest {
  subjectId: string | undefined;
  password: string | undefined;
  passwordRepeat: string | undefined;
}

class CompanyRightUser extends CompanyUser {
  isAllowed: boolean | null | undefined;

  constructor(dataRightUser?: any) {
    super(dataRightUser);
    if (dataRightUser) {
      this.isAllowed = dataRightUser.isAllowed;
    }
  }
}

export default CompanyUser;
export { CompanyUserCreateRequest, CompanyUserChangePasswordRequest, CompanyRightUser };