import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import TenantContext from 'idserver/TenantContext';
import { makeStyles, useTheme, withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';
import { AppPaletteColor, getContrastColor } from 'general/helpers/ui/AppStyleHelper';

export enum CopyrightColorOption {
  contrastBackground = 0,
  contrastPortalBackground = 1,
}

interface CopyrightProps extends WithStyles<typeof AppStyle> {
  colorOption?: CopyrightColorOption;
}

const useStylesCopyright = (colorOption: CopyrightColorOption) => {
  const theme = useTheme();
  let typoColor = '';

  if (colorOption === CopyrightColorOption.contrastPortalBackground) {
    typoColor = getContrastColor(AppPaletteColor.portalBackground, theme);
  } else {
    typoColor = getContrastColor(AppPaletteColor.backgroundDefault, theme);
  }

  return makeStyles({
    copyrightTypography: {
      color: typoColor,
    },
  })();
}

function Copyright(props: CopyrightProps) {
  const { colorOption } = props;
  const classesCopyright = useStylesCopyright(colorOption ?? CopyrightColorOption.contrastBackground);
  const tenantContext = useContext(TenantContext);

  return (
    <Typography variant="body2" align="center" className={classesCopyright.copyrightTypography}>
      {'Copyright © '}
      {tenantContext.tenant.name}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default withStyles(AppStyle)(Copyright);