import React, { useState } from 'react';
import { Controller, SetValueConfig, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import ManageUserRightsService from 'manage/users/helpers/ManageUserRightsService';
import { UserRightsProps } from 'manage/users/views/UserRights';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxBlankIcon, IndeterminateCheckBox as IndeterminateCheckBoxIcon } from '@material-ui/icons';
import EditActionButtonsGroup from 'general/components/common/EditActionButtonsGroup';
import UserPaperTitle, { UserPaperTitleOption } from 'manage/users/components/UserPaperTitle';
import ManageUserHasRightsView, { ManageUserHasRightsViewRow } from 'manage/users/models/ManageUserHasRightsView';
import ManageRight, { ManageUserHasRight } from 'manage/users/models/ManageRight';
import { CustomTable, CustomTableHeadRow, CustomTableHeadCell, CustomTableRow, CustomTableCell, AlignX, CustomTableEditRowCheckbox, CustomCheckboxStyle } from 'general/components/customTable';
import ButtonGroupContainer from 'general/components/container/ButtonGroupContainer';
import BoxedAlert from 'general/components/common/BoxedAlert';

interface UserRightsEditProps extends UserRightsProps {
  rightsData: ManageUserHasRightsView | undefined;
  userRightsService: ManageUserRightsService;
  onSaved: () => void;
  onCanceled: () => void;
}

function UserRightsEdit(props: UserRightsEditProps) {
  const { classes, user, rightsData, userRightsService, onSaved, onCanceled } = props;
  const { t } = useTranslation(['manage']);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { getValues, trigger, control, setValue, reset } =
    useForm({
      defaultValues: rightsData
    });

  const { fields } = useFieldArray({
    control,
    name: "rights"
  });

  async function saveAsync() {
    setErrorMessage('');

    if (!await trigger()) return;

    try {
      setSaveIsLoading(true);

      const data = getValues();
      const rights = data.rights?.map((r) => {
        const ur = new ManageUserHasRight();
        ur.subjectId = user?.subjectId;
        ur.isAllowed = r.userIsAllowed;
        ur.right = new ManageRight();
        ur.right.rightId = r.right?.rightId;
        return ur;
      }) ?? new Array<ManageUserHasRight>();

      // Send changed data
      await userRightsService.createOrUpdateRights(rights);

      // Go back to view mode
      onSaved();

    } catch (error) {
      if ((error?.response?.status ?? 0) === 400) {
        setErrorMessage(t(`manage:userDetails.rightsTab.error.${error?.response?.data?.code}`));
      } else {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      }
      setSaveIsLoading(false);
    }
  }

  return (
    <>
      <Box className={classes.portalPaperTitleBox}>
        <UserPaperTitle option={UserPaperTitleOption.rights} />
        <EditActionButtonsGroup
          editMode={true}
          onSaveButtonClick={saveAsync}
          onCancelButtonClick={onCanceled}
          disabled={saveIsLoading}
        />
      </Box>
      <Box className={classes.portalPaperContentBox}>
        <BoxedAlert message={errorMessage} severity="error" paddingBottom={2} />
        <RightsTable fields={fields} control={control}
          setValue={setValue} reset={reset} getValues={getValues}
          translation={t} />
      </Box>
    </>
  );
}

interface RightsTableProps {
  fields: any;
  control: any;
  setValue: (name: string, value: unknown, config?: SetValueConfig) => void;
  reset: any;
  getValues: any;
  translation: TFunction;
}

interface ManageUserHasRightEditRow extends ManageUserHasRightsViewRow {
  id: string;
}

function RightsTable(props: RightsTableProps) {
  const { fields, control, setValue, getValues, reset } = props;
  const t = props.translation;

  const columns = {
    name: {
      width: 200,
    },
    userIsAllowed: {
      width: 110,
      alignX: AlignX.center,
    },
    description: {
      width: 600,
    },
  };

  function setIsAllowed(isAllowed: boolean | null) {
    // Set value to all fields
    fields?.forEach((groupHasRight: ManageUserHasRightEditRow, index: number) => {
      setValue(`rights[${index}].userIsAllowed`, isAllowed);
    });

    // Get current values
    const data = getValues();

    // Rerender view with current values
    reset({ rights: data.rights });
  }

  return (
    <>
      <Box mt={-2} mb={1}>
        <ButtonGroupContainer>
          <ButtonGroup disableElevation variant="contained" color="default">
            <Button onClick={() => setIsAllowed(true)} startIcon={<CheckBoxIcon />}>
              {t('manage:rights.buttons.allowAll')}
            </Button>
            <Button onClick={() => setIsAllowed(null)} startIcon={<IndeterminateCheckBoxIcon />}>
              {t('manage:rights.buttons.setAllUndefined')}
            </Button>
            <Button onClick={() => setIsAllowed(false)} startIcon={<CheckBoxBlankIcon />}>
              {t('manage:rights.buttons.disallowAll')}
            </Button>
          </ButtonGroup>
        </ButtonGroupContainer>
      </Box>
      {fields !== null ? <CustomTable
        fixedTableHeader={true}
        headerChildren={
          <CustomTableHeadRow>
            <CustomTableHeadCell width={columns.name.width}>{t('manage:manageRight.name')}</CustomTableHeadCell>
            <CustomTableHeadCell width={columns.userIsAllowed.width} alignX={columns.userIsAllowed.alignX}>{t('manage:manageRight.isAllowed')}</CustomTableHeadCell>
            <CustomTableHeadCell width={columns.description.width}>{t('manage:manageRight.description')}</CustomTableHeadCell>
          </CustomTableHeadRow>}>
        {fields.map((userHasRight: ManageUserHasRightEditRow, index: number) => {
          if (index === 0) console.log('Render RightsTable');
          return (
            <CustomTableRow key={userHasRight?.id}>
              <Controller
                name={`rights[${index}].right.rightId`}
                control={control}
                defaultValue={userHasRight?.right?.rightId}
                render={() => (<></>)}
              />
              <Controller
                name={`rights[${index}].right.name`}
                control={control}
                defaultValue={userHasRight?.right?.name}
                render={() => (<CustomTableCell width={columns.name.width}>{userHasRight?.right?.name}</CustomTableCell>)}
              />
              <CustomTableCell width={columns.userIsAllowed.width} alignX={columns.userIsAllowed.alignX} isCheckbox={true}>
                <CustomTableEditRowCheckbox
                  name={`rights[${index}].userIsAllowed`}
                  control={control}
                  defaultValue={userHasRight?.userIsAllowed}
                  isIndeterminateEnabled={true}
                  customStyle={{ useCustomColor: true } as CustomCheckboxStyle}
                />
              </CustomTableCell>
              <Controller
                name={`rights[${index}].right.description`}
                control={control}
                defaultValue={userHasRight?.right?.description}
                render={() => (<CustomTableCell width={columns.description.width}>{userHasRight?.right?.description}</CustomTableCell>)}
              />
            </CustomTableRow>);
        })}
      </CustomTable> : null}
    </>
  );
}

export default UserRightsEdit;