import React from 'react';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';

interface DefaultPaperProps extends WithStyles<typeof AppStyle> {
  children: React.ReactNode
}

function DefaultPaper(props: DefaultPaperProps) {
  const classes = props.classes;

  return (
    <div className={classes.defaultPaper}>
      {props.children}
    </div>
  );
}

export default withStyles(AppStyle)(DefaultPaper);