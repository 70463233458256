import LangProvider from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import UrlHelper from 'general/helpers/UrlHelper';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';

LangProvider
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		// files to load
		ns: ['account', 'app', 'common', 'general', 'glossary', 'idserver',
			'validation', 'manage', 'company'],

		// default namespace (needs no prefix on calling t)
		defaultNS: 'app',

		// fallback, can be a string or an array of namespaces
		fallbackNS: 'common',

		// fallback to one language
		fallbackLng: 'en',

		// Language codes to lookup 
		// en-US->en, de-DE->de
		load: 'languageOnly',

		backend: {
			// path where resources get loaded from
			loadPath: `${UrlHelper.getBaseUrl()}/locales/{{lng}}/{{ns}}.json`,
		},

		debug: false,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		}
	});

LangProvider
	// configure moment dateTime formatter
	.on('languageChanged', (lng: string) => {
		console.log('languageChanged to ' + lng);
		moment.locale(lng);
	})

export default LangProvider;