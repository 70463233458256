//import * as createPalette from '@material-ui/core/styles/createPalette';
import { Theme, getContrastRatio, decomposeColor, lighten, darken } from '@material-ui/core/styles';
import { PaletteColor, PaletteColorOptions } from '@material-ui/core/styles/createPalette';

// Add custom theme options
// Sources: https://javascript.plainenglish.io/extend-material-ui-theme-in-typescript-a462e207131f
//          https://mui.com/customization/palette/
declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    portalBackground?: PaletteColorOptions;
    //portalBackground?: PaletteOptions['primary'];
  }

  interface Palette {
    portalBackground: PaletteColor;
    //portalBackground: Palette['primary'];
  }
}

export enum AppPaletteColor {
  primary = 0,
  secondary = 1,
  backgroundDefault = 2,
  backgroundPaper = 3,
  portalBackground = 4,
}

function getSelectedColor(paletteColor: AppPaletteColor, theme: Theme): PaletteColor {
  let selectedColor: PaletteColor;
  switch (paletteColor) {
    case AppPaletteColor.secondary:
      selectedColor = theme.palette.secondary;
      break;
    case AppPaletteColor.backgroundDefault:
      selectedColor = getPaletteColor(theme.palette.background.default);
      break;
    case AppPaletteColor.backgroundPaper:
      selectedColor = getPaletteColor(theme.palette.background.paper);
      break;
    case AppPaletteColor.portalBackground:
      selectedColor = theme.palette.portalBackground;
      break;
    default:
      selectedColor = theme.palette.primary;
      break;
  }
  return selectedColor ?? {
    light: "#fff",
    main: "#646a6e",
    dark: "#000",
    contrastText: "#fff",
  } as PaletteColor;
}

function getContrastColor(paletteColor: AppPaletteColor, theme: Theme): string {
  const selectedColor = getSelectedColor(paletteColor, theme);
  //console.log('selectedColor', selectedColor);
  //console.log('selectedColor light', selectedColor.light);
  //console.log('selectedColor dark', selectedColor.dark);
  const lightColor = selectedColor.light ?? "#fff";
  const darkColor = selectedColor.dark ?? "#000";
  const ratioLight = getContrastRatio(lightColor, selectedColor.main);
  const ratioDark = getContrastRatio(darkColor, selectedColor.main);
  //console.log('getContrastColor ratioLight', ratioLight);
  //console.log('getContrastColor ratioDark', ratioDark);
  if (ratioLight > ratioDark) {
    return lightColor;
  } else {
    return darkColor;
  }
}

function getContrastTextColorFaded(paletteColor: AppPaletteColor, theme: Theme, alpha: number): string {
  const selectedColor = getSelectedColor(paletteColor, theme);
  const contrastTextObj = decomposeColor(selectedColor.contrastText);
  return `rgba(${contrastTextObj.values[0]}, ${contrastTextObj.values[1]}, ${contrastTextObj.values[2]}, ${alpha})`;
}

function getContrastTextColor(main: string, lightText?: string, darkText?: string): string {
  const lightColor = lightText ?? '#fff';
  const darkColor = darkText ?? '#000';
  const ratioLight = getContrastRatio(main, lightColor);
  const ratioDark = getContrastRatio(main, darkColor);
  if (ratioLight > ratioDark) {
    return lightColor;
  } else {
    return darkColor;
  }
}

function getPaletteColor(main: string | undefined, contrastCoeff?: number,
  lightText?: string, darkText?: string): PaletteColor {
  const mainColor = main ?? '#fff';
  const coeff = contrastCoeff ?? 0.4;
  return {
    main: mainColor,
    light: lighten(mainColor, coeff),
    dark: darken(mainColor, coeff),
    contrastText: getContrastTextColor(mainColor, lightText, darkText),
  } as PaletteColor
}

function getPaletteColorOptions(main: string | undefined, contrastCoeff?: number,
  lightText?: string, darkText?: string): PaletteColorOptions {
  const color = getPaletteColor(main, contrastCoeff, lightText, darkText);
  return {
    main: color.main,
    light: color.light,
    dark: color.dark,
    contrastText: color.contrastText,
  } as PaletteColorOptions
}

/* Not working => use createColor from material-ui-color package
function makeHexColor(value: string | undefined, defaultValue?: string | undefined) {
  defaultValue = defaultValue ?? '#fff';
  let colorValue = value ?? defaultValue;

  const regex = /#(?:[0-9a-fA-F]{3}){1,2}/
  const colorChecked = regex.exec(colorValue)
  if (!colorChecked) return defaultValue;
  return colorChecked.toString();
}
*/

export { getSelectedColor, getContrastColor, getContrastTextColorFaded, getContrastTextColor, getPaletteColorOptions };