import React from 'react';
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import AppStyle from "general/styles/AppStyle";
import { useTranslation } from 'react-i18next';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

export enum GroupPaperTitleOption {
	basicData,
	rights,
	rightsConfig,
}

export interface GroupPaperTitleProps extends WithStyles<typeof AppStyle> {
	option: GroupPaperTitleOption
}

function GroupPaperTitle(props: GroupPaperTitleProps) {
	const { classes, option } = props;
	const { t } = useTranslation(['company']);

	function IconElement() {
		switch (option) {
			case GroupPaperTitleOption.basicData: {
				return (<GroupOutlinedIcon className={classes.titleAvatar} />);
			}
			case GroupPaperTitleOption.rights: {
				return (<LockOutlinedIcon className={classes.titleAvatar} />);
			}
			case GroupPaperTitleOption.rightsConfig: {
				return (<LockOutlinedIcon className={classes.titleAvatar} />);
			}
			default: {
				return (null);
			}
		}
	}

	function TitleElement() {
		let titleKey: string;
		switch (option) {
			case GroupPaperTitleOption.basicData: {
				titleKey = 'company:groupDetails.paperTitleBasicData';
				break;
			}
			case GroupPaperTitleOption.rights: {
				titleKey = 'company:groupDetails.paperTitleRights';
				break;
			}
			case GroupPaperTitleOption.rightsConfig: {
				titleKey = 'company:groupRightsConfig.paperTitleRights';
				break;
			}
			default: {
				titleKey = 'paperNoTitle';
				break;
			}
		}
		return (
			<Typography component="h2" variant="h6" >
				{t(titleKey)}
			</Typography>);
	}

	return (
		<>
			<IconElement />
			<TitleElement />
		</>
	);
}

export default withStyles(AppStyle)(GroupPaperTitle);