import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Grid, TextField } from '@material-ui/core';
import { TenantDetailsDesignProps } from './TenantDetailsDesign';
import CompanyTenantTheme from '../models/CompanyTenantTheme';
import InputActionButtonsGroup from 'general/components/common/InputActionButtonsGroup';
import { HorizontalAlignment } from 'general/helpers/ui/ComponentsHelper';
import CompanyTenantThemesService from '../helpers/CompanyTenantThemesService';
import FormColorPicker from 'general/components/common/FormColorPicker';
import TenantContext, { TenantTheme } from 'idserver/TenantContext';
import { Color } from 'material-ui-color';

export interface TenantDetailsDesignEditProps extends TenantDetailsDesignProps {
  theme: CompanyTenantTheme | undefined;
  tenantThemesService: CompanyTenantThemesService;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  onSaved: (saved: boolean) => void;
}

type ThemeFormInputs = {
  name: string;
  primaryColor: string;
  secondaryColor: string;
  portalBackgroundColor: string;
}

function TenantDetailsDesignEdit(props: TenantDetailsDesignEditProps) {
  const { tenant, theme, tenantThemesService, isLoading, setIsLoading, setErrorMessage, onSaved } = props;
  const { t } = useTranslation(['company']);
  const tenantContext = useContext(TenantContext);
  const editTheme = useRef<CompanyTenantTheme | undefined>();
  const { register, getValues, trigger, errors, control } = useForm<ThemeFormInputs>({
    defaultValues: {
      name: theme?.name,
      primaryColor: theme?.primaryColor,
      secondaryColor: theme?.secondaryColor,
      portalBackgroundColor: theme?.portalBackgroundColor,
    }
  });

  useEffect(() => {
    console.log('Load theme edit', theme?.themeId);

    // Set theme as current theme
    if (editTheme.current?.themeId !== theme?.themeId) { // Prevents infinity loop
      console.log('Set theme', theme?.themeId);

      // Init editTheme
      editTheme.current = new CompanyTenantTheme();
      editTheme.current.themeId = theme?.themeId;
      editTheme.current.primaryColor = theme?.primaryColor;
      editTheme.current.secondaryColor = theme?.secondaryColor;
      editTheme.current.portalBackgroundColor = theme?.portalBackgroundColor;

      // Set theme for preview
      tenantContext.setTenantTheme({
        primary: theme?.primaryColor,
        secondary: theme?.secondaryColor,
        portalBackground: theme?.portalBackgroundColor
      } as TenantTheme);
    }

  }, [theme, tenantContext]);

  async function saveThemeAsync() {
    setErrorMessage('');

    if (!await trigger()) return;

    const data = getValues();
    const changedTheme = new CompanyTenantTheme();
    changedTheme.themeId = theme?.themeId;
    changedTheme.tenantId = tenant?.tenantId;
    changedTheme.name = data.name;
    changedTheme.primaryColor = data.primaryColor;
    changedTheme.secondaryColor = data.secondaryColor;
    changedTheme.portalBackgroundColor = data.portalBackgroundColor;

    //console.log('saveThemeAsync', changedTheme);

    try {
      setIsLoading(true);

      // Send changed data
      await tenantThemesService.updateTheme(changedTheme);

      // Reload active theme
      tenantContext.resetTenantTheme();

      // Go back to view mode and show success message
      onSaved(true)

    } catch (error) {
      if ((error?.response?.status ?? 0) === 400) {
        setErrorMessage(t(`company:tenantDetails.designTab.errorEdit.${error?.response?.data?.code}`));
      } else {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleSaveEditTheme() {
    console.log('handleSaveEditTheme');
    saveThemeAsync();
  }

  function handleCancelEditTheme() {
    console.log('handleCancelEditTheme');

    // Reload active theme
    tenantContext.resetTenantTheme();

    // Trigger cancel
    onSaved(false)
  }

  enum ChangeColorOption {
    primary = 0,
    secondary = 1,
    portalBackground = 2,
  }

  function handleColorChanged(colorOption: ChangeColorOption, color: Color) {
    if (!editTheme.current) return;

    const hexColor = `#${color?.hex}`;

    switch (colorOption) {
      case ChangeColorOption.primary: editTheme.current.primaryColor = hexColor; break;
      case ChangeColorOption.secondary: editTheme.current.secondaryColor = hexColor; break;
      case ChangeColorOption.portalBackground: editTheme.current.portalBackgroundColor = hexColor; break;
    }

    previewThemeAsync();
  }

  async function previewThemeAsync() {
    tenantContext.setTenantTheme({
      primary: editTheme.current?.primaryColor,
      secondary: editTheme.current?.secondaryColor,
      portalBackground: editTheme.current?.portalBackgroundColor,
    } as TenantTheme);
  }

  const valRequired = t('validation:required');
  const valMaxLength100 = t('validation:maxLength', { maxLength: 100 });
  return (
    <>
      <Grid item xs={12}>
        <InputActionButtonsGroup
          editMode={true}
          onSaveButtonClick={handleSaveEditTheme}
          onCancelButtonClick={handleCancelEditTheme}
          disabled={isLoading}
          horizontalAlignment={HorizontalAlignment.right}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          margin="none"
          fullWidth
          label={t('company:companyTenantTheme.name')}
          name="name"
          autoComplete="name"
          inputRef={register({ required: valRequired, maxLength: { value: 100, message: valMaxLength100 } })}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <FormColorPicker
          label={t('company:companyTenantTheme.primaryColor')}
          name="primaryColor"
          control={control}
          onColorChange={(color: Color) => handleColorChanged(ChangeColorOption.primary, color)} />
      </Grid>
      <Grid item xs={12}>
        <FormColorPicker
          label={t('company:companyTenantTheme.secondaryColor')}
          name="secondaryColor"
          control={control}
          onColorChange={(color: Color) => handleColorChanged(ChangeColorOption.secondary, color)} />
      </Grid>
      <Grid item xs={12}>
        <FormColorPicker
          label={t('company:companyTenantTheme.portalBackgroundColor')}
          name="portalBackgroundColor"
          control={control}
          onColorChange={(color: Color) => handleColorChanged(ChangeColorOption.portalBackground, color)} />
      </Grid>
      {/*
        <Grid item xs={12}>
          <Button variant="contained" color="default"
            onClick={previewThemeClicked}>
            {t('company:tenantDetails.designTab.buttonRefreshPreviewTheme')}
          </Button>
        </Grid>
      */}
    </>
  );
}

export default TenantDetailsDesignEdit;