import BaseResponse from "general/models/api/BaseResponse";
import {
  fileType_image, fileType_bin, fileType_dir,
  imageKey_thumbnail, imageKey_fullscreen, imageKey_original, getApiUrlPortalFileShare
} from "../helpers/Files";

class ContentFile {
  fileId: string | undefined;
  tenantId: string | undefined;
  parentFileId: string | undefined;
  name: string | undefined;
  createDate: Date | undefined;
  modificationDate: Date | undefined;
  fileType: string | undefined;
  fileSize: number | undefined;
  storageFileSize: number | undefined;
  owner: ContentFileOwner | undefined;
  url: string | undefined;
  images: ContentFileImages | undefined;

  constructor(dataFile?: any) {
    if (dataFile) {
      this.fileId = dataFile.fileId;
      this.tenantId = dataFile.tenantId;
      this.parentFileId = dataFile.parentFileId;
      this.name = dataFile.name;
      this.createDate = dataFile.createDate;
      this.modificationDate = dataFile.modificationDate;
      this.fileType = dataFile.fileType;
      this.fileSize = dataFile.fileSize;
      this.storageFileSize = dataFile.storageFileSize;
      this.owner = new ContentFileOwner(dataFile.owner);
      this.url = dataFile.url;
      this.images = new ContentFileImages(dataFile.images);
    }
  }

  get isImage(): boolean {
    return (this.fileType === fileType_image);
  }

  get isDir(): boolean {
    return (this.fileType === fileType_dir);
  }

  get isBin(): boolean {
    return (this.fileType === fileType_bin);
  }

  get preview(): ContentFilePreview {
    const filePreview = new ContentFilePreview();

    let format = '';
    if (this.isImage) {
      if (this.images) {
        if (this.images.hasThumbnailImage) {
          format = imageKey_thumbnail;
        } else if (this.images.hasFullscreenImage) {
          format = imageKey_fullscreen;
        } else if (this.images.hasOriginalImage) {
          format = imageKey_original;
        }
      }
      filePreview.imageUrl = this.downloadUrl + (format !== '' ? '/' + format : '');
      filePreview.imageAlt = this.name;
    } else if (this.isDir) {
      filePreview.iconKey = 'Folder';
    } else if (this.isBin) {
      filePreview.iconKey = 'InsertDriveFileOutlined';
    }
    return filePreview;
  }

  get downloadUrl() {
    return getApiUrlPortalFileShare() + '/' + this.url;
  }
}

class ContentFilePreview {
  imageUrl: string | undefined;
  imageAlt: string | undefined;
  iconKey: string | undefined;
}

class ContentFileOwner {
  subjectId: string | undefined;
  name: string | undefined;

  constructor(dataOwner?: any) {
    if (dataOwner) {
      this.subjectId = dataOwner.subjectId;
      this.name = dataOwner.name;
    }
  }
}

class ContentFileImages {
  hasThumbnailImage: boolean | undefined;
  hasFullscreenImage: boolean | undefined;
  hasOriginalImage: boolean | undefined;

  constructor(dataImages?: any) {
    if (dataImages) {
      this.hasThumbnailImage = dataImages.hasThumbnailImage;
      this.hasFullscreenImage = dataImages.hasFullscreenImage;
      this.hasOriginalImage = dataImages.hasOriginalImage;
    }
  }
}

class ContentFileResult extends ContentFile {
  response: BaseResponse | undefined;

  constructor(dataResult?: any) {
    super(dataResult);
    if (dataResult) {
      this.response = new BaseResponse(dataResult.response);
    }
  }
}

export default ContentFile;
export { ContentFilePreview, ContentFileOwner, ContentFileImages, ContentFileResult }