
export const CustomTableRowCheckboxStyle = {
  fontSize: '14px',
  margin: '-9px 0 -8px -8px',
  padding: '8px 9px 9px 9px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& svg': {
    width: 24,
    height: 24,
  },
}