import { Box, BoxProps, LinearProgress, Typography } from "@material-ui/core";
import React from "react";

export interface LinearProgressBoxWithLabelProps extends BoxProps {
  value: number;
  visible?: boolean;
}

function LinearProgressBoxWithLabel(props: LinearProgressBoxWithLabelProps) {
  const { value, visible } = props;

  let ownBoxProps: BoxProps = {
    display: 'flex',
    alignItems: 'center',
  }

  let clearProps = {
    value: null,
    visible: null,
  }

  return (
    <>
      {(visible ?? true) ?
        <Box {...props} {...ownBoxProps} {...clearProps}>
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={value} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              {`${Math.round(value)}%`}
            </Typography>
          </Box>
        </Box> : null}
    </>
  );
}

export default LinearProgressBoxWithLabel;