import BaseService from "general/helpers/api/BaseService";
import CompanyRight from "company/users/models/CompanyRight";

class CompanyGroupRightsConfigService extends BaseService {
  private _groupId: string | undefined;
  private _serviceUrl = `${this._baseUrlApiPortal}/company/groups`;

  constructor(groupId: string) {
    super();
    this._groupId = groupId;
    this._serviceUrl = `${this._serviceUrl}/${groupId}/rightsConfig`;
  }

  get groupId(): string | undefined {
    return this._groupId;
  }

  public async getRights(hasRight: boolean, lang: string): Promise<Array<CompanyRight>> {
    const resp = await (await this.useApiWithAuth())
      .get(`${this._serviceUrl}?hasRight=${hasRight ? 'true' : 'false'}&lang=${lang}`);
    return resp.data.map((data: any) => {
      return new CompanyRight(data);
    });
  }

  public async addRights(rightIds: Array<string>): Promise<void> {
    await (await this.useApiWithAuth())
      .post(this._serviceUrl, JSON.stringify(rightIds));
  }

  public async removeRights(rightIds: Array<string>): Promise<void> {
    await (await this.useApiWithAuth())
      .put(this._serviceUrl, JSON.stringify(rightIds));
  }
}

export default CompanyGroupRightsConfigService;