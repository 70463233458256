import Oidc, { WebStorageStateStore } from 'oidc-client';
import UrlHelper from 'general/helpers/UrlHelper';
import { AccountUser } from 'account/AccountContext';
import Routes from 'general/helpers/Routes';
import StringHelper from 'general/helpers/types/StringHelper';

export const manageUserBackupKey = 'ContentManage';

class AccountProvider {
  private static _oidcManager: Oidc.UserManager;
  private static get OidcManager(): Oidc.UserManager {
    if (AccountProvider._oidcManager == null) {
      console.log('Init OidcUserManager');
      const baseUrl = UrlHelper.getBaseUrl();
      AccountProvider._oidcManager = new Oidc.UserManager({
        authority: UrlHelper.getApiUrl(),
        client_id: "serviceportalclient",
        redirect_uri: `${baseUrl}${Routes.Account.LoginOidcCallback}`,
        response_type: "code",
        scope: "offline_access openid profile email phone role right serviceportal",
        automaticSilentRenew: true,
        // silent_redirect_uri: `${baseUrl}${Routes.Account.LoggedOut}`,
        monitorSession: false, // deactivated checkSession call => if idserver is logged out, it gets stuck in a loop and triggers 12 login attempts
        //checkSessionInterval: 1000 * 60 * 30, // 30 minutes
        // stopCheckSessionOnError: true,
        post_logout_redirect_uri: `${baseUrl}${Routes.Account.LoggedOut}`,
        userStore: new WebStorageStateStore({ store: localStorage }) // Default used sessionStorage => Relogin on new tabs or windows and problem by logout 
      });
      AccountProvider._oidcManager.events.addUserSignedOut(() => {
        console.log('User signed out'); // Not working anymore, but not needed => monitorSession=false stops event
      });
    }
    return AccountProvider._oidcManager;
  }

  public static get CurrentAfterLoginRedirectUrl(): string | undefined {
    const value = sessionStorage.getItem('app.currentAfterLoginRedirectUrl') ?? '';
    if (value === '') return undefined;
    return value;
  }

  public static set CurrentAfterLoginRedirectUrl(value: string | undefined) {
    sessionStorage.setItem('app.currentAfterLoginRedirectUrl', value ?? '');
  }

  public static async getAccessTokenAsync(): Promise<string | undefined> {
    try {
      let user = await AccountProvider.OidcManager.getUser();
      if (!user) throw new Error('User is null');
      if (!user.access_token) throw new Error('access_token is null');
      if (user.expired) {
        // Try get new access token with existing refresh token
        console.log('AccountProvider access token expired -> try renew with refresh token...');
        user = await AccountProvider.OidcManager.signinSilent();
        if (user?.expired ?? true) {
          console.log('AccountProvider no refresh token or expired -> trigger relogin...');
          throw new Error('access_token expired');
        }
      }
      return user.access_token;
    } catch (error) {
      console.error(error);

      // sign out current invalid user
      await AccountProvider.logoutAsync();

      return undefined;
    }
  }

  public static async loginAsync(): Promise<boolean> {
    try {
      const mgr = AccountProvider.OidcManager;
      const user = await mgr.getUser();
      if (user && user.profile) {
        console.log("User logged in", user.profile);
      } else {
        console.log("User not logged in");
        mgr.signinRedirect();
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public static async handleLoginCallbackAsync(): Promise<AccountUser | null> {
    try {
      const user = await AccountProvider.OidcManager.signinRedirectCallback();
      if (!user) throw new Error('User is null');
      return AccountProvider.getAccountUserFromOidcUser(user);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async logoutAsync(): Promise<boolean> {
    try {
      // Also log off any saved ManageUser session
      await AccountProvider.removeUserBackupAsync(manageUserBackupKey);

      // Logout current user
      await AccountProvider.OidcManager.signoutRedirect();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private static makeBackupUserKey(key: string) {
    return `app.userBackup:${key}`;
  }

  public static async getBackupUserAsync(key: string): Promise<Oidc.User | null> {
    try {
      const userStr = localStorage.getItem(AccountProvider.makeBackupUserKey(key));
      if (userStr && !StringHelper.empty(userStr)) {
        return Oidc.User.fromStorageString(userStr);
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  public static async getBackupAccountUserAsync(key: string): Promise<AccountUser | null> {
    try {
      const user = await AccountProvider.getBackupUserAsync(key);
      if (user) {
        return AccountProvider.getAccountUserFromOidcUser(user);
      }

    } catch (error) {
      console.error(error);
    }
    return null;
  }

  public static async backupCurrentUserAsync(key: string): Promise<void> {
    try {
      const user = await AccountProvider.OidcManager.getUser();
      if (user) {
        localStorage.setItem(AccountProvider.makeBackupUserKey(key), user.toStorageString());
      }
    } catch (error) {
      console.error(error);
    }
  }

  public static async restoreBackupUserAsync(key: string): Promise<void> {
    try {
      // Read backuped user
      const user = await AccountProvider.getBackupUserAsync(key);
      if (!user) return; // Exit if no user backuped

      // Remove backuped user => So that it is no longer displayed
      await AccountProvider.removeUserBackupAsync(key);

      // Set backuped user to current user
      await AccountProvider.OidcManager.storeUser(user);

    } catch (error) {
      console.error(error);
    }
  }

  public static async removeUserBackupAsync(key: string): Promise<void> {
    try {
      localStorage.removeItem(AccountProvider.makeBackupUserKey(key));
    } catch (error) {
      console.error(error);
    }
  }

  public static async removeCurrentUserAsync(): Promise<void> {
    try {
      await AccountProvider.OidcManager.removeUser();
    } catch (error) {
      console.error(error);
    }
  }

  public static async getAccountUserAsync(): Promise<AccountUser | null> {
    try {
      const user = await AccountProvider.OidcManager.getUser();
      if (!user?.profile) return null;

      console.log('User expired', user?.expired);

      return AccountProvider.getAccountUserFromOidcUser(user);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private static getAccountUserFromOidcUser(user: Oidc.User) {
    if (!user) throw new Error('User is null');
    if (!user.profile) throw new Error('User profile is null');

    const a = new AccountUser();
    a.sub = user.profile.sub;
    a.preferred_username = user.profile.preferred_username;
    a.name = user.profile.name;
    a.given_name = user.profile.given_name;
    a.family_name = user.profile.family_name;
    a.email = user.profile.email;
    a.phone_number = user.profile.phone_number;

    // Role can be an array or a single string
    const role = user.profile['role'];
    if (role) {
      if (typeof role === 'string') {
        a.roles = [role]
      } else {
        a.roles = role;
      }
    }

    // Right can be an array or a single string
    const right = user.profile['right'];
    if (right) {
      if (typeof right === 'string') {
        a.rights = [right]
      } else {
        a.rights = right;
      }
    }

    return a;
  }
}

export default AccountProvider;