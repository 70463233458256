import React from 'react';
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import AppStyle from "general/styles/AppStyle";
import { useTranslation } from 'react-i18next';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

export enum UserPaperTitleOption {
	user,
	groups,
	rights
}

export interface UserPaperTitleProps extends WithStyles<typeof AppStyle> {
	option: UserPaperTitleOption
}

function UserPaperTitle(props: UserPaperTitleProps) {
	const { classes, option } = props;
	const { t } = useTranslation(['manage']);

	function IconElement() {
		switch (option) {
			case UserPaperTitleOption.user: {
				return (<AccountCircleOutlinedIcon className={classes.titleAvatar} />);
			}
			case UserPaperTitleOption.groups: {
				return (<GroupOutlinedIcon className={classes.titleAvatar} />);
			}
			case UserPaperTitleOption.rights: {
				return (<LockOutlinedIcon className={classes.titleAvatar} />);
			}
			default: {
				return (null);
			}
		}
	}

	function TitleElement() {
		let titleKey: string;
		switch (option) {
			case UserPaperTitleOption.user: {
				titleKey = 'manage:userDetails.userTab.paperAccountTitle';
				break;
			}
			case UserPaperTitleOption.groups: {
				titleKey = 'manage:userDetails.groupsTab.paperGroupsTitle';
				break;
			}
			case UserPaperTitleOption.rights: {
				titleKey = 'manage:userDetails.rightsTab.paperRightsTitle';
				break;
			}
			default: {
				titleKey = 'paperNoTitle';
				break;
			}
		}
		return (
			<Typography component="h2" variant="h6" >
				{t(titleKey)}
			</Typography>);
	}

	return (
		<>
			<IconElement />
			<TitleElement />
		</>
	);
}

export default withStyles(AppStyle)(UserPaperTitle);