import React from "react";
import { PropsWithChildren, ReactElement } from "react";
import { useStyles } from 'general/components/customDataGrid/styles/TableStyles'
import { CSSProperties } from "react";

export interface CustomTableHeadRowProps { }

export function CustomTableHeadRow(props: PropsWithChildren<CustomTableHeadRowProps>): ReactElement {
  const { children } = props;
  const classes = useStyles();

  const headRowStyle = {
    display: 'flex',
    flex: '1 0 auto',
    minWidth: '100px',
  } as CSSProperties

  return (
    <div style={headRowStyle} className={classes.tableHeadRow}>
      {children}
    </div>
  );
}