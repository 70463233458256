import { AxiosInstance } from "axios";
import ApiHelper, { ApiConfigParams } from "general/helpers/api/ApiHelper";

class BaseService {
  protected _apiHelper: ApiHelper;
  protected _baseUrlApiPortal = '/apiportal';

  public constructor(config?: ApiConfigParams, baseUrlApiPortal?: string) {
    this._apiHelper = new ApiHelper(config);
    if (baseUrlApiPortal) this._baseUrlApiPortal = baseUrlApiPortal;
  }

  protected useApi(): AxiosInstance {
    return this._apiHelper.Api;
  }

  protected async useApiWithAuth(): Promise<AxiosInstance> {
    return this._apiHelper.useAuth();
  }
}

export default BaseService;