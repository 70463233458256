import { createStyles, lighten, Theme } from '@material-ui/core/styles';
import { AppPaletteColor, getContrastColor, getContrastTextColorFaded } from 'general/helpers/ui/AppStyleHelper';
import { AppDrawerWidth } from 'general/styles/AppStyle';

const drawerCloseButtonWidth = 40;
const drawerItemHoverFocus = {
	backgroundColor: 'rgba(255, 255, 255, 0.3)',
};

const getDrawerItemColor = (theme: Theme) => {
	return getContrastTextColorFaded(AppPaletteColor.secondary, theme, 0.7);
}

const AppNavDrawerStyle = (theme: Theme) => {
	const backgroundContrastColor = getContrastColor(AppPaletteColor.secondary, theme);
	const backgroundLightedColor = lighten(theme.palette.secondary.main, 0.05);
	const foregroundColor = getDrawerItemColor(theme);
	const foregroundHeaderColor = theme.palette.secondary.contrastText;

	return createStyles({
		categoryHeader: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			backgroundColor: backgroundLightedColor,
		},
		categoryHeaderPrimary: {
			color: foregroundHeaderColor,
		},
		drawer: {
			width: AppDrawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: AppDrawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9) + 1,
			},
		},
		drawerToggleButton: {
			width: drawerCloseButtonWidth,
			color: foregroundColor,
			'&:hover,&:focus': drawerItemHoverFocus,
		},
		drawerBrandContainer: {
			width: '100%',
			display: 'flex',
			flexWrap: 'nowrap',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		drawerBrandText: {
			flex: '0 1 auto',
			maxWidth: AppDrawerWidth - drawerCloseButtonWidth - theme.spacing(3),
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		drawerCloseButtonContainer: {
			flex: '0 1 auto',
			padding: theme.spacing(0, 1, 0, 0),
			marginRight: theme.spacing(2) * -1,
		},
		drawerOpenButtonContainer: {
			padding: theme.spacing(0),
		},
		item: {
			paddingTop: 1,
			paddingBottom: 1,
			backgroundColor: backgroundLightedColor,
			color: foregroundColor,
			'&:hover,&:focus': drawerItemHoverFocus,
		},
		itemDrawerClose: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			backgroundColor: backgroundLightedColor,
			color: foregroundColor,
			'&:hover,&:focus': drawerItemHoverFocus,
		},
		itemCategory: {
			backgroundColor: theme.palette.secondary.main,
			boxShadow: `0 -1px 0 ${backgroundContrastColor} inset`,
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
		itemAccount: {
			paddingLeft: '6px',
		},
		firebase: {
			fontSize: 24,
			color: foregroundHeaderColor,
		},
		itemActiveItem: {
			color: backgroundContrastColor,
		},
		itemPrimary: {
			fontSize: 'inherit',
		},
		itemIcon: {
			minWidth: 'auto',
			marginRight: theme.spacing(2),
		},
		itemIconDrawerClose: {
			minWidth: 'auto',
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		dividerBox: {
			height: theme.spacing(2),
			backgroundColor: backgroundLightedColor,
		},
		dividerBoxDrawerClose: {
			height: theme.spacing(0),
		},
	});
}

export default AppNavDrawerStyle;
export { getDrawerItemColor, drawerItemHoverFocus };