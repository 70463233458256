import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';
import ResponsiveDialog, { DialogResult } from 'general/components/common/ResponsiveDialog';
import CompanyGroup from 'company/users/models/CompanyGroup';
import CompanyUserGroupsService from 'company/users/helpers/CompanyUserGroupsService';
import { Box } from '@material-ui/core';
import BoxedAlert from 'general/components/common/BoxedAlert';
import { CustomDataGrid } from 'general/components/customDataGrid/CustomDataGrid';

export interface UserGroupsAddDialogProps {
  open: boolean;
  onClose: (dialogResult: DialogResult, selectedGroups: Array<CompanyGroup> | undefined) => void;
  userGroupsService: CompanyUserGroupsService;
}

function UserGroupsAddDialog(props: UserGroupsAddDialogProps) {
  const { open, onClose, userGroupsService } = props;
  const { t } = useTranslation(['company']);
  const [groupRows, setGroupRows] = useState(new Array<CompanyGroup>());
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const tableInstance = useRef<TableInstance<CompanyGroup> | undefined>(undefined);

  useEffect(() => {
    // Load groups
    getGroupsAsync(open, userGroupsService);
  }, [open, userGroupsService]);

  async function getGroupsAsync(open: boolean, currentService: CompanyUserGroupsService) {
    if (open && currentService.subjectId) {
      console.log('AddDialog load user groups');
      setErrorMessage('');
      setIsLoading(true);
      try {
        const groups = await currentService.getGroups(false);
        setGroupRows(groups);
      } catch (error) {
        console.error(error);
        setErrorMessage(error?.message ?? '');
      } finally {
        // Reset
        setIsLoading(false);
      }
    }
  }

  async function handleDialogClose(dialogResult: DialogResult) {
    if (dialogResult === DialogResult.ok) {
      const selectedGroups = new Array<CompanyGroup>();
      const selectedRows = tableInstance?.current?.selectedFlatRows;
      if (selectedRows) {
        selectedRows.forEach((groupRow: Row<CompanyGroup>) => {
          selectedGroups.push(new CompanyGroup(groupRow.original));
        });
      }
      onClose(dialogResult, selectedGroups);
    }
    else {
      onClose(dialogResult, undefined);
    }
  }

  const groupColumns = useMemo(() => {
    console.log('AddDialog init group columns');
    return [
      {
        Header: 'companyUserGroupsAdd',
        HideHeader: true,
        columns: [
          {
            Header: t('company:companyGroup:groupOrder'),
            accessor: 'groupOrder',
            width: 120,
          },
          {
            Header: t('company:companyGroup:groupName'),
            accessor: 'groupName',
            width: 300,
          },
          {
						Header: t('company:companyGroup:tenants'),
						accessor: 'tenants',
						width: 150,
					},
        ]
      },
    ];
  }, [t]);

  return (
    <ResponsiveDialog
      open={open}
      onClose={handleDialogClose}
      title={t('company:userDetails.groupsTab.userGroupsAddDialog.title')}
      buttonOkContent={t('company:userDetails.groupsTab.userGroupsAddDialog.buttonOk')}
      maxWidth="md"
      fullWidth={true}>
      <Box>
        <BoxedAlert message={errorMessage} severity="error" />
        <CustomDataGrid<CompanyGroup>
          name="companyUserGroupsAdd"
          isLoading={isLoading}
          columns={groupColumns}
          data={groupRows}
          disableRowSelection={false}
          onInitialized={(instance: TableInstance<CompanyGroup> | undefined) => tableInstance.current = instance}
        />
      </Box>
    </ResponsiveDialog>
  );
}

export default UserGroupsAddDialog;