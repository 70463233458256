import BaseRight, { BaseGroupHasRight, BaseUserHasRight } from "./BaseRight";

class CompanyRight extends BaseRight {

  constructor(dataRight?: any) {
    if (dataRight) {
      super(dataRight);
    } else {
      super();
    }
  }
}

class CompanyUserHasRight extends BaseUserHasRight {

  constructor(dataUserRight?: any) {
    if (dataUserRight) {
      super(dataUserRight);
    } else {
      super();
    }
  }
}

class CompanyGroupHasRight extends BaseGroupHasRight {

  constructor(dataGroupRight?: any) {
    if (dataGroupRight) {
      super(dataGroupRight);
    } else {
      super();
    }
  }
}

export default CompanyRight;
export { CompanyUserHasRight, CompanyGroupHasRight };