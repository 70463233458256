import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import AppStyle from 'general/styles/AppStyle';
import IconActionButton from 'general/components/common/IconActionButton';
import { Add as AddIcon, Edit as EditIcon, FileCopy as CopyIcon, Delete as DeleteIcon, SaveOutlined as SaveIcon, CancelOutlined as CancelIcon } from '@material-ui/icons';
import { getJustifyContentValue, HorizontalAlignment } from 'general/helpers/ui/ComponentsHelper';

export interface InputActionButtonsGroupProps extends WithStyles<typeof AppStyle> {
  editMode: boolean;
  children?: React.ReactNode;
  childrenEditMode?: React.ReactNode;
  disabled?: boolean;
  disabledAdd?: boolean;
  disabledEdit?: boolean;
  disabledCopy?: boolean;
  disabledDelete?: boolean;
  saveButtonType?: "button" | "reset" | "submit" | undefined;
  horizontalAlignment?: HorizontalAlignment | undefined;
  onAddButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onSaveButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onCancelButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onEditButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onCopyButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onDeleteButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}

function InputActionButtonsGroup(props: InputActionButtonsGroupProps) {
  const { editMode, children, childrenEditMode, disabled, disabledAdd, disabledEdit, disabledCopy, disabledDelete,
    saveButtonType, horizontalAlignment, onAddButtonClick, onSaveButtonClick, onCancelButtonClick, onEditButtonClick, 
    onCopyButtonClick, onDeleteButtonClick } = props;
  const { t } = useTranslation(['common']);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      groupIconActionButtons: {
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginLeft: '-12px',
        justifyContent: getJustifyContentValue(horizontalAlignment)
      },
    }),
  );
  const classesElement = useStyles();

  return (
    <Box className={classesElement.groupIconActionButtons}>
      {(editMode) ?
        <>
          <IconActionButton label={t('common:button:save')} icon={<SaveIcon />} disabled={disabled || disabledEdit} type={saveButtonType} onClick={onSaveButtonClick} />
          <IconActionButton label={t('common:button:cancel')} icon={<CancelIcon />} disabled={disabled || disabledEdit} onClick={onCancelButtonClick} />
          {childrenEditMode}
        </>
        :
        <>
          {onAddButtonClick ? <IconActionButton label={t('common:button:add')} disabled={disabled || disabledAdd} icon={<AddIcon />} onClick={onAddButtonClick} /> : null}
          <IconActionButton label={t('common:button:edit')} icon={<EditIcon />} disabled={disabled || disabledEdit} onClick={onEditButtonClick} />
          {onCopyButtonClick ? <IconActionButton label={t('common:button:copy')} disabled={disabled || disabledCopy} icon={<CopyIcon />} onClick={onCopyButtonClick} /> : null}
          {onDeleteButtonClick ? <IconActionButton label={t('common:button:delete')} disabled={disabled || disabledDelete} icon={<DeleteIcon />} onClick={onDeleteButtonClick} /> : null}
          {children}
        </>
      }
    </Box>
  );
}

export default withStyles(AppStyle)(InputActionButtonsGroup);
