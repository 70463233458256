import React from 'react';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

interface PortalPaperProps extends WithStyles<typeof AppStyle> {
  children: React.ReactNode
}

function PortalPaper(props: PortalPaperProps) {
  const classes = props.classes;

  return (
    <Paper className={classes.portalPaper}>
      {props.children}
    </Paper>
  );
}

export default withStyles(AppStyle)(PortalPaper);