import React, { CSSProperties } from "react";
import { PropsWithChildren, ReactElement } from "react";
import { useStyles } from 'general/components/customDataGrid/styles/TableStyles'

export interface CustomTableRowProps {
  id?: string;
}

export function CustomTableRow(props: PropsWithChildren<CustomTableRowProps>): ReactElement {
  const { children, id } = props;
  const classes = useStyles();

  const rowStyle = {
    display: 'flex',
    flex: '1 0 auto',
  } as CSSProperties

  return (
    <div id={id} style={rowStyle} className={classes.tableRow}>
      {children}
    </div>
  );
}