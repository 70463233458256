import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import UrlHelper from 'general/helpers/UrlHelper';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Routes from 'general/helpers/Routes';
import DefaultPaper from 'general/components/container/DefaultPaper';
import { Container, Box, Typography, CircularProgress, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CompanyTenantEmailConfirmRequest } from '../models/CompanyTenantEmailSetting';
import CompanyTenantEmailConfirmService from '../helpers/CompanyTenantEmailConfirmService';

function TenantEmailConfirm() {
	const { t } = useTranslation(['company']);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(true);
	const companyTenantEmailConfirmService = useRef(new CompanyTenantEmailConfirmService());

	useEffect(() => {
		console.log('tenantEmailConfirm triggered');
		confirmAsync(t);
	}, [t]);

	async function confirmAsync(ti: TFunction) {
		try {
			const req = new CompanyTenantEmailConfirmRequest();
			req.token =  UrlHelper.getQueryVariable(window, 'token');
			await companyTenantEmailConfirmService.current.confirmTenantEmail(req);
		} catch (error) {
			console.log('Error Handler here');
			if ((error?.response?.status ?? 0) === 400) {
				setErrorMessage(ti(`company:tenantEmailConfirm.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
		} finally {
			setLoading(false);
		}
	};

	function ConfirmedView() {
		return (
			<>
				<Box margin={2}>
					<Alert severity="success">
						{t('company:tenantEmailConfirm.textEmailConfirmed')}
					</Alert>
				</Box>
				<Button variant="contained" color="default"
					component={Link} to={Routes.Portal.Company.Tenants}>
					{t('company:tenantEmailConfirm.buttonNav')}
				</Button>
			</>
		);
	}

	return (
		<Container component="main" maxWidth="xs">
			<DefaultPaper>
				<Typography component="h1" variant="h5">
					{t('company:tenantEmailConfirm.title')}
				</Typography>
				{loading ? <CircularProgress /> :
					errorMessage === '' ? <ConfirmedView /> : null}
				{errorMessage !== '' ? <Box margin={2}><Alert severity="error">{errorMessage}</Alert></Box> : null}
			</DefaultPaper>
		</Container>
	);
}

export default TenantEmailConfirm;