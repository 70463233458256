import React, { useContext, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PortalPaper from 'general/components/container/PortalPaper';
import AppStyle from 'general/styles/AppStyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Grid, Box, TextField, Button, InputAdornment, IconButton } from '@material-ui/core';
import { ManageUserCreateRequest } from 'manage/users/models/ManageUser';
import ManageUsersService from 'manage/users/helpers/ManageUsersService';
import BoxedAlert from 'general/components/common/BoxedAlert';
import GroupBox from 'general/components/container/GroupBox';
import FormCheckBox from 'general/components/common/FormCheckBox';
import { regExpEmail } from 'general/helpers/ui/ValidationHelper';
import RouteContext from 'general/RouteContext';
import { UserDetailsRouteState } from 'manage/users/views/UserDetails';
import Routes from 'general/helpers/Routes';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import UserPaperTitle, { UserPaperTitleOption } from 'manage/users/components/UserPaperTitle';

export interface UserCreateProps extends WithStyles<typeof AppStyle> { }

function UserCreate(props: UserCreateProps) {
	const classes = props.classes;
	const { t } = useTranslation(['common', 'manage']);
	const [createIsLoading, setCreateIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
	const routeHistory = useHistory();
	const initRouteContext = useRef(useContext(RouteContext)); // useRef prevent double calls
	const usersService = useRef(new ManageUsersService());
	const { register, handleSubmit, watch, errors, control } =
		useForm({
			defaultValues: {
				email: '',
				password: '',
				passwordRepeat: '',
				givenName: '',
				familyName: '',
				name: '',
				phoneNumber: '',
				languageCode: '',
				isRoleAdmin: false,
				isRoleContentManager: false,
				isRoleCompany: false,
				isRoleCompanyEmployee: false,
				isRoleConsumer: false,
				isActive: false,
				isEmailVerified: false,
			}
		});

	async function onSubmit(data: any) {
		setErrorMessage(undefined);
		const request = new ManageUserCreateRequest();
		request.email = data.email;
		request.password = data.password;
		request.passwordRepeat = data.passwordRepeat;
		request.givenName = data.givenName;
		request.familyName = data.familyName;
		request.name = data.name;
		request.phoneNumber = data.phoneNumber;
		request.languageCode = data.languageCode;
		request.isRoleAdmin = data.isRoleAdmin;
		request.isRoleContentManager = data.isRoleContentManager;
		request.isRoleCompany = data.isRoleCompany;
		request.isRoleCompanyEmployee = data.isRoleCompanyEmployee;
		request.isRoleConsumer = data.isRoleConsumer;
		request.isActive = data.isActive;
		request.isEmailVerified = data.isEmailVerified;

		try {
			setCreateIsLoading(true);

			// Send data
			const createdUser = await usersService.current.createUser(request);

			// Set specific state for success message
			const routeState: UserDetailsRouteState = {
				successUserCreatedAlert: {
					visible: true,
					username: createdUser?.getDisplayName()
				}
			};
			initRouteContext.current.setRouteState(Routes.Portal.Manage.UserDetails, routeState);

			//Redirect to newly created user with REPLACE to remove createUser route for goBack navigation in userDetails view
			routeHistory.replace(`${Routes.Portal.Manage.UserDetails}/${createdUser.subjectId}`);
		} catch (error) {
			if ((error?.response?.status ?? 0) === 400) {
				setErrorMessage(t(`manage:userCreate.error.${error?.response?.data?.code}`));
			} else {
				console.error(error);
				setErrorMessage(error?.message ?? '');
			}
			setCreateIsLoading(false);
		};
	}

	const valRequired = t('validation:required');
	const valMaxLengthExceeded = t('validation:maxLengthExceeded');
	const valInvalidEmail = t('validation:invalidEmail');
	const valMinLength8 = t('validation:minLength', { minLength: 8 });
	const valMaxLength10 = t('validation:maxLength', { maxLength: 10 });
	const valMaxLength30 = t('validation:maxLength', { maxLength: 30 });
	const valMaxLength80 = t('validation:maxLength', { maxLength: 80 });
	const valMaxLength100 = t('validation:maxLength', { maxLength: 100 });
	const valpasswordsNotMatch = t('validation:passwordsNotMatch');
	return (
		<PortalPaper>
			<Box className={classes.portalPaperTitleBox}>
				<UserPaperTitle option={UserPaperTitleOption.user} />
			</Box>
			<Box className={classes.portalPaperContentBox}>
				<form className={classes.defaultForm} onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2} className={classes.defaultFormGrid}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('manage:manageUser.email')}
								name="email"
								autoComplete="email"
								autoFocus
								inputRef={register({
									required: valRequired,
									maxLength: { value: 320, message: valMaxLengthExceeded },
									pattern: { value: regExpEmail, message: valInvalidEmail }
								})}
								error={!!errors.email}
								helperText={errors.email ? errors.email.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('manage:manageUser.password')}
								name="password"
								autoComplete="current-password"
								type={showPassword ? "text" : "password"}
								inputRef={register({
									required: valRequired,
									minLength: { value: 8, message: valMinLength8 },
									maxLength: { value: 30, message: valMaxLength30 }
								})}
								error={!!errors.password}
								helperText={errors.password ? errors.password.message : ''}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												onMouseDown={() => setShowPassword(!showPassword)}
												tabIndex={-1}
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('manage:manageUser.passwordRepeat')}
								name="passwordRepeat"
								type={showPasswordRepeat ? "text" : "password"}
								inputRef={register({
									required: valRequired,
									minLength: { value: 8, message: valMinLength8 },
									maxLength: { value: 30, message: valMaxLength30 },
									validate: (value) => value === watch('password') || valpasswordsNotMatch
								})}
								error={!!errors.passwordRepeat}
								helperText={errors.passwordRepeat ? errors.passwordRepeat.message : ''}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
												onMouseDown={() => setShowPasswordRepeat(!showPasswordRepeat)}
												tabIndex={-1}
											>
												{showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('manage:manageUser.givenName')}
								name="givenName"
								autoComplete="givenName"
								inputRef={register({ required: valRequired, maxLength: { value: 100, message: valMaxLength100 } })}
								error={!!errors.givenName}
								helperText={errors.givenName ? errors.givenName.message : ''}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('manage:manageUser.familyName')}
								name="familyName"
								autoComplete="familyName"
								inputRef={register({ required: valRequired, maxLength: { value: 100, message: valMaxLength100 } })}
								error={!!errors.familyName}
								helperText={errors.familyName ? errors.familyName.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('manage:manageUser.name')}
								name="name"
								autoComplete="name"
								inputRef={register({ required: false, maxLength: { value: 100, message: valMaxLength100 } })}
								error={!!errors.name}
								helperText={errors.name ? errors.name.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('manage:manageUser.phoneNumber')}
								name="phoneNumber"
								autoComplete="phoneNumber"
								inputRef={register({ required: false, maxLength: { value: 80, message: valMaxLength80 } })}
								error={!!errors.phoneNumber}
								helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="none"
								fullWidth
								label={t('manage:manageUser.languageCode')}
								name="languageCode"
								autoComplete="languageCode"
								inputRef={register({ required: false, maxLength: { value: 10, message: valMaxLength10 } })}
								error={!!errors.languageCode}
								helperText={errors.languageCode ? errors.languageCode.message : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<GroupBox label={t('manage:manageUser.roles')}>
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<FormCheckBox label={t('idserver:roles.Admin')} name="isRoleAdmin" control={control} />
									</Grid>
									<Grid item xs={12}>
										<FormCheckBox label={t('idserver:roles.ContentManager')} name="isRoleContentManager" control={control} />
									</Grid>
									<Grid item xs={12}>
										<FormCheckBox label={t('idserver:roles.Company')} name="isRoleCompany" control={control} />
									</Grid>
									<Grid item xs={12}>
										<FormCheckBox label={t('idserver:roles.CompanyEmployee')} name="isRoleCompanyEmployee" control={control} />
									</Grid>
									<Grid item xs={12}>
										<FormCheckBox label={t('idserver:roles.Consumer')} name="isRoleConsumer" control={control} />
									</Grid>
								</Grid>
							</GroupBox>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormCheckBox label={t('manage:manageUser.isActive')} name="isActive" control={control} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormCheckBox label={t('manage:manageUser.isEmailVerified')} name="isEmailVerified" control={control} />
						</Grid>
						<BoxedAlert message={errorMessage} severity="error" container="gridItem" />
						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={createIsLoading}
							>
								{createIsLoading ?
									t('loading') :
									t('common:button.create')}
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button fullWidth color="default" onClick={() => routeHistory.goBack()}>
								{t('common:button.cancel')}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</PortalPaper>
	);
}

export default withStyles(AppStyle)(UserCreate);